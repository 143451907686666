import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { convertDate, initialName } from '../../function/function'
// import RejectStamp from "../../../images/reject_stamp_circle.png"
import ViewIcon from '../../../images/view-details.png'
import RejectIcon from '../../../images/remove.png'
import FinishIcon from '../../../images/notepad-green.png'
import reasonIcon from '../../../images/question.png'
import DataStatus from '../../../data/status'
import RptUplodaIcon from '../../../images/rpt_upload.png'
import { baseUrl } from '../../../environment'

let DS = new DataStatus()
class row_web extends Component{
    render(){
        const data = this.props.data
        let formApplicant = data.formApplicant
        let confirmStatus = data.flagStatus
        let idStatus = confirmStatus.id

        let salesUser = formApplicant.salesUser
        let hospital = formApplicant.hospital
        let hospitalName = (hospital) ? hospital.name : formApplicant.hospitalName
        let denyFlagId = DS.statusFlag()[2].id

        let completionFile = data.uploadCompletions
        let sizeCompletion = completionFile.length

        let bookDate = new Date(data.bookingDate)
        let currDate = new Date()

        let startTime = data.startTime
        let reasonLeave = (data.confirmReason) ? data.confirmReason : (data.reasonLeave) ? data.reasonLeave : ""
        let approvedStatusId = DS.statusFlag()[0].id
        let doneStatusId = DS.statusFlag()[1].id

        return(
            <tr>
                <td>
                    <div style={{display: "flex"}}>
                        <div className="pic-dt-td">
                            {initialName(salesUser.name)}
                        </div> {salesUser.name}
                    </div>
                </td>
                <td style={{textAlign: "center"}}>{hospitalName}</td>
                <td style={{textAlign: "center"}}>{formApplicant.contactPerson}</td>
                <td style={{textAlign: "center", color: "#949494"}}>
                    {convertDate(data.bookingDate)}
                    {
                        (startTime != "00:00")
                        ? <><br/>{startTime}</>
                        : ""
                    }
                </td>
                {/* <td style={{textAlign: "center"}}>{convertDate(data.finishActDate)}</td> */}
                <td id="bs-sts-rw">
                    {
                        DS.statusFlag().map(dt => {
                            if(dt.id == idStatus)
                            return< div style={{color: dt.color, fontSize: "12px", borderRadius: "5px", fontWeight: "bold", display: "flex", alignItems: "center"}}>
                                        <img src={dt.icon} style={{width: "20px"}}/>&nbsp;&nbsp;{confirmStatus.name}
                                    </div>
                        })
                    }
                </td>
                <td style={{textAlign: "center", color: "#949494"}}>
                    {
                        (idStatus == denyFlagId)
                        ?
                            <div id="bs-rsn-rw" style={{maxWidth: "120px", 
                                        fontSize: "11px", 
                                        fontWeight: "bold",
                                        padding: "5px", 
                                        border: "1px solid #CCC", 
                                        textAlign: "left", 
                                        margin: "auto", 
                                        borderRadius: "5px"}}>
                                <img src={reasonIcon} style={{width: "15px", position: "absolute", marginLeft: "-15px", marginTop: "-10px"}}/>
                                {(reasonLeave != "") ? <span style={{color: "#000"}}>{reasonLeave}</span> : <span>No reason</span>}
                            </div>
                        :
                            (completionFile.length > 0)
                            ?   
                                <a onClick={() => this.props.previewImage(completionFile)} style={{display: "flex", justifyContent: "center"}}>
                                    <div style={{display: "flex"}}>
                                        <img style={{maxWidth: "40px", maxHeight: "25px", borderRadius: "5px"}} src={baseUrl+completionFile[0].url}/>
                                        {
                                            (sizeCompletion > 1)
                                            ?
                                                <div id="tip-sz-rpt-up" style={{border: "2px solid #CCC", 
                                                            background: "#FFF", 
                                                            fontSize: "11px",
                                                            marginLeft: "-5px",
                                                            marginTop: "-5px", 
                                                            height: "20px", 
                                                            width: "20px", 
                                                            display: "flex", 
                                                            alignItems: "center", 
                                                            borderRadius: "100%",
                                                            justifyContent: "center"}}
                                                >
                                                    {sizeCompletion - 1}+
                                                </div>
                                            :
                                                ""
                                        }
                                    </div>
                                    
                                    
                                </a>
                            :   ""
                    }
                </td>
                <td style={{textAlign: "center"}}>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <Link to={"user_sales/"+formApplicant.formId}>   
                            {/* <button  style={{display: "flex", alignItems: "center",background: "#FFF", border: "none", outline: "none"}}> */}
                                <img src={ViewIcon} style={{width: "20px"}}/>
                                {/* <span style={{fontSize: "10px", color: "#000", fontWeight: "bold"}}>Details</span> */}
                            {/* </button> */}
                        </Link>

                        {
                            (confirmStatus.name != "DENY" && (bookDate > currDate))
                            ?
                                <>
                                &nbsp;
                                <a onClick={() => this.props.reject(data.scheduleId)}>
                                    <img src={RejectIcon} style={{width: "20px"}}/>
                                </a>
                                </>
                            :
                                ""
                        }
                        {
                            (currDate >= bookDate && idStatus == approvedStatusId) 
                            ?   <>&nbsp;<a onClick={() => this.props.finish(data.scheduleId)}><img src={FinishIcon} style={{width: "22px", height: "22px", marginTop: "-2px"}}/></a></>
                            :   (confirmStatus.id == doneStatusId && completionFile.length == 0)
                                ? 
                                    <>
                                        &nbsp;
                                        <a onClick={() => this.props.finish(data.scheduleId)}>
                                            <img src={RptUplodaIcon} style={{width: "22px", height: "22px", marginTop: "-2px"}}/>
                                        </a>
                                    </>
                                :
                                    ""
                        }
                    </div>
                    {/* <Link to={'/dashboard/user_sales/'+formApplicant.formId}>Details</Link> */}
                </td>
            </tr>
        )
    }
}

export default row_web