import React, {useEffect, useState} from 'react'
import Styled from 'styled-components'
import ApprIcon from '../../../images/quality.png'
import DenyIcon from '../../../images/multiply.png'
import DoneIcon from '../../../images/check-blue.png'
import SickIcon from '../../../images/medicine.png'
import FilterAtv from '../../../images/filter-atv.png'
import { useDispatch } from 'react-redux'
import { filterStatusApplicant, setPageDataApplicant } from '../../../redux/action'

const Base = Styled.div`
    background: rgba(0,0,0, 0.4);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
`

const FilterMobile = (props) => {

    // let dataUserSales = useSelector((state) => state.dataUserSales)
    let dispatch = useDispatch();

    const selectFilter = (id, name) => {
        let obj = {
            id: id,
            name: name
        }

        dispatch(filterStatusApplicant(obj))
        dispatch(setPageDataApplicant(1))
        props.hideFilter()
    }

    const hidePopup = (e) => {
        let elm = document.getElementById("flt-mbl-apl-xckk")
        if(elm && !elm.contains(e.target)){
            props.hideFilter()
        }
    }
    
    return(
        <Base onClick={hidePopup}>
            <div id="flt-mbl-apl-xckk" style={{background: "#FFF", width: "200px"}}>
                <div style={{display: "flex", 
                            alignItems: "center", 
                            padding: "10px 20px", 
                            borderBottom: "1px solid #e5e5e5", 
                            background: "#f5f5f5"}}
                >
                    <img src={FilterAtv} style={{width: "20px"}}/>
                    &nbsp;
                    <div style={{fontWeight: "bold", fontSize: "16px"}}>Filter</div>
                </div>
                <div onClick={() => selectFilter("")} 
                    className="menu-hvr-item-item" 
                    style={{padding: "10px 20px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                >
                    <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px", color: "#868686"}}>All Data</div>
                </div>
                <div onClick={() => selectFilter("00000000-0000-0000-0000-100000000050", "Approved")} 
                    className="menu-hvr-item-item" 
                    style={{padding: "10px 20px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                >
                    <img src={ApprIcon} style={{width: "14px"}}/>
                    <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>Approved</div>
                </div>
                
                <div onClick={() => selectFilter("00000000-0000-0000-0000-100000000070", "Done")} 
                    className="menu-hvr-item-item" 
                    style={{padding: "10px 20px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                >
                    <img src={DoneIcon} style={{width: "14px"}}/>
                    <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>Done</div>
                </div>

                <div onClick={() => selectFilter("00000000-0000-0000-0000-100000000060", "Deny")} 
                    className="menu-hvr-item-item" 
                    style={{padding: "10px 20px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                >
                    <img src={DenyIcon} style={{width: "14px"}}/>
                    <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>Deny</div>
                </div>

                <div onClick={() => selectFilter("00000000-0000-0000-0000-100000000030", "Sick")} 
                    className="menu-hvr-item-item" 
                    style={{padding: "10px 20px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                >
                    <img src={SickIcon} style={{width: "14px"}}/>
                    <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>Sick</div>
                </div>
            </div>
        </Base>
    )
}

export default FilterMobile