import React, { Component } from 'react'
import Header from '../header/header_mobile'
import Styled from 'styled-components'
import { baseUrl } from '../../environment/index'

const PicProfile = Styled.div`
    width: 120px;
    height: 120px;
    border-radius: 100%;
    border: 1px solid #f1f1f1;
    background: #FFF;
    overflow: hidden;
    margin: auto;
    margin-top: 50px;
`

const Name = Styled.div`
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    text-align: center;
    margin-top: 15px;
`

const Status = Styled.div`
    max-width: 200px;
    padding: 4px 10px;
    background: #C9E8FB;
    border-radius: 10px;
    display: inline-block;
    align-items: center;
    justify-content: center;
    margin: auto;
`

class profile_mobile extends Component{
    render(){
        return(
            <>
                <Header title="Profile"/>
                <div style={{marginTop: "85px"}}>
                    <div>
                        <PicProfile>
                            <img src={baseUrl+this.props.avatar} style={{width: "100%", height: "100%", objectFit: "contain"}}/>
                        </PicProfile>
                        <Name>{this.props.name}</Name>
                        <div style={{textAlign: "center"}}>
                            <Status>
                                <span style={{color: '#08A0F7'}}>
                                    {this.props.position}
                                </span>
                            </Status>
                        </div>
                    </div>
                    <div id="bs-ipt-prf" style={{padding: "24px"}}>
                        <div style={{marginBottom: "20px"}}>
                            <p style={{color: '#333333', fontSize: "16px", fontWeight: "500", marginBottom: "10px"}}>Your Name</p>
                            <input className="frm-input-mbl" value={this.props.name} style={{width: "100%"}} type="text"/>
                        </div>
                        <div style={{marginBottom: "20px"}}>
                            <p style={{color: '#333333', fontSize: "16px", fontWeight: "500", marginBottom: "10px"}}>Email</p>
                            <input className="frm-input-mbl" style={{width: "100%"}}  value={this.props.email} type="text"/>
                        </div>
                        <div style={{marginBottom: "20px"}}>
                            <p style={{color: '#333333', fontSize: "16px", fontWeight: "500", marginBottom: "10px"}}>Position</p>
                            <input className="frm-input-mbl" style={{width: "100%"}}  value={this.props.position} type="text"/>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default profile_mobile