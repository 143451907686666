import React, { Component } from 'react'
import Styled from 'styled-components'
import { getFullName, getRoleName } from '../../function/read_token'
import { getTime, getDate as getDateFunc, convertDateDDMMYYYString, convertDate, convertDate2 } from '../../function/function'
import { dropDownTypeOfTools, dropDownDemoTerms } from '../data/data'
import IconHsp from '../../../images/hospital-buildings-64.png'
import AddIcon from '../../../images/add_crc.png'
import MinusIcon from '../../../images/minus_crc.png'
import DateFnsUtils from '@date-io/date-fns';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
    TimePicker
  } from '@material-ui/pickers';
import ChoicesHospital from './pop_select_rs_mobile'
import UserSalesWeb from './user_sales_web'
import GreenCheks from '../../../images/green-check-mark.png'
import Spinner from '../../../images/Spinner-0.7s-200px.gif'
import People from '../../../images/user-3-color-64px.png'
import info from '../../../images/question.png'
import DenyIcon from '../../../images/remove.png'
import { baseUrl, baseUrlUpload } from '../../../environment'
import axios from 'axios'
import axios2 from 'axios'
import PopupAddHospital from '../hospitals/popup_add_hospital'

const TitleInput = Styled.div`
    color: "#949494";
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 12px;
`

const ButtonSave = Styled.button`
    width: 206px;
    height: 40px;
    color: #FFF;
    background: #46BD84;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    border: none;
    outline: none;    
`

const BarLoadUpload = Styled.div`
    width: 100px; 
    height: 5px;
    background: #CCC;
    overflow: hidden;
    borderRadius: 4px;
    marginTop: 3px;
    position: relative;S
`
const PercentUploaded = Styled.div`
    background: #4a7c8f;
    height: 100%;
    width: 0%;
    borderRadius: 4px;
`

class user_sales_mobile extends Component{
    constructor(){
        super()
        this.state = {
            applicantSchedules: [],
            salesName: getFullName(),
            rsName: "",
            addressRs: "",
            contactPerson: "",
            start: [],
            startDateDenied : [],
            startDate: new Date(),
            endDate: new Date(),
            kindAct: "demo",
            describe: "",
            upload: "",
            applicantSchedules: [],
            applicantDeniedSchedules: [],
            fileName: "",
            typeTools: "",
            demo: "",
            isTricefy: "yes",
            additionalAcc: "",
            regionId: "",
            srcRs: [],
            rsSelected: null,
            isSearcingRs: false,
            proofAccDocumentId: "",
            isSelectRs: false,
            successUpload: false,
            urlDocument: "",
            visibilityType: [],
            isDownloadProof: false,
            typeDate: "single",
            dateAgg: []
        }

        this.fileInput = React.createRef()
        this.barPercentUpload = React.createRef()
    }

    componentDidMount(){
        /*init data start*/
        let salesWeb = new UserSalesWeb()
        let obj = salesWeb.setDateUserSales()
        let dataStart = []
        dataStart.push(obj)
  
        this.disableInput()
        this.setValue()
        this.setVisibilityTypeId()
    }

    setVisibilityTypeId = async () => {
        let resp = await axios.get("/file/getVisibilityType")
        let result = resp.data
        let data = result.data
        this.setState({
            visibilityType: data
        })
    }

    getTypeId = (type) => {
        let id = ""
        let data = this.state.visibilityType
        for(let i = 0;i<data.length;i++){
            if(type == data[i].name){
                id = data[i].id
            }
        }

        return id
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps != this.props){
            this.disableInput()
        }
    }

    getSalesName = () => {
        let data = this.props.data
        if(data.salesUser){
            let sales = data.salesUser
            let profile = sales.profile
            let fn = profile.firstName
            let ln = profile.lastName
            let full = fn+" "+ln
            return full
        }else{
            return ""
        }
    }

    animateUpload = () => {
        let i = 0;
        let itv = setInterval(() => {
            i++;
            if(i < 100){
                this.barPercentUpload.current.style.width = i+"px"
            }
            
            if(i>=100){
                i = 100
                clearInterval(itv)
                this.setState({successUpload: true})
            } 
        }, 1)
    }

    setValue = () => {
        let id = this.props.id
        let data = this.props.data
        console.log(data)
        this.fileInput.current.value = ""
        // let empId = this.getEmpId(data.userEmpSvc)

        if(id != "form" && data != ""){
            /*set data start date or schedule*/
            let startDate = []
            let formApplicant = data
            let salesUser = formApplicant.salesUser
            let salesName = salesUser.name
            let hospital = formApplicant.hospital
            let rsName = formApplicant.hospitalName
            let kindAct = formApplicant.kindActivity
            let contact = formApplicant.contactPerson
            let typeTools = formApplicant.typeTools
            let additionalAcc = formApplicant.additionalAccessories
            let includeTricefy = formApplicant.includeTricefy
            let describe = formApplicant.describeOther
            // let applicantSchedule = formApplicant.applicantSchedules
            let address = formApplicant.address
            let proofDocument = formApplicant.proofAccDocument
            let fileName = ""
            let proofAccDocumentId = ""
            let urlDocument = ""

            let region = formApplicant.hospital.region;
            let regionId = (region != null) ? region.id : ""

            if(proofDocument != null){
                fileName = proofDocument.name
                proofAccDocumentId = proofDocument.id
                urlDocument = proofDocument.url
            }

            /*set book date*/
            /*set start activity data, data hanta diambil dengan schedule status bukan deny*/
            let applicantSchedules = formApplicant.applicantSchedules
            applicantSchedules.map(dt => {
                let confirmStatus = dt.confirmStatus
                if(confirmStatus.name.toLowerCase() != "deny"){
                    let startTime = (dt.startTime) ? dt.startTime : "07:00" /*defaulr start time : 07:00*/
                    let date = new Date(dt.year+"-"+dt.month+"-"+dt.date+" "+startTime+":00")
                    let userSalesWeb = new UserSalesWeb()
                    let obj = userSalesWeb.setDateUserSales(date)
                    startDate.push(obj)
                }
            })

            /*set start date schudeles denied from applicant*/
            let applicantDeniedSchedules = formApplicant.applicantSchedulesDenied
            let startDateDenied = this.setStartDateDenied(applicantDeniedSchedules)
   
            // console.log("type tools : "+typeTools)
            this.setState({
                salesName: salesName,
                rsSelected: hospital,
                rsName: rsName,
                contactPerson: contact,
                start: startDate,
                startDateDenied: startDateDenied,
                regionId: regionId,
                kindAct: kindAct,
                applicantSchedules: data.applicantSchedules,
                applicantDeniedSchedules: data.applicantSchedulesDenied,
                describe: describe,
                typeTools: typeTools,
                isTricefy: includeTricefy,
                additionalAcc: additionalAcc,
                proofAccDocumentId: proofAccDocumentId,
                fileName: fileName,
                urlDocument: urlDocument,
                addressRs: address,
                applicantSchedules: this.props.data.applicantSchedules
            })
        }else{
            /*set data start date or schedule*/
            let userSalesWeb = new UserSalesWeb()
            let obj = userSalesWeb.setDateUserSales()
            let dataStart = []
            dataStart.push(obj)

            this.setState({
                rsSelected: null,
                contactPerson: "",
                start: dataStart,
                addressRs: "",
                additionalAcc: "",
                describe: "",
                typeTools: dropDownTypeOfTools[0],
                isTricefy: "yes",
                fileName: ""
            })
        }
    }

    setStartDateDenied = (schedules) => {
        let start = []
        schedules.map(dt => {
            let startTime = (dt.startTime) ? dt.startTime : "07:00" /*default start time : 07:00*/
            let date = new Date(dt.year+"-"+dt.month+"-"+dt.date+" "+startTime+":00")
            let userSalesWeb = new UserSalesWeb()
            let obj = userSalesWeb.setDateUserSales(date)
            start.push(obj)
        })
        return start
    }

    selectFileInput = async (e) => {
        let file = e.target.files[0]
        let fname = file.name
        let typeId = this.getTypeId("public")
        this.setState({
            fileName: fname,
            upload: file
        })

        let form = new FormData()
        form.append("file", file)
        form.append("visibilityTypeId", typeId)
        let url = baseUrlUpload
        let dataLogin = await window.localStorage.getItem("token")
        let token;
        
        if (dataLogin != null) {
            dataLogin = JSON.parse(window.localStorage.getItem("token"))
            token = dataLogin.token
        }

        const requestOptions = {
            method: "POST",
            headers: { 'Authorization': "Bearer "+token },
            body: form
        }

        const xhr = new XMLHttpRequest();
        xhr.upload.onload = () => {
            console.log("Completed upload")
        }
        
        xhr.upload.onerror = () => {
            console.error("upload failed")
        }

        xhr.upload.onabort = () => {
            console.error('Upload cancelled.');
        }

        xhr.upload.onprogress = (event) => {
            // event.loaded returns how many bytes are downloaded
            // event.total returns the total number of bytes
            // event.total is only available if server sends `Content-Length` header
            let percent = (event.loaded / event.total) * 100
            let barLoadUpload = this.barPercentUpload.current
            barLoadUpload.style.width = percent+"%"
        }

        xhr.onreadystatechange = () => {
            if (xhr.readyState == XMLHttpRequest.DONE) {
                let resp = JSON.parse(xhr.responseText)
                if(resp.success){
                    let data = resp.data
                    let idFile = data.id
                    let fileName = data.name
                    this.setState({
                        proofAccDocumentId: idFile,
                        fileName: fileName,
                        successUpload: true
                    })
                }
            }
        }

        xhr.open("POST", url+"/file/uploadFile")
        xhr.setRequestHeader("Authorization", "Bearer "+token)
        xhr.send(form)
    }

    uploadFile = async (url, form, config) => {
        let response = await axios2.post(url, form, config)
        return response.data.data
    }

    getEmpId = (data) => {
        if(data){
            return data.id
        }else{
            return ""
        }
    }

    selectFile = () => {
        if(this.props.id == "form"){
            this.fileInput.current.click()
        }
    }

    changeFile = (e) => {
        let file = e.target.files[0]
        let fileName = e.target.files[0].name
        this.setState({
            upload: file,
            fileName: fileName
        })
    }

    save = async () => {
        let isValid = this.inputValidation()
        let isValid2 = this.inputFileValidation()
        let isValid3 = this.validateDateActivity()
        if(!isValid || !isValid2 || !isValid3) return false

        let ste = this.state
        let date = ste.startDate
        let endDate = ste.endDate
        let strDate = getDateFunc(date)
        let strTime = getTime(date)
        let strEndDate = getDateFunc(endDate)
        let dateTimeStr = strDate+" "+strTime
        
        let typeDate = ste.typeDate
        let hasSameApplicant = (typeDate == "single") ? false : true;
        
        let bookDate = [] /*param date for single date type*/ 
        let bookMultipleStart = ""
        let bookMultipleEnd = ""
        if(this.state.typeDate == "single") bookDate = ste.start
        if(this.state.typeDate == "multiple") {
            bookMultipleStart = dateTimeStr
            bookMultipleEnd = strEndDate+" 23:59:00"
        }

        this.props.save(
            ste.rsSelected,
            // ste.rsName,
            ste.contactPerson,
            bookDate,
            ste.kindAct,
            ste.describe,
            ste.typeTools,
            ste.additionalAcc,
            ste.proofAccDocumentId,
            ste.isTricefy,
            ste.addressRs,
            bookMultipleStart,
            bookMultipleEnd,
            hasSameApplicant,
            ste.regionId
        ).then(e => {
            if(e == "success"){
                this.setValue()
            }
        })
    }

    inputValidation = () => {
        let elm = document.getElementsByClassName("frm-input-mbl")
        let isValid = true
        for(let i = 0;i<elm.length;i++){
            let v = elm[i].value
            let isMandatory = elm[i].getAttribute("isMandatory")

            if(v == 0 && isMandatory) {
                if(isMandatory == "true"){
                    let attr = elm[i].getAttribute("attr")
                    if(attr != "address-rs"){
                        elm[i].style.border = "1px solid #F00"
                        isValid = false
                    }
                }
            }else{
                elm[i].style.border = "1px solid #949494"
            }
        }

        /*validation RS Name*/
        let elmRs = document.getElementsByClassName("frm-input-mbl-rs")
        let rsName = this.state.rsSelected
        if(rsName == null){
            elmRs[0].style.border = "1px solid #F00"
            isValid = false
        }else{
            elmRs[0].style.border = "1px solid #949494"
        }
        
        return isValid
    }
    
    inputFileValidation = () => {
        let kinds = this.state.kindAct
        let eId = document.getElementById("ipt-upl-proof")
        let elm = this.fileInput.current
        let v = elm.value

        if(v == 0 && kinds == "others"){
            eId.style.border = "1px solid #F00"
            return false
        }else{
            eId.style.border = "1px solid #CCC"
            return true
        }
    }

    disableInput = () => {
        let id = this.props.id
        let input = document.getElementsByClassName("frm-input-mbl")
        
        for(let i = 0;i<input.length;i++){
            if(id != "form"){
                /*element input applicant*/
                let idElement = input[i].getAttribute("id")
                
                if(idElement != "apl-ipt"){
                    input[i].disabled = true
                    input[i].style.border = "1px solid #CCC"
                }else{
                    if(!this.isManager()){
                        input[i].disabled = true
                        input[i].style.border = "1px solid #CCC"
                    }
                }
            }
        }
    }

    isManager = () => {
        if(getRoleName().toLowerCase() == "director / manager applicant"){
            return true
        }else{
            return false
        } 
    }

    setEmployee = () => {
        let empId = this.state.employeeId
        this.props.setEmployee(empId)
    }

    handleDateChange = (date, index) => {
        let salesWeb = new UserSalesWeb()
        let dateSte = [...this.state.start]
        
        let obj = salesWeb.setDateUserSales(date)
        let startTime = dateSte[index].startTime

        dateSte[index] = obj
        dateSte[index].startTime = startTime

        this.setState({
            start: dateSte
        })
    }

    handleStartTimeChange = (time, index) => {
        let state = [...this.state.start]
        let tm = getTime(time)
        tm = tm.substr(0,5)
        state[index].startTime = tm

        this.setState({
            startTime: state
        })
    }

    handleFinishDateChange = (date) => {
        this.setState({
            finish: date
        })
    }

    handleFinishTimeChange = (time) => {
        this.setState({
            finishTime: time
        })
    }

    selectRs = () => {
        this.setState({
            isSelectRs: !this.state.isSelectRs
        })
    }

    selectingRs = (data) => {
        let address = (data.address != null) ? data.address : ""
        this.setState({
            rsSelected: data,
            addressRs: address,
            isSelectRs: false
        })
    }

    addStartActivity = () => {
        let ste = [...this.state.start]
        let obj = new UserSalesWeb().setDateUserSales(new Date())
        ste.push(obj)

        this.setState({
            start: ste
        })
        
        this.validateDateActivity()
    }

    validateDateActivity = () => {
        let isValidDate = true
        let dateAgg = []
        let dateData = this.state.start
        
        for(let i = 0;i<dateData.length;i++){
            let d = dateData[i].date
            let m = dateData[i].month
            let y = dateData[i].year

            let idx = dateAgg.indexOf(d+m+y)
            if(idx == -1){
                dateAgg.push(d+m+y)
            }else{
                isValidDate = false
                break
            }
        }
        return isValidDate
    }

    removeStartAcitivity = (index) => {
        let ste = [...this.state.start]
        ste.splice(index, 1)
        // console.log(ste)
        
        this.setState({
            start: ste
        })
    }

    downloadFile = async () => {
        let data = this.state.proofAccDocumentId
        let fileName = this.state.fileName
        const FileDownload = require('js-file-download');
        
        this.setState({
            isDownloadProof: true
        })

        axios({
            url: "/file/"+data, //your url
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            FileDownload(response.data, fileName);
            setTimeout(() => { 
                this.setState({
                    isDownloadProof: false
                })
            }, 500)
        });
    }

    adaddHospitals = () => {
        this.setState({
            popup: <PopupAddHospital 
                        save={this.saveHospitals}
                        regions={this.props.region} 
                        cancel={() => this.setState({popup: ""})}/>,
            isSelectRs: false
        })
    }

    saveHospitals = (resp) => {
        let data = resp.data
        this.setState({
            rsSelected: data,
            addressRs: data.address
        })
    }

    selectDate = (idx) => {
        let elm = document.getElementsByClassName("MuiButtonBase-root")
        elm[idx].click()
    }

    selectTime = (idx) => {
        let elm = document.getElementsByClassName("ipt-tim-picker")
        elm[idx].click()
    }

    selectDateStMultiple = () => {
        let elm = document.getElementsByClassName("MuiButtonBase-root")
        elm[0].click()
    }

    selectDateFnMultiple = () => {
        let elm = document.getElementsByClassName("MuiButtonBase-root")
        elm[1].click()
    }

    selectTimeMultiple = () => {
        let elm = document.getElementsByClassName("ipt-tim-picker-mlt")
        elm[0].click()
    }
    
    render(){
        return(
            <>
                {this.state.popup}
                {
                    (this.state.isSelectRs)
                    ? <ChoicesHospital hide={this.selectRs} select={this.selectingRs} addHospitals={this.adaddHospitals}/>
                    : ""
                }
                <div id="wrp-bs-usls" style={{padding: "20px", marginTop: "60px"}}>
                    <div className="bs-input" style={{marginBottom: "20px"}}>
                        <TitleInput>Name / Sales Marketing</TitleInput>
                        <input className="frm-input-mbl" 
                            value={this.state.salesName}
                            disabled="true"
                            isMandatory="true"
                            // onChange={(e) => this.setState({salesName: e.target.value})}
                            placeholder="Name / Sales Marketing" 
                            style={{width: "100%", border: "1px solid #CCC"}}/>
                    </div>

                    <div className="bs-input" style={{marginBottom: "20px"}}>
                        <TitleInput>Rs name / Clinic / Customer</TitleInput>
                        {
                            (this.props.id == "form")
                            ?
                                <a onClick={this.selectRs}><div className="frm-input-mbl-rs" style={Styles.rsInput}>
                                    <img src={IconHsp} style={{width: "20px"}}/>&nbsp;&nbsp;
                                    {
                                        (this.state.rsSelected == null)
                                        ?
                                            <>
                                            <div style={{fontSize: "12px", fontWeight: "bold", color: "#CCC"}}>{"Select Hospital"}</div>
                                            </>
                                        :
                                            <div>
                                                <div style={{fontSize: "10px", fontWeight: "bold", color: "#000"}}>{this.state.rsSelected.name}</div>
                                                <div style={{fontSize: "8px", fontWeight: "bold", color: "#8b8b8b"}}>{this.state.rsSelected.region.name}</div>
                                            </div>
                                    }
                                </div></a>
                            :
                                <div className="frm-input-mbl-rs" style={Styles.rsInput}>
                                    <img src={IconHsp} style={{width: "20px"}}/>&nbsp;&nbsp;
                                    {
                                        (this.state.rsSelected == null)
                                        ?
                                            <>
                                            <div style={{fontSize: "12px", fontWeight: "bold", color: "#CCC"}}>{"Select Hospital"}</div>
                                            </>
                                        :
                                            <div>
                                                <div style={{fontSize: "10px", fontWeight: "bold", color: "#000"}}>{this.state.rsSelected.name}</div>
                                                <div style={{fontSize: "8px", fontWeight: "bold", color: "#8b8b8b"}}>{this.state.rsSelected.region.name}</div>
                                            </div>
                                    }
                                </div>
                        }
                    </div>

                    {/* <div className="bs-input" style={{marginBottom: "20px"}}>
                        <TitleInput>Rs name / Clinic / Customer</TitleInput>
                        <input className="frm-input-mbl" 
                            value={this.state.rsName}
                            onChange={(e) => this.setState({rsName: e.target.value})}
                            placeholder="Rs name / Clinic / Customer" 
                            style={{width: "100%"}}/>
                    </div> */}

                    <div className="bs-input" style={{marginBottom: "20px"}}>
                        <TitleInput>Address RS / Clinic</TitleInput>
                        <input className="frm-input-mbl" 
                            attr="address-rs"
                            disabled
                            value={this.state.addressRs}
                            onChange={(e) => this.setState({addressRs: e.target.value})}
                            placeholder="Address RS / Clinic" 
                            style={{width: "100%"}}/>
                    </div>
                    <div className="bs-input" style={{marginBottom: "20px"}}>
                        <TitleInput>Contact Person</TitleInput>
                        <input className="frm-input-mbl" 
                            value={this.state.contactPerson}
                            onChange={(e) => this.setState({contactPerson: e.target.value})}
                            placeholder="Contact Person" 
                            isMandatory="true"
                            style={{width: "100%"}}/>
                    </div>
                    <div className="bs-input" style={{marginBottom: "20px", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        <div className="bs-input" style={{width: "100%"}}>
                            <div id="bs-input-slc-tp-dt" style={{display: "flex", alignItems: "center", marginBottom: "12px"}}>
                                <TitleInput style={{marginBottom: "0px"}}>Start activity</TitleInput>
                                {
                                    ((this.props.id == "form"))
                                    ?
                                        <select
                                            value={this.state.typeDate}
                                            onChange={(e) => this.setState({typeDate: e.target.value})}
                                            style={{borderRadius: "3px", 
                                                    padding: "3px",
                                                    fontSize: "11px", 
                                                    border: "1px solid #CCC", 
                                                    marginLeft: "20px"}}
                                        >
                                            <option value={"single"}>Single Date</option>
                                            <option value={"multiple"}>Multiple Date</option>
                                        </select>
                                    :
                                        ""
                                }
                            </div>

                            {
                                (this.props.id == "form")
                                ?
                                    (this.state.typeDate == "single")
                                    ?
                                        <div>
                                        {
                                            this.state.start.map((dt, index) => {
                                                let seq = parseInt(index) + 1
                                                let lengthStart = this.state.start.length
                                                let isReadyDate = false

                                                /*validasi start activity*/
                                                /*reset date aggregator in first looping*/
                                                if(index == 0){
                                                    this.state.dateAgg = []
                                                }

                                                let d = dt.date
                                                let m = dt.month
                                                let y = dt.year

                                                let stm = dt.startTime.split(":")
                                                let hr = stm[0]
                                                let mt = stm[1]

                                                let date = m+"/"+d+"/"+y
                                                let time = hr+":"+mt

                                                let idx = this.state.dateAgg.indexOf(d+m+y)
                                                if(idx == -1){
                                                    this.state.dateAgg.push(d+m+y)
                                                }else{
                                                    isReadyDate = true
                                                }

                                                
                                                return <div id="mb-sg-ip-wrp" style={{marginTop: "10px"}}>
                                                            {/* <button onClick={() => this.selectDate(index)}>Test</button> */}
                                                            <div id="mb-sg-ip" style={{display: "flex", alignItems: "center"}}>
                                                                <div className="bs-input"
                                                                    onClick={() => this.selectDate(index)} 
                                                                    style={{height: "40px", 
                                                                            background: "#FFF",
                                                                            fontSize: "14px", 
                                                                            border: "1px solid #CCC", 
                                                                            width: "100%", 
                                                                            borderRadius: "10px", 
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            padding: "0px 15px",
                                                                            marginRight: "20px"}}>
                                                                        {convertDate(date)}
                                                                </div>
                                                                <div className="bs-input" 
                                                                    onClick={() => this.selectTime(index)}
                                                                    style={{height: "35px", 
                                                                            background: "#FFF", 
                                                                            border: "1px solid #CCC", 
                                                                            fontSize: "14px",
                                                                            width: "100px", 
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            padding: "0px 15px",
                                                                            borderRadius: "10px"}}>
                                                                        {time}
                                                                </div>
                                                                
                                                                <div style={{display: "none"}}>
                                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                        <KeyboardDatePicker
                                                                            margin="normal"
                                                                            className="date-picker"
                                                                            label="Date"
                                                                            // format="MM/dd/yyyy"
                                                                            format="MMM dd, yyyy"
                                                                            inputVariant="outlined"
                                                                            value={date}
                                                                            onChange={(date) => this.handleDateChange(date, index)}
                                                                        />
                                                                        &nbsp;
                                                                        <TimePicker
                                                                            style={{width: "120px"}}
                                                                            id="time-picker"
                                                                            clearable
                                                                            className="ipt-tim-picker"
                                                                            ampm={false}
                                                                            label="Time"
                                                                            inputVariant="outlined"
                                                                            margin="normal"
                                                                            value={date}
                                                                            onChange={(e) => this.handleStartTimeChange(e, index)}
                                                                        />
                                                                    </MuiPickersUtilsProvider>
                                                                </div>
                                                                {
                                                                    (lengthStart == seq)
                                                                    ? 
                                                                        <a onClick={this.addStartActivity}>
                                                                            <div style={{marginLeft: "10px", marginTop: "5px"}}>
                                                                                <img 
                                                                                    src={AddIcon}
                                                                                    style={{width: "24px", opacity: "0.4"}}
                                                                                />
                                                                            </div>
                                                                        </a>
                                                                    : 
                                                                        <a onClick={() => this.removeStartAcitivity(index)}>
                                                                            <div style={{marginLeft: "15px"}}>
                                                                                <img src={MinusIcon}
                                                                                    style={{width: "24px", opacity: "0.6", marginTop: "5px"}}
                                                                                />
                                                                            </div>
                                                                        </a>
                                                                }
                                                            </div>
                                                            {
                                                                (isReadyDate)
                                                                ?
                                                                    <div style={{color: "#F00", fontSize: "11px", marginTop: "3px", marginBottom: "5px"}}>
                                                                        This date have been set on previous input
                                                                    </div>
                                                                :
                                                                    ""
                                                            }
                                                        </div>
                                            })
                                        }
                                        </div>
                                    :
                                        <>
                                        <div>
                                            <div style={{fontSize: "11px", color: "#8a8a8a", marginLeft: "10px"}}>Start Date</div>
                                            <div id='fsh-200-dt' style={{display: "flex"}}>
                                                <div className="bs-input"
                                                    onClick={() => this.selectDateStMultiple()} 
                                                    style={{height: "40px", 
                                                            background: "#FFF",
                                                            fontSize: "14px", 
                                                            border: "1px solid #CCC", 
                                                            width: "100%", 
                                                            borderRadius: "10px", 
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: "0px 15px",
                                                            marginRight: "20px"}}>
                                                        {convertDate2(this.state.startDate)}
                                                </div>
                                                <div className="bs-input" 
                                                    onClick={() => this.selectTimeMultiple()}
                                                    style={{height: "40px", 
                                                            background: "#FFF", 
                                                            border: "1px solid #CCC", 
                                                            fontSize: "14px",
                                                            width: "100px", 
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: "0px 15px",
                                                            borderRadius: "10px"}}>
                                                        {getTime(this.state.startDate).substring(0,5)}
                                                </div>
                                                <div style={{display: "none"}}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            margin="normal"
                                                            id="date-picker"
                                                            label="Start Date"
                                                            // format="MM/dd/yyyy"
                                                            style={{width: "100%"}}
                                                            format="MMM dd, yyyy"
                                                            inputVariant="outlined"
                                                            value={this.state.startDate}
                                                            onChange={(date) => this.setState({startDate: date})}
                                                        />
                                                        &nbsp;
                                                        <TimePicker
                                                            style={{width: "120px"}}
                                                            id="time-picker"
                                                            className='ipt-tim-picker-mlt'
                                                            clearable
                                                            ampm={false}
                                                            label="Time"
                                                            inputVariant="outlined"
                                                            margin="normal"
                                                            value={this.state.startDate}
                                                            // value={dt.month+"-"+dt.date+"-"+dt.year+" "+dt.startTime}
                                                            onChange={(date) => this.setState({startDate: date})}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </div>
                                        </div>
                                        <div id='fsh-201-dt' style={{marginTop: "10px"}}>
                                            <div style={{fontSize: "11px", color: "#8a8a8a", marginLeft: "10px"}}>Finish Date</div>
                                            <div className="bs-input"
                                                onClick={() => this.selectDateFnMultiple()} 
                                                style={{height: "40px", 
                                                        background: "#FFF",
                                                        fontSize: "14px", 
                                                        border: "1px solid #CCC",
                                                        borderRadius: "10px", 
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: "0px 15px",
                                                        marginRight: "20px"}}>
                                                    {convertDate2(this.state.endDate)}
                                            </div>
                                            <div style={{display: "none"}}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        margin="normal"
                                                        id="date-picker"
                                                        label="Finish Date"
                                                        style={{width: "100%"}}
                                                        // format="MM/dd/yyyy"
                                                        format="MMM dd, yyyy"
                                                        inputVariant="outlined"
                                                        value={this.state.endDate}
                                                        onChange={(date) => this.setState({endDate: date})}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            
                                        </div>
                                        </>
                                :
                                    /*read only start activity*/
                                    <>
                                    <>
                                    {
                                        this.state.applicantSchedules.map(dt => {
                                            let bookDate = dt.bookingDate
                                            let date = new Date(bookDate.replace(" ", "T"))
                                            
                                            let userApplicant = dt.userApplicant
                                            let userProfile = userApplicant.userProfile
                                            let nameUser = userProfile.name
                                            let emailUser = userProfile.email
                                            return <div style={{borderLeft: "3px solid #d9dbdb", paddingLeft: "10px"}}>
                                                        <div id="ro-sa-usm" 
                                                            style={{display: "flex", marginBottom: "10px"}}>
                                                            <div className="bs-input rd-only"
                                                                style={{height: "40px", 
                                                                        background: "#FFF",
                                                                        fontSize: "14px", 
                                                                        border: "1px solid #CCC", 
                                                                        width: "100%", 
                                                                        borderRadius: "10px", 
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        padding: "0px 15px",
                                                                        marginRight: "20px"}}>
                                                                    {convertDate(bookDate)}
                                                            </div>
                                                            <div className="bs-input" 
                                                                style={{height: "40px", 
                                                                        background: "#FFF", 
                                                                        border: "1px solid #CCC", 
                                                                        fontSize: "14px",
                                                                        width: "100px", 
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        padding: "0px 15px",
                                                                        borderRadius: "10px"}}>
                                                                    {getTime(date).substring(0, 5)}
                                                            </div>
                                                        </div>
                                                        <div id="dsc-rtl-usr-rsn-002" style={{display: "flex", alignItems: "center", marginBottom: "20px"}}>
                                                            <div><img src={People} style={{width: "20px"}}/></div>
                                                            &nbsp;
                                                            <div>
                                                                <div style={{fontSize: "12px", fontWeight: "bold", color: "#000"}}>{nameUser}</div>
                                                                <div style={{fontSize: "9px", fontWeight: "bold", color: "#767676", marginTop: "-3px"}}>{emailUser}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                        })
                                    }
                                    </>
                                    <>
                                    {
                                        this.state.applicantDeniedSchedules.map(dt => {
                                            let bookDate = dt.bookingDate
                                            let date = new Date(bookDate.replace(" ", "T"))

                                            let userApplicant = dt.userApplicant
                                            let userProfile = userApplicant.userProfile
                                            let nameUser = userProfile.name
                                            let emailUser = userProfile.email
                                            let confirmReason = dt.confirmReason
                                            return <div style={{borderLeft: "3px solid #d9dbdb", paddingLeft: "10px"}}>
                                                        <div id="ro-sa-usm" 
                                                            style={{display: "flex", marginBottom: "10px"}}>
                                                            <div className="bs-input rd-only"
                                                                style={{height: "40px", 
                                                                        background: "#FFF",
                                                                        fontSize: "14px", 
                                                                        border: "1px solid #CCC", 
                                                                        width: "100%", 
                                                                        borderRadius: "10px", 
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        padding: "0px 15px",
                                                                        marginRight: "20px"}}>
                                                                    {convertDate(bookDate)}
                                                            </div>
                                                            <div className="bs-input" 
                                                                style={{height: "40px", 
                                                                        background: "#FFF", 
                                                                        border: "1px solid #CCC", 
                                                                        fontSize: "14px",
                                                                        width: "100px", 
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        padding: "0px 15px",
                                                                        borderRadius: "10px"}}>
                                                                    {getTime(date).substring(0, 5)}
                                                            </div>
                                                        </div>
                                                        <div id="dsc-rtl-usr-rsn-001" style={{display: "flex", alignItems: "center", marginBottom: "20px"}}>
                                                            <div id="dny-rtl-ust-rsn-001" style={{position: "relative"}}>
                                                                <img src={People} style={{width: "20px"}}/>
                                                                <img src={DenyIcon} style={{width: "12px", position: "absolute", left: "-3px", marginTop: "10px"}}/>
                                                            </div>
                                                            &nbsp;
                                                            <div>
                                                                <div style={{fontSize: "12px", fontWeight: "bold", color: "#000"}}>{nameUser}</div>
                                                                <div style={{fontSize: "9px", fontWeight: "bold", color: "#767676", marginTop: "-3px"}}>{emailUser}</div>
                                                            </div>
                                                            <div style={{fontSize: "11px", 
                                                                        fontWeight: "bold", 
                                                                        padding: "5px", 
                                                                        paddingLeft: "15px", 
                                                                        marginLeft: "10px", 
                                                                        border: "1px solid #CCC", 
                                                                        position: "relative",
                                                                        borderRadius: "3px"}}>
                                                                <img src={info} style={{width: "14px", position: "absolute", left: "-5px"}}/>
                                                                {confirmReason}
                                                            </div>
                                                        </div>
                                                    </div>
                                        })
                                    }
                                    </>
                                    </>
                            }
                        </div>
                    </div>
                   
                    <div className="bs-input" style={{marginBottom: "20px"}}>
                        <TitleInput>Kind of activity</TitleInput>
                        <select className="frm-input-mbl" 
                            style={{width: "100%"}}
                            isMandatory="true"
                            value={this.state.kindAct}
                            onChange={(e) => this.setState({kindAct: e.target.value})}
                        >
                            <option value="demo">Demo</option>
                            <option value="training">Training</option>
                            <option value="others">Others</option>
                        </select>
                        {/* <input className="frm-input-mbl" 
                            onChange={(e) => this.setState({kindAct: e.target.value})}
                            placeholder="Kind of activity" 
                            style={{width: "100%"}}/> */}
                    </div>
                    <div className="bs-input" style={{marginBottom: "20px"}}>
                        <TitleInput>Describe your others</TitleInput>
                        <input className="frm-input-mbl" 
                            value={this.state.describe}
                            onChange={(e) => this.setState({describe: e.target.value})}
                            placeholder="Describe your others" 
                            style={{width: "100%"}}/>
                    </div>
                    <div className="bs-input" style={{marginBottom: "20px"}}>
                        <TitleInput>Upload proof of ACC</TitleInput>
                        {
                            (this.state.proofAccDocumentId == "" && this.props.id == "form")
                            ?
                                <a onClick={this.selectFile}>
                                    <div className="" 
                                        id="ipt-upl-proof"
                                        style={Styles.divInput}
                                    >
                                        <div style={{color: "rgb(114, 114, 114)"}}>
                                            {
                                                (this.state.fileName != "") ? 
                                                    <>
                                                        {
                                                            (this.state.successUpload)
                                                            ?
                                                                <div style={{display: "flex", alignItems: "center", fontSize: "12px"}}>
                                                                    <img src={GreenCheks} style={{width: "15px"}}/>&nbsp;&nbsp;&nbsp;
                                                                    <div style={{lineHeight: "1.2"}}>
                                                                        {this.state.fileName}
                                                                        <div style={{fontSize: "10px", color: "green", fontWeight: "bold"}}>upload successfully</div>
                                                                    </div>
                                                                </div>
                                                            :
                                                                <div style={{display: "flex", alignItems: "center", fontSize: "12px"}}>
                                                                    <img src={Spinner} style={{width: "30px"}}/>
                                                                    <div style={{lineHeight: "1.2"}}>
                                                                        {this.state.fileName}
                                                                        <BarLoadUpload id="br-ld-upl-mbl">
                                                                            <PercentUploaded ref={this.barPercentUpload}/>
                                                                        </BarLoadUpload>    
                                                                    </div>
                                                                </div>
                                                        }
                                                    </>
                                                :
                                                    <span style={{fontSize: "12px"}}>Select file</span>
                                            }
                                        </div>
                                        {fileIcon}
                                        <input type="file" onChange={this.selectFileInput} ref={this.fileInput} style={{display: "none"}}/>
                                    </div>
                                </a>
                            :
                                <a onClick={() => this.downloadFile()}>
                                    <div className="" 
                                        id="ipt-upl-proof"
                                        style={Styles.divInput}
                                    >
                                        <div style={{color: "rgb(114, 114, 114)"}}>
                                            {
                                                (this.state.fileName != "") 
                                                ?        
                                                    <div style={{display: "flex", alignItems: "center", fontSize: "12px"}}>
                                                        <div style={{lineHeight: "1.2"}}>
                                                            {this.state.fileName}
                                                        </div>
                                                    </div>
                                                :
                                                    <span style={{fontSize: "12px"}}>Select file</span>
                                            }
                                        </div>
                                        {fileIcon}
                                        <input type="file" onChange={this.selectFileInput} ref={this.fileInput} style={{display: "none"}}/>
                                    </div>
                                    {
                                        (this.state.isDownloadProof)
                                            ?
                                            <div id="dbf-uled-mbl" 
                                                style={{display: "flex", 
                                                        alignItems: "center",
                                                        marginBottom: "-10px",
                                                        marginLeft: "5px"}}
                                            >
                                                <img src={Spinner} style={{width: "25px"}}/>
                                                <div style={{fontSize: "12px", marginLeft: "0px", color: "#CCC", fontWeight: "bold"}}>downloading</div>
                                            </div>
                                        :   ""
                                    }
                                    
                                </a>
                        }
                        
                    </div>
                    <div className="bs-input" style={{marginBottom: "20px"}}>
                        <TitleInput>Type of tools</TitleInput>
                        <select className="frm-input-mbl" 
                            value={this.state.typeTools}
                            isMandatory="true"
                            onChange={(e) => this.setState({typeTools: e.target.value})}
                            placeholder="Type" 
                            style={{width: "100%"}}
                        >
                        {
                            dropDownTypeOfTools.map(dt => {
                                return <option value={dt}>{dt}</option>
                            })
                        }
                        </select>
                    </div>
                    {/* <div className="bs-input" style={{marginBottom: "20px"}}>
                        <TitleInput>Demo terms</TitleInput>
                        <select className="frm-input-mbl" 
                            value={this.state.demo}
                            onChange={(e) => this.setState({demo: e.target.value})}
                            placeholder="Type" 
                            style={{width: "100%"}}
                        >
                        {
                            dropDownDemoTerms.map(dt => {
                                return <option value={dt}>{dt}</option>
                            })
                        }
                        </select>
                    </div> */}
                    {/* <div className="bs-input" style={{marginBottom: "20px"}}>
                        <TitleInput>Training terms</TitleInput>
                        <select className="frm-input-mbl"
                            value={this.state.training} 
                            onChange={(e) => this.setState({training: e.target.value})}
                            placeholder="Type" 
                            style={{width: "100%"}}
                        >
                        {
                            dropDownDemoTerms.map(dt => {
                                return <option value={dt}>{dt}</option>
                            })
                        }
                        </select>
                    </div> */}
                    
                    {/* <div className="bs-input" style={{marginBottom: "20px"}}>
                        <TitleInput>Region</TitleInput>
                        <select className="frm-input-mbl" 
                            value={this.state.regionId}
                            onChange={(e) => this.setState({regionId: e.target.value})}
                            placeholder="Type" 
                            style={{width: "100%"}}
                        >
                            <option value={""} style={{display: "none"}}>Select Region</option>
                            {this.props.region.map(dt => {
                                return <option value={dt.id}>{dt.name}</option>
                            })}
                        </select>
                    </div> */}

                    <div className="bs-input" style={{marginBottom: "20px"}}>
                        <TitleInput>Include tricefy</TitleInput>
                        <select className="frm-input-mbl" 
                            value={this.state.isTricefy}
                            onChange={(e) => this.setState({isTricefy: e.target.value})}
                            placeholder="Type" 
                            isMandatory="true"
                            style={{width: "100%"}}
                        >
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                            <option value="others">Others</option>
                        </select>
                    </div>
                    {/* {
                        (this.props.id != "form")
                        ?
                            <div className="bs-input" style={{marginBottom: "20px"}}>
                                <TitleInput>Applicant</TitleInput>
                                <select className="frm-input-mbl" 
                                    id="apl-ipt"
                                    value={this.state.employeeId}
                                    onChange={(e) => this.setState({employeeId: e.target.value})}
                                    placeholder="Type" 
                                    style={{width: "100%"}}
                                >
                                    <option value={""} style={{display: "none"}}>{"Select applicant"}</option>
                                    {this.props.employeesAppl.map(dt => {
                                        let profile = dt.user.profile
                                        let fn = profile.firstName
                                        let ln = profile.lastName
                                        let full = fn+" "+ln
                                        return <option value={dt.id}>{full}</option>
                                    })}
                                </select>
                            </div>
                        :
                            ""
                    } */}
                    <div className="bs-input" style={{marginBottom: "20px"}}>
                        <TitleInput>Additioanl Accessories</TitleInput>
                        <input className="frm-input-mbl"
                            isMandatory="true"
                            placeholder="Additional Accessories..."
                            value={this.state.additionalAcc} 
                            onChange={(e) => this.setState({additionalAcc: e.target.value})}
                            style={{width: "100%"}}/>
                    </div>
                    <div style={{textAlign: "center"}}>
                        {
                            (!this.props.inProcess)
                            ?
                                (this.props.id == 'form')
                                ?  <ButtonSave onClick={this.save}>Save</ButtonSave>
                                :   (this.isManager())
                                    ? <button className="btn-sv" onClick={this.setEmployee}>Save</button>
                                    : ""
                            :
                                <ButtonSave style={{opacity: "0.8"}}>Please wait...</ButtonSave>
                        }
                    </div>
                </div>
            </>
        )
    }
}

const Styles = {
    rsInput : {
        display: "flex", 
        alignItems: "center", 
        height: "41px", 
        padding: "0px 12px", 
        border: "1px solid #949494", 
        borderRadius: "10px",
    },
    divInput : {
        display: "flex", 
        alignItems: "center", 
        height: "41px", 
        padding: "0px 12px", 
        border: "1px solid #949494", 
        borderRadius: "10px",
        justifyContent: "space-between"
    }
}

const fileIcon = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="1" width="15" height="18">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 1.67651H16.7106V18.2211H2.5V1.67651Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask0)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.3109 2.92651C4.93007 2.92651 3.7834 4.04485 3.7509 5.42401V14.3365C3.72007 15.764 4.84507 16.9398 6.2584 16.9715H12.9784C14.3692 16.914 15.4709 15.7582 15.4609 14.3415V6.94985L11.5984 2.92651H6.3209H6.3109ZM6.32092 18.2215H6.23009C4.12842 18.174 2.45509 16.4257 2.50092 14.3232V5.40901C2.54925 3.34151 4.25675 1.67651 6.30925 1.67651H6.32342H11.8651C12.0351 1.67651 12.1976 1.74568 12.3159 1.86818L16.5368 6.26568C16.6484 6.38151 16.7109 6.53735 16.7109 6.69818V14.3365C16.7259 16.4273 15.0976 18.1357 13.0034 18.2207L6.32092 18.2215Z" fill="#949494"/>
                    </g>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0818 7.48699H13.786C12.261 7.48283 11.021 6.23949 11.021 4.71616V2.29199C11.021 1.94699 11.301 1.66699 11.646 1.66699C11.991 1.66699 12.271 1.94699 12.271 2.29199V4.71616C12.271 5.55282 12.9518 6.23449 13.7877 6.23699H16.0818C16.4268 6.23699 16.7068 6.51699 16.7068 6.86199C16.7068 7.20699 16.4268 7.48699 16.0818 7.48699Z" fill="#949494"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4902 13.4238H6.99023C6.64523 13.4238 6.36523 13.1438 6.36523 12.7988C6.36523 12.4538 6.64523 12.1738 6.99023 12.1738H11.4902C11.8352 12.1738 12.1152 12.4538 12.1152 12.7988C12.1152 13.1438 11.8352 13.4238 11.4902 13.4238Z" fill="#949494"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.78641 10.2971H6.98975C6.64475 10.2971 6.36475 10.0171 6.36475 9.67212C6.36475 9.32712 6.64475 9.04712 6.98975 9.04712H9.78641C10.1314 9.04712 10.4114 9.32712 10.4114 9.67212C10.4114 10.0171 10.1314 10.2971 9.78641 10.2971Z" fill="#949494"/>
                </svg>

export default user_sales_mobile