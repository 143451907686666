import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom';
import Logo from "../images/Beyond Logo mbl.png";
// import MenuList from './pop_header_mobile'

const Header_mobile_transparant = (props) => {

    const [menu, setMenu] = useState("")

    // const moreMenuAction = () => {
    // //   setMenu(<MenuList xmenu={xmenu}/>)
    // }


    return(
        <Fragment>
            <div
            style={{
                padding: "15px",
                alignItems: "center",      
                justifyContent: "space-between",
                display: "flex"
            }}
            >
                <Link to="/" style={{marginTop: "1px", marginBottom: "-1px"}}>
                    <img src={Logo} style={{width: "74px", height:"22px"}} />
                </Link>
                <div>
                    <a>
                        {moreMenu}
                    </a>
                </div>
            </div>
            {menu}
        </Fragment>
        
    )
}

const moreMenu = <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.625 0.5H16.375V2.25H0.625V0.5ZM0.625 6.625H16.375V8.375H0.625V6.625ZM0.625 12.75H16.375V14.5H0.625V12.75Z" fill="#333333"/>
                 </svg>


export default Header_mobile_transparant