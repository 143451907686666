import axios from 'axios'
import React, { Component, createRef } from 'react'
import Styled, { ThemeConsumer } from 'styled-components'
import CameraIcon from '../../../images/photo-camera-interface-symbol-for-button.png'
import Service from './service'
import { baseUrl } from '../../../environment'
import { fi, tr } from 'date-fns/locale'

const Base = Styled.div`
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1000;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0px
`

const MainBase = Styled.div`
    width: 70%;
    max-width: 350px;
    background: #FFF;
    border-radius: 4px;
`

class Finish extends Component {
    
    constructor(){
        super()
        this.state = {
            windowWidth: window.innerWidth,
            filesInput: null,
            filesArray: [],
            urlPreview: "",
            visibilityType: [],
            allSuccess: false
        }

        this.alert = createRef()
        this.inputFile = createRef()
    }

    componentDidMount(){
        this.setVisibilityTypeId()
    }

    changeFile = () => {
        let file = this.inputFile.current.files
        // let len = file.length
        const a = Array.from(file)
        this.setState({
            filesInput: file,
            filesArray: a
        })
    }

    setVisibilityTypeId = async () => {
        let resp = await axios.get("/file/getVisibilityType")
        let result = resp.data
        let data = result.data
        this.setState({
            visibilityType: data
        })
    }

    getTypeId = (type) => {
        let id = ""
        let data = this.state.visibilityType
        for(let i = 0;i<data.length;i++){
            if(type == data[i].name){
                id = data[i].id
            }
        }

        return id
    }

    ok = (e) => {
        let files = this.state.filesArray
        let elm = document.getElementById("bs-lslt-fls-zz")
        let elmInput = document.getElementById("fns-slc-img")
        let childElm = elm.children

        for(let i = 0;i<files.length;i++){
            let bsBar = document.getElementsByClassName("bs-grn-pr-upl")
            bsBar[i].style.display = "block"

            e.target.style.background = "#CCC"
            elmInput.style.display = "none"

            let file = files[i];
            let child = childElm[i]
            this.uploading(file, child, i)
        }
    }

    // yesConfirmFinish = async (form) => {
    //     let respFinish = await axios.post("/applicant/scheduleCompletionf/"+this.state.selectedId, form)
    //     let data = respFinish.data
    //     if(data.success){
    //         let serv = new Service()
    //         let resp = await serv.fetchDataApi()
    //         this.props.setDataApplicantRdx(resp)
    //         this.props.cancel()
    //     }
    // }

    // ok = () => {
    //     let file = this.inputFile.current.files[0]
    //     let form = new FormData()
    //     
    //     form.append("files", file)
    //     form.append("visibilityTypeId", typeId)
    //     this.props.yes(form)
    // }

    uploading = async (file, barElement, sequence) => {
        let typeId = this.getTypeId("public")
        let form = new FormData()

        form.append("files", file)
        form.append("visibilityTypeId", typeId)
        let dataLogin = await window.localStorage.getItem("token")
        let token;
        
        if (dataLogin != null) {
            dataLogin = JSON.parse(window.localStorage.getItem("token"))
            token = dataLogin.token
        }

       
        let childChildren = barElement.children[1]
        let barLoadUpload = childChildren.children
        // const requestOptions = {
        //     method: "POST",
        //     headers: { 'Authorization': "Bearer "+token },
        //     body: form
        // }

        const xhr = new XMLHttpRequest();
        xhr.upload.onload = () => {
            console.log("Completed upload")
        }
        
        xhr.upload.onerror = () => {
            // console.error("upload failed")
            barLoadUpload[0].style.background = "#F00"
        }

        xhr.upload.onabort = () => {
            console.error('Upload cancelled');
        }

        xhr.upload.onprogress = (event) => {
            // event.loaded returns how many bytes are downloaded
            // event.total returns the total number of bytes
            // event.total is only available if server sends `Content-Length` header
            let percent = Math.floor((event.loaded / event.total) * 100)
            barLoadUpload[1].innerText = percent+"%"
            barLoadUpload[0].style.width = percent+"%"
        }

        xhr.onreadystatechange = () => {
            if (xhr.readyState == XMLHttpRequest.DONE) {
                // let resp = JSON.parse(xhr.responseText)
                /*check all upload 100%*/
                let elm = document.getElementById("bs-lslt-fls-zz")
                let childElm = []
                if(elm){
                    childElm = elm.children
                }

                let isAll100 = true
                for(let i = 0;i<childElm.length;i++){
                    let child = childElm[i]
                    let child1 = child.children;
                    let percent = child1[1].innerText
                    
                    percent = percent.replace("%", "")
                    percent = parseInt(percent)
                    if(percent < 100){
                        isAll100 = false
                    }
                }

                if(isAll100){
                    this.setState({
                        allSuccess: true
                    })
                    this.props.refreshApplicant()
                }
                // if(resp.success){
                //     let data = resp.data
                //     let idFile = data.id
                //     let fileName = data.name
                //     // this.setState({
                //     //     proofAccDocumentId: idFile,
                //     //     fileName: fileName,
                //     //     successUpload: true
                //     // })

                //     let elm = document.getElementById("bs-lslt-fls-zz")
                //     let childElm = elm.children
                //     for(let i = 0;i<childElm.length;i++){
                //         let child = childElm[i]
                //         let child1 = child[1];
                //         let percent = child1.innerText
                //         percent = percent.replace("%","")
                //     }
                // }
            }
        }

        xhr.open("POST", baseUrl+"/applicant/scheduleCompletion/"+this.props.id)
        xhr.setRequestHeader("Authorization", "Bearer "+token)
        xhr.send(form)
    }

    render(){
        return(
            <Base id="dialog-conf">
                <MainBase>
                    <div style={{padding: "10px", borderBottom: "1px solid #d4dbe0"}}>
                        {
                            (this.state.windowWidth > 1202)
                            ? <span style={{fontWeight: "bold"}}>Report Schedule</span>
                            : <span style={{fontSize: "12px", fontWeight: "bold"}}>Report Schedule</span>
                        }
                    </div>
                    <div style={{padding: "10px", lineHeight: "1.2", height: "auto"}}>
                        {
                            (!this.state.allSuccess)
                            ?
                                <> {/*start base*/}
                                <div id="bs-lslt-fls-zz">
                                {
                                    (this.state.filesArray.length > 0)
                                    ?
                                        this.state.filesArray.map((dt, index) => {
                                            return  <div style={{padding: "5px", 
                                                                border: "1px solid #eaeaea", 
                                                                borderRadius: "3px", 
                                                                display: "flex", 
                                                                justifyContent: "space-between",
                                                                alignItems: "center", 
                                                                marginBottom: "5px"}}
                                                    >
                                                        <div style={{display: "flex", alignItems: "center"}}>
                                                            {
                                                                (window.innerWidth > 1202)
                                                                ? 
                                                                    <>
                                                                    <img src={URL.createObjectURL(dt)} style={{width: "50px", height: "40px", objectFit: "contain"}}/>
                                                                    <div style={{fontSize: "12px", marginLeft: "8px", fontWeight: "bold"}}>{dt.name}</div>
                                                                    </>
                                                                :
                                                                    <>
                                                                    <img src={URL.createObjectURL(dt)} style={{width: "40px", height: "30px", objectFit: "contain"}}/>
                                                                    <div style={{fontSize: "10px", marginLeft: "8px", fontWeight: "bold"}}>{dt.name}</div>
                                                                    </>
                                                            }
                                                        </div>
                                                        {
                                                            (window.innerWidth > 1202)
                                                            ?
                                                                <div className='bs-grn-pr-upl' style={{width: "37px", 
                                                                        height: "37px", 
                                                                        border: "3px solid #eaeaea",
                                                                        display: "none", 
                                                                        borderRadius: "100%", 
                                                                        position: "relative",
                                                                        overflow: "hidden",
                                                                        fontSize: "12px"}}>
                                                                <div className='br-grn-cr-up;' style={{background: "green", position: "absolute", height: "100%", width: "0%"}}/>
                                                                <div className='pr-grn-cr-up' 
                                                                    style={{position: "absolute", 
                                                                        display: "flex", 
                                                                        alignItems: "center", 
                                                                        justifyContent: "center", 
                                                                        height: "100%",
                                                                        fontWeight: "bold", 
                                                                        width: "100%"}}>0%</div>
                                                            </div>
                                                            :
                                                                <div className='bs-grn-pr-upl' style={{width: "28px", 
                                                                    height: "28px", 
                                                                    border: "3px solid #eaeaea",
                                                                    display: "none", 
                                                                    borderRadius: "100%", 
                                                                    position: "relative",
                                                                    overflow: "hidden",
                                                                    fontSize: "10px"}}>
                                                                <div className='br-grn-cr-up;' style={{background: "green", position: "absolute", height: "100%", width: "0%"}}/>
                                                                <div className='pr-grn-cr-up' 
                                                                    style={{position: "absolute", 
                                                                        display: "flex", 
                                                                        alignItems: "center", 
                                                                        justifyContent: "center", 
                                                                        height: "100%",
                                                                        fontWeight: "bold", 
                                                                        width: "100%"}}>0%</div>
                                                                </div>
                                                        }
                                                    </div>
                                        })
                                    :   ""
                                }
                                </div>
        
                                {
                                (this.state.filesArray.length == 0) 
                                ?
                                        <a onClick={() => {this.inputFile.current.click()}}>
                                            <div id="fns-slc-img" 
                                                style={{display: "flex", 
                                                    width: "130px",  
                                                    background: "#CCC", 
                                                    justifyContent: "center", 
                                                    borderRadius: "5px",
                                                    alignItems: "center", 
                                                    flexDirection: "column"}}>
                                                <img src={CameraIcon} style={{width: "20px", marginTop: "30px"}}/> 
                                                <div style={{fontSize: "11px", fontWeight: "bold", marginTop: "5px", marginBottom: "30px"}}>Upload Image</div>
                                            </div>
                                        </a>
                                    :
                                        <a onClick={() => {this.inputFile.current.click()}}>
                                            <div id="fns-slc-img" 
                                                style={{display: "flex", 
                                                    width: "120px",  
                                                    border: "1px solid #CCC", 
                                                    marginTop: "10px",
                                                    background: "#f3f3f3",
                                                    padding: "5px",
                                                    justifyContent: "center", 
                                                    alignItems: "center",
                                                    borderRadius: "5px",
                                                    alignItems: "center", 
                                                    flexDirection: "row"}}>
                                                
                                                {
                                                    (window.innerWidth > 1202)
                                                    ?
                                                        <>
                                                        <img src={CameraIcon} style={{width: "20px"}}/> 
                                                        &nbsp;&nbsp;
                                                        <div style={{fontSize: "12px", fontWeight: "bold"}}>Change Image</div>
                                                        </>
                                                    :
                                                        <>
                                                        <img src={CameraIcon} style={{width: "15px"}}/> 
                                                        &nbsp;&nbsp;
                                                        <div style={{fontSize: "10px", fontWeight: "bold"}}>Change Image</div>
                                                        </>
                                                }
                                            </div>
                                        </a>
                                }
                                
                                <input ref={this.inputFile} 
                                    onChange={this.changeFile} 
                                    multiple="multiple" 
                                    accept="image/png, image/gif, image/jpeg" 
                                    type="file" style={{display: "none"}}/>
                                </> /* end base*/
                            :
                                <div>
                                    <div style={{fontWeight: "bold"}}>Upload report applicant is<br/>successfully</div>
                                </div>
                        }
                    </div>
                    {
                        (!this.state.allSuccess)
                        ?
                            <div style={{display: "flex", justifyContent: "end", alignItems: "center", padding: "10px"}}>
                                <button onClick={this.ok} 
                                    className="btn-primary" 
                                    style={{padding: "10px", fontSize: "12px", width: "50px", height: "25px", borderRadius: "3px"}}
                                >
                                    Yes
                                </button>
                                &nbsp;
                                <button onClick={this.props.cancel} 
                                    className="btn-gray" 
                                    style={{padding: "10px", fontSize: "12px", width: "50px", height: "25px", borderRadius: "3px"}}
                                >
                                    Cancel
                                </button>
                            </div>
                        :
                            <div style={{display: "flex", justifyContent: "end", alignItems: "center", padding: "10px"}}>
                                <div/>
                                <button onClick={this.props.cancel} 
                                    className="btn-primary" 
                                    style={{padding: "10px", fontSize: "12px", width: "50px", height: "25px", borderRadius: "3px"}}
                                >
                                    ok
                                </button>
                            </div>
                    }
                </MainBase>
            </Base>
        )
    }
}

// const mapDispatch = dispatch => {
//     return{
//         searchRsApplicant: (data) => dispatch(seacrhRsApplicantByName(data)),
//         filterStatus: (data) => dispatch(filterStatusApplicant(data))
//     }
// }

// const mapStateToProps = state => {
//     return{
//         searchParam: state.dataApplicant.searchName,
//         page: state.dataApplicant.page,
//         filterStatusParam: state.dataApplicant.filterStatus
//     }
// }

export default Finish
// export default Finish