import React from 'react'
import { Component } from 'react';
// import Loader from '../images/Rolling-1s-200px.svg'
import Loader from '../images/Pulse-1s-51px.gif'
export default class loader extends Component{
    render(){
        return(
            <div style={{textAlign: "center", padding: "40px", marginTop: "65px"}}>
              <img src={Loader} style={{width: "30px"}}/>
              <div style={{color: "#CCC"}}>Loading...</div>
            </div>
        )
    }
}