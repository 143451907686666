export const dropDownTypeOfTools = [
    "P6", 
    "P8", 
    "S6", 
    "S8",
    "S8 Touch",
    "S10",
    "S10 Expert",
    "E6",
    "E8",
    "E10",
    "Logic P7",
    "Logic P9",
    "Logic S7",
    "Logic S8",
    "Logic S9",
    "Logic E9",
    "Logic E10",
    "Swift"
]

export const dropDownDemoTerms = [
    "E6 P8, 1 user (5 days)", 
    "P6 P8, 1 user (2 days)",
    "P6 P8, more than 1 user (3-4 days)",
    "S6-S10exp, 1 user (4 days)",
    "S6-S10exp, more than 1 user (4-5 days)",
    "E6-E10, 1 user (5 days)",
    "E6-E10, 1 user (5-6 days)"
]