import React, { Component } from 'react'
import Header from '../../header/header'
import Styled from 'styled-components'
import LeaveIcon from '../../../images/exit.png'
import SickIcon from '../../../images/medicine.png'
import Leave from './leave-web'
import Sick from './sick-web'

const FilterBase = Styled.div`
    width: 124px;
    padding: 0px 10px;
    height: 37px;
    background: #FFF;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 25px;
`

class leave_sick_web extends Component{

    constructor(){
        super()
        this.state = {
            position: "leave"
        }
    }

    setPosition = (pos) => {
        this.setState({
            position: pos 
        })
    }

    render(){
        return(
            <>
                <Header/>
                <div style={{padding: "40px 51px"}}>
                    <div style={{fontSize: "24px", 
                                color: "#1F4788",
                                display: "flex", 
                                justifyContent: "space-between", 
                                alignItems: 'center'}}>
                        <span style={{fontWeight: "bold"}}>Leave & Sick</span>
                    </div>
                    <div style={{display: "flex", alignItems: "center", marginTop: "25px", borderBottom: "rgb(159 159 159 / 20%) 1px solid"}}>
                        {
                            (this.state.position == "leave")
                            ?   <a style={{color: "#372C8A", display: "flex", alignItems: "center", borderBottom: "3px solid #372C8A", paddingBottom: "10px"}}>
                                    <img src={LeaveIcon} style={{width: "20px"}}/>
                                    <div style={{fontWeight: "bold", marginLeft: "5px"}}>Leave</div>
                                </a>
                            :   <a onClick={() => this.setPosition("leave")} style={{color: "#372C8A", display: "flex", alignItems: "center", paddingBottom: "13px", opacity: "0.5"}}>
                                    <img src={LeaveIcon} style={{width: "20px"}}/>
                                    <div style={{fontWeight: "bold", marginLeft: "5px"}}>Leave</div>
                                </a>

                        }
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {
                            (this.state.position == "sick")
                            ?
                                <a style={{color: "red", display: "flex", alignItems: "center", paddingBottom: "10px",  borderBottom: "3px solid red"}}>
                                    <img src={SickIcon} style={{width: "20px"}}/>
                                    <div style={{fontWeight: "bold", marginLeft: "5px"}}>Sick</div>
                                </a>
                            :
                                <a onClick={() => this.setPosition("sick")} style={{color: "red", display: "flex", alignItems: "center", paddingBottom: "13px", opacity: "0.5"}}>
                                    <img src={SickIcon} style={{width: "20px"}}/>
                                    <div style={{fontWeight: "bold", marginLeft: "5px"}}>Sick</div>
                                </a>
                        }
                    </div>
                    {
                        (this.state.position == "leave")
                        ? <Leave isLoad={this.props.isLoadLeave} fetchLeave={this.props.fetchLeave} data={this.props.dataLeave}/>
                        : <Sick isLoad={this.props.isLoadSick} data={this.props.dataSick} fetchLeave={this.props.fetchLeave}/>
                    }
                </div>
            </>
        )
    }
}

const chevron = <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.625 1.4375L7.5 7.5625L1.375 1.4375" stroke="#717171" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

export default leave_sick_web