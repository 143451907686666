import React, { Component } from 'react'
import Header from '../../header/header'
import axios from 'axios'
import Web from './hospitals_web'
import formatRelativeWithOptions from 'date-fns/esm/fp/formatRelativeWithOptions'

class hospitals extends Component {

    constructor(){
        super()
        this.state = {
            dataHospitals: [],
            isLoad: true,
            pos: 1,
            page: 0,
            search: "",
            regions: []
        }
    }

    componentDidMount(){
        this.fetchHospitals()
        this.fetchRegion()
    }

    fetchHospitals = () => {
        axios.get("/hospital/getHospitals?size=1000").then(resp => {
            let data = resp.data.data
            let page = this.setPage(data)
            this.setState({
                dataHospitals: data,
                page: page
            })
        })
    }

    fetchRegion = () => {
        axios.get("/hospital/getRegions").then(response => {
            let result = response.data
            let regions = result.data
            this.setState({
                regions: regions
            })
        })
    }

    setPage(data){
        let l = data.length
        let page = Math.ceil(l / 10)
        return page
    }

    next = (size) => {
        let pos = this.state.pos
        if(pos < size){
            let next = parseInt(pos) + 1
            this.setState({
                pos: next
            })
        }
    }

    prev = () => {
        let pos = this.state.pos
        if(pos > 1){
            let prv = parseInt(pos) - 1
            this.setState({
                pos: prv
            })
        }
    }

    spliceData = (index) => {
        let data = [...this.state.dataHospitals]
        data.splice(index, 1)
        this.setState({
            dataHospitals: data
        })
    }

    last = (size) => {
        this.setState({
            pos: size
        })
    }

    first = () => {
        this.setState({
            pos: 1
        })
    }

    search = (val) => {
        this.setState({
            search: val
        })
    }

    updateData = (data, index) => {
        let dataHospitals = [...this.state.dataHospitals]
        dataHospitals[index] = data

        this.setState({
            dataHospitals: dataHospitals
        })
    }

    render(){
        return(
            <>
                <Header/>
                <Web isLoad={this.state.isLoad}
                    pos={this.state.pos}
                    next={this.next}
                    prev={this.prev}
                    last={this.last}
                    first={this.first}
                    search={this.state.search}
                    searchAction={this.search}
                    regions={this.state.regions}
                    spliceData={this.spliceData}
                    fetchHospitals={this.fetchHospitals}
                    updateData={this.updateData}
                    dataHospitals={this.state.dataHospitals}/>
            </>
        )
    }
}

export default hospitals