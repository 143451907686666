import React, { Component } from 'react'
import Styled from 'styled-components'
import IconHsp from '../../../images/hospital-buildings-64.png'
import Service from '../../../service/hospitals_service'
import Loader from '../../../images/Pulse-1s-51px.gif'

const BasePopup = Styled.div`
    width: 80%;
    // height: 80%;
    max-width: 400px;
    max-height: 400px;
    background: #FFF;
    border-radius: 4px;
`

class popup_add_hospital extends Component {

    constructor(){
        super()
        this.state = {
            rsName: "",
            rsAddress: "",
            regionId: "",
            isSuccess: true,
            msg: "",
            isProcess: false,
            ww: window.innerWidth
        }
    }

    // componentDidMount(){
    //     alert(this.state.ww)
    // }

    save = async () => {
        let ste = this.state
        let obj = {
            address: ste.rsAddress,
            name: ste.rsName,
            regionId: ste.regionId
        }
        
        let elm1 = document.getElementById("ipt-rsn")
        let elm2 = document.getElementById("ipt-rgi")
        let isValid = true
        
        elm1.style.border = "1px solid #CCC"
        elm2.style.border = "1px solid #CCC"

        if(ste.regionId == ""){
            isValid = false;
            elm2.style.border = "1px solid rgb(255 153 153)"
        }

        
        if(ste.rsName == ""){
            isValid = false;
            elm1.style.border = "1px solid rgb(255 153 153)"
        }

        if(!isValid){
            return false
        }

        this.setState({isProcess: true})
        let service = new Service();
        let resp = await service.addHospitals(obj);
        
        if(resp.status != 200){
            let data = resp.data
            this.setState({
                isSuccess: false,
                msg: data.message,
                isProcess: false
            })
        }else{
            this.props.save(resp.data)
            this.props.cancel()
        }
        
    }

    cancel = () => {
        if(!this.state.isProcess){
            this.props.cancel()
        }
    }

    render(){

        const optionsRegions = this.props.regions.map(dt => {
            return <option value={dt.id}>{dt.name}</option>
        })

        return(
            <div id="bp-ad-hp" className="block-popup">
                {
                    (this.state.ww > 1202)
                    ?
                        //popup design for desktop version
                        <BasePopup id="dsk-vrs-ad-hsp">
                            <div style={{padding: "20px"}}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <img src={IconHsp} style={{width: "28px", marginRight: "10px"}}/>
                                    <span style={{fontSize: "16px", fontWeight: "bold"}}>Add Hospital</span>
                                </div>
                            </div>
                            
                            {
                                (!this.state.isSuccess)
                                ?   <div id="msg-ps-hsptls" 
                                        style={{textAlign: "center", marginBottom: "10px", color: "red", fontSize: "13px", fontWeight: "bold"}}>
                                        {this.state.msg}
                                    </div>
                                :   ""
                            }

                            <div style={{padding: "0px 20px"}}>
                                <input placeholder="RS Name"
                                    id="ipt-rsn"
                                    onChange={(e) => this.setState({rsName: e.target.value})}
                                    value={this.state.rsName} 
                                    style={{border: "1px solid #CCC", background: "rgb(246 246 246)"}}
                                    className="frm-input" type="text"
                                />
                                <textarea placeholder="RS Address"
                                    onChange={(e) => this.setState({rsAddress: e.target.value})}
                                    value={this.state.address}
                                    id="ad-rss-adrs" 
                                    className="frm-input" type="text"
                                    style={{border: "1px solid #CCC", 
                                            background: "rgb(246 246 246)", 
                                            marginTop: "10px",  
                                            height: "100px",  
                                            resize: "none",      
                                            padding: "12px 20px"}}
                                
                                />
                                <div style={{display: "flex", alignItems: "center",marginTop: "10px"}}>
                                    <select 
                                        id="ipt-rgi"
                                        value={this.state.regionId} 
                                        onChange={(e) => this.setState({regionId: e.target.value})} 
                                        style={{border: "1px solid #CCC", background: "rgb(246 246 246)"}}
                                        className="frm-input"
                                    >
                                        <option value="" style={{display: "none"}}>Select region</option>
                                        {optionsRegions}
                                    </select>
                                </div>
                                <div style={{display: "flex", alignItems: "center",marginTop: "10px", justifyContent: "flex-end", padding: "20px 0px"}}>
                                    {
                                        (this.state.isProcess)
                                        ? 
                                            <button className='btn-primary' 
                                                onClick={this.save}
                                                style={{height: "32px",
                                                        fontSize: "13px", 
                                                        width: "100px", 
                                                        border: "1px solid #c9c8c8",
                                                        borderRadius: "5px", 
                                                        background: "#FFF"}}>
                                                <img src={Loader} style={{width: "24px"}}/>
                                            </button>
                                        :
                                            <button className='btn-primary' 
                                                onClick={this.save}
                                                style={{padding: "6px 0px", fontSize: "13px", width: "100px", borderRadius: "5px"}}>
                                                Save
                                            </button>
                                    }
                                    &nbsp;
                                    {
                                        (this.state.isProcess)
                                        ?
                                            <button 
                                                className='btn-secondary' 
                                                style={{padding: "6px 0px", 
                                                        fontSize: "13px", 
                                                        width: "100px", 
                                                        border: "none",
                                                        color: "#CCC", 
                                                        borderRadius: "5px",
                                                        background: "#eaeaea"}}>
                                                Cancel
                                            </button>
                                        :
                                            <button onClick={this.props.cancel} 
                                                className='btn-secondary' 
                                                style={{padding: "6px 0px", 
                                                        fontSize: "13px", 
                                                        width: "100px", 
                                                        border: "none", 
                                                        borderRadius: "5px",
                                                        background: "#CCC"}}>
                                                Cancel
                                            </button>
                                    }
                                </div>
                            </div>
                        </BasePopup>
                    :
                        //popup design for mobile version
                        <BasePopup id="mbl-vrs-ad-hsp">
                            <div style={{padding: "20px"}}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <img src={IconHsp} style={{width: "20px", marginRight: "10px"}}/>
                                    <span style={{fontSize: "14px", fontWeight: "bold"}}>Add Hospital</span>
                                </div>
                            </div>
                            
                            {
                                (!this.state.isSuccess)
                                ?   <div id="msg-ps-hsptls" 
                                        style={{textAlign: "center", marginBottom: "10px", color: "red", fontSize: "12px", fontWeight: "bold"}}>
                                        {this.state.msg}
                                    </div>
                                :   ""
                            }

                            <div style={{padding: "0px 20px"}}>
                                <input placeholder="RS Name"
                                    id="ipt-rsn"
                                    onChange={(e) => this.setState({rsName: e.target.value})}
                                    value={this.state.rsName} 
                                    style={{border: "1px solid #CCC", height: "auto", padding: "10px", background: "rgb(246 246 246)", fontSize: "12px"}}
                                    className="frm-input" type="text"
                                />
                                <textarea placeholder="RS Address"
                                    onChange={(e) => this.setState({rsAddress: e.target.value})}
                                    value={this.state.address}
                                    id="ad-rss-adrs" 
                                    className="frm-input" type="text"
                                    style={{border: "1px solid #CCC", 
                                            background: "rgb(246 246 246)", 
                                            marginTop: "10px",
                                            fontSize: "12px",  
                                            height: "100px",  
                                            resize: "none",      
                                            padding: "10px"}}
                                
                                />
                                <div style={{display: "flex", alignItems: "center",marginTop: "10px"}}>
                                    <select 
                                        id="ipt-rgi"
                                        value={this.state.regionId} 
                                        onChange={(e) => this.setState({regionId: e.target.value})} 
                                        style={{border: "1px solid #CCC", background: "rgb(246 246 246)", fontSize: "12px", height: "auto", padding: "10px",}}
                                        className="frm-input"
                                    >
                                        <option value="" style={{display: "none"}}>Select region</option>
                                        {optionsRegions}
                                    </select>
                                </div>
                                <div style={{display: "flex", alignItems: "center",marginTop: "10px", justifyContent: "flex-end", padding: "20px 0px"}}>
                                    {
                                        (this.state.isProcess)
                                        ? 
                                            <button className='btn-primary' 
                                                onClick={this.save}
                                                style={{height: "32px",
                                                        fontSize: "10px", 
                                                        width: "100px", 
                                                        border: "1px solid #c9c8c8",
                                                        borderRadius: "5px", 
                                                        background: "#FFF"}}>
                                                <img src={Loader} style={{width: "24px"}}/>
                                            </button>
                                        :
                                            <button className='btn-primary' 
                                                onClick={this.save}
                                                style={{padding: "6px 0px", fontSize: "10px", width: "100px", borderRadius: "5px"}}>
                                                Save
                                            </button>
                                    }
                                    &nbsp;
                                    {
                                        (this.state.isProcess)
                                        ?
                                            <button 
                                                className='btn-secondary' 
                                                style={{padding: "6px 0px", 
                                                        fontSize: "10px", 
                                                        width: "100px", 
                                                        border: "none",
                                                        color: "#CCC", 
                                                        borderRadius: "5px",
                                                        background: "#eaeaea"}}>
                                                Cancel
                                            </button>
                                        :
                                            <button onClick={this.props.cancel} 
                                                className='btn-secondary' 
                                                style={{padding: "6px 0px", 
                                                        fontSize: "10px", 
                                                        width: "100px", 
                                                        border: "none", 
                                                        borderRadius: "5px",
                                                        background: "#CCC"}}>
                                                Cancel
                                            </button>
                                    }
                                </div>
                            </div>
                        </BasePopup>
                }
                
            </div>
        )
    }
}

export default popup_add_hospital