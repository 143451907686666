import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import SignAnim from '../sign-anim/SignAnim';
import Logo from '../../images/Beyond Logo.png';
import './Login.css';

export default class Login_web extends Component {

    constructor(){
        super()
        this.state = {
            name: "",
            password: "",
            regAs: "",
            email: "",
            userName: "",
            region: ""
        }

        this.reg = this.reg.bind(this)
    }

    componentDidMount() {
        document.title = 'Login';
    }

    doLogin(event) {
        event.preventDefault();
    }

    handleEmail = (e) => {
        let val = e.target.value
        this.setState({
            email: val
        })
    }

    handleFname = (e) => {
        let val = e.target.value
        this.setState({
            name: val
        })
    }

    handleLname = (e) => {
        let val = e.target.value
        this.setState({
            lname: val
        })
    }

    handlePassword = (e) => {
        let val = e.target.value
        this.setState({
            password: val
        })
    }

    handleRegAs = (e) => {
        let val = e.target.value
        this.setState({
            regAs: val
        })
    }

    handleUsername = (e) => {
        let val = e.target.value
        this.setState({
            userName: val
        })
    }

    handleRegion = (e) => {
        let val = e.target.value
        this.setState({
            region: val
        })
    }

    reg(){
        this.props.register(
            this.state.name, 
            this.state.password, 
            this.state.email, 
            this.state.regAs, 
            this.state.region)
    }

    render() {
        return (
            <div className='lgn lgn-root'>
                <div className='lgn lgn-section'>
                    <div style={{ margin: '1em' }}>
                        <Link to="/">
                            <img src={Logo} alt='logo' style={{ height: '40px' }} />
                        </Link>
                        {/* <img src={process.env.PUBLIC_URL + 'assets/images/logo.png'} alt='logo' style={{ height: '40px' }} /> */}
                    </div>
                    <div className='lgn form'>
                        <p className='title' style={{ marginBottom: '50px' }}>Register</p>
                        {/* <div className='input' style={{ marginBottom: '20px', width: '416px'}}>
                            <input type='text' onChange={this.handleUsername} placeholder='Username' style={{ background: 'transparent', border: 'none', width: '100%' }} />
                        </div> */}
                        <div className='input' style={{ marginBottom: '20px', width: '416px'}}>
                            <input type='text' onChange={this.handleFname} placeholder='Name' style={{ background: 'transparent', border: 'none', width: '100%' }} />
                        </div>
                        {/* <div className='input' style={{ marginBottom: '20px',   width: '416px'}}>
                            <input type='text' onChange={this.handleLname} placeholder='Last Name' style={{ background: 'transparent', border: 'none', width: '100%' }} />
                        </div> */}
                        <div className='input' style={{ marginBottom: '20px', width: '416px'}}>
                            <input type='text' onChange={this.handleEmail} placeholder='E-mail' style={{ background: 'transparent', border: 'none', width: '100%' }} />
                        </div>
                        <div className='input' style={{ marginBottom: '20px', width: '416px'}}>
                            <input type='password' onChange={this.handlePassword} placeholder='Password' style={{ background: 'transparent', border: 'none', width: '100%' }} />
                        </div>
                        
                        <div className='input' style={{ marginBottom: '20px',   width: '416px'}}>
                            <select onChange={this.handleRegAs} type='password' style={{ background: 'transparent', border: 'none', width: '100%', outline: "none" }}>
                                <option value="" style={{display: "none"}}>Register as</option>
                                {
                                    this.props.roles.map(dt => {
                                        return <option value={dt.id}>{dt.name}</option>
                                    })
                                }
                                
                            </select>
                        </div>
                        
                        {
                            (this.state.regAs == "00000000-0000-0000-0000-000000000013")
                            ?
                                <div className='input' style={{ marginBottom: '40px',   width: '416px'}}>
                                    <select onChange={this.handleRegion} type='password' style={{ background: 'transparent', border: 'none', width: '100%', outline: "none" }}>
                                        <option value="" style={{display: "none"}}>Region</option>
                                        {
                                            this.props.region.map(dt => {
                                                return <option value={dt.id}>{dt.name}</option>
                                            })
                                        }
                                        
                                    </select>
                                </div>
                            :
                                ""
                        }
                        
                        {
                            (this.state.fname == 0 || this.state.password == 0 || this.state.lname == 0 || this.state.email == 0 || this.state.regAs == 0)
                            ?
                                <button className='button-disable' onClick={this.reg} style={{ marginBottom: '40px' }}>Create Account</button>
                            :
                                (!this.props.inProcess)
                                ?
                                    <button className='button' style={{ marginBottom: '40px' }} onClick={this.reg}>Create Account</button>
                                :
                                    <button className='button-process' style={{ marginBottom: '40px', height: "35px", width: "170px"}}/>
                        }

                        {/* <div className='lgn lgn-footer' style={{ marginBottom: '40px' }}>
                            <div>Already have an account?  <Link to='/login'><span style={{color: '#1F4788'}}><u>Log in now!</u></span></Link></div>
                        </div> */}
                    </div>
                    <div className='lgn lgn-footer'>
                        <p className='caption' style={{ color: '#949494' }}>2021@BeyondMedical</p>
                        <p className='caption'>Need Help?</p>
                    </div>
                </div>
                <div className='lgn lgn-section anim'>
                    <SignAnim />
                </div>
            </div>
        );
    }
}
