import React, { Component } from 'react'
import SrcIcon from '../../../images/search-interface-symbol.svg'
import Styled from 'styled-components'
import Remove from '../../../images/remove.png'
import Details from '../../../images/view-details.png'
import IconHsp from '../../../images/hospital-buildings-64.png'
import PopAddEdit from './pop_add_edit'
import Confirmation2 from '../../dialog/confirmation/confirmation2'
import axios from 'axios'

const SrcBase = Styled.div`
    width: 223px;
    padding: 0px 10px;
    height: 37px;
    background: #FFF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    border: 1px solid #e7e7e7;
`


const Btn = Styled.button`
    width: 226px;
    height: 42px;
    background: #7784EE;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    color: #FFF;
    border: none;
    outline: none;
`

class hospitals_web extends Component {

    constructor(){
        super()
        this.state = {
            popup: "",
            dataSelected: ""
        }
    }

    add = () => {
        this.setState({
            dataSelected: "",
            popup: <PopAddEdit 
                        dataSelected="" 
                        regions={this.props.regions} 
                        fetchHospitals={this.props.fetchHospitals}
                        cancel={() => this.setState({popup: ""})}/>
        })
    }

    delete = (data, index) => {
        this.setState({
            dataSelected: data,
            indexSelected: index,
            popup: <Confirmation2 
                        cancel={() => {this.setState({popup: ""})}}
                        yes={this.yesDelete}
                        title="Delete Hospital" 
                        description="Are you sure, you want delete hospital ?"/>
        })
    }

    yesDelete = async () => {
        let idSelected = this.state.dataSelected.id
        let resp = await axios.delete("/hospital/deleteHospital/"+idSelected)
        let respData = resp.data

        if(respData.success){
            this.props.spliceData(this.state.indexSelected)
        }
        
        this.setState({
            popup: ""
        })
    }

    select = (data, index) => {
        this.setState({
            dataSelected: data,
            popup: <PopAddEdit
                        updateData={this.props.updateData} 
                        index={index}
                        dataSelected={data} 
                        regions={this.props.regions} 
                        fetchHospitals={this.props.fetchHospitals}
                        cancel={() => this.setState({popup: ""})}/>
        })
    }

    render(){
        let size = 0
        let seq = 0
        const data = this.props.dataHospitals.map((dt, index) => {
            
            let st = (this.props.pos > 1) ? (this.props.pos * 10) - 9 : this.props.pos
            let ed = this.props.pos * 10

            let isView = false
            if(dt.name.toLowerCase().match(this.props.search.toLowerCase())){
                isView = true
            }
            
            if(isView){
                seq++
                if(seq >= st && seq <= ed && isView){
                    return <tr>
                                <td>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <img src={IconHsp} style={{width: "20px"}}/>
                                        <div style={{marginLeft: "10px", fontWeight: "bold"}}>{dt.name}</div>
                                    </div>
                                </td>
                                <td>{dt.region.name}</td>
                                <td>{dt.address}</td>
                                <td style={{textAlign: "center"}}>
                                    <img src={Details} onClick={() => this.select(dt, index)} style={{width: "20px"}}/>
                                    <img src={Remove} onClick={() => this.delete(dt, index)} style={{width: "20px"}}/>
                                </td>
                            </tr>
                }
            }

            let idx = parseInt(index) + 1
            let len = this.props.dataHospitals.length
            if(idx == len){
                size = Math.ceil(seq / 10)
            }
        }) 
        
        return(
            <div style={{padding: "40px 51px"}}>
                
                {this.state.popup}

                <div style={{display: "flex", marginTop: "50px", justifyContent: "space-between"}}>
                    <SrcBase>
                        <img src={SrcIcon} style={{width: "15px"}}/>
                        <div>
                            <input type="text" 
                                onChange={(e) => this.props.searchAction(e.target.value)}
                                value={this.props.searchParam}
                                style={{width: "100%", 
                                        marginLeft: "10px", 
                                        border: "none", 
                                        outline: "none"}} 
                                placeholder="Search by name"
                            />
                        </div>
                    </SrcBase>

                    <Btn onClick={this.add}>+ Add Hospital</Btn>
                </div>
                <div id="tbl-bs-hsp" style={{marginTop: "50px"}}>
                    <table style={{width: "100%"}}>
                        <thead>
                            <th>Name</th>
                            {/* <th>Last Name</th> */}
                            <th>Region</th>
                            <th>Address</th>
                            <th>Action</th>
                        </thead>
                        <tbody>
                            {
                                data
                            }
                        </tbody>
                    </table>
                </div>
                <div id="ft-tbl-dir" style={{marginTop: "50px",}}>
                    <span style={{fontSize: "14px",lineHeight: "28px", color: "#333333"}}>
                        Showing {this.props.pos} of {size} entries
                    </span>
                    <div>
                        <button className="img-pgn" onClick={this.props.first}>First</button>
                        <button className="img-pgn" onClick={() => this.props.prev}>Prev</button>
                        <button className="img-pgn">{this.props.pos}</button>
                        <button className="img-pgn" onClick={() => this.props.next(size)}>Next</button>
                        <button className="img-pgn" onClick={() => this.props.last(size)}>Last</button> 
                    </div>
                </div>
            </div>
        )
    }
}

export default hospitals_web