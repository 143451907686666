import React, { Component } from 'react'
import Styled from 'styled-components'
import Popup from "../../popup_save_schedule";
import axios from 'axios';
import CurrencyInput from 'react-currency-input'
import { getRoleName } from '../../function/read_token'
import xIcon from '../../../images/cancel.png'
import UploadIcon from '../../../images/upload.png'
import { baseUrl, baseUrlUpload } from '../../../environment';

const TitleInput = Styled.div`
    color: "#949494";
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 12px;
`

const ButtonSave = Styled.button`
    width: 206px;
    height: 40px;
    color: #FFF;
    background: #46BD84;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    border: none;
    outline: none;    
`

const CaptionFilled = Styled.div`
    font-size: 10px; 
    color: #F00; 
    text-align: right;
    display: none;
`

class techniciant_mobile extends Component{

    constructor(){
        super()
        this.state = {
            isProcess: false,
            popup: "",
            custName: "",
            contactName: "",
            address: "",
            contact: "",
            start: "",
            finish: "",
            reportCategory: "Installation",
            status: "on progress",
            comment: "",
            type: "",
            serialNumber: "",
            repair: "",
            pending: "",
            attachmentInput: [],
            attachment: [],
            deletedFile: [],
            product: [{name: "", price: "", qty: ""}],
            fnameUploading: "",
            visibilityType: []
        }

        this.inputAttachment = React.createRef()
        this.barPercentUpload = React.createRef()
    }

    componentDidMount(){
        document.addEventListener("keyup", (e) => {
            let elm = e.target
            let classInput = elm.getAttribute("class")
            if(classInput == "frm-input-mbl"){
                elm.style.border = "1px solid #949494"
                let prt = elm.parentElement
                if(prt.children[2] !== undefined) prt.children[2].style.display = "none"
            }
        })

        this.setValue()
        this.disableInput()
        this.setVisibilityTypeId()
    }

    save = () => {
        /*set data files id*/
        let filesId = []
        for(let i = 0;i<this.state.attachmentInput.length;i++){
            let data = this.state.attachmentInput[i]
            let id = data.id
            filesId.push(id)
        }

        const data = {
            "address": this.state.address,
            "contactName": this.state.contactName,
            "customerComment": this.state.comment,
            "customerName": this.state.custName,
            "endDate": this.state.finish+" 00:00:00",
            "reportCategory": this.state.reportCategory,
            "jobStatus": "okee",
            "pendingMatters": this.state.pending,
            "phoneOrExt": this.state.contact,
            "repairDetail": this.state.repair,
            "serialNumber": this.state.serialNumber,
            "startDate": this.state.start+" 00:00:00",
            "statusReportForm": this.state.status,
            "type": this.state.type,
            "products": this.state.product,
            "filesId": filesId
        }

        let isValid = this.validationInput()
        
        if(!isValid){
            return false
        }

        this.setState({
            isProcess: true
        })

        /*set id if exists*/
        if(this.props.data.id){
            data.id = this.props.data.id
        }

        if(this.state.status != "pending"){
            data.pendingMatters = ""
        }
        
        if(this.props.data.id){
            this.updateData(data, this.state.deletedFile)
        }else{
            this.saveData(data)
        }
    }

    saveData = (data) => {
        axios.post("/report", data).then(result => {
            this.setState({
                popup:  <Popup status="success" isMobile={true}/>,
                custName: "",
                contactName: "",
                address: "",
                contact: "",
                start: "",
                finish: "",
                reportCategory: "Installation",
                status: "on progress",
                comment: "",
                type: "",
                serialNumber: "",
                repair: "",
                pending: "",
                isProcess: false,
                filesId: [],
                products: [{name: "", price: "", qty: "", id: null}],
            })

            /*delete value input*/
            let elm = document.getElementsByClassName("frm-input")
            for(let i = 0;i<elm.length;i++){
                elm[i].value = ""
            }

            /*hide popup for 3 second*/
            this.hidePoup()
        }).catch(error => {
            this.setState({
                popup:  <Popup status="failed" isMobile={true}/>,
                isProcess: false
            })

            this.hidePoup()
        })
    }

    updateData = (data, deletedFile) => {
        axios.put("/report", data).then(result => {
            this.setState({
                popup:  <Popup status="success" isMobile={true}/>,
                custName: "",
                contactName: "",
                address: "",
                contact: "",
                start: "",
                finish: "",
                reportCategory: "Installation",
                status: "on progress",
                comment: "",
                type: "",
                serialNumber: "",
                repair: "",
                pending: "",
                isProcess: false,
                products: [{name: "", price: "", qty: "", id: null}],
            })

            /*delete value input*/
            let elm = document.getElementsByClassName("frm-input")
            for(let i = 0;i<elm.length;i++){
                elm[i].value = ""
            }

            /*deleting attachment*/
            for(let i = 0;i<deletedFile.length;i++){
                let id = deletedFile[i]
                axios.delete("/report/file/"+id)
            }

            /*hide popup for 3 second*/
            this.hidePoup()
        }).catch(error => {
            this.setState({
                popup:  <Popup status="failed" isMobile={true}/>,
                isProcess: false
            })

            this.hidePoup()
        })
    }

    hidePoup = () => {
        setTimeout(() => {
            this.setState({
                popup: ""
            })
        }, 3000)
    }

    validationInput = () => {
        let input = document.getElementsByClassName("frm-input-mbl")
        let isValid = true
        for(let i = 0;i<input.length;i++){
            if(input[i].value == 0 && i < (input.length - 1)){
                input[i].style.border = "1px solid #F00"
                /*show caption*/
                let prt = input[i].parentElement
                if(prt.children[2] !== undefined) prt.children[2].style.display = "block"

                /*set is valid*/
                isValid = false
            }else{
                input[i].style.border = "1px solid #949494"
            }
        }

        return isValid
    }

    setValue = () => {
        let data = this.props.data 

        if(this.props.data != ""){
            let reportCategory = (data.reportCategory == null) ? "Installation" : data.reportCategory 
            let start = data.startDate.split(" ")[0]
            let end = data.endDate.split(" ")[0]
            
            let obj = [{name: "", price: "", qty: ""}]
            let product = (data.products.length == 0) ? obj : data.products

            this.setState({
                custName: data.customerName,
                contactName: data.contactName,
                address: data.address,
                contact: data.phoneOrExt,
                start: start,
                finish: end,
                reportCategory: reportCategory,
                status: data.statusReportForm,
                comment: data.customerComment,
                type: data.type,
                serialNumber: data.serialNumber,
                repair: data.repairDetail,
                pending: data.pendingMatters,
                product: product,
                isProcess: false,
                attachment: data.filesAttach,
                attachmentInput: data.filesAttach
            })
        }
    }

    setProduct = (type, index, e) => {
        let isM = this.isManager()
        let isF = this.isFinished()

        if(!isM && !isF){
            let product = [...this.state.product]
            if(type == "name") product[index].name = e.target.value
            if(type == "price") product[index].price = e.target.value
            if(type == "qty") product[index].qty = e.target.value
     
            this.setState({
                product: product
            })
        }
    }

    handleChangeCurrency = (e, maskedvalue, floatvalue) => {
        let isM = this.isManager()
        let isF = this.isFinished()
        let product = [...this.state.product]

        if(!isM && !isF){
            let seq = e.target.getAttribute("seq")
            let product = [...this.state.product]
            product[seq].price = floatvalue
        }

        this.setState({
            product: product
        })
    }

    addItemProduct = () => {
        let isM = this.isManager()
        let isF = this.isFinished()

        if(!isM && !isF){
            let product = [...this.state.product]
            let obj = {name: "", price: "", qty: ""}
            product.push(obj)
            
            this.setState({
                product: product
            })
        }
    }

    removeItemProduct = (index) => {
        let isM = this.isManager()
        let isF = this.isFinished()

        if(!isM && !isF){
            let product = [...this.state.product]
            product.splice(index, 1)

            this.setState({
                product: product
            })
        }
    }

    getTotal = () => {
        let total = 0
        let data = this.state.product
        for(let i = 0;i<data.length;i++){
            let q = data[i].qty
            let p = data[i].price
            let t = q * p
            total += t
        }

        return total
    }

    isManager = () => {
        let isM = false
        let role = getRoleName()
        if(role.toLowerCase() == "director / manager techniciant"){
            isM = true
            this.disableInput()
        }
        
        return isM
    }

    disableInput = () => {
        let data = this.props.data
        let status = data.statusReportForm

        let role = getRoleName()
        if(role.toLowerCase() == "director / manager techniciant" || status == "finished"){
            let elm = document.getElementsByClassName("frm-input-mbl")
            for(let i = 0;i<elm.length;i++){
                elm[i].disabled = true
            }
        }
    }

    isFinished = () => {
        let data = this.props.data
        let status = data.statusReportForm
        if(status == "finished") {
            return true
        }else{
            return false
        }
    }

    uploadFile = () => {
        this.inputAttachment.current.click()
    }

    getTypeId = (type) => {
        let id = ""
        let data = this.state.visibilityType
        for(let i = 0;i<data.length;i++){
            if(type == data[i].name){
                id = data[i].id
            }
        }

        return id
    }

    setVisibilityTypeId = async () => {
        let resp = await axios.get("/file/getVisibilityType")
        let result = resp.data
        let data = result.data
        this.setState({
            visibilityType: data
        })
    }

    changeAttachment = async (e) => {
        let file = e.target.files[0]
        let fname = file.name
        let typeId = this.getTypeId("public")
        this.setState({
            fnameUploading: fname,
            upload: file
        })

        let form = new FormData()
        form.append("file", file)
        form.append("visibilityTypeId", typeId)
        let url = baseUrlUpload
        let dataLogin = await window.localStorage.getItem("token")
        let token;
        
        if (dataLogin != null) {
            dataLogin = JSON.parse(window.localStorage.getItem("token"))
            token = dataLogin.token
        }

        const requestOptions = {
            method: "POST",
            headers: { 'Authorization': "Bearer "+token },
            body: form
        }

        const xhr = new XMLHttpRequest();
        xhr.upload.onload = () => {
            console.log("Completed upload")
        }
        
        xhr.upload.onerror = () => {
            console.error("upload failed")
        }

        xhr.upload.onabort = () => {
            console.error('Upload cancelled.');
        }

        xhr.upload.onprogress = (event) => {
            //event.loaded returns how many bytes are downloaded
            //event.total returns the total number of bytes
            //event.total is only available if server sends `Content-Length` header
            let barLoadUpload = this.barPercentUpload.current
            let barUpload = barLoadUpload.parentElement
            barUpload.style.display = "block" /*show bar upload file*/

            let percent = (event.loaded / event.total) * 100
            barLoadUpload.style.width = percent+"%"
        }

        xhr.onreadystatechange = () => {
            if (xhr.readyState == XMLHttpRequest.DONE) {
                /*hide bar upload file*/
                let barLoadUpload = this.barPercentUpload.current
                let barUpload = barLoadUpload.parentElement
                barUpload.style.display = "none"

                let resp = JSON.parse(xhr.responseText)
                if(resp.success){
                    let data = resp.data
                    let stateAttachment = this.state.attachmentInput
                    stateAttachment.push(data)
                    this.setState({
                        attachment: stateAttachment,
                        attachmentInput: stateAttachment,
                        fnameUploading: ""
                    })
                }
            }
        }

        xhr.open("POST", url+"/file/uploadFile")
        xhr.setRequestHeader("Authorization", "Bearer "+token)
        xhr.send(form)
    }

    removeAttachment = (e, index) => {
        e.stopPropagation()
        let data = this.state.attachment
        let id = data[index].id

        data.splice(index, 1)

        /*set data deleted id file*/
        let dataFileDeleted = this.state.deletedFile
        dataFileDeleted.push(id)

        this.setState({
            attachment: data,
            deletedFile: dataFileDeleted
        })
    }

    render(){

        const priceInput = this.state.product.map((dt, index) => {
                    let l = this.state.product.length
                    let idx = parseInt(index) + 1
                    return  <div class="lst-prod-ipt" style={{marginBottom: "20px", display: "flex"}}>
                                <div>
                                    <div>
                                        <input placeholder="product name" 
                                                value={dt.name}
                                                onChange={(e) => this.setProduct("name", index, e)}
                                                style={styles.inputProduct} type="text"/>
                                    </div>
                                    <div style={{display: "flex"}}>
                                        <div style={{marginRight: "5px"}}>
                                            <span style={{fontSize: '10px'}}>Price :</span>
                                            <CurrencyInput
                                                precision="0" 
                                                seq={index}
                                                placeholder="price"
                                                onChangeEvent={this.handleChangeCurrency} 
                                                value={dt.price}
                                                style={styles.inputProduct} 
                                                type="text"
                                            />
                                        </div>
                                        <div style={{width: "50px", marginRight: "5px"}}>
                                            <span style={{fontSize: '10px'}}>Qty :</span>
                                            <input  type="text"
                                                value={dt.qty} 
                                                onChange={(e) => this.setProduct("qty", index, e)}
                                                style={{fontSize: '11px', 
                                                        textAlign: "center", 
                                                        padding: "2px 0px",
                                                        width: "100%",
                                                        border: "1px solid rgb(148, 148, 148)",
                                                        height: "30px", 
                                                        boxSizing: "border-box"}} 
                                               
                                            />
                                        </div>
                                        <div>
                                        <span style={{fontSize: '10px'}}>Total :</span>
                                            <CurrencyInput 
                                                precision="0"
                                                seq={index} 
                                                value={dt.price * dt.qty}
                                                disabled="true"
                                                style={styles.inputProduct} 
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {
                                        (l == idx)
                                        ?
                                            <a onClick={this.addItemProduct}>
                                                <img 
                                                    src="https://img.icons8.com/material-outlined/48/000000/plus--v1.png" 
                                                    style={{width: "24px", opacity: "0.4", marginTop: "3px", marginLeft: "4px"}}
                                                />
                                            </a>
                                        :
                                            <a onClick={() => this.removeItemProduct(index)} style={{marginTop: "3px", marginLeft: "4px"}}>
                                                <img src="https://img.icons8.com/small/64/000000/minus.png"
                                                    style={{width: "24px", opacity: "0.6", marginTop: "3px", marginLeft: "4px"}}
                                                />
                                            </a>
                                    }
                                </div>
                            </div>
        })

        return(
            <>
                {this.state.popup}
                <div id="frm-tech" style={{padding: "20px", marginTop: "65px"}}>
                    <div className="bs-input" style={{marginBottom: "20px"}}>
                        <TitleInput>Customer Name</TitleInput>
                        <input className="frm-input-mbl" 
                            value={this.state.custName}
                            onChange={(e) => this.setState({custName: e.target.value})}
                            placeholder="Customer name" 
                            style={{width: "100%"}}/>
                        <CaptionFilled>Field must be filled</CaptionFilled>
                    </div>
                    <div className="bs-input" style={{marginBottom: "20px"}}>
                        <TitleInput>Contact Name</TitleInput>
                        <input className="frm-input-mbl" 
                            value={this.state.contactName}
                            onChange={(e) => this.setState({contactName: e.target.value})}
                            placeholder="Contact name" 
                            style={{width: "100%"}}/>
                        <CaptionFilled>Field must be filled</CaptionFilled>
                    </div>
                    <div className="bs-input" style={{marginBottom: "20px"}}>
                        <TitleInput>Address</TitleInput>
                        <input className="frm-input-mbl" 
                            value={this.state.address}
                            onChange={(e) => this.setState({address: e.target.value})}
                            placeholder="Address" 
                            style={{width: "100%"}}/>
                        <CaptionFilled>Field must be filled</CaptionFilled>
                    </div>
                    <div className="bs-input" style={{marginBottom: "20px"}}>
                        <TitleInput>Contact</TitleInput>
                        <input className="frm-input-mbl" 
                            value={this.state.contact}
                            onChange={(e) => this.setState({contact: e.target.value})}
                            placeholder="Contact" 
                            style={{width: "100%"}}/>
                        <CaptionFilled>Field must be filled</CaptionFilled>
                    </div>

                    <div className="bs-input" style={{marginBottom: "20px", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        <div className="bs-input" style={{width: "48%"}}>
                            <TitleInput>Start Work</TitleInput>
                            <input className="frm-input-mbl" 
                                value={this.state.start} 
                                onChange={(e) => this.setState({start: e.target.value})}
                                type="date" 
                                style={{width: "100%"}}/>
                        </div>
                        <div className="bs-input" style={{width: "48%"}}>
                            <TitleInput>Finish Work</TitleInput>
                            <input className="frm-input-mbl" 
                                value={this.state.finish}
                                onChange={(e) => this.setState({finish: e.target.value})}
                                type="date" style={{width: "100%"}}/>
                        </div>
                    </div>

                    <div className="bs-input" style={{marginBottom: "20px"}}>
                        <TitleInput>Report Catgory</TitleInput>
                        <select className="frm-input-mbl" 
                            style={{width: "100%"}} 
                            value={this.state.reportCategory}
                            onChange={(e) => this.setState({reportCategory: e.target.value})}
                        >
                            <option value="installation">Installation</option>
                            <option value="service">Service</option>
                            <option value="waranty">Warranty</option>
                            <option value="others">Others</option>
                        </select>
                        {/* <input className="frm-input-mbl" 
                            onChange={(e) => this.setState({reportCategory: e.target.value})}
                            placeholder="Report category" 
                            style={{width: "100%"}}/> */}
                    </div>
                    <div className="bs-input" style={{marginBottom: "20px"}}>
                        <TitleInput>Status</TitleInput>
                        <select className="frm-input-mbl" 
                            style={{width: "100%"}} 
                            value={this.state.status}
                            onChange={(e) => this.setState({status: e.target.value})}
                        >
                            <option value="pending">Pending</option>
                            <option value="on progress">On Progress</option>
                            <option value="finished">Finished</option>
                            <option value="rejected">rejected</option>
                        </select>
                        {/* <input className="frm-input-mbl" 
                            onChange={(e) => this.setState({status: e.target.value})}
                            placeholder="status" 
                            style={{width: "100%"}}/> */}
                    </div>
                    <div className="bs-input" style={{marginBottom: "20px"}}>
                        <TitleInput>Customer Comment</TitleInput>
                        <input className="frm-input-mbl" 
                            value={this.state.comment}
                            onChange={(e) => this.setState({comment: e.target.value})} 
                            placeholder="Customer comment" 
                            style={{width: "100%"}}/>
                        <CaptionFilled>Field must be filled</CaptionFilled>
                    </div>
                    <div className="bs-input" style={{marginBottom: "20px"}}>
                        <TitleInput>Type</TitleInput>
                        <input className="frm-input-mbl" 
                            value={this.state.type}
                            onChange={(e) => this.setState({type: e.target.value})} 
                            placeholder="Type" 
                            style={{width: "100%"}}/>
                        <CaptionFilled>Field must be filled</CaptionFilled>
                    </div>
                    <div className="bs-input" style={{marginBottom: "20px"}}>
                        <TitleInput>Serial Number</TitleInput>
                        <input className="frm-input-mbl"
                            value={this.state.serialNumber} 
                            onChange={(e) => this.setState({serialNumber: e.target.value})}
                            placeholder="Serial number" 
                            style={{width: "100%"}}/>
                        <CaptionFilled>Field must be filled</CaptionFilled> 
                    </div>
                    <div className="bs-input" style={{marginBottom: "20px"}}>
                        <TitleInput>Repair Detail</TitleInput>
                        <input className="frm-input-mbl" 
                            value={this.state.repair}
                            onChange={(e) => this.setState({repair: e.target.value})} 
                            placeholder="Repair detail" 
                            style={{width: "100%"}}/>
                        <CaptionFilled>Field must be filled</CaptionFilled>
                    </div>

                    <div className="bs-input" style={{marginBottom: "20px"}}>
                        <TitleInput>Atachment</TitleInput>
                        <div style={{overflow: "hidden"}}>
                            {
                                this.state.attachment.map((dt, index) => {
                                    return <a onClick={() => this.props.selectFile(dt)}>
                                                <div style={{marginRight: "5px",marginBottom: "8px", float: "left", maxWidth: "90%", display: "flex"}}>
                                                <div style={{padding: "5px 10px", 
                                                        display: "flex",
                                                        background: "#f1f1f1",
                                                        border: "1px solid #d9d9d9",
                                                        borderRadius: "20px",
                                                        float: "left"}}>
                                                    <div style={{fontSize: "11px", fontWeight: "bold"}}>
                                                        {dt.name}
                                                    </div>
                                                </div>
                                                <a onClick={(e) => this.removeAttachment(e, index)}>
                                                    <img src={xIcon}
                                                        style={{width: "10px", 
                                                                height: "10px", 
                                                                background: "#FFF", 
                                                                border: "1px solid #CCC", 
                                                                marginLeft: "-8px",
                                                                padding: "4px", 
                                                                borderRadius: "100%"}}/>
                                                </a>
                                            </div></a>
                                })
                            }

                            <a onClick={this.uploadFile}><div id="atc-s-fls-tch-up-mbl" style={{padding: "5px 10px", 
                                        display: "flex",
                                        background: "#FFF",
                                        border: "1px solid #d9d9d9", 
                                        marginRight: "10px",
                                        marginBottom: "10px",
                                        alignItems: "center",
                                        borderRadius: "20px", 
                                        float: "left"}}>
                                <img src={UploadIcon} style={{width: "18px", marginRight: "5px"}}/>
                                <div style={{fontSize: "11px", fontWeight: "bold"}}>
                                    {
                                        (this.state.fnameUploading == "")
                                        ? "Upload File"
                                        : this.state.fnameUploading
                                    }
                                    <div id="br-atch" style={{background: "#f1f1f1", 
                                                height: "8px", 
                                                width: "100px", 
                                                position: "relative",
                                                display: "none",
                                                border: "1px solid #e4e4e4", 
                                                borderRadius: "5px"}}>
                                        <div id="br-pct-atch" 
                                            ref={this.barPercentUpload} 
                                            style={{height: "100%", 
                                                    width: "0%", 
                                                    background: "rgb(65 163 131)", 
                                                    borderRadius: "5px"}}></div>
                                    </div>
                                </div>
                            </div></a>

                            <input id="tch-wb-ch-99" 
                                    ref={this.inputAttachment}
                                    onChange={this.changeAttachment}
                                    type="file" style={{display: "none"}}/>
                        </div>
                    </div>

                    <div className="bs-input" style={{marginBottom: "20px"}}>
                        <TitleInput>Product</TitleInput>
                        <div id="bs-prod-input" style={{paddingLeft: "10px", borderLeft: "2px solid #CCC"}}>
                            {priceInput}
                            <div style={{background: "rgb(239, 239, 239)", padding: "10px", marginTop: "-5px"}}>
                                Total : &nbsp; 
                                <CurrencyInput 
                                    precision="0"
                                    value={this.getTotal()}
                                    type="text"
                                    disabled="true"
                                    style={{background: "none", border: "none", fontSize: "11px"}}
                                />
                            </div>
                        </div>
                    </div>
                    {/* {
                        (this.state.status == "pending")
                        ?
                            <div className="bs-input" style={{marginBottom: "20px"}}>
                                <TitleInput>Pending Matters</TitleInput>
                                <input className="frm-input-mbl" 
                                    onChange={(e) => this.setState({pending: e.target.value})} 
                                    value={this.state.pending}
                                    placeholder="Pending matters" 
                                    style={{width: "100%"}}/>
                            </div>
                        :
                            ""
                    } */}
                    
                    <div style={{textAlign: "center"}}>
                        {
                            (!this.state.isProcess)
                            ?
                                (!this.isManager() && !this.isFinished())
                                ?
                                    <ButtonSave onClick={this.save}>Save</ButtonSave>
                                :   ""
                            :
                                <ButtonSave style={{opacity: "0.7"}}>Please wait...</ButtonSave>
                        }
                        
                        
                    </div>
                </div>
            </>
        )
    }
}

const styles = {
    inputProduct : {
        fontSize: "11px", 
        height: "30px", 
        width: "100%", 
        padding: "0px 10px",
        borderRadius: "5px",
        boxSizing: "border-box",
        border: "1px solid rgb(148, 148, 148)"
    }
}

export default techniciant_mobile