import React, { Component } from 'react'
import Styled from 'styled-components'

const Base = Styled.div`
    width: 408px;
    height: 73px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 40px;
    background: #DCF3EB;
    border: 0.5px solid #34A770;
    border-radius: 8px;
    z-index: 1000;
`

const Base2 = Styled.div`
    width: 408px;
    height: 73px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 40px;
    background: #FFF8F8;
    border: 0.5px solid #F85640;
    border-radius: 8px;
    z-index: 1000;
`

class popup extends Component{

    componentDidMount(){
        let elm = document.getElementById("popup")
        if(this.props.isMobile){
            elm.style.width = "80%"
            elm.style.top = "60px"
        }

        let wElm = elm.offsetWidth
        let ww = window.innerWidth
        let mlw = ww / 2
        let ml = mlw - (wElm / 2)
        elm.style.left = ml+"px"

        
    }

    render(){
        return(
            <>
                {
                    (this.props.status == "success")
                    ?
                        <Base id="popup">
                            <div style={{padding: "0px 20px", display: "flex", alignItems: "center"}}>
                                {successIcon}
                                <div style={{marginLeft: "20px"}}>
                                    <span style={{color: '#13854E', fontSize: "13px", fontWeight: "bold"}}>Success</span><br/>
                                    <span style={{color: '#10673E', fontSize: "11px"}}>Yeay, your application is success!</span>
                                </div>
                            </div>
                        </Base>
                    :
                        <Base2 id="popup">
                            <div style={{padding: "0px 20px", display: "flex", alignItems: "center"}}>
                                {failIcon}
                                <div style={{marginLeft: "20px"}}>
                                    <span style={{color: '#F85640', fontSize: "13px", fontWeight: "bold"}}>Failed</span><br/>
                                    <span style={{color: '#F85640', fontSize: "11px"}}>
                                        {this.props.message}
                                    </span>
                                </div>
                            </div>
                        </Base2>
                }
            </>
        )
    }
}

const failIcon = <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="14" cy="14" r="14" fill="#F85640"/>
                    <path d="M15.75 8.35L15.51 15.595H13.74L13.485 8.35H15.75ZM14.67 19.105C14.29 19.105 13.975 18.99 13.725 18.76C13.485 18.52 13.365 18.225 13.365 17.875C13.365 17.525 13.485 17.235 13.725 17.005C13.975 16.765 14.29 16.645 14.67 16.645C15.04 16.645 15.345 16.765 15.585 17.005C15.825 17.235 15.945 17.525 15.945 17.875C15.945 18.225 15.825 18.52 15.585 18.76C15.345 18.99 15.04 19.105 14.67 19.105Z" fill="white"/>
                </svg>

const successIcon = <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="14" cy="14" r="14" fill="#34A770"/>
                        <path d="M10.1494 14.6833L13.4492 17.9831L20.0489 11.3835" stroke="white" stroke-width="1.45814" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>


export default popup