import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import Web from './web'
import Mobile from './mobile'
import Success from './success'

class recovery_password extends Component{

    constructor(){
        super()
        this.state = {
            isLoad: true,
            isValid: true,
            isChanged: false,
            windowWidth: window.innerWidth
        }
    }

    componentDidMount(){
       this.fetchData()
       this.setState({
           windowWidth: window.innerWidth
       })
    }

    fetchData = () => {
        let id = this.props.match.params.id
        let url = "/auth/recovery-password/"+id
        axios.get(url).then(response => {
            let resp = response.data
            if(resp.success){
                this.setState({ isValid: true })
            }
        }).catch(error => {
            this.setState({
                isValid: false
            })
        })
        .finally(() => {
            this.setState({
                isLoad: false
            })
        })
    }

    reset = (pass) => {
        let id = this.props.match.params.id
        let url = "/auth/recovery-password/"+id

        let param = {"newPassword": pass}
        axios.post(url, param).then(response => {
            this.setState({
                isChanged: true
            })
        })
    }

    render(){
        return(
            (!this.state.isChanged)
            ?
                (this.state.windowWidth > 1202)
                ?
                    <Web reset={this.reset} 
                        isValid={this.state.isValid} 
                        isLoad={this.state.isLoad}/>
                :
                    <Mobile reset={this.reset} 
                            isValid={this.state.isValid} 
                            isLoad={this.state.isLoad}/>
            :
                <Success/>
        )
    }
}

export default recovery_password