import axios from 'axios'
import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import Styled from 'styled-components'
import { setDataStatusApplicant } from '../redux/action'
import ChecklistGreen from '../images/checklist-green-64.png'
import { relativeTimeThreshold } from 'moment'

const Base = Styled.div`
    background: rgba(0,0,0,0.4);
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 10000;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`

const MainBase = Styled.div`
    max-width: 350px;
    width: 80%;
    background: #FFF;
    border-radius: 5px;
    overflow: hidden;
`

const ButtonSave = Styled.button`
    padding: 5px;
    width: 50px;
    color: #FFF;
    background: #376f9f;
    border-radius: 3px;
    font-weight: 500;
    font-size: 10px;
    margin-right: 5px;
    border: none;
    outline: none;    
`

const ButtonCancel = Styled.button`
    padding: 5px;
    width: 50px;
    color: #000;
    background: #ddd;
    border-radius: 3px;
    font-weight: 500;
    font-size: 10px;
    border: 1px solid #CCC;
    outline: none;    
`

class availability extends Component {

    constructor(){
        super()
        this.state = {
            type: "leave",
            description: "",
            windowWidth: window.innerWidth,
            isSeven: true,
            date: [""]
        }

        this.baseForm = createRef()
        this.baseSuccess = createRef()
    }

    componentDidMount(){
        document.addEventListener("resize", this.resize())
    }

    componentWillUnmount(){
        document.removeEventListener("resize", this.resize())
    }

    resize = () => {
        let ww = window.innerWidth
        this.setState({
            windowWidth: ww
        })
    }

    submit = async () => {
        let url = ""
        let type = this.state.type
        let param = null
        if(type == "busy") url = "/applicant/doBusy"
        
        if(type == "leave") {
            param = []
            this.state.date.map(value => {
                let nwDate = new Date(value)
                
                let date = nwDate.getDate().toString()
                date = (date < 10) ? "0"+date : date.toString()

                let month = parseInt(nwDate.getMonth()) + 1
                month = (month < 10) ? "0"+month : month.toString()
                
                let year = nwDate.getFullYear()
                let obj = {
                    date: date,
                    month: month,
                    reason: this.state.description,
                    year: year.toString()
                }
                param.push(obj)
            })
            url = "/applicant/doLeave"
        }
        
        if(type == "sick") {
            url = "/applicant/doSick"
            param = {
                reason: this.state.description
            }
        }

        try{
            let resp = await axios.post(url, param).then()
            let result = resp.data
            if(result.success){
                let respSts = await axios.get("/applicant/myStatus")
                let data = respSts.data.data
                if(type == "sick"){
                    this.props.setAvailableUser(data)
                    this.props.cancel() /*hide popup*/
                }else{
                    this.baseForm.current.style.display = "none"
                    this.baseSuccess.current.style.display = "block"
                }
            }
        }catch(error){
            let resp = error.response
            let data = resp.data
            let message = data.message
            let elm = document.getElementById("alt-resp-ls")
            elm.innerHTML = message
            elm.style.display = "block"
        }
    }

    changeType = (e) => {
        let v = e.target.value
        this.setState({
            type: v
        })
        let elm = document.getElementById("alt-resp-ls")
        elm.innerHTML = ""
        elm.style.display = "none"
    }

    changeLeaveDate = (e, index) => {
        let date = new Date(e.target.value)
        let now = new Date()
        
        const diffTime = Math.abs(date - now);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        let stateDate = this.state.date
        stateDate[index] = e.target.value

        if(diffDays < 7){
            this.setState({
                date: stateDate,
                isSeven: false
            })
        }else{
            this.setState({
                date: stateDate,
                isSeven: true
            })
        }
    }

    addDate = () => {
        let date = this.state.date
        date.push(new Date())
        this.setState({
            date: date,
            isSeven: false
        })
    }

    minDate = (seq) => {
        let date = [...this.state.date]
        date.splice(seq, 1)

        this.setState({
            date: date
        })
    }

    submitAvilable = async () => {
        let resp = await axios.post("/applicant/doAvailable")
        let data = resp.data
        if(data.success){
            let status = data.data.status
            this.props.setAvailableUser(status)
            this.props.cancel() /*hide popup*/
        }
    }

    render(){
        return(
            <Base>
                <MainBase>
                {
                    (this.state.windowWidth > 1202)
                    ?   <div id="lv-svs-frm" ref={this.baseSuccess} style={{padding: "20px", display: "none"}}>
                            <div style={{display: "flex"}}>
                                <img src={ChecklistGreen} style={{width: "50px", height: "50px", flex: "0 0 50px"}}/>
                                <div style={{fontSize: "14px"}}>Leaving request is successfully, please wait for approval your request.</div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row-reverse", paddingTop: "20px"}}>
                                <ButtonSave onClick={this.props.cancel} className="btn-primary">
                                    Ok
                                </ButtonSave>
                            </div>
                        </div>
                    :
                        <div id="lv-svs-frm" ref={this.baseSuccess} style={{padding: "20px", display: "none"}}>
                            <div style={{display: "flex"}}>
                                <img src={ChecklistGreen} style={{width: "30px", height: "30px", flex: "0 0 30px"}}/>
                                <div style={{fontSize: "12px"}}>Leaving request is successfully, please wait for approval your request.</div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row-reverse", paddingTop: "10px"}}>
                                <ButtonSave onClick={this.props.cancel} className="btn-primary">
                                    Ok
                                </ButtonSave>
                            </div>
                        </div>
                }
                {
                    (!this.props.setAvailable)
                    ?
                        (this.state.windowWidth > 1202) /*wev desktop version*/
                        ?
                            <>
                                <div id="base-form-avaiability" ref={this.baseForm}>
                                    <div style={{fontSize: "14px", fontWeight: "bold", padding: "10px", borderBottom: "1px solid #eaeaea"}}>Set Not Available</div>
                                    <div style={{marginTop: "10px"}}>
                                        <table style={{width: "90%", boxSizing: "border-box"}}>
                                            <tbody>
                                                <tr>
                                                    <td style={{border: "none", padding: "0px 10px"}}/>
                                                    <td style={{border: "none", padding: "0px 10px"}}>
                                                        <div id="alt-resp-ls" 
                                                            style={{background: "#F00", 
                                                                    color: "#FFF", 
                                                                    display: "none",
                                                                    fontSize: "10px", 
                                                                    padding: "3px 10px", 
                                                                    borderRadius: "2px", 
                                                                    marginBottom: "5px"}}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{border: "none", fontSize: "12px", textAlign: "right", padding: "4px 10px", fontWeight: "600"}}>Type</td>
                                                    <td style={{border: "none", padding: "0px 10px"}}>
                                                        <select 
                                                            onChange={this.changeType}
                                                            value={this.state.type} 
                                                            style={{border: "1px solid #cfcfcf", padding: "8px", width: "100px", fontSize: "12px"}}
                                                        >
                                                            {/* <option value="busy">Busy</option> */}
                                                            <option value="leave">Leave</option>
                                                            <option value="sick">Sick</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                                {
                                                    (this.state.type == "leave")
                                                    ?
                                                        <>
                                                        {/* <tr>
                                                            <td style={{border: "none", fontSize: "12px", textAlign: "right", padding: "4px 10px", fontWeight: "600"}}>Days</td>
                                                            <td style={{border: "none", padding: "0px 10px", padding: "4px 10px"}}>
                                                                <input type="text" 
                                                                    value={this.state.date} 
                                                                    onChange={this.changeLeaveDate} 
                                                                    style={{border: (this.state.isSeven) ? "1px solid #cfcfcf" : "1px solid red", fontSize: "12px", padding: "10px", outline: "none", width: "20px"}}/>
                                                            </td>
                                                        </tr> */}
                                                        <tr>
                                                            <td style={{border: "none", fontSize: "12px", textAlign: "right", padding: "4px 10px", fontWeight: "600"}}>Date</td>
                                                            <td style={{border: "none", padding: "0px 10px", padding: "4px 10px"}}>
                                                                {
                                                                    this.state.date.map((value, index) => {
                                                                        let length = this.state.date.length
                                                                        
                                                                        /*validasi seven days*/
                                                                        let date = new Date(value)
                                                                        let now = new Date()          
                                                                        const diffTime = Math.abs(date - now);
                                                                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                                                                        let isSeven = true
                                                                        if(diffDays < 7) isSeven = false

                                                                        return  <div style={{display: "flex", alignItems: "center"}}>
                                                                                    <input type="date" 
                                                                                        value={value} 
                                                                                        onChange={(e) => this.changeLeaveDate(e, index)} 
                                                                                        style={{border: (isSeven) ? "1px solid #cfcfcf" : "1px solid red", fontSize: "12px", padding: "10px", outline: "none"}}/>
                                                                                    &nbsp;&nbsp;&nbsp;
                                                                                    {
                                                                                        (length == parseInt(index) + 1)
                                                                                        ?
                                                                                            <a onClick={this.addDate}>
                                                                                                <div style={{fontSize: "20px", fontWeight: "bold"}}>+</div>
                                                                                            </a>
                                                                                        : 
                                                                                            <a onClick={() => this.minDate(index)}>
                                                                                                <div style={{fontSize: "20px", fontWeight: "bold", color: "#919191"}}>-</div>
                                                                                            </a>
                                                                                    }
                                                                                    
                                                                                </div>
                                                                    })
                                                                }
                                                                <div style={{marginTop: "-2px"}}>
                                                                    {
                                                                        (this.state.isSeven)
                                                                        ? <span style={{fontSize: "10px", color: "#ababab"}}>*Set seven days from now</span>
                                                                        : <span style={{fontSize: "10px", color: "red"}}>*Set seven days from now</span>
                                                                    }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        </>
                                                    : ""
                                                }
                                                {
                                                    (this.state.type != "busy")
                                                    ?
                                                        <tr valign="top">
                                                            <td style={{border: "none", fontSize: "12px", textAlign: "right", padding: "4px 10px", fontWeight: "600"}}>Description</td>
                                                            <td style={{border: "none", padding: "0px 10px", padding: "4px 10px"}}>
                                                                <textarea onChange={(e) => this.setState({description: e.target.value})} 
                                                                    placeholder="type Description here."
                                                                    style={{border: "1px solid #cfcfcf", 
                                                                        width: "100%",
                                                                        resize: "none", 
                                                                        padding: "8px", 
                                                                        fontSize: "12px", 
                                                                        outline: "none", 
                                                                        height: "100px"}}
                                                                />
                                                            </td>
                                                        </tr>
                                                    : ""
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "end", padding: "15px"}}>
                                        {
                                            ((this.state.type == "leave" && (!this.state.isSeven || this.state.date == "" || this.state.description == "")) || (this.state.type == "sick" && this.state.description == ""))
                                            ? <ButtonSave className="btn-primary" style={{opacity: "0.5"}}>Submit</ButtonSave>
                                            : <ButtonSave onClick={this.submit} className="btn-primary">Submit</ButtonSave>
                                        }
                                        <ButtonCancel onClick={this.props.cancel}>Cancel</ButtonCancel>
                                    </div>
                                </div>
                            </> /*web desktop version*/
                        :
                            <div id="avb-mbl-frm" ref={this.baseForm} style={{padding: "0px 20px"}}> {/** wev mobile version */}
                                <div style={{fontSize: "12px", fontWeight: "bold", padding: "10px", borderBottom: "1px solid #eaeaea", textAlign: "center"}}>Set Not Available</div>
                                <div style={{marginTop: "10px"}}>
                                    <table style={{width: "100%", boxSizing: "border-box"}}>
                                        <tbody>
                                            <tr>
                                                <td style={{border: "none", padding: "0px 10px"}}>
                                                    <div id="alt-resp-ls" 
                                                        style={{background: "#F00", 
                                                                color: "#FFF", 
                                                                display: "none",
                                                                fontSize: "10px", 
                                                                padding: "3px 10px", 
                                                                borderRadius: "2px", 
                                                                marginBottom: "5px"}}/>
                                                </td>
                                            </tr>
                                            <tr style={{marginBottom: "10px"}}>
                                                <td style={{border: "none", padding: "5px 10px"}}>
                                                    <div style={{border: "none", fontSize: "10px", fontWeight: "600", marginBottom: "5px"}}>Type :</div>
                                                    <div>
                                                        <select 
                                                            onChange={(e) => this.setState({type: e.target.value})}
                                                            value={this.state.type} 
                                                            style={{border: "1px solid #cfcfcf", padding: "5px", width: "100%", fontSize: "10px"}}
                                                        >
                                                            <option value="leave">Leave</option>
                                                            <option value="sick">Sick</option>
                                                        </select>
                                                    </div>
                                                </td>
                                            </tr>
                                            {
                                                (this.state.type == "leave")
                                                ?
                                                    <tr>
                                                        <td id="dt-mbl-lpp" style={{border: "none", padding: "5px 10px"}}>
                                                            <div style={{border: "none", fontSize: "10px", fontWeight: "600", marginBottom: "5px"}}>Date :</div>
                                                       
                                                            {
                                                                this.state.date.map((value, index) => {
                                                                    let length = this.state.date.length
                                                                    
                                                                    /*validasi seven days*/
                                                                    let date = new Date(value)
                                                                    let now = new Date()          
                                                                    const diffTime = Math.abs(date - now);
                                                                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                                                                    let isSeven = true
                                                                    if(diffDays < 7) isSeven = false

                                                                    return  <div style={{display: "flex", alignItems: "center"}}>
                                                                                <input type="date" 
                                                                                    value={value} 
                                                                                    onChange={(e) => this.changeLeaveDate(e, index)} 
                                                                                    style={{border: (isSeven) ? "1px solid #cfcfcf" : "1px solid red", fontSize: "10px", padding: "5px", outline: "none"}}/>
                                                                                &nbsp;&nbsp;&nbsp;
                                                                                {
                                                                                    (length == parseInt(index) + 1)
                                                                                    ?
                                                                                        <a onClick={this.addDate}>
                                                                                            <div style={{fontSize: "20px", fontWeight: "bold"}}>+</div>
                                                                                        </a>
                                                                                    : 
                                                                                        <a onClick={() => this.minDate(index)}>
                                                                                            <div style={{fontSize: "20px", fontWeight: "bold", color: "#919191"}}>-</div>
                                                                                        </a>
                                                                                }
                                                                                
                                                                            </div>
                                                                    })
                                                            }
                                                            <div style={{marginTop: "-2px"}}>
                                                                {
                                                                    (this.state.isSeven)
                                                                    ? <span style={{fontSize: "10px", color: "#ababab"}}>*Set seven days from now</span>
                                                                    : <span style={{fontSize: "10px", color: "red"}}>*Set seven days from now</span>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                : ""
                                            }
                                            {
                                                (this.state.type != "busy")
                                                ?
                                                    <tr valign="top">
                                                        {/* <td style={{border: "none", fontSize: "12px", textAlign: "right", padding: "4px 10px", fontWeight: "600"}}>Description</td> */}
                                                        <td style={{border: "none", padding: "5px 10px"}}>
                                                            <div style={{border: "none", fontSize: "10px", fontWeight: "600", marginBottom: "5px"}}>Description :</div>
                                                            <textarea onChange={(e) => this.setState({description: e.target.value})} 
                                                                placeholder="type Description here."
                                                                style={{border: "1px solid #cfcfcf", 
                                                                    width: "100%",
                                                                    resize: "none", 
                                                                    padding: "5px", 
                                                                    fontSize: "10px", 
                                                                    outline: "none", 
                                                                    height: "50px"}}
                                                            />
                                                        </td>
                                                    </tr>
                                                : ""
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div id="btn-mbl-lv-xdr" style={{display: "flex", justifyContent: "end", padding: "15px 10px"}}>
                                    {
                                        ((this.state.type == "leave" && (!this.state.isSeven || this.state.date == "" || this.state.description == "")) || (this.state.type == "sick" && this.state.description == ""))
                                        ? <ButtonSave className="btn-primary" style={{opacity: "0.5"}}>Submit</ButtonSave>
                                        : <ButtonSave onClick={this.submit} className="btn-primary">Submit</ButtonSave>
                                    }
                                    <ButtonCancel onClick={this.props.cancel}>Cancel</ButtonCancel>
                                </div>
                            </div> /**web mobile version */
                                            
                    :
                        <>
                            {
                                (this.state.windowWidth > 1202)
                                ?
                                    <> {/*web desktop version */}
                                    <div style={{fontSize: "14px", fontWeight: "bold", padding: "10px", borderBottom: "1px solid #eaeaea"}}>Set Available</div>
                                    <div style={{marginTop: "10px", padding: "0px 10px"}}>
                                        <span style={{fontSize: "14px"}}>Are you sure?, you are already available<br/>for this time?</span>
                                    </div>
                                    </>
                                :
                                    <> {/*web mobile version */}
                                    <div style={{fontSize: "12px", fontWeight: "bold", padding: "10px", borderBottom: "1px solid #eaeaea"}}>Set Available</div>
                                    <div style={{marginTop: "10px", padding: "0px 10px", lineHeight: "1.2"}}>
                                        <span style={{fontSize: "12px"}}>Are you sure?, you are already available<br/>for this time?</span>
                                    </div>
                                    </>
                            }
                            <div style={{display: "flex", justifyContent: "end", padding: "15px"}}>
                                <ButtonSave onClick={this.submitAvilable} className="btn-primary">Submit</ButtonSave>&nbsp;
                                <ButtonCancel onClick={this.props.cancel}>Cancel</ButtonCancel>
                            </div>
                        </>
                }
                </MainBase>
            </Base>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return{
        setAvailableUser : (data) => dispatch(setDataStatusApplicant(data))
    }
}

export default connect(null, mapDispatchToProps) (availability)