import react from 'react'
import { Component } from 'react';
import Header from '../../header/header_mobile';
import Styled from 'styled-components'
import LeaveIcon from '../../../images/exit.png'
import SickIcon from '../../../images/medicine.png'
import LeaveMobile from './leave_mobile'
import SickMobile from './sick_mobile'

const NavMenu = Styled.div`
    display: flex;
    align-items: center;
`

const NavMenuItem = Styled.div`
    width: 50%;
    padding: 10px 0px;
    border-bottom: 3px solid #dcdcdc;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
`

class leave_sick_mobile extends Component{

    constructor(){
        super()
        this.state = {
            position: "leave"
        }
    }

    setPosition = (pos) => {
        this.setState({
            position: pos 
        })
    }

    render(){
        return(
            <div id="bs-mbl-lvsck" style={{marginTop: "65px"}}>
                <Header title="Leave & Sick"/>
                <NavMenu>
                    {
                        (this.state.position == "leave")
                        ?
                            <NavMenuItem style={{color: "rgb(55, 44, 138)", borderBottom: "3px solid rgb(55, 44, 138)"}}>
                                <img src={LeaveIcon} style={{width: "16px"}}/>
                                <div style={{marginLeft: "5px"}}>Leave</div>
                            </NavMenuItem>
                        :
                            <NavMenuItem onClick={() => this.setPosition("leave")} style={{color: "rgb(55, 44, 138)", borderBottom: "3px solid rgb(55, 44, 138)", opacity: "0.3"}}>
                                <img src={LeaveIcon} style={{width: "16px"}}/>
                                <div style={{marginLeft: "5px"}}>Leave</div>
                            </NavMenuItem>
                    }
                    
                    {
                        (this.state.position == "sick")
                        ?
                            <NavMenuItem style={{color: "red", borderBottom: "3px solid red"}}>
                                <img src={SickIcon} style={{width: "16px"}}/>
                                <div style={{marginLeft: "5px"}}>Sick</div>
                            </NavMenuItem>
                        :
                            <NavMenuItem onClick={() => this.setPosition("sick")} style={{color: "red", borderBottom: "3px solid red", opacity: "0.3"}}>
                                <img src={SickIcon} style={{width: "16px"}}/>
                                <div style={{marginLeft: "5px"}}>Sick</div>
                            </NavMenuItem>
                    }
                </NavMenu>
                {
                    (this.state.position == "leave")
                    ? <LeaveMobile dataLeave={this.props.dataLeave} isLoadLeave={this.props.isLoadLeave} fetchLeave={this.props.fetchLeave}/>
                    : <SickMobile dataSick={this.props.dataSick} isLoadSick={this.props.isLoadSick}/>
                }
            </div>
        )  
    }
}

export default leave_sick_mobile