import React from 'react'
import axios from 'axios'
import {errorFetch} from '../component/function/function'

export default class main {

    fetchApi = async (url, method, payload) => {
        // console.log("fetch api main service")
        let resp = ""
        if(method.toLowerCase() == "post"){
            try{
                resp = await axios.post(url, payload)
                return resp;
            }catch(error){
                this.catchError(error)
            }
        }

        if(method.toLowerCase() == "get"){
            try{
                resp = await axios.get(url)
                return resp;
            }catch(error){
                this.catchError(error)
            }
        }

        if(method.toLowerCase() == "put"){
            try{
                resp = await axios.put(url, payload)
                return resp;
            }catch(error){
                this.catchError(error)
            }
        }

        if(method.toLowerCase() == "delete"){
            try{
                resp = await axios.delete(url, payload)
                return resp;
            }catch(error){
                this.catchError(error)
            }
        }
    }

    catchError = (error) => {
        let errResp = error.response
        if(errResp){
            let status = errResp.status
            errorFetch(status)
        }else{

        }
    }
}