import React from 'react'
import MainService from './main'
const baseUrlSuperior = "/superior"

export default class superior_service {
    
    fetchMultipleSchedules = async () => {
            let url = baseUrlSuperior+"/getScheduleApplicantMultiple?size=1000"
            let mainService = new MainService();
            let resp = await mainService.fetchApi(url, 'get')
            return resp;
    } 

}