import axios from 'axios'
import Header from '../../header/header'
import Web from './admin_web'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setDataUser } from '../../../redux/action'

class admin extends Component{

    constructor(){
        super()
        this.state = {
            statusData: [],
            roleData: [],
            regions: []
        }
    }

    componentDidMount(){
        this.fetchUser()
        this.fetchStatus()
        this.fetchRole()
        this.fetchRegion()
    }

    fetchUser = () => {
        if(!this.props.isLoaded){
            axios.get("/user?size=1000").then(response => {
                let resp = response.data
                let obj = {}
                obj.data = resp.data
                obj.total = resp.result.total
                this.props.setDataUser(obj)
            })
        }
    }

    fetchStatus = () => {
        axios.get("/status/getStatusesUser").then(response => {
            let resp = response.data
            let data = resp.data
            this.setState({
                statusData: data
            })
        })
    }

    fetchRegion = () => {
        axios.get("/hospital/getRegions").then(response => {
            let result = response.data
            let regions = result.data
            this.setState({
                regions: regions
            })
        })
    }

    fetchRole = () => {
        axios.get("/role/getRolesRegister").then(resp => {
            let roles = resp.data.data
            this.setState({
                roleData: roles
            })
        })
    }

    render(){
        return(
            <>
                <Header/>
                <Web data={this.props.dataUser} 
                    dataStatus={this.state.statusData}
                    roleData={this.state.roleData}
                    regions={this.state.regions}
                />
            </>
        )
    }
}

const mapStateToProps = state => {
    return{
        dataUser: state.dataUser.data,
        total: state.dataUser.total,
        page: state.dataUser.page,
        isLoaded: state.dataUser.isLoaded
    }
}

const mapDispatchToProps = dispatch => {
    return{
        setDataUser: (data) => dispatch(setDataUser(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (admin)