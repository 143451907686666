import {
    setDataReportAction, 
    setDataUserSalesAction, 
    setPageReportAcrtion, 
    updateDataReportAction,
    updateDataUserSalesAction,
    searchRreportByNameAction,
    setDataUserAction,
    setPageDataUserSalesAction,
    setPageDataUserAction,
    searcDataUserAction,
    setLoadedFlsUser,
    searchDataUserSalesByNameAction,
    filterReportAction,
    setAvailableAction,
    setDataApplicantAction,
    setPageDataApplicantAction,
    setDataStatusApplicantAction,
    searchRsApplicantAction,
    filterStatusAction,
    filterStatusApplicantAction,
    setSortReportAction,
    setDataCalendarAction
} from './action_type'

export const setDataReport = (data) => {
    return {
        data: data,
        type: setDataReportAction
    }
}

export const setPageReport = (data) => {
    return{
        data: data,
        type: setPageReportAcrtion
    }
}

export const updateDataReport = (data) => {
    return{
        data: data,
        type: updateDataReportAction
    }
}

export const filterReport = (data) => {
    return{
        type: filterReportAction,
        data: data
    }
}

export const setDataUserSales = (data) => {
    return{
        data: data,
        type: setDataUserSalesAction
    }
}

export const updateDataUserSales = (data) => {
    return{
        data: data,
        type: updateDataUserSalesAction
    }
}

export const setDataStatusApplicant = (data) => {
    return{
        data: data,
        type: setDataStatusApplicantAction
    }
}

export const setDataApplicant = (data) => {
    return{
        data: data,
        type: setDataApplicantAction
    }
}

export const filterStatus = (data) => {
    return{
        type: filterStatusAction,
        data: data
    }
}

export const filterStatusApplicant = (idStatus) => {
    return{
        type: filterStatusApplicantAction,
        data: idStatus
    }
}

export const setDataCalendar = (data) => {
    return{
        type: setDataCalendarAction,
        data: data
    }
}

// export const updateDataUserSales = (data) => {
//     return{
//         data: data,
//         type: updateDataUserSalesAction
//     }
// }

export const searchReportByName = (name) => {
    return{
        data: name,
        type: searchRreportByNameAction
    }
}

export const setSortReport = (sort, type) => {
    return{
        sort: sort,
        typeSort: type,
        type: setSortReportAction
    }
}

export const seacrhRsApplicantByName = (name) => {
    return{
        data: name,
        type: searchRsApplicantAction
    }
}

export const setDataUser = (data) => {
    return{
        data: data,
        type: setDataUserAction
    }
}

export const setPageDataUser = (data) => {
    return{
        data: data,
        type: setPageDataUserAction
    }
}

export const setPageDataUserSales = (data) => {
    return{
        type: setPageDataUserSalesAction,
        data: data
    }
}

export const setPageDataApplicant = (data) => {
    return{
        data: data,
        type: setPageDataApplicantAction
    }
}

export const searchDataUserSales = (data) => {
    return{
        type: searchDataUserSalesByNameAction,
        data: data
    }
}

export const searchDataUser = (data) => {
    return{
        type: searcDataUserAction,
        data: data
    }
}

export const setLoadedFalseUser = () => {
    return{
        type: setLoadedFlsUser
    }
}

export const setAvailable = () => {
    return{
        type: setAvailableAction
    }
}