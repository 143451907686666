import React, { Component } from 'react'
import Mobile from './index_mobile'
import Web from './index_web'

class index extends Component{

    constructor(){
        super()
        this.state = {
            windowWidth: window.innerWidth,
            // isLogedIn : false,
            // checking : true
        }
    }

    componentDidMount(){
        this.setState({windowWidth: window.innerWidth})
        window.addEventListener("resize", () => {
            this.setState({windowWidth: window.innerWidth})
        })
    }

    render(){
        return(
            <>
            {
                (this.state.checking)
                ?
                    <div>please wait..</div>
                :
                    (!this.state.isLogedIn)
                    ?
                        (this.state.windowWidth > 1202)
                        ?
                            <Web/>
                        :
                            <Mobile/>
                    :
                        <div>please wait..</div>
            }          
            </>
        )
    }
}

export default index