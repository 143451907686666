import React from 'react'
import MainService from './main'
let baseUrlApplicant = "/applicant"

export default class applicant_service {

    doDeny = async (id, reason) => {
        let url = baseUrlApplicant+"/doDeny"
        let obj = {
            reason: reason,
            scheduleId: id
        }
        
        let resp = await new MainService().fetchApi(url, "post", obj)
        return resp
    }

    fetchDataApi = async () => {
        let url = baseUrlApplicant+"/getSchedules?size=1000"
        let resp = await new MainService().fetchApi(url, "get")
        return resp
    }

    fetchStatus = async () => {
        let url = baseUrlApplicant+"/myStatus"
        let resp = new MainService().fetchApi(url, "get")
        return resp
    }

}