import React, { Component } from 'react'
import Web from './user_sales_web'
import Mobile from './user_sales_mobile'
import axios from 'axios'
import Popup from '../../popup_save_schedule'
import Loader from '../../loader'
import Header from '../../header/header'
import HeaderMobile from '../../header/header_mobile'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'
import { setDataCalendar, setDataUser, setDataUserSales } from '../../../redux/action'
import NotFoundData from '../../not_found_data'
import { ca, ro } from 'date-fns/locale'
import { getRoleId, getRoleName } from '../../function/read_token'
import { errorFetch } from '../../function/function'
import sales_services from '../../../service/sales_service'

class user_sales extends Component{

    constructor(){
        super()
        this.state = {
            windowWidth : window.innerWidth,
            region: [],
            employeesAppl: [],
            inPorcess: false,
            fetchProcess: true,
            isSuccess: false,
            popup: "",
            clearState: false,
            dataApplicant: "",
            isNotFound: false,
            region: "00000000-0000-0000-0000-000000002001"
        }
    }

    componentDidMount(){
        window.addEventListener("resize", this.resize)
        let role = getRoleName()
        this.fetchDataApplicant()
        this.fetchRegion()
    }

    resize = () => {
        let ww = window.innerWidth
        this.setState({
            windowWidth: ww
        })
    }

    fetchDataApplicant = () => {
        let role = getRoleName()
        let url = (role.toLowerCase() == "user sales") ? "sales/getApplicantForm/" : "superior/getApplicantForm/"
        if(role.toLowerCase() == "applicant") url = "applicant/getApplicantForm/"
        
        let id = this.props.match.params.id
        if(id == "form") return false

        try{
            axios.get(url+id).then(response => {
                let resp = response.data
                let data = resp.data
                // this.fetchEmloyeeAppl(data)
                this.setState({
                    dataApplicant: data,
                    fetchProcess: false
                })
            })
        }catch(error){
            let response = error.response
            errorFetch(response.status)
        }
    }

    save = async (
        rsSelected,
        // rsName,
        contactPerson,
        bookDate,
        kindAct,
        describe,
        typeTools,
        additionaAcc,
        proofAccDocumentId,
        isTricefy,
        addressRs,
        startDate,
        endDate,
        hasSameApplicant,
        regionId
        ) => {

        this.setState({
            inPorcess: true
        })

        let rsId = rsSelected.id
        let rsName = rsSelected.name
        let isMobilePop = (window.innerWidth < 1201) ? true : false

        let objSave = {
            additionalAccessories: additionaAcc,
            contactPerson: contactPerson,
            dateBooks: (!hasSameApplicant) ? bookDate : [],
            describeOther: describe,
            hospitalId: rsId,
            regionId: regionId,
            hospitalName: rsName,
            kindActivity: kindAct,
            typeTools: typeTools,
            trainingTerms: "string",
            demoTerms: "string",
            proofAccDocumentId: proofAccDocumentId,
            includeTricefy: isTricefy,
            address: addressRs,
            hasSameApplicant: hasSameApplicant,
            startDate: (hasSameApplicant) ? startDate : "",
            endDate: (hasSameApplicant) ? endDate : ""
        }

        // console.log(objSave)
        // return false;

        let data = await axios.post("/sales/createSchedule", objSave).then(response => 
        {
            let resp = response.data
            if(resp.success){
                this.setState({popup: <Popup status="success" isMobile={isMobilePop}/>})
                this.fetchDataAfterSave() /*for refresh data table*/
                this.fetchDataCalendar() /*refresh data calendar*/
            }else{
                this.setState({popup: <Popup status="failed" message="ini adalah message error" isMobile={isMobilePop}/>})
            }

            this.setState({
                inPorcess: false,
                clearState: true,
            })

            this.clearInput()
            this.hidepopup()
            return "success"
        }).catch(error => {
            let rsp = error.response
            let msg = rsp.data.message
            this.setState({popup: <Popup status="failed" message={msg} isMobile={isMobilePop}/>})
            this.setState({
                inPorcess: false,
            })

            this.clearInput()
            this.hidepopup()
            return "failed"
        })

        return data
    }

    hidepopup = () => {
        setTimeout(() => {
            this.setState({popup: ""})
        }, 3000)
    }

    clearInput = () => {
        /*mobile input*/
        let input = document.getElementsByClassName("frm-input-mbl")
        for(let i = 0;i<input.length;i++){
            let ipt = input[i]
            ipt.value = ""
        }

        this.setState({
            clearState: true
        })
    }

    falseClearState = () => {
        this.setState({
            clearState: false
        })
    }

    fetchDataAfterSave = () => {
        let role = getRoleName()
        let url = (role.toLowerCase() == "user sales") ? "/sales/getSchedules" : "/superior/getApplicantSchedules"
        axios.get(url+"?size=1000").then(response => {
            let result = response.data
            let dataSales = result.data
            let total = result.result.total
            
            let data = {}
            data.result = dataSales
            data.total = total
            this.props.setDataUserSales(data)
        })
    }

    fetchRegion = () => {
        axios.get("/hospital/getRegions").then(response => {
            let result = response.data
            let regions = result.data
            this.setState({
                region: regions
            })
        })
    }

    convertDateCalender(date){
        let char = date.split(" ")

        let day = char[1].replace(",", "");
        let year = char[2]
        let month = char[0].toLowerCase()
        let numMonth = ""

        /*month*/
        if(month == "january") numMonth = "01"
        if(month == "february") numMonth = "02"
        if(month == "march") numMonth = "03"
        if(month == "april") numMonth = "04"
        if(month == "may") numMonth = "05"
        if(month == "june") numMonth = "06"
        if(month == "july") numMonth = "07"
        if(month == "august") numMonth = "08"
        if(month == "september") numMonth = "09"
        if(month == "october") numMonth = "10"
        if(month == "november") numMonth = "11"
        if(month == "december") numMonth = "12"

        return day+"/"+numMonth+"/"+year
    }

    setEmployee = (employeeId) => {
        let data = this.state.dataApplicant
        let applId = data.id
        let empId = employeeId

        if(empId == ""){
            this.setState({
                isSuccess: true
            })
            return false
        }

        /*creat json object*/
        let obj = {}
        obj.applicantId = applId
        obj.empId = empId

        this.setState({
            inPorcess: true
        })

        axios.post("/applicant/setManualApplicant", obj).then(response => {
            let resp = response.data
            let data = resp.data
            let id = data.id

            let dataRedux = this.props.dataUserSales
            let list = dataRedux.data
            let newData = list.map(dt => {
                if(dt.id == id){
                    dt = data
                }
                return dt
            })

            dataRedux.data = newData
            /*set new data to redux*/
            this.props.setDataUserSales(dataRedux)
            this.setState({
                inPorcess: false,
                isSuccess: true
            })
        })
    }

    fetchDataCalendar = async () => {
        let salesService = new sales_services()
        let now = new Date()
        let m = parseInt(now.getMonth()) + 1
        m = (m < 10) ? "0"+m : m
        let y = now.getFullYear()
        
        let param = {
            year: y.toString(),
            month: m.toString(),
            regionTypeId: this.state.region
        }

        let resp = await salesService.fetchScheduleCalendar(param)
        this.props.setDataCalendarRdx(resp)
    }

    render(){
        return(
            <>
            {
                (
                    getRoleId() != "00000000-0000-0000-0000-000000000010" && 
                    getRoleId() != "00000000-0000-0000-0000-000000000014" &&
                    getRoleId() != "00000000-0000-0000-0000-000000000013"
                )
                ? <Redirect to="/dashboard"/>
                : ""
            }
            {this.state.popup}
            {
                (this.state.isSuccess)
                ? <Redirect to="/dashboard"/>
                : ""
            }
            {
                (this.state.windowWidth > 1202)
                ?
                    <>
                        <Header/>
                        {
                            (this.props.match.params.id != "form" && this.state.fetchProcess)
                            ?
                                <Loader/>
                            :
                                (this.state.isNotFound)
                                ?
                                    <NotFoundData/>
                                :    
                                    <Web inProcess={this.state.inPorcess}
                                        isForm={(this.props.match.params.id != "form") ? false : true}
                                        convertDateCalender={this.convertDateCalender}
                                        id={this.props.match.params.id}
                                        clearState={this.state.clearState}
                                        data={this.state.dataApplicant} 
                                        isSuccess={this.state.isSuccess}
                                        employeesAppl={this.state.employeesAppl}
                                        falseClearState={this.falseClearState}
                                        region={this.state.region} 
                                        setEmployee={this.setEmployee}
                                        save={this.save}/>
                        }
                    </>
                :
                    <>
                    <HeaderMobile title="User sales"/>
                    {
                        (this.props.match.params.id != "form" && this.state.fetchProcess)
                        ? 
                            <Loader/>
                        :
                            (this.state.isNotFound)
                            ?
                                <NotFoundData/>
                            :
                                <Mobile inProcess={this.state.inPorcess} 
                                    id={this.props.match.params.id}
                                    clearState={this.state.clearState}
                                    falseClearState={this.falseClearState}
                                    isSuccess={this.state.isSuccess}
                                    employeesAppl={this.state.employeesAppl}
                                    data={this.state.dataApplicant}
                                    region={this.state.region} 
                                    setEmployee={this.setEmployee}
                                    save={this.save}/>
                    }
                    </>
            }
            </>
            
        )
    }
}

const mapStateToProps = state => {
    return{
        dataUserSales: state.dataUserSales,
    }
}

const mapDispatchToProps = dispatch => {
    return{
        setDataUserSales: (data) => dispatch(setDataUserSales(data)),
        setDataCalendarRdx: (data) => dispatch(setDataCalendar(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (user_sales)