import React, { Component } from 'react'
import ApprIcon from '../images/quality.png'
import DenyIcon from '../images/multiply.png'
import DoneIcon from '../images/check-blue.png'
import SickIcon from '../images/medicine.png'
import PendingIcon from '../images/sand-clock.png'

export default class status extends Component {
    statusConfrm = () => {
        return [ 
            {
                "id":"00000000-0000-0000-0000-100000000050",
                "name":"Approved",
                "color": "#126A65",
                "icon": ApprIcon
            },
            {
                "id":"00000000-0000-0000-0000-100000000070",
                "name":"Done",
                "color": "#126A65",
                "icon": DoneIcon
            },
            {
                "id":"00000000-0000-0000-0000-100000000060",
                "name":"Deny",
                "color": "#126A65",
                "icon": DenyIcon
            },
            {
                "id":"00000000-0000-0000-0000-100000000030",
                "name":"Sick",
                "color": "#126A65",
                "icon": SickIcon
            },
        ]
    }
    
    statusFlag = () => {
        return [ 
            {
                "id":"00000000-0000-0000-0000-aa0000000020",
                "name":"Approved",
                "color": "#126A65",
                "icon": ApprIcon
            },
            {
                "id":"00000000-0000-0000-0000-aa0000000040",
                "name":"Done",
                "color": "rgb(76 110 186)",
                "icon": DoneIcon
            },
            {
                "id":"00000000-0000-0000-0000-aa0000000030",
                "name":"Deny",
                "color": "#E21B1B",
                "icon": DenyIcon
            },
            {
                "id":"00000000-0000-0000-0000-ae0000000010",
                "name":"Sick",
                "color": "#F00",
                "icon": SickIcon
            },
            {
                "id":"00000000-0000-0000-0000-aa0000000010",
                "name":"Pending",
                "color": "#676767",
                "icon": PendingIcon
            }
        ]
    }
}
