import React, { Component, Fragment } from 'react'
import LandingImg from './images/welcome.png'
import LandingImg2 from './images/Frame 184 5.png'
import Styled from 'styled-components'
import { Link } from 'react-router-dom'
import Header from '../header_mobile_transparant'

const LoginButton = Styled.button`
    width: 292px;
    height: 41px;
    background: #1F4788;
    border-radius: 6px;
    color: #FFF;
    font-size: 14px;
    border: 1px solid #1F4788;
    margin-bottom: 25px;
` 

const RegsiterButton = Styled.button`
    width: 292px;
    height: 41px;
    border: 1px solid #1F4788;
    box-sizing: border-box;
    border-radius: 6px;
    margin-bottom: 50px;
    color: #1F4788;
    font-size: 14px;
`

export default class index_mobile extends Component{
    render(){
        return(
            <Fragment>
                <Header/>
                <div style={{textAlign: "center", marginTop: "50px"}}>
                    <div style={{fontSize: "20px",fontWeight: "600",textAlign: "center",color: "#1F4788"}}>
                        Beyond Medical Service<br/>and Report
                    </div>
                    <img src={LandingImg} style={{margin: "auto", marginTop: "50px"}}/>
                    <img src={LandingImg2} style={{marginBottom: "50px"}}/>
                    <br/>
                    <Link to="/login">
                        <LoginButton>Login</LoginButton>
                    </Link>
                    <br/>
                    <Link to="/register">
                        <RegsiterButton>Register</RegsiterButton>
                    </Link>
                </div>
            </Fragment>
        )   
    }
}