import './style/main.css';
import Dashboard from './component/dashboard/dahsboard'
import LandingPage from './component/landing_page'
import Login from './component/auth/Login'
import Register from './component/auth/Register'
import ForgotPassword from './component/auth/Forget_password'
import Profile from './component/profile/profile'
import RecoveryPassword from './component/recovery-password'
import axios from 'axios'
import axios2 from 'axios'
import NotFound from './component/404'
import EmailPage from './component/email/index'
import { baseUrl } from './environment'
import { logout } from './component/function/function'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

/*get token*/
let dataLogin = window.localStorage.getItem("token")
let token;
if (dataLogin != null) {
  dataLogin = JSON.parse(window.localStorage.getItem("token"))
  token = dataLogin.token
}


/*set bse url axios*/
axios.defaults.baseURL = baseUrl;
if(token != null && token !== undefined) {
  axios.defaults.headers.common['Authorization'] = "Bearer "+token;
  axios2.defaults.headers.common['Authorization'] = "Bearer "+token;
}

const pathName = window.location.pathname

const isLogedIn = ( component ) => {
    if(token != null && token != ""){
      return <Redirect to="/dashboard"/>
    } else {
      return component
    }
}

const isLogedOut = (component) => {
  if(token !== undefined){
    if(token == null || token == ""){
      console.log(token)
      return <Redirect to="/"/>
    } else {
      if(component) return component
    }
  }else{
    return <Redirect to="/"/>
  }
}

function App() {

  // useEffect(() => {
  //   alert("dandi rahmawan")
  // })

  return (
    <div className="App">
      <Router>
          <Switch>
            {/* <> */}
              <Route exact path="/">
                {isLogedIn(<LandingPage/>)}
              </Route>
              {/* <Route exact path="/logout"> */}
                {/* {logout()} */}
                {/* {isLogedOut(<Login/>)} */}
              {/* </Route> */}
              <Route path="/email">
                <EmailPage/>
              </Route>
              <Route exact path="/profile">
                  {isLogedOut(<Profile/>)}
              </Route>
              <Route path="/dashboard">
                  {isLogedOut(<Dashboard/>)}
              </Route>
              <Route exact path="/login">
                  {isLogedIn(<Login/>)}
              </Route>
              <Route exact path="/register">
                  {isLogedOut(<Register/>)}
              </Route>
              <Route exact path="/forgot_password">
                  {isLogedIn(<ForgotPassword/>)}
              </Route>
              <Route path="/recovery-password/:id" component={RecoveryPassword}/>
              <Route path="/**" component={NotFound}/>
            {/* </> */}
          </Switch>
      </Router>
    </div>
  );
}

export default App;
