import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Item from './item_mobile'
import { getRoleName } from '../../function/read_token'
import { connect } from 'react-redux'
import { setPageDataUserSales } from '../../../redux/action'
import ConfirmDelete from './delete_confirm'
import axios from "axios";
import { setDataUserSales } from "../../../redux/action"
import FilterDsb from '../../../images/filter-dsb.png'
import FilterAtv from '../../../images/filter-atv.png'
import FilterMobile from './filter_mobile'
import PreviewImage from '../../preview_image_appl_report'
import { isManagerApplicant } from '../../function/function'
import DataStatus from '../../../data/status'

let size = 0
let DS = new DataStatus()

class director_mobile extends Component{

    constructor(){
        super()
        this.state = {
            popup: ""
        }
    }

    setPagePosition = (type) => {
        let currPage = this.props.page
        if(type == "next"){
            if(currPage < size){
                currPage += 1
            }
        }else{
            if(currPage > 1){
                currPage -= 1
            }
        }
        
        /*redux props action*/
        this.props.setPage(currPage)
    }

    lastPage = () => {
        this.props.setPage(size)
    }

    firstPage = () => {
        this.props.setPage(1)
    }

    delete = (id) => {
        this.setState({
            popup: <ConfirmDelete no={() => this.setState({popup: ""})} yes={() => this.yesDelete(id)}/>
        })
    }

    delete = (id) => {
        this.setState({
            popup: <ConfirmDelete no={() => this.setState({popup: ""})} yes={() => this.yesDelete(id)}/>
        })
    }

    yesDelete = (id) => {
        axios.delete("/applicant/cancelApplicant/"+id).then(response => {
            let resp = response.data
            if(resp.success){
                this.setDataAfterDeleteRedux(id)
                this.setState({
                    popup: ""
                })
            }
        })
    }

    filter = () => {
        this.setState({
            popup: <FilterMobile hideFilter={() => this.setState({popup: ""})}/>
        })
    }

    setDataAfterDeleteRedux = (id) => {
        let dataSales = this.props.dataUserSales
        let data = [...dataSales.data]
        let idx = 0
        for(let i = 0;i<data.length;i++){
            if(id == data[i].id){
                idx = i
                break
            }
        }

        /*delete array index*/
        data.splice(idx, 1)

        /*set new data to redux*/
        let newData = {}
        let total = dataSales.total - 1
        newData.result = data
        newData.total = total

        /*parse data to redux*/
        this.props.setDataUserSales(newData)
    }

    previewImage = (data) => {
        this.setState({
            popup: <PreviewImage data={data} hideImage={() => this.setState({popup: ""})}/>
        })
    }

    setItem = (dt, index, seq) => {
        let filterParam = this.props.dataUserSales.filterStatus
        let idFilterParam = filterParam.id

        let isView = false
        let data = {seq: 0, item: null}

        let st = (this.props.page > 1) ? (this.props.page * 10) - 9 : this.props.page
        let ed = this.props.page * 10

        let search = this.props.searchParam
        let userApplicant = (dt.userApplicant) ? dt.userApplicant.userProfile.name : ""
        // let rsName = dt.formApplicant.hospital.name
        let rsName = dt.formApplicant.hospitalName
        let contactPerson = dt.formApplicant.contactPerson

        /*validasi searching schedules*/
        if(
            search == 0 || 
            rsName.toLowerCase().match(search.toLowerCase()) || /*search by rs name*/
            userApplicant.toLowerCase().match(search.toLowerCase()) || /*search by applicant*/
            contactPerson.toLowerCase().match(search.toLowerCase()) /*search by contact person*/
        ){
            isView = true
        }

        /*data status schedules*/
        let confirmStatus = dt.flagStatus
        let statusId = confirmStatus.id

        /*validasi filter status schedule*/
        if(idFilterParam != "" && idFilterParam != statusId){
            isView = false
        }

        if(isView) {
            seq++
            data.seq = seq
        }

        let idx = parseInt(index) + 1
        let len = this.props.data.length
        if(idx == len){
            size = Math.ceil(seq / 10)
        }
        
        if(seq >= st && seq <= ed && isView){
            data.item = <Item previewImage={this.previewImage} 
                            data={dt} 
                            key={index} 
                            delete={() => this.delete(dt.id)}/>
        }

        return data
    }

    setItemDirector = (dt, index, seq) => {
        let filterParam = this.props.dataUserSales.filterStatus
        let idFilterParam = filterParam.id

        let isView = false
        let data = {seq: seq, item: null}

        let st = (this.props.page > 1) ? (this.props.page * 10) - 9 : this.props.page
        let ed = this.props.page * 10

        let search = this.props.searchParam
        let userApplicant = dt.userApplicant.userProfile.name
        let rsName = dt.formApplicant.hospital.name
        let contactPerson = dt.formApplicant.contactPerson
        let sales = dt.formApplicant.salesUser.name
        // console.log("sales "+sales)

        /*validasi searching schedules*/
        if(
            search == 0 || 
            sales.toLowerCase().match(search.toLowerCase()) || /*search sales name*/
            rsName.toLowerCase().match(search.toLowerCase()) || /*search by rs name*/
            userApplicant.toLowerCase().match(search.toLowerCase()) || /*search by applicant*/
            contactPerson.toLowerCase().match(search.toLowerCase()) /*search by contact person*/
        ){
            isView = true
        }

        /*data status schedules*/
        let confirmStatus = dt.flagStatus
        let statusId = confirmStatus.id
        /*validasi filter status schedule*/
        if(idFilterParam != "" && idFilterParam != statusId){
            isView = false
        }

        if(isView) {
            seq++
            data.seq = seq
        }

        let idx = parseInt(index) + 1
        let len = this.props.data.length
        if(idx == len){
            size = Math.ceil(seq / 10)
        }
        
        if(seq >= st && seq <= ed && isView){
            data.item = <Item previewImage={this.previewImage} 
                            data={dt} 
                            key={index} 
                            delete={() => this.delete(dt.id)}/>
        }

        return data
    }

    render(){
        let seq = 0
        let filterParam = this.props.dataUserSales.filterStatus
        let idFilterParam = filterParam.id
        let filterName = filterParam.name

        const data = this.props.data.map((dt, index) => {
            if(!isManagerApplicant()){
                let data = this.setItem(dt, index, seq)
                seq = data.seq
                return data.item
            }else{
                let data = this.setItemDirector(dt, index, seq)
                seq = data.seq
                return data.item
            }
        })

        return(
            <>
                {/* <PreviewImage/> */}
                {this.state.popup}
                <div id="wrp-hdr-content" 
                    style={{position: "fixed", 
                            width: "100%", 
                            top: "64px", 
                            zIndex: "1", 
                            background: "#FFF", 
                            boxShadow: "2px 2px 2px #ededed",
                            borderBottom: "1px solid #dedede"}}
                >
                    <div style={{padding: "20px", 
                                fontSize: "14px",
                                paddingTop: "15px", 
                                paddingBottom: "15px", 
                                display: "flex",
                                borderTop: "1px solid #dedede",
                                justifyContent: "space-between", 
                                alignItems: "center"}}>
                        <span style={{fontSize: "12px", fontWeight: "bold"}}>Showing {this.props.page} of {size} entries</span>
                        <div style={{display: "flex", alignItems: "center"}}>
                            {
                                (getRoleName().toLowerCase() == "user sales")
                                ?
                                    <Link to={"/dashboard/user_sales/form"}>
                                    <button style={{fontSize: "12px", 
                                                    color: "#7784EE", 
                                                    fontWeight: "bold", 
                                                    padding: "3px 10px", 
                                                    background: "none", 
                                                    border: "none", 
                                                    borderRight: "1px solid #CCC", 
                                                    marginRight: "10px"}}>
                                        + Create
                                    </button>
                                    </Link>
                                :   ""
                            }
                            <a onClick={this.filter}>
                                {
                                    (idFilterParam == "")
                                    ?
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <img src={FilterDsb} style={{width: "14px", opacity: "0.5"}}/>
                                            &nbsp;
                                            <div style={{fontSize: "12px", fontWeight: "bold", color: "#878787"}}>Filter</div>
                                        </div>
                                    :
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <img src={FilterAtv} style={{width: "14px"}}/>
                                            &nbsp;
                                            <div style={{fontSize: "12px", fontWeight: "bold", color: "#000"}}>
                                                {filterName}
                                            </div>
                                        </div>
                                }
                            </a>
                        </div>
                        
                    </div>
                </div>
                <div style={{marginTop: "120px"}}>
                    {
                        (this.props.data.length <= 0)
                        ?   <div style={{padding: "50px 20px", textAlign: "center", fontWeight: "bold", fontSize: "14px", color: "#878787", marginTop: "50px"}}>
                                You don't have<br/>any schedule for currently
                            </div>
                        :   <>
                            {data}
                            <div style={{textAlign: "center", padding: "30px 0px"}}>
                                <button className="img-pgn" onClick={this.firstPage}>First</button>
                                <button className="img-pgn" onClick={() => this.setPagePosition("prev")}>Prev</button>
                                <button className="img-pgn">{this.props.page}</button>
                                <button className="img-pgn" onClick={() => this.setPagePosition("next")}>Next</button>
                                <button className="img-pgn" onClick={this.lastPage}>Last</button> 
                            </div>
                            </>
                    }
                   
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return{
        page: state.dataUserSales.page,
        searchParam: state.dataUserSales.searchName,
        dataUserSales: state.dataUserSales
    }
}

const mapDispatchToProps = dispatch => {
    return{
        setPage: (data) => dispatch(setPageDataUserSales(data)),
        setDataUserSales: (data) => dispatch(setDataUserSales(data))
    }
} 

export default connect(mapStateToProps, mapDispatchToProps) (director_mobile)