import React from 'react';
import './SignAnim.css';
import HalfCircle1 from './shape/HalfCircle1.svg';
import List from './shape/List.svg';
// import Bubble1Purple from './shape/Bubble1Purple.svg';
import Bubble1Purple from './shape/Bubble1Purple.png';
// import Chat from './shape/Chat.svg';
import Chat from './shape/Chat.png';
import ListDots from './shape/ListDots.svg';
// import Flag from './shape/Flag.svg';
import Flag from './shape/Flag.png';
import HalfCircle2 from './shape/HalfCircle2.svg';
import ListZigzag from './shape/ListZigzag.svg';
// import Hand from './shape/Hand.svg';
import Hand from './shape/Hand.png';
// import Bubble2Yellow from './shape/Bubble2Yellow.svg';
import Bubble2Yellow from './shape/Bubble2Yellow.png';
import HalfCircle3 from './shape/HalfCircle3.svg';
import Bubble3Small from './shape/Bubble3Small.svg';
// import Box from './shape/Box.svg';
import Box from './shape/Box.png';
// import Bubble4Green from './shape/Bubble4Green.svg';
import Bubble4Green from './shape/Bubble4Green.png';
import Square from './shape/Square.svg';
import ListDots2 from './shape/ListDots2.svg';
import SquareDiagonal from './shape/SquareDiagonal.svg';

export default function SignAnim() {
    return (
        <div className='sga'>
            <div style={{ position: 'absolute', top: '0', left: '45%' }}>
                <img src={HalfCircle1} alt='shape' />
            </div>
            <div style={{ position: 'absolute', top: '5%', right: '5%' }}>
                <img src={List} alt='shape' />
            </div>
            <div style={{ position: 'absolute', top: '10%', left: '20%' }}>
                <img src={Bubble1Purple} alt='shape' />
            </div>
            <div style={{ position: 'absolute', top: '5%', right: '20%' }}>
                <img src={Chat} alt='shape' />
            </div>
            <div style={{ position: 'absolute', top: '27%', left: '5%' }}>
                <img src={ListDots} alt='shape' />
            </div>
            <div style={{ position: 'absolute', top: '33%', left: '-5%' }}>
                <img src={Flag} alt='shape' />
            </div>
            <div style={{ position: 'absolute', top: '50%', left: '-3%' }}>
                <img src={HalfCircle2} alt='shape' />
            </div>
            <div style={{ position: 'absolute', top: '40%', right: '15%' }}>
                <img src={ListZigzag} alt='shape' />
            </div>
            <div style={{ position: 'absolute', top: '50%', left: '25%' }}>
                <img src={Hand} alt='shape' />
            </div>
            <div style={{ position: 'absolute', top: '55%', right: '25%' }}>
                <img src={Bubble2Yellow} alt='shape' />
            </div>
            <div style={{ position: 'absolute', top: '65%', right: '20%' }}>
                <img src={HalfCircle3} alt='shape' />
            </div>
            <div style={{ position: 'absolute', top: '75%', left: '35%' }}>
                <img src={Bubble3Small} alt='shape' />
            </div>
            <div style={{ position: 'absolute', top: '70%', right: '27%' }}>
                <img src={Box} alt='shape' />
            </div>
            <div style={{ position: 'absolute', bottom: '7%', left: '10%' }}>
                <img src={Bubble4Green} alt='shape' />
            </div>
            <div style={{ position: 'absolute', bottom: '5%', left: '5%' }}>
                <img src={Square} alt='shape' />
            </div>
            <div style={{ position: 'absolute', bottom: '0', right: '0' }}>
                <img src={SquareDiagonal} alt='shape' />
            </div>
            <div style={{ position: 'absolute', bottom: '5%', right: '5%' }}>
                <img src={ListDots2} alt='shape' />
            </div>
        </div>
    );
}
