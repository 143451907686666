import React, { Component } from 'react'
import InfoIcon from "../images/information-gray.png";

class NotAvailable extends Component {

    constructor(){
        super()
        this.state = {
            windowWith: window.innerWidth
        }
    }

    componentDidMount(){
        this.setWidthAndHeight()
        document.addEventListener("resize", this.resize())
    }

    resize = () => {
        let ww = window.innerWidth
        this.setState({
            windowWith: ww
        })
    }

    setWidthAndHeight = () => {
        let hh = document.getElementById("header-byd")
        let hhh = hh.offsetHeight
        let whh = hh.offsetWidth
        let wh = window.innerHeight - hhh
        
        let elm = document.getElementById("bs-n-avl")
        if(elm){
            elm.style.width = whh+"px"
            elm.style.height = wh+"px"
            elm.style.background = "#eee"
        }
    }

    render(){
        return(
            (this.state.windowWith > 1202)
            ?
                <div id="bs-n-avl" style={{position: "fixed", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <img src={InfoIcon} style={{width: "80px"}}/>&nbsp;&nbsp;&nbsp;&nbsp;
                    <div>
                        <span style={{fontWeight: "bold", fontSize: "20px"}}>You are not available</span><br/>
                        <span style={{color: "#929292", fontSize: "14px"}}>
                        if you already for available for currently<br/> 
                        please click slide button on top of corner right<br/>
                        to change availability stats
                        </span>
                    </div>
                </div>
            : 
                <div id="bs-n-avl-mbl" style={{position: "fixed", display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%"}}>                <img src={InfoIcon} style={{width: "40px"}}/>&nbsp;&nbsp;&nbsp;&nbsp;
                    <div style={{lineHeight: "1"}}>
                        <span style={{fontWeight: "bold", fontSize: "16px"}}>You are not available</span><br/>
                        <span style={{color: "#929292", fontSize: "10px"}}>
                        if you already for available for currently<br/> 
                        please click slide button on top of corner right<br/>
                        to change availability stats
                        </span>
                    </div>
                </div>
        )
    }
}

export default NotAvailable