import { Component } from "react";
import { baseUrlUpload as baseUrl } from '../environment/index'
import axios from "axios";

export default class email_service extends Component{
    
    denySchedules = async (id) => {
        let resp = null;
        try{
            resp = await axios.get(baseUrl+"/mainConfirm/denyRefreshTraining/"+id);
        }catch(error){
            resp = error.response
        }
        return resp
    }

    approveSchedules = async (id) => {
        try{
            let resp = await axios.get(baseUrl+"/mainConfirm/approveRefreshTraining/"+id);
            return resp
        }catch(error) {
            let resp = error.response
            return resp
        }
    }

}
