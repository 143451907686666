import React, { Component } from 'react'
import Web from './manager_web'
import Mobile from './manager_mobile'
import axios from 'axios'
import { connect } from "react-redux";
import { setDataReport } from '../../../redux/action';
import { errorFetch } from '../../function/function';

class manager extends Component{

    constructor(){
        super()
        this.state = {
            windowWidth: window.innerWidth,
            dataReport : [],
            isLoad: false
        }
    }

    componentDidMount(){
        window.addEventListener("resize", this.resize)
        if(!this.props.isLoadedReport){
            this.setState({
                isLoad: true
            })
            this.fetchData()
        }
    }

    resize = () => {
        let ww = window.innerWidth
        this.setState({
            windowWidth: ww
        })
    }

    fetchData = (sortBy, sortType) => {
        let url = ""
        let sort = (!sortType) ? "desc" : sortType

        if(!sortBy){
            url = "/report?size=10000&sort="+sort+"&order=createDate"
        }else{
            if(sortBy == "customerName"){
                url = "/report?size=10000&sort="+sort+"&order=customerName"
            }else if(sortBy == "techniciant"){
                url = "/report?size=10000&sort="+sort+"&order=user.profile.name"
            }else if(sortBy == "startDate"){
                url = "/report?size=10000&sort="+sort+"&order=startDate"
            }else if(sortBy == "endDate"){
                url = "/report?size=10000&sort="+sort+"&order=endDate"
            }else{
                url = "/report?size=10000&sort="+sort+"&order=createDate"
            }
        }

        this.setState({
            isLoad: true
        })

        try{
            axios.get(url).then(response => {
                let result = response.data
                let dataSales = result.data
                let total = result.result.total
                
                let data = {}
                data.result = dataSales
                data.total = total
                this.props.setReportData(data)

                this.setState({
                    isLoad: false
                })
            })
        }catch(error){
            let response = error.response
            errorFetch(response.status)
        }
    }

    render(){
        return(
            <>
                {
                    (this.state.windowWidth > 1202)
                    ?
                        <Web isLoad={this.state.isLoad} 
                            page={this.props.pageReport} 
                            total={this.props.total}
                            fetchData={this.fetchData}
                            data={this.props.dataReport}/>
                    :   
                        <Mobile isLoad={this.state.isLoad} 
                            page={this.props.pageReport} 
                            total={this.props.total}
                            fetchData={this.fetchData}
                            data={this.props.dataReport}/>
                }
            </>
        )
    }
}

const mapStateToProps = state => {
    return{
        isLoadedReport: state.dataReport.isLoaded,
        dataReport: state.dataReport.data,
        pageReport: state.dataReport.pageReport,
        total: state.dataReport.total
    }
}

const mapDispatchToProps = dispatch => {
    return{
        setReportData : (data) => dispatch(setDataReport(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (manager)