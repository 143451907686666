export const setDataReportAction = "set_data_report"
export const setPageReportAcrtion = "set_report_page"
export const updateDataReportAction = "update_data_report"
export const searchRreportByNameAction = "search_report_by_name"
export const filterReportAction = "filter_report"

export const setDataUserSalesAction = "set_data_user_sales"
export const setPageDataUserSalesAction = "set_page_data_user_sales"
export const updateDataUserSalesAction = "ubpdate_data_user_sales_action"
export const searchDataUserSalesByNameAction = "search_user_sales_by_name"

export const setDataApplicantAction = "set_data_applicant"
export const setPageDataApplicantAction = "set_page_data_applicant_action"
export const updateDataApplicantAction = "update_data_applicant_action"
export const searchRsApplicantAction = "search_rs_applicant_by_name"

export const setDataUserAction = "set_data_user_action"
export const setPageDataUserAction = "set_page_data_user_action"
export const updateDataUserAction = "update_data_user_action"
export const searcDataUserAction = "search_data_user_action"
export const setLoadedFlsUser = "set_loader_false_user"

export const setAvailableAction = "set_available"
export const setDataStatusApplicantAction = "set_data_status_applicant_action"
export const filterStatusAction = "filter_status_action"
export const filterStatusApplicantAction = "filter_status_applicant_action"

export const setSortReportAction = "set_sort_report_action"
export const setDataCalendarAction = "set_data_calendar_action"