import React from 'react'
import ImgIcon from '../images/page-not-found.png';

const Not_found_data = () => {
    return(
        <div style={{textAlign: "center", padding: "50px"}}>
            <img src={ImgIcon} style={{width: "100px"}}/><br/>
            <div style={{marginTop: "10px"}}>
                <div style={{fontWeight: "600", fontSize: "20px", color: "#a6a5a5"}}>Data not found</div>
                <div style={{color: "#a6a5a5", fontSize: "12px"}}>Data you are looking for is not found.</div>
            </div>        
        </div>
        
    )
}

export default Not_found_data