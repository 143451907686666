import React, { Component } from 'react'
import Header from '../header/header'
import Sidebar from '../menu/sidebar'
import Styled from 'styled-components'
import { baseUrl } from '../../environment/index'
import Sal from 'sweetalert2'
import axios from 'axios'
import { getUserName } from '../function/read_token'

const PicProfile = Styled.div`
    width: 120px;
    height: 120px;
    border-radius: 100%;
    background: #FFF;
    border: 1px solid #e7e6e6;
    overflow: hidden;
`

const Name = Styled.div`
    font-size: 22px;
    font-weight: normal;
    color: #333333;
`

const Status = Styled.div`
    height: 24px;
    background: #C9E8FB;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    padding: 0px 10px;
`
const BaseForm = Styled.div`
    margin-left: 209px;
    margin-right: 142px;
    border-radius: 8px;
    background: #FFF;
    padding: 30px;
`
const MnProfile = Styled.div`
    margin-right: 20px;
    font-weight: bold;
    padding-bottom: 20px;
    float: left;
    color: #1F4788;
    font-size: 14px;
`
class profile_web extends Component{

    constructor(){
        super()
        this.state = {
            menu: "info",
            popup: "",
            oldPass: "",
            newPass: "",
            repeatNewPass: ""
        }
    }

    setMenu = (name) => {
        this.setState({
            menu: name
        })
    }

    changePass = () => {
        let oldPass = this.state.oldPass
        let newPass = this.state.newPass
        let repeatNewPass = this.state.repeatNewPass

        if(oldPass == 0 || newPass == 0 || repeatNewPass == 0){
            Sal.fire('Failed', 'All data must be filled', 'error')
            return false
        }

        if(newPass != repeatNewPass){
            Sal.fire('Failed', 'Retype password is not match with new password', 'error')
            return false
        }

        if(newPass.length < 6){
            Sal.fire('Failed', 'Minimum new password is 6 character', 'error')
            return false
        }

        let obj = {}
        obj.email = getUserName()
        obj.newPassword = newPass
        obj.oldPassword = oldPass
        obj.username = getUserName()
        axios.put("/user/changePassword", obj).then(response => {
            Sal.fire('Success', 'Change password is successfully', 'success')
            this.setState({
                newPass: "",
                oldPass: "",
                repeatNewPass: ""
            })
        }).catch(error => {
            let resp = error.response
            let data = resp.data
            let msg = data.message
            Sal.fire('Failed', msg, 'error')
        })
        
    }
    
    render(){
        return(
            <>
                <Sidebar/>
                <div id="bs-content" style={{marginLeft: "222px"}}>
                    <Header/>
                    <div style={{padding: "40px"}}>
                        <div id="title-mn-prf" style={{display: "flex", alignItems: 'center', marginBottom: "48px"}}>
                            <span style={{fontSize: "24px", color: "#949494"}}>Dashboard</span>&nbsp;&nbsp;&nbsp;&nbsp;
                            {dot}&nbsp;&nbsp;&nbsp;&nbsp;
                            <span style={{fontSize: "24px", color: "#1F4788"}}>Profile</span>
                        </div>
                        <div id="hd-prf" style={{display: "flex", alignItems: "center", flexShrink: 1}}>
                            <PicProfile>
                                <img src={baseUrl+this.props.avatar} style={{width: "100%", height: "100%", objectFit: "contain"}}/>
                            </PicProfile>
                            <div style={{marginLeft: "40px"}}>
                                <Name>{this.props.name}</Name>
                                <Status>{this.props.position}</Status>
                            </div>
                        </div>
                    </div>
                    <BaseForm>
                        <div style={{paddingTop: "0px", paddingBottom: "20px", overflow: "hidden"}}>
                            {
                                (this.state.menu == "info")
                                ? <MnProfile style={{borderBottom: "2px solid #1F4788"}}>Info Account</MnProfile>
                                : <a onClick={() => this.setMenu("info")}><MnProfile style={{color: "#CCC"}}>Info Account</MnProfile></a>
                            }
                            {
                                (this.state.menu == "change password")
                                ? <MnProfile style={{borderBottom: "2px solid  #1F4788"}}>Change Password</MnProfile>
                                : <a onClick={() => this.setMenu("change password")}><MnProfile style={{color: "#CCC"}}>Change Password</MnProfile></a>
                            }
                        </div>
                        {
                            (this.state.menu == "info")
                            ?
                                <>
                                <div style={{marginBottom: "20px"}}>
                                    <div style={{fontSize: "20px", color: "#333333", marginBottom: "20px"}}>Your Name</div>
                                    <input type="text" value={this.props.name} className="frm-input" style={styles.inputForm}/>
                                </div>
                                <div style={{marginBottom: "20px"}}>
                                    <div style={{fontSize: "20px", color: "#333333", marginBottom: "20px"}}>Email</div>
                                    <input type="text" value={this.props.email} className="frm-input" style={styles.inputForm}/>
                                </div>
                                <div style={{marginBottom: "20px"}}>
                                    <div style={{fontSize: "20px", color: "#333333", marginBottom: "20px"}}>Position</div>
                                    <input type="text" value={this.props.position} className="frm-input" style={styles.inputForm}/>
                                </div>
                                </>
                            :
                                <>
                                <div style={{marginBottom: "20px"}}>
                                    <div style={{fontSize: "20px", color: "#333333", marginBottom: "20px"}}>Old Password</div>
                                    <input type="password" placeholder="Old password" onChange={(e) => this.setState({oldPass: e.target.value})} value={this.state.oldPass} className="frm-input" style={styles.inputForm}/>
                                </div>
                                <div style={{marginBottom: "20px"}}>
                                    <div style={{fontSize: "20px", color: "#333333", marginBottom: "20px"}}>New Password</div>
                                    <input type="password" placeholder="New password" onChange={(e) => this.setState({newPass: e.target.value})} value={this.state.newPass} className="frm-input" style={styles.inputForm}/>
                                </div>
                                <div style={{marginBottom: "20px"}}>
                                    <div style={{fontSize: "20px", color: "#333333", marginBottom: "20px"}}>Retype New Password</div>
                                    <input type="password" placeholder="Repeat new password" onChange={(e) => this.setState({repeatNewPass: e.target.value})} value={this.state.repeatNewPass} className="frm-input" style={styles.inputForm}/>
                                </div>
                                <div style={{marginTop: "30px"}}>
                                    <button className="btn-sv" onClick={this.changePass} style={{background: "#1F4788", marginLeft: "0px"}}>Change Password</button>
                                </div>
                                </>
                        }
                    </BaseForm>
                </div>
            </>
        )
    }
}

const styles = {
    inputForm: {
        border: "1px solid #CAD2E8", 
        fontSize: "18px", 
        color: "#949494"
    }
}

const dot = <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="3" cy="3" r="3" fill="#1F4788"/>
            </svg>


export default profile_web