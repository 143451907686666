import React, { Component } from 'react'
import Mobile from './profile_mobile'
import Web from './profile_web'

class profile extends Component{

    constructor(){
        super()
        this.state = {
            windowWidth: window.innerWidth,
            name: "",
            email: "",
            position: "",
            avatar: ""
        }
    }

    componentDidMount(){
        window.addEventListener("resize", () => {
            let ww = window.innerWidth
            this.setState({
                windowWidth: ww
            })
        })

        let token = localStorage.getItem("token")
        if(token){
            let joToken = JSON.parse(token)
            this.setState({
                email: joToken.username,
                name: joToken.profile.name,
                position: joToken.userType.name,
                avatar: joToken.profile.uriAvatar
            })
        }
    }

    render(){
        return(
            <>
                {
                    (this.state.windowWidth > 1202)
                    ?
                        <Web name={this.state.name}
                            email={this.state.email}
                            avatar={this.state.avatar}
                            position={this.state.position}    
                        />
                    :
                        <Mobile name={this.state.name}
                            email={this.state.email}
                            position={this.state.position}
                            avatar={this.state.avatar}    
                        />
                }
            </>
           
        )
    }
}

export default profile