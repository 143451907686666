import React, { Component } from 'react'
import WebDirector from './director_web'
import Mobile from './director_mobile'
import axios from 'axios'
import { connect } from 'react-redux'
import { setDataUserSales } from '../../../redux/action'
import Header from '../../header/header_mobile'
import Loader from '../../loader'
import { errorFetch, isManagerApplicant, isUserSales } from '../../function/function'
import { getRoleName } from '../../function/read_token'
import SalesService from '../../../service/sales_service'
import SuperiorService from '../../../service/superior_service'

class director extends Component{

    constructor(){
        super()
        this.state = {
            windowWidth: window.innerWidth
        }
    }

    componentDidMount(){
        window.addEventListener("resize", this.resize)
        this.fetchData()
    }

    resize = () => {
        let ww = window.innerWidth
        this.setState({
            windowWidth: ww
        })
    }

    fetchData = async () => {
        /*isLoaded param from redux*/
        let isLoaded = this.props.isLoaded

        if(!isLoaded){
            let resp = ""
            let role = getRoleName()
            if(role.toLowerCase() == 'user sales'){
                let service = new SalesService()
                resp = await service.fetchSchedules()
            }else{
                let service = new SuperiorService();
                resp = await service.fetchMultipleSchedules()
            }

            let result = resp.data
            let dataSales = result.data
            let total = result.result.total
            
            let data = {}
            data.result = dataSales
            data.total = total
            this.props.setDataUserSales(data)
        }
    }

    render(){

        const title = (isManagerApplicant()) ? "Director" : (isUserSales()) ? "User Sales" : "Applicant"

        return(
            <>  
                {
                    (this.state.windowWidth > 1202)
                    ?
                        <WebDirector data={this.props.dataUserSales}
                            isLoaded={this.props.isLoaded}
                            page={this.props.page}
                            total={this.props.total}/>
                    :
                        <>
                            <Header title={title} enableSearch={true}/>
                            {
                                (!this.props.isLoaded)
                                ?
                                    <Loader/>
                                :
                                    <Mobile data={this.props.dataUserSales}
                                        isLoaded={this.props.isLoaded}
                                        page={this.props.page}
                                        total={this.props.total}/>
                            }
                        </>
                }
            </>
        )
    }
}


const mapStateToPrps = state => {
    return{
        dataUserSales: state.dataUserSales.data,
        page: state.dataUserSales.page,
        isLoaded: state.dataUserSales.isLoaded,
        total: state.dataUserSales.total
    }
}

const mapDispatchToProps = dispatch => {
    return{
        setDataUserSales: (data) => dispatch(setDataUserSales(data))
    }
}

export default connect(mapStateToPrps, mapDispatchToProps) (director)