import React from 'react'
import { Component } from 'react'
import Styled from 'styled-components'
import UserIcon from '../../../images/user.png'
import Close from '../../../images/close.png'
import axios from 'axios'
import Swal from 'sweetalert2'
import GifLoader from '../../../images/Pulse-1s-51px.gif'
import { connect } from 'react-redux'
import { setDataUser, setPageDataUser } from '../../../redux/action'
import LockIcon from '../../../images/padlock_blue_16px.png'
import { Portal } from '@material-ui/core'

const Base = Styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    left: 0;
    top: 0;
`
const NameInput = Styled.div`
    width: 150px; 
    text-align: right;
    padding-right: 20px;
    font-weight: bold;
    font-size: 16px;
`
class popup extends Component{

    constructor(){
        super()
        this.state = {
            id: "",
            name: "",
            email: "",
            typeId: "",
            status: "",
            region: "",
            isProcess: false
        }
    }

    componentDidMount(){
        let data = this.props.data
        let id = data.userId
        let role = data.role
        let status = data.status

        let roleId = role.id
        let statusId = status.id
        let name = data.name
        let email = data.email
        let region = ""

        if(roleId == "00000000-0000-0000-0000-000000000013"){
            let dataRegion = role.region
            let regionId = dataRegion.id
            region = regionId
        }

        this.setState({
            name: name,
            id: id,
            email: email,
            status: statusId,
            typeId: roleId,
            region: region
        })
    }

    changeType = (e) => {
        let val = e.target.value

        if(val == "00000000-0000-0000-0000-000000000013"){
            let region = this.props.regions[0]
            let regId = region.id
            this.setState({
                region: regId,
                typeId: val
            })
        }else{
            this.setState({
                region: "",
                typeId: val
            })
        }
    }

    save = () => {
        let obj = {}
        obj.email = this.state.email
        obj.fullName = this.state.name
        // obj.statusId = this.state.status
        obj.typeId = this.state.typeId
        
        if(this.state.typeId == "00000000-0000-0000-0000-000000000013"){
            obj.regionId = this.state.region
        }
        
        this.setState({
            isProcess: true
        })
        
        axios.put("/user/updateUser", obj).then(response => {
            axios.get("/user?size=1000").then(resp => {
                let data = resp.data
                let dataRedux = this.props.dataUser

                /*get current page user*/
                let page = dataRedux.page

                /*SET DATA USER TO REDUX*/
                this.props.setDataUserRedux(data)

                /*set current page*/
                this.props.setPageUser(page)
                Swal.fire("Success", "Update data user successfully", "success")
                this.props.close()
            })
        })
    }

    render(){
        
        const optionsRegions = this.props.regions.map(dt => {
            return <option value={dt.id}>{dt.name.replace("_", " ")}</option>
        })

        const optionsRole = this.props.roles.map(dt => {
            return <option value={dt.id}>{dt.name}</option>
        })

        return(
            <Base>
                <div style={{background: "#FFF", width: "600px", borderRadius: "5px", overflow: "hidden"}}>
                    <div style={{display: "flex", alignItems: "center", padding: "15px 20px", justifyContent: "space-between", borderBottom: "1px solid #CCC"}}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <img src={UserIcon} style={{width: "20px"}}/>&nbsp;&nbsp;
                            <div style={{fontWeight: "bold", fontSize: "18px"}}>User</div>
                        </div>
                        <div id="bs-sct-usr-188xx" style={{display: "flex", alignItems: "center"}}>
                            {/* <a style={{color: "#588ce0", fontSize: "12px"}}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <img src={LockIcon} style={{width: "13px", marginRight: "3px"}}/>
                                    Reset password
                                </div>
                            </a>
                            &nbsp; &nbsp;&nbsp; */}
                            <a onClick={this.props.close}><img src={Close} style={{width: "10px"}}/></a>
                        </div>
                    </div>
                    <div style={{padding: "20px 0px"}}>
                        <div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
                            <NameInput>Name</NameInput>
                            <div>
                                <input placeholder="name"
                                    onChange={(e) => this.setState({name: e.target.value})}
                                    value={this.state.name} 
                                    style={{border: "none", background: "rgb(230 230 230)",width: "350px"}}
                                    className="frm-input" type="text"
                                />
                            </div>
                        </div>
                        {/* <div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
                            <NameInput>Last Name</NameInput>
                            <div>
                                <input placeholder="name"
                                    onChange={(e) => this.setState({lname: e.target.value})}
                                    value={this.state.lname} 
                                    style={{border: "none", background: "rgb(230 230 230)",width: "350px"}}
                                    className="frm-input" type="text"
                                />
                            </div>
                        </div> */}
                        <div style={{display: "flex", alignItems: "center",marginTop: "10px"}}>
                            <NameInput>Email</NameInput>
                            <div>
                                <input placeholder="email"
                                    disabled
                                    // onChange={(e) => this.setState({email: e.target.value})}
                                    value={this.state.email} 
                                    style={{border: "none", background: "#CCC",width: "350px"}}
                                    className="frm-input" type="text"
                                />
                            </div>
                        </div>
                        <div style={{display: "flex", alignItems: "center",marginTop: "10px"}}>
                            <NameInput>User Type</NameInput>
                            <div>
                                <select 
                                    value={this.state.typeId} 
                                    onChange={this.changeType} 
                                    style={{border: "none", background: "rgb(230 230 230)",width: "350px"}}
                                    className="frm-input"
                                >
                                    {optionsRole}
                                </select>
                            </div>
                        </div>
                        {
                            (this.state.typeId == "00000000-0000-0000-0000-000000000013")
                            ?
                                <div style={{display: "flex", alignItems: "center",marginTop: "10px"}}>
                                    <NameInput>Region</NameInput>
                                    <div>
                                        <select 
                                            value={this.state.region} 
                                            onChange={(e) => this.setState({region: e.target.value})} 
                                            style={{border: "none", background: "rgb(230 230 230)",width: "350px"}}
                                            className="frm-input"
                                        >
                                            {/* <option value="" style={{display: "none"}}>- select region -</option> */}
                                            {optionsRegions}
                                        </select>
                                    </div>
                                </div>
                            : ""
                        }
                        
                        {/* <div style={{display: "flex", alignItems: "center",marginTop: "10px"}}>
                            <NameInput>Status</NameInput>
                            <div>
                                <select 
                                    value={this.state.status} 
                                    onChange={(e) => this.setState({status: e.target.value})} 
                                    style={{border: "none", background: "rgb(230 230 230)",width: "350px"}}
                                    className="frm-input"
                                >
                                    {optionsStatus}
                                </select>
                            </div>
                        </div> */}
                        <div style={{display: "flex", alignItems: "center",marginTop: "20px", marginBottom: "10px"}}>
                            <div style={{width: "150px"}}/>
                            <div>
                                {
                                    (!this.state.isProcess)
                                    ?
                                        <button 
                                            onClick={this.save} 
                                            className="btn-sv" 
                                            style={{background: "#7784EE", paddding: "10px", fontSize: "12px"}}>
                                                Save Change
                                        </button>
                                    :
                                        <button
                                            className="btn-sv" 
                                            style={{background: "#efefef", border: "1px solid #CCC", paddding: "10px", fontSize: "12px"}}>
                                                <img src={GifLoader} style={{width: "30px", marginTop: "5px"}}/>
                                        </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Base>
        )
    }
}

const mapStateToProps = state => {
    return{
        dataUser: state.dataUser
    }
}

const mapDispatchToProps = dispacth => {
    return{
        setDataUserRedux: (data) => dispacth(setDataUser(data)),
        setPageUser: (data) => dispacth(setPageDataUser(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (popup)