import React, { Component } from 'react'
import HeaderMobile from '../../header/header_mobile'
import Item from './item_mobile'
import LoaderGif from '../../../images/Pulse-1s-51px.gif'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { setPageReport, setSortReport } from '../../../redux/action'
import Excel from '../../../images/excel.png'
import { convertDateDDMMYYYString, isManagerTechniciant } from '../../function/function'
import axios from 'axios'
import { baseUrl } from '../../../environment'
import { getRoleId, getRoleName } from '../../function/read_token'
import FilterDsb from '../../../images/filter-dsb.png'
import FilterAtv from '../../../images/filter-atv.png'
import FilterMobile from './filter_mobile'
import SortIcon from '../../../images/sort.png'
import SortMobile from './sort_mobile'
// import AzSort from '../../../images/sort-az.png'
// import ZaSort from '../../../images/sort-za.png'
import AzSort from '../../../images/fast-forward-asc.png'
import ZaSort from '../../../images/fast-forward-desc.png'

let size = 0

class manager_mobile extends Component{

    constructor(){
        super()
        this.state = {
            isExporting: false,
            popup: ""
        }
    }

    setPagePosition = (type) => {
        let currPage = this.props.page
        if(type == "next"){
            if(currPage < size){
                currPage += 1
                window.scrollTo(0, 0)
            }
        }else{
            if(currPage > 1){
                currPage -= 1
            }
        }
        
        /*redux props action*/
        this.props.setPage(currPage)
    }

    firstPos = () => {
        this.props.setPage(1)
    }

    lastPos = () => {
        this.props.setPage(size)
        window.scrollTo(0,0)
    }

    export = () => {
        const FileDownload = require('js-file-download');
        let date = new Date()
        let name = "export "+convertDateDDMMYYYString(date)+".xlsx"

        this.setState({
            isExporting: true
        })

        axios({
            url: "/report/excel", //your url
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            // const url = window.URL.createObjectURL(new Blob([response.data]));
            FileDownload(response.data, name);
            this.setState({
                isExporting: false
            })
        });
    }

    isManager = () => {
        return isManagerTechniciant()
    }

    filter = () => {
        this.setState({
            popup: <FilterMobile hideFilter={() => this.setState({popup: ""})}/>
        })
    }

    sorting = () => {
        this.setState({
            popup: <SortMobile hideSorting={() => this.setState({popup: ""})}
                            fetchData={this.props.fetchData}/>
        })
    }

    render(){
        let seq = 0
        const dataReport = this.props.data.map((dt, index) => {
            let isView = false
            let search = this.props.searchParam
            let filter = this.props.filterParam

            let costumerName = dt.customerName.toLowerCase()
            let techniciant = dt.user
            let profile = techniciant.profile
            let nameTehcniciant = (profile) ? profile.name.toLowerCase() : ""

            if(search == 0 || costumerName.match(search) || nameTehcniciant.match(search)){
                isView = true
            }

            if(filter != 0 && filter.toLowerCase() != dt.statusReportForm.toLowerCase()){
                isView = false
            }
            
            if(isView)
            {
                seq++
                let st = (this.props.page > 1) ? (this.props.page * 10) - 9 : this.props.page
                let ed = this.props.page * 10
      
                if(seq >= st && seq <= ed){
                    return <Item data={dt}/>
                }
            }

            let idx = parseInt(index) + 1
            let len = this.props.data.length
            if(idx == len){
                size = Math.ceil(seq / 10)
            }
        })
        
        return(
            <>
                <HeaderMobile title="Techniciant" enableSearch={true}/>
                {this.state.popup}
                <div style={{padding: "16px", fontSize: "14px", paddingBottom: "5px", display: "flex", alignItems: "center", marginTop: "65px", justifyContent: "space-between"}}>
                    <span style={{fontSize: "12px", fontWeight: "bold"}}>
                        Page {this.props.page} of {size}
                    </span>
                    
                    <div style={{display: "flex", marginTop: "0px"}}>
                        {
                            (this.isManager())
                            ?
                                (!this.state.isExporting)
                                ?
                                    <a onClick={this.export}>
                                        <img src={Excel} style={{width: "20px", marginRight: "10px", marginTop: "2px"}}/>
                                    </a>
                                :
                                    <a>
                                        <img src={Excel} style={{width: "20px", marginRight: "10px", marginTop: "2px", opacity: "0.5"}}/>
                                    </a>
                            :
                                ""
                        }

                        {
                            (getRoleId() == "00000000-0000-0000-0000-000000000012")
                            ?
                                <Link to={"/dashboard/techniciant/form"}>
                                    <button style={{fontSize: "11px", 
                                                    color: "#7784EE", 
                                                    fontWeight: "bold", 
                                                    padding: "0px 5px", 
                                                    background: "none", 
                                                    border: "none", 
                                                    borderRight: "1px solid #CCC"}}>
                                        + Create
                                    </button>
                                </Link>
                            :
                                ""
                        }

                        <a onClick={this.filter}>
                            {
                                (this.props.filterParam == "")
                                ?
                                    <div style={{display: "flex", alignItems: "center", padding: "0px 5px", borderRight: "1px solid #CCC"}}>
                                        <img src={FilterDsb} style={{width: "12px", opacity: "0.5"}}/>
                                        &nbsp;
                                        <div style={{fontSize: "11px", fontWeight: "bold", color: "#878787"}}>Filter</div>
                                    </div>
                                :
                                    <div style={{display: "flex", alignItems: "center", padding: "0px 5px", borderRight: "1px solid #CCC"}}>
                                        <img src={FilterAtv} style={{width: "12px"}}/>
                                        &nbsp;
                                        <div style={{fontSize: "11px", fontWeight: "bold", color: "#000"}}>
                                            {this.props.filterParam}
                                        </div>
                                    </div>
                            }
                        </a>

                        <a onClick={this.sorting}>
                            {
                                (this.props.sortParam == "")
                                ?
                                    <div style={{display: "flex", alignItems: "center", padding: "0px 5px"}}>
                                        <img src={SortIcon} style={{width: "14px", opacity: "0.5"}}/>
                                        &nbsp;
                                        <div style={{fontSize: "11px", fontWeight: "bold", color: "#878787"}}>Sort</div>
                                    </div>
                                :
                                    <div style={{display: "flex", alignItems: "center", padding: "0px 5px"}}>
                                        {
                                            (this.props.sortType == "asc")
                                            ? <img src={AzSort} style={{width: "12px", opacity: "0.5"}}/>
                                            : <img src={ZaSort} style={{width: "12px", opacity: "0.5"}}/>
                                        }
                                        &nbsp;
                                        <div style={{fontSize: "11px", fontWeight: "bold", color: "#000"}}>
                                            {this.props.sortParam}
                                        </div>
                                    </div>
                            }
                        </a>
                    </div>
                </div>
                {
                    
                    (this.props.isLoad)
                    ?
                        <div style={{textAlign: "center", padding: "30px"}}>
                            <img src={LoaderGif} style={{width: "35px"}}/>
                        </div>
                    :
                        (this.props.data.length < 1)
                        ?
                            <div style={{textAlign: "center", fontSize: "12px", fontWeight: "bold", color: "#CCC", padding: "50px"}}>
                                No data to display
                            </div>
                        :
                            <>
                                <div>
                                    {dataReport}
                                </div>
                                <div style={{textAlign: "center", padding: "30px 0px"}}>
                                    <button className="img-pgn" onClick={this.firstPos}>First</button>
                                    <button className="img-pgn" onClick={() => this.setPagePosition("prev")}>Prev</button>
                                    <button className="img-pgn">{this.props.page}</button>
                                    <button className="img-pgn" onClick={() => this.setPagePosition("next")}>Next</button>
                                    <button className="img-pgn" onClick={this.lastPos}>Last</button> 
                                </div>
                            </>
                }
            </>
        )
    }
}

const chevron = <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.625 1.4375L7.5 7.5625L1.375 1.4375" stroke="#717171" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

const mapStateToProps = state => {
    return{
        searchParam: state.dataReport.searchName,
        filterParam: state.dataReport.filter,
        sortParam: state.dataReport.sortBy,
        sortType: state.dataReport.sortType
    }
}

const mapDispatchToProps = dispatch => {
    return{
        setPage: (data) => dispatch(setPageReport(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (manager_mobile)