import React from 'react'
import axios from 'axios'
import MainService from './main'
const BaseUrlSales = "/sales"

export default class sales_services {

    fetchSchedules = async () => {
        let url = BaseUrlSales+"/getSchedules?size=1000"
        let resp = await new MainService().fetchApi(url, "get")
        return resp
    }
    
    fetchScheduleCalendar = async (jsonParam) => axios.post("/sales/getScheduleAvailable", jsonParam).then(res => {
        return res.data.data
    }).catch((error) => {
        return []
    })

}