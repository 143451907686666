import React, { Component } from 'react'
import Styled from 'styled-components'
import SrcIcon from '../../../images/search-interface-symbol.svg'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { searchDataUser, setDataUser, setPageDataUser } from '../../../redux/action'
import Row from './row_web'
import Loader from '../../loader'
import PopupUser from './popup'
import StatusConfirm from './status_confirm'
import axios from 'axios'
import { StepLabel } from '@material-ui/core'
import Swal from 'sweetalert2'

const Base = Styled.div`
    position: relative;
`
const SrcBase = Styled.div`
    width: 223px;
    padding: 0px 10px;
    height: 37px;
    background: #FFF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    border: 1px solid #e7e7e7;
`

const FilterBase = Styled.div`
    width: 124px;
    position: relative;
    height: 37px;
    background: #FFF;
    border: 1px solid #e7e7e7;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 25px;
`

const Btn = Styled.button`
    width: 226px;
    height: 42px;
    background: #7784EE;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    color: #FFF;
    border: none;
    outline: none;
`

let size = 0
class admin_web extends Component{

    constructor(){
        super()
        this.state = {
            popup:"",
            filterPop: false,
            roleFilterSelected: ""
        }
    }

    componentDidMount() {
        document.title = 'Login';
        document.addEventListener("click", this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClickOutside)
    }

    handleClickOutside = (e) => {
        let elm = document.getElementById("bs-fltr-chs-adm")
        if(elm && !elm.contains(e.target)){
            this.setState({
                filterPop: false
            })
        }
    }

    setPagePosition = (type) => {
        let currPage = this.props.page
        if(type == "next"){
            if(currPage < size){
                currPage += 1
            }
        }else{
            if(currPage > 1){
                currPage -= 1
            }
        }
        
        /*redux props action*/
        this.props.setPage(currPage)
    }

    fistPos = () => {
        this.props.setPage(1)
    }

    lastPos = () => {
        this.props.setPage(size)
    }
    
    search = (e) => {
        this.props.search(e.target.value)
        this.props.setPage(1)
    }

    selectUser = (data) => {
        this.setState({
            popup: <PopupUser
                        close={() => this.setState({popup: ""})} 
                        data={data} 
                        statuses={this.props.dataStatus} 
                        regions={this.props.regions}
                        roles={this.props.roleData}/>
        })
    }

    deleteUser = (id) => {
        this.setState({
            popup: <StatusConfirm title={"Deactivate user"}
                                desc="Are you sure, you want deactivate this user ?" 
                                no={() => this.setState({popup: ""})} 
                                yes={() => this.yesDeactivate(id)}/>
        })
    }

    activateUser = (id) => {
        this.setState({
            popup: <StatusConfirm title={"Activate user"}
                                desc="Are you sure, you want activate this user ?" 
                                no={() => this.setState({popup: ""})} 
                                yes={() => this.yesActivate(id)}/>
        })
    }

    yesDeactivate = (id) => {
        axios.delete("/user/notActiveUser/"+id).then(resp => {
            axios.get("/user?size=1000").then(response => {
                let resp = response.data
                let obj = {}
                obj.data = resp.data
                obj.total = resp.result.total
                this.props.setDataUser(obj)

                /*hide alert*/
                Swal.fire("Success", "Delete user successfully", "success")
                this.setState({
                    popup: ""
                })
            })
            
        }).catch(error => {
            Swal.fire("Failed", "Something wrong when delete user", "error")
            this.setState({
                popup: ""
            })
        })
    }

    yesActivate = (id) => {
        let obj = {"userId": id}
        axios.post("/user/activeUser/", obj).then(resp => {
            axios.get("/user?size=1000").then(response => {
                let resp = response.data
                let obj = {}
                obj.data = resp.data
                obj.total = resp.result.total
                this.props.setDataUser(obj)

                /*hide alert*/
                Swal.fire("Success", "Activate user successfully", "success")
                this.setState({
                    popup: ""
                })
            })
            
        }).catch(error => {
            Swal.fire("Failed", "Something wrong when activate user", "error")
            this.setState({
                popup: ""
            })
        })
    }

    filter = (e) => {
        e.stopPropagation()
        this.setState({
            filterPop: true
        })
    }

    selectFilter = (data) => {
        console.log(data)
        this.setState({
            roleFilterSelected: data,
            filterPop: false
        })
    }

    render(){

        let seq = 0
        const data = this.props.data.map((dt, index) => {
            let isView = false
            let fullname = dt.name
            if(fullname.toLowerCase().match(this.props.searchParam.toLowerCase())){
                isView = true
            }

            let role = dt.role
            let roleId = role.id
            let roleFilterSelected = this.state.roleFilterSelected
            let roleIdSelected = (roleFilterSelected.id) ? roleFilterSelected.id : ""

            /*filterin by type user*/
            if(roleIdSelected != ""){
                if(roleIdSelected != roleId){
                    isView = false;
                }
            }

            if(isView){
                seq++
                let st = (this.props.page > 1) ? (this.props.page * 10) - 9 : this.props.page
                let ed = this.props.page * 10
                
                if(seq >= st && seq <= ed && isView){
                    return <Row select={() => this.selectUser(dt)} 
                                activate={() => this.activateUser(dt.userId)}
                                deactivate={() => this.deleteUser(dt.userId)} 
                                data={dt}/>
                }
            }

            let idx = parseInt(index) + 1
            let len = this.props.data.length
            if(idx == len){
                size = Math.ceil(seq / 10)
            }
        })

        return(
            <Base>
                {this.state.popup}                
                <div style={{padding: "40px 51px"}}>
                    <div style={{fontSize: "24px", 
                                color: "#1F4788",
                                display: "flex", 
                                justifyContent: "space-between", 
                                alignItems: 'center'}}>
                        <span>Dashboard {/*Manager*/}</span>

                        <Link to="/register">
                            <Btn>+ Register User</Btn>
                        </Link>
                    </div>
                    <div style={{display: "flex", marginTop: "50px"}}>
                        <SrcBase>
                            <img src={SrcIcon} style={{width: "15px"}}/>
                            <div>
                                <input type="text" 
                                    onChange={(e) => this.search(e)}
                                    value={this.props.searchParam}
                                    style={{width: "100%", 
                                            marginLeft: "10px", 
                                            border: "none", 
                                            outline: "none"}} 
                                    placeholder="Search by name"
                                />
                            </div>
                        </SrcBase>
                        <FilterBase>
                            <FilterBase onClick={this.filter}
                                style={{cursor: "pointer", border: "none", marginLeft: "0px", padding: "0px 10px"}}
                            >
                                <div style={{color: '#717171', fontSize: "14px"}}>
                                    {
                                        (this.state.roleFilterSelected == "")
                                        ? "Filter By"
                                        : this.state.roleFilterSelected.name
                                    }
                                </div>
                                {chevron}
                            </FilterBase>

                            {
                                (this.state.filterPop)
                                ?
                                    <div id="bs-fltr-chs-adm" 
                                        style={{width: "100%", 
                                                background: "#FFF", 
                                                position: "absolute", 
                                                top: "38px",
                                                borderRadius: "5px",
                                                border: "1px solid #e7e7e7", 
                                                right: "0px"}}>
                                        
                                        <div onClick={() => this.selectFilter("")} 
                                            className="menu-hvr-item-item" 
                                            style={{padding: "5px 10px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                                        >
                                            <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px", color: "#868686"}}>All Data</div>
                                        </div>
                                        {
                                            this.props.roleData.map(dt => {
                                                return  <div onClick={() => this.selectFilter(dt)} 
                                                            className="menu-hvr-item-item" 
                                                            style={{padding: "5px 10px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                                                        >
                                                            <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>{dt.name}</div>
                                                        </div>
                                            })
                                        }
                                        {/* <div onClick={() => this.selectFilter("00000000-0000-0000-0000-000000000012")} 
                                            className="menu-hvr-item-item" 
                                            style={{padding: "5px 10px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                                        >
                                            <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>Tehcniciant</div>
                                        </div>
                                        <div onClick={() => this.selectFilter("00000000-0000-0000-0000-000000000013")} 
                                            className="menu-hvr-item-item" 
                                            style={{padding: "5px 10px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                                        >
                                            <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>Applicant</div>
                                        </div>
                                        <div onClick={() => this.selectFilter("00000000-0000-0000-0000-000000000011")} 
                                            className="menu-hvr-item-item" 
                                            style={{padding: "5px 10px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                                        >
                                            <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>Manager</div>
                                        </div>
                                        <div onClick={() => this.selectFilter("00000000-0000-0000-0000-000000000010")} 
                                            className="menu-hvr-item-item" 
                                            style={{padding: "5px 10px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                                        >
                                            <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>Director</div>
                                        </div>
                                        <div onClick={() => this.selectFilter("00000000-0000-0000-0000-000000000014")} 
                                            className="menu-hvr-item-item" 
                                            style={{padding: "5px 10px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                                        >
                                            <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>User Sales</div>
                                        </div> */}
                                    </div>
                                :
                                    ""
                            }
                        </FilterBase>
                    </div>

                    <div id="tbl-bs" style={{marginTop: "50px"}}>
                        <table style={{width: "100%"}}>
                            <thead>
                                <th>Name</th>
                                {/* <th>Last Name</th> */}
                                <th>Email</th>
                                <th>Type</th>
                                <th>Status</th>
                                <th></th>
                            </thead>
                            <tbody>
                                {
                                    (!this.props.isLoaded)
                                    ?
                                        <tr>
                                            <td colSpan="5">
                                                <Loader/>
                                            </td>
                                        </tr>
                                    :
                                        data
                                }
                            </tbody>
                        </table>
                        {/* {this.props.data.length} */}
                        <div id="ft-tbl-dir" style={{marginTop: "50px",}}>
                            <span style={{fontSize: "14px",lineHeight: "28px", color: "#333333"}}>
                                Showing {this.props.page} of {size} entries
                            </span>
                            <div>
                                <button className="img-pgn" onClick={this.fistPos}>First</button>
                                <button className="img-pgn" onClick={() => this.setPagePosition("prev")}>Prev</button>
                                <button className="img-pgn">{this.props.page}</button>
                                <button className="img-pgn" onClick={() => this.setPagePosition("next")}>Next</button>
                                <button className="img-pgn" onClick={this.lastPos}>Last</button> 
                            </div>
                        </div>
                    </div>
                </div>
                {/* </BaseMain> */}
            </Base>
        )
    }
}

// const radio = <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
//                 <circle cx="13" cy="13" r="12.5" fill="white" fill-opacity="0.06" stroke="#08A0F7"/>
//             </svg>

const chevron = <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.625 1.4375L7.5 7.5625L1.375 1.4375" stroke="#717171" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>


const mapDispatchToProps = dispatch => {
    return{
        setPage: (data) => dispatch(setPageDataUser(data)),
        search: (data) => dispatch(searchDataUser(data)),
        setDataUser: (data) => dispatch(setDataUser(data))
    }
}

const mapStateToProps = state => {
    return{
        page: state.dataUser.page,
        isLoaded: state.dataUser.isLoaded,
        searchParam: state.dataUser.searchName
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (admin_web)