import React from 'react'
import { Component } from 'react';
import Logo from '../../images/Beyond Logo.png';
import Styled from 'styled-components'
import Loader from '../loader'
import Gif from '../../images/Pulse-1s-51px.gif'

const Input = Styled.input`
    height: 38px;
    outline: none;
    border: none;
    background: #F5F5FD;
    padding: 0px 10px;
    border-radius: 8px;
    font-size: 12px;
    width: 100%;
    box-sizing: border-box;
`

const ButtonChange = Styled.button`
    width: 283px;
    height: 51px;
    color: #FFF;
    background: #1F4788;
    border: none;
    outline: none;
    border-radius: 8px;
`
const TextInput = Styled.div`
    margin-bottom: 30px; 
    text-align: left; 
    margin-top: 15px; 
    color: #949494; 
    font-size: 10px;
`
class mobile extends Component {

    constructor(){
        super()
        this.state = {
            newPass: "",
            repeatNewPass: "",
            isProcess: false
        }

        this.newPassInput = React.createRef()
        this.repeatInput = React.createRef()
    }

    reset = () => {
        let pass = this.state.newPass
        let repeat = this.state.repeatNewPass

        let char = document.getElementById("char-vld")
        let charMatch = document.getElementById("char-mtch-vld")
   
        if(pass == 0) {
            this.newPassInput.current.style.border = "1px solid #F00"
            return false
        }else{
            this.newPassInput.current.style.border = "1px solid #949494"
        }

        if(repeat == 0) {
            this.repeatInput.current.style.border = "1px solid #F00"
            return false
        }else{
            this.repeatInput.current.style.border = "1px solid #949494"
        }

        if(pass.length < 8){
            char.style.color = "#F00"
            return false
        }else{
            char.style.color = "#949494"
        }

        if(pass != repeat){
            charMatch.style.color = "#F00"
            return false
        }else{
            charMatch.style.color = "#949494"
        }

        this.props.reset(this.state.newPass)
        this.setState({
            isProcess: true
        })
    } 

    render(){
        return(
            <>
                <div style={{textAlign: "center"}}>
                    <img src={Logo} style={{marginTop: "40px"}}/>
                    {
                        (this.props.isLoad)
                        ?
                            <Loader/>
                        :
                            <>
                            <div style={{color: '#1F4788', fontWeight: "600", fontSize: "22px", marginTop: "70px", marginBottom: "20px"}}>
                                Reset Password
                            </div>
                            {
                                (this.props.isLoad == false && this.props.isValid)
                                ?
                                    <>
                                        <div style={{color: "#333333", fontSize: "14px"}}>
                                            Your new password must be different<br/>
                                            from previous used password.         
                                        </div>
                                        <div style={{margin: "42px"}}>
                                            <Input ref={this.newPassInput} 
                                                value={this.state.newPass}
                                                onChange={(e) => this.setState({newPass: e.target.value})}
                                                type="password" 
                                                placeholder="New Password"/>
                                            <TextInput id="char-vld">Must be at least 8 characters. </TextInput>
                                            <Input ref={this.repeatInput} 
                                                value={this.state.repeatNewPass}
                                                onChange={(e) => this.setState({repeatNewPass: e.target.value})}
                                                type="password" 
                                                placeholder="Repeat New Password"/>
                                            <TextInput id="char-mtch-vld">Both password must match.</TextInput>
                                        </div>
                                        {
                                            (!this.state.isProcess)
                                            ? 
                                                <ButtonChange onClick={this.reset}>Reset Password</ButtonChange>
                                            :
                                                <ButtonChange style={{background: "#FFF", border: "1px solid #CCC"}}>
                                                    <img src={Gif} style={{width: "35px", marginTop: "5px"}}/>
                                                </ButtonChange>
                                        }
                                    </>
                                :
                                    <div style={{color: "#333333", fontSize: "18px"}}>
                                        The request link for reset password<br/>
                                        is not available or expired.         
                                    </div>
                            }
                            </>
                    }
                    
                    
                </div>
            </>
        )
    }
}

export default mobile