import React, { Component } from 'react'
import CalendarIcon from '../../../images/calendar.png'
import ReasonIcon from '../../../images/question.png'
import UserIcon from '../../../images/user-3-color-64px.png'
import { convertDate } from '../../function/function'
import Styled from 'styled-components'
import LoaderGif from '../../../images/Pulse-1s-51px.gif'

const PageButton = Styled.button`
    padding: 4px;
    border: 1px solid #dedede;
    background: rgb(239, 241, 248);
    font-wight: bold;
`

class sick_web extends Component{

    constructor(){
        super()
        this.state = {
            page: 1,
            size: 1
        }
    }

    componentDidMount(){
        this.setSize()
    }

    componentDidUpdate(prevProps){
        if(prevProps != this.props){
            this.setSize()
        }
    }

    setSize = () => {
        let data = this.props.data
        let size = data.length / 10
        this.setState({
            size: Math.ceil(size)
        })
    }

    next = () => {
        let currPage = this.state.page
        if(currPage < this.state.size)
        {
            currPage++;
            this.setState({
                page: currPage
            })
        }
    }

    prev = () => {
        let currPage = this.state.page
        if(currPage > 1){
            currPage--;
            this.setState({
                page: currPage
            })
        }
    }

    last = () => {
        this.setState({
            page: this.state.size
        })
    }

    first = () => {
        this.setState({
            page: 1
        })
    }

    render(){

        const listData = this.props.data.map((dt, index) => {
            let seq = parseInt(index) + 1
            let userApplicant = dt.userApplicant
            let sickReason = userApplicant.sickReason
            let userProfile = userApplicant.userProfile
            let background = (index%2==0) ? "none" : "#eff1f8"
            let date = convertDate(dt.bookingDate)

            let start = 10 * this.state.page - 10 + parseInt(1)
            let finish = 10 * this.state.page

            if(seq >= start && seq <= finish){
                return <tr style={{background: background}}>
                            <td style={{padding: "10px 5px", background: "none", border: "none"}}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <img src={UserIcon} style={{width: "18px"}}/>
                                    <div style={{marginLeft: "5px", fontSize: "14px"}}>{userProfile.name}</div>
                                </div>
                            </td>
                            <td style={{padding: "10px 0px", background: "none", border: "none"}}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <img src={ReasonIcon} style={{width: "16px"}}/>
                                    <div style={{marginLeft: "5px", fontSize: "14px"}}>
                                        {
                                            (sickReason == null)
                                            ? <span style={{color: "#CCC", fontSize: "12px"}}>No description</span>
                                            : <span>{sickReason}</span>
                                        }
                                    </div>
                                </div>
                            </td>
                            <td style={{padding: "10px 0px", background: "none", border: "none", width: "150px"}}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <img src={CalendarIcon} style={{width: "16px"}}/>
                                    <div style={{marginLeft: "5px", fontSize: "14px"}}>{date}</div>
                                </div>
                            </td>
                        </tr>
            }
        })

        return(
            <div id="bs-sick-ls">
                <table style={{width: "80%"}}>
                    <thead style={{border: "none"}}>
                        <th style={{width: "250px", textAlign: "left", padding: "10px 5px", background: "none", fontWeight: "bold", border: "none", fontSize: "12px", borderBottom: "rgb(159 159 159 / 20%) 1px solid"}}>Aplicant</th>
                        <th style={{textAlign: "left", padding: "10px 0px", background: "none", border: "none", fontWeight: "bold", fontSize: "12px", borderBottom: "rgb(159 159 159 / 20%) 1px solid"}}>Description</th>
                        <th style={{width: "100px", textAlign: "left", padding: "10px 0px", background: "none", fontWeight: "bold", border: "none", fontSize: "12px", borderBottom: "rgb(159 159 159 / 20%) 1px solid"}}>Date</th>
                    </thead>
                    <tbody>
                        {
                            (this.props.data.length == 0)
                            ?   (!this.props.isLoad)
                                ?   <tr><td colSpan={3} style={{textAlign: "center", padding: "50px", color: "#CCC", fontWeight: "bold"}}>No data to display</td></tr>
                                :   <tr>
                                        <td colSpan="3" style={{textAlign: "center", padding: "50px"}}>
                                            <img src={LoaderGif} style={{width: "35px"}}/>
                                        </td>
                                    </tr>
                            : listData
                        }
                    </tbody>
                </table>
                <div id="ft-l-s-tbl" style={{width: "80%"}}>
                    {
                        (this.props.data.length == 0)
                        ?
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", padding: "20px 0px", borderTop: "1px solid #CCC"}}>
                                {/* <div style={{fontSize: "14px"}}>Showing 51 to 57 of 57 entries</div> */}
                                <div style={{fontSize: "14px"}}>Showing {this.state.page} of {this.state.size} pages</div>
                                <div>
                                    <PageButton onClick={this.first}>First</PageButton>
                                    <PageButton onClick={this.prev}>Prev</PageButton>
                                    <PageButton style={{minWidth: "40px"}}>{this.state.page}</PageButton>
                                    <PageButton onClick={this.next}>Next</PageButton>
                                    <PageButton onClick={this.last}>Last</PageButton>
                                </div>
                            </div>
                        : ""
                    }
                </div>
            </div>
        )
    }
}

export default sick_web