import React from 'react'
import axios from 'axios'
const BaseUrlHospitals = "/hospital"

class hospital_service {

    fetchHospitals = async () => {
        let resp = await axios.get(BaseUrlHospitals+"/getHospitals?size=1000");
        return resp
    }

    addHospitals = async (payload) => {
        try {
            let resp = await axios.post(BaseUrlHospitals+"/saveHospital", payload)
            return resp
        } catch (error) {
            let resp = error.response
            return resp
        }
    }
}

export default hospital_service