import { getRoleName } from "./read_token"
import axios from 'axios'
import ReactDOM from 'react-dom'
import ErrorPage from '../403'

export const convertDate = (date) => {
    let day = ""
    let dDisplay = ""
    let year = ""
    let mn = ""

    if(date){
        let d = new Date(date.replace(' ','T'))
        day = d.getDate()
        if(day < 10){
            dDisplay = "0"+day
        }else{
            dDisplay = day
        }

        let m = parseInt(d.getMonth()) + 1
        if(m == 1) mn = "Jan"
        if(m == 2) mn = "Feb"
        if(m == 3) mn = "Mar"
        if(m == 4) mn = "Apr"
        if(m == 5) mn = "May"
        if(m == 6) mn = "Jun"
        if(m == 7) mn = "Jul"
        if(m == 8) mn = "Aug"
        if(m == 9) mn = "Sep"
        if(m == 10) mn = "Oct"
        if(m == 11) mn = "Nov"
        if(m == 12) mn = "Dec"

        year = d.getFullYear()
    }
    return dDisplay+" "+mn+", "+year
}

export const convertDate2 = (date) => {
    let day = ""
    let dDisplay = ""
    let year = ""
    let mn = ""

    if(date){
        let d = new Date(date)
        day = d.getDate()
        if(day < 10){
            dDisplay = "0"+day
        }else{
            dDisplay = day
        }

        let m = parseInt(d.getMonth()) + 1
        if(m == 1) mn = "Jan"
        if(m == 2) mn = "Feb"
        if(m == 3) mn = "Mar"
        if(m == 4) mn = "Apr"
        if(m == 5) mn = "May"
        if(m == 6) mn = "Jun"
        if(m == 7) mn = "Jul"
        if(m == 8) mn = "Aug"
        if(m == 9) mn = "Sep"
        if(m == 10) mn = "Oct"
        if(m == 11) mn = "Nov"
        if(m == 12) mn = "Dec"

        year = d.getFullYear()
    }
    return dDisplay+" "+mn+", "+year
}

export const convertDateDDMMYYYString = (date) => {
    let d = new Date(date)
    console.log(d)
    let day = d.getDate()
    let dDisplay = ""
    if(day < 10){
        dDisplay = "0"+day
    }else{
        dDisplay = day
    }

    let m = parseInt(d.getMonth()) + 1
    let mn = (m < 10) ? "0"+m : m
    let year = d.getFullYear()
    let sec = d.getSeconds()
    let min = d.getMinutes()
    let h = d.getHours()

    return dDisplay+mn+year+sec+min+h
}

export const  initialName = (name) => {
    if(name != null && name !== undefined && name != ""){
        let l = name.split(" ")
        let init = ""
        
        for(let i = 0;i<l.length;i++){
            if(i<2){
                init += l[i][0]
            }else{
                break
            }
        }

        return init.toUpperCase()
    }else{
        return ""
    }
}

export const getTime = (date) => {
    let dt = new Date(date)
    let h = dt.getHours()
    let m = dt.getMinutes()
    let s = dt.getSeconds()

    h = (h < 10) ? "0"+h : h
    m = (m < 10) ? "0"+m : m
    s = (s < 10) ? "0"+s : s
    return h+":"+m+":"+s
}

export const getDate = (date) => {
    let d = new Date(date)
    
    let day = d.getDate()
    let dDisplay = ""
    if(day < 10){
        dDisplay = "0"+day
    }else{
        dDisplay = day
    }

    let m = parseInt(d.getMonth()) + 1
    let mn = (m < 10) ? "0"+m : m
    let year = d.getFullYear()
    return year+"-"+mn+"-"+dDisplay
}

export const isManagerApplicant = () => {
    // alert("dandi rahmawan")
    let isM = false
    let role = getRoleName()
    if(role.toLowerCase() == "director" || role.toLowerCase() == "director / manager applicant"){
        isM = true
    }
    
    return isM
}

export const isManagerTechniciant = () => {
    // alert("dandi rahmawan")
    let isM = false
    let role = getRoleName()
    if(role.toLowerCase() == "manager" || role.toLowerCase() == "director / manager techniciant"){
        isM = true
    }
    
    return isM
}

export const isUserSales = () => {
    let isM = false
    let role = getRoleName()
    if(role.toLowerCase() == "user sales"){
        isM = true
    }
    
    return isM
}

export const downloadFile = async (url) => {
    const FileDownload = require('js-file-download');
    let date = new Date()
    let name = "export "+convertDateDDMMYYYString(date)+".xlsx"

    this.setState({
        isExporting: true
    })

    let resp = await axios({
        url: url, //your url
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        // const url = window.URL.createObjectURL(new Blob([response.data]));
        FileDownload(response.data, name);
        this.setState({
            isExporting: false
        })
    });
}

export const errorFetch = (codeError, desc) => {
    if(codeError == 401){
        window.localStorage.removeItem("token")
        window.location.reload()
    }else{
        ReactDOM.render(<ErrorPage statusCode={codeError} desc={desc}/>, document.getElementById("root"))
    }
}

export const element403 = () => {
    let elm = document.createElement("div")
    elm.setAttribute("id", "403-elm");
    elm.style.display = "flex";
    elm.style.justifyContent = "center";

    elm.innerHTML = "terjadi kesalahan"
    return elm;
}

export const logout = () => {
    window.localStorage.removeItem("token")
    window.location.reload()
}