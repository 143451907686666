import React from 'react'
import { Link } from 'react-router-dom'
import { convertDate } from "../../function/function"

const pendingIcon = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0.750107C15.108 0.750107 19.25 4.89111 19.25 10.0001C19.25 15.1081 15.108 19.2501 10 19.2501C4.891 19.2501 0.75 15.1081 0.75 10.0001C0.75 4.89111 4.891 0.750107 10 0.750107Z" stroke="#08A0F7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9.99512 6.20421V10.6232" stroke="#08A0F7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9.995 13.7961H10.005" stroke="#08A0F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

const acceptedIcon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.25 2.75C17.358 2.75 21.5 6.891 21.5 12C21.5 17.108 17.358 21.25 12.25 21.25C7.141 21.25 3 17.108 3 12C3 6.891 7.141 2.75 12.25 2.75Z" stroke="#34A770" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8.43994 12.0002L10.8139 14.3732L15.5599 9.6272" stroke="#34A770" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>


const rejectedIcon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.25 2.75C17.358 2.75 21.5 6.891 21.5 12C21.5 17.108 17.358 21.25 12.25 21.25C7.141 21.25 3 17.108 3 12C3 6.891 7.141 2.75 12.25 2.75Z" stroke="#F85640" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M14.3955 9.5949L9.60352 14.3869" stroke="#F85640" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M14.3971 14.3898L9.60107 9.5928" stroke="#F85640" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

const row_web = (props) => {
    
    const colosStatus = (status) => {
        if(status.toLowerCase() == "pending") return '#08A0F7'
        if(status.toLowerCase() == "finished") return '#34A770'
        if(status.toLowerCase() == "rejected") return '#F85640'
        if(status.toLowerCase() == "on progress") return "#CCCCC"
    }

    const icon = (status) => {
        if(status.toLowerCase() == "pending") return pendingIcon
        if(status.toLowerCase() == "finished") return acceptedIcon
        if(status.toLowerCase() == "rejected") return rejectedIcon
        if(status.toLowerCase() == "on progress") return acceptedIcon
    }

    const initialName = (name) => {
        let l = name.split(" ")
        let init = ""
        
        for(let i = 0;i<l.length;i++){
            if(i<2){
                init += l[i][0]
            }else{
                break
            }
        }

        return init.toUpperCase()
    }

    const techniciantName = () => {
        if(props.data.user.profile){
            let profile = props.data.user.profile
            return profile.name
        }else{
            return ""
        }
    }

    const seq = () => {
        let no = props.data.sequenceNum
        if(no < 1){
            return 0;
        }else{
            return no;
        }
    }

    return(
        <tr className="select">
            <td style={{textAlign: "center"}}>
                <div style={{display: "flex", 
                        alignItems: "center",
                        margin: "auto", 
                        justifyContent: "center", 
                        height: "30px",
                        borderRadius: "100%",
                        border: "1px solid #CCC", 
                        fontSize: "11px",
                        fontWeight: "bold",
                        width: "30px"}}>{seq()}</div>
            </td>
            <td style={{textAlign: "center"}}>
                {props.data.serialNumber}
            </td>
            <td>
                <div style={{display: "flex", alignItems: "center"}}>
                    <div className="pic-dt-td">
                        {/* TW */}
                        {initialName(props.data.customerName)}
                    </div>    
                    {props.data.customerName}
                </div>
            </td>
            <td>
                <div style={{display: "flex", alignItems: "center"}}>
                    <div className="pic-dt-td">
                        {initialName(techniciantName())}
                    </div> 
                    {techniciantName()}
                </div>
            </td>
            <td style={{textAlign: "center", fontSize: "13px"}}>
                {convertDate(props.data.startDate)}
            </td>
            <td style={{textAlign: "center",  fontSize: "13px"}}>
                {convertDate(props.data.endDate)}
            </td>
            <td>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <div style={{display: "flex", 
                                alignItems: "center", 
                                justifyContent: "center",
                                overflowY: "auto", 
                                background: props.data.colorStatus,
                                border: "1px solid #d8d8d8", 
                                padding: "4px 8px", borderRadius: "10px"}}>
                        {icon(props.data.statusReportForm)}&nbsp;
                        <span style={{color: colosStatus(props.data.statusReportForm), fontSize: "13px"}}>
                            {props.data.statusReportForm}
                        </span>
                       
                    </div>
                </div>
            </td>
            <td style={{textAlign: "center"}}>
                <Link to={"/dashboard/techniciant/"+props.data.id}>Details</Link>
            </td>
        </tr>
    )
}

export default row_web
