import React, { Component } from 'react'
import Styled from 'styled-components'
import axios from 'axios'

const Block = Styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
`

class pop_add_edit extends Component {

    constructor(){
        super()
        this.state = {
            address: "",
            rsName: "",
            regionId: "",
            isProcess: false,
            id: "",
        }
    }

    componentDidMount(){
        if(this.props.dataSelected != ""){
            let d = this.props.dataSelected
           let regionId = d.region.id

            this.setState({
                address: d.address,
                rsName: d.name,
                regionId: regionId,
                id: d.id
            })
        }
    }

    saveEdit = async () => {
        let elm = document.getElementById("p-alt-pp")
        let obj = {
                    "address": this.state.address,
                    "name": this.state.rsName,
                    "regionId": this.state.regionId
                }
        
                
        let isValid = this.validation(obj)
        if(!isValid){
            elm.style.display = "block"
            return false
        }


        elm.style.display = "none"
        this.setState({
            isProcess: true
        })
        
        let rspData = ""
        if(this.props.dataSelected == ""){
            let resp = await axios.post("/hospital/saveHospital", obj)
            rspData = resp.data
        }else{
            obj.id = this.state.id
            let resp = await axios.put("/hospital/updateHospital", obj)
            rspData = resp.data
        }
        
        
        if(rspData.success){
            if(this.state.id != ""){
                let newData = this.setUpdatedData(obj)
                this.props.updateData(newData, this.props.index)
            }else{
                this.props.fetchHospitals()
            }

            this.props.cancel()
        }
    }

    setUpdatedData = (obj) => {
        let oldData = this.props.dataSelected
        var { name, address, regionId } = obj;
        
        /*get data region*/
        let dataRegion = ""
        let regions = this.props.regions
        for(let i = 0;i<regions.length;i++){
            let id = regions[i].id
            if(id == regionId){
                dataRegion = regions[i]
                break;
            }
        }

        oldData.name = name
        oldData.address = address
        oldData.region = dataRegion

        return oldData
    }

    validation = (param) => {
        let isValid = true;
        if(param.address == "") isValid = false;
        if(param.name == "") isValid = false;
        if(param.regionId == "") isValid = false
        return isValid;
    }

    render(){

        const optionsRegions = this.props.regions.map(dt => {
            return <option value={dt.id}>{dt.name}</option>
        })

        return(
            <Block id="ad-hsp-pp">
                <div style={{background: "#FFF", width: "400px", borderRadius: "10px"}}>
                    {
                        (this.props.dataSelected == "")
                        ? <div style={{padding: "20px", fontWeight: "bold", borderBottom: "1px solid #e0e0e0"}}>Add Hospital</div>
                        : <div style={{padding: "20px", fontWeight: "bold", borderBottom: "1px solid #e0e0e0"}}>Edit Hospital</div>
                    }
                    
                    <div id="p-alt-pp" 
                        style={{padding: "8px", 
                                fontSize: "12px", 
                                background: "#F00",
                                display: "none", 
                                color: "#FFF", 
                                textAlign: "center"}}
                    >
                        All field must be filled
                    </div>
                    
                    <div style={{display: "flex", alignItems: "center", flexDirection: "column", padding: "10px"}}>
                        <input placeholder="RS Name"
                            onChange={(e) => this.setState({rsName: e.target.value})}
                            value={this.state.rsName} 
                            style={{border: "none", background: "rgb(230 230 230)",width: "350px"}}
                            className="frm-input" type="text"
                        />

                        <textarea placeholder="RS Address"
                            onChange={(e) => this.setState({address: e.target.value})}
                            value={this.state.address} 
                            className="frm-input" type="text"
                            style={{border: "none", 
                                    background: "rgb(230 230 230)", 
                                    width: "350px", 
                                    marginTop: "10px",  
                                    height: "100px",  
                                    resize: "none",      
                                    padding: "12px 20px"}}
                           
                        />

                        <div style={{display: "flex", alignItems: "center",marginTop: "10px"}}>
                            {/* <NameInput>Region</NameInput> */}
                            <div>
                                <select 
                                    value={this.state.regionId} 
                                    onChange={(e) => this.setState({regionId: e.target.value})} 
                                    style={{border: "none", background: "rgb(230 230 230)",width: "350px"}}
                                    className="frm-input"
                                >
                                    <option value="" style={{display: "none"}}>Select region</option>
                                    {optionsRegions}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div style={{padding: "20px", fontWeight: "bold", display: "flex", justifyContent: "flex-end"}}>
                        <button onClick={this.props.cancel}
                            className="btn-sv" 
                            id="cl-ad-pp"
                            style={{background: "#e67272", height: "auto", padding: "10px", margin: "5px", borderRadius: "4px", fontSize: "12px", width: "100px"}}>Cancel
                        </button>
                        {
                            (!this.state.isProcess)
                            ?
                                <button onClick={this.saveEdit}
                                    className="btn-sv" 
                                    id="sv-ad-pp"
                                    style={{background: "#7784EE", height: "auto", padding: "10px", margin: "5px", borderRadius: "4px", fontSize: "12px", width: "100px"}}>Save
                                </button>
                            :
                                <button
                                    className="btn-sv" 
                                    id="sv-ad-pp"
                                    style={{background: "#7784EE", height: "auto", padding: "10px", margin: "5px", borderRadius: "4px", fontSize: "12px", width: "100px", opacity: "0.6"}}>Saving..
                                </button>
                        }
                    </div>
                </div> 
            </Block>
        )
    }
}

export default pop_add_edit