import React, { Component } from 'react';
import LoginWeb from './login_web'
import LoginMobile from './login_mobile'
import axios from 'axios'
import Swal from 'sweetalert2'

export default class Login extends Component {

    constructor(){
        super()
        this.state = {
            windowWidth: window.innerWidth,
            isLogedIn : false,
            checking : true,
            isLoadLogin: false
        }
    }

    componentDidMount(){
        // if(document.cookie != ''){
        //     window.location = "/user/home"
        //     this.setState({
        //         isLogedIn: true,
        //         checking: false
        //     })
        // }else{
            this.setState({
                isLogedIn: false,
                checking: false
            })
        // }

        this.setState({windowWidth: window.innerWidth})
        window.addEventListener("resize", () => {
            this.setState({windowWidth: window.innerWidth})
        })
    }

    login = (username, password) => {
        let obj = {}
        obj.username = username
        obj.password = password
        
        this.setState({
            isLoadLogin: true
        })

        axios.post("/auth/login", obj).then(result => {
            let dataLogin = result.data.data
            /*set data login to locastorage*/
            window.localStorage.setItem('token', JSON.stringify(dataLogin));
            window.location.reload()
        }).catch(error => {
            let resp = error.response
            if(resp){
                let msg = resp.data.message
                Swal.fire(
                    'Failed',
                    msg,
                    'error'
                );
            }else{
                Swal.fire(
                    'Failed',
                    "Network error, please check your connection",
                    'error'
                );
            }

            this.setState({
                isLoadLogin: false
            })
        })
    }

    render() {
        return (
            (this.state.windowWidth > 1202)
            ?
                <LoginWeb isLoadLogin={this.state.isLoadLogin} login={this.login}/>
            :
                <LoginMobile isLoadLogin={this.state.isLoadLogin} login={this.login}/>
        )
        //     (this.state.checking)
        //     ?
        //         <div>please wait..</div>
        //     :
        //         (!this.state.isLogedIn)
        //         ?
        //             (this.state.windowWidth > 1202)
        //             ?
        //                 <LoginWeb isLoadLogin={this.state.isLoadLogin} login={this.login}/>
        //             :
        //                 <LoginMobile login={this.login}/>
        //         :
        //             <div>please wait..</div>
        // );
    }
}
