import React, { Component } from 'react'
import Styled from 'styled-components'

const Base = Styled.div`
    background: rgba(0,0,0,0.4);
    left: 0;
    top: 0;
    z-index: 1000;
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
const BaseRejectConfirm = Styled.div`
    max-width: 300px;
    width: 80%;
    border-radius: 4px;
    background: #FFF;
    display: flex;
    flex-direction: column;
`
class deny_leave_confirm extends Component{

    constructor(){
        super()
        this.state = {
            reason: ""
        }
    }

    submit = () => {
        this.props.submit(this.props.id, this.state.reason)
    }

    render(){
        let windowWidth = window.innerWidth
        return(
            <Base id="conf-dny-lve">
                <BaseRejectConfirm>
                    <div style={{height: "100%"}}>
                        {
                            (windowWidth > 1202)
                            ? <div style={{padding: "15px", fontSize: "16px", fontWeight: "bold", borderBottom: "1px solid #CCC"}}>Reject Reason</div>
                            : <div style={{padding: "15px", fontSize: "14px", fontWeight: "bold", borderBottom: "1px solid #CCC"}}>Reject Reason</div>
                        }
                        <div style={{padding: "15px", paddingBottom: "0px"}}>
                            {
                                (windowWidth > 1202)
                                ?   <textarea placeholder='type reason here'
                                        onChange={(e) => this.setState({reason: e.target.value})} 
                                        style={{width: "100%", 
                                                height: "100px", 
                                                resize: "none", 
                                                border: "none", 
                                                borderBottom: "2px solid #CCC", 
                                                outline: "none"}}>
                                    </textarea>
                                :
                                    <textarea placeholder='type reason here'
                                        onChange={(e) => this.setState({reason: e.target.value})} 
                                        style={{width: "100%", 
                                                height: "80px",
                                                fontSize: "12px", 
                                                resize: "none", 
                                                border: "none", 
                                                borderBottom: "2px solid #CCC", 
                                                outline: "none"}}>
                                    </textarea>
                            }
                        </div>
                    </div>
                    <div style={{padding: "15px", display: "flex", justifyContent: "flex-end"}}>
                        {
                            (windowWidth > 1202)
                            ?
                                (this.state.reason != "")
                                ?  <button className='btn-primary' onClick={this.submit} style={{padding: "7px", fontSize: "12px", borderRadius: "4px"}}>Submit</button>
                                : <button className='btn-primary' style={{padding: "7px", fontSize: "12px", borderRadius: "4px", opacity: "0.5"}}>Submit</button>
                            :
                                (this.state.reason != "")
                                ?  <button className='btn-primary' onClick={this.submit} style={{padding: "5px", fontSize: "10px", borderRadius: "4px"}}>Submit</button>
                                : <button className='btn-primary' style={{padding: "5px", fontSize: "10px", borderRadius: "4px", opacity: "0.5"}}>Submit</button>
                        }
                        &nbsp;&nbsp;
                        {
                            (windowWidth > 1202)
                            ? <button className='btn-secondary' onClick={this.props.cancel} style={{padding: "7px", fontSize: "12px", borderRadius: "4px", outline: "none", border: "1px solid #CCC"}}>Cancel</button>
                            : <button className='btn-secondary' onClick={this.props.cancel}  style={{padding: "5px", fontSize: "10px", borderRadius: "4px", outline: "none", border: "1px solid #CCC"}}>Cancel</button>
                        }
                        
                    </div>
                </BaseRejectConfirm>
            </Base>
        )
    }
}

export default deny_leave_confirm