import React, { Component } from 'react'
import Header from '../../header/header'
import Styled from 'styled-components'
import SrcIcon from '../../../images/search-interface-symbol.svg'
import LoaderGif from '../../../images/Pulse-1s-51px.gif'
import { getRoleName } from "../../function/read_token"
import Row from './row_web'
import RowDirector from './row_director_web'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import DataStatus from '../../../data/status'
import { filterStatus, searchDataUserSales, setDataUserSales, setPageDataUserSales} from '../../../redux/action'
import ConfirmDelete from './delete_confirm'
import { isUserSales } from '../../function/function'
import ApprIcon from '../../../images/quality.png'
import DenyIcon from '../../../images/multiply.png'
import DoneIcon from '../../../images/check-blue.png'
import SickIcon from '../../../images/medicine.png'
import PreviewImage from '../../preview_image_appl_report'
import axios from 'axios'
import { isManagerApplicant } from '../../function/function'

const Base = Styled.div`
    position: relative;
`
const SrcBase = Styled.div`
    width: 223px;
    padding: 0px 10px;
    height: 37px;
    background: #FFF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    border: 1px solid #e7e7e7;
`

const FilterBase = Styled.div`
    width: 124px;
    position: relative;
    height: 37px;
    background: #FFF;
    border: 1px solid #e7e7e7;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 25px;
`

const Btn = Styled.button`
    width: 226px;
    height: 42px;
    background: #7784EE;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    color: #FFF;
    border: none;
    outline: none;
`

let size = 0
let DS = new DataStatus()
class director_web extends Component{

    constructor(){
        super()
        this.state = {
            popup: "",
            filterPop: false,
            seq: 0 /*loop untuk mengatahui data yang di tampikan, keperluar paging*/
        }
    }

    componentDidMount(){
        window.addEventListener("click", this.hideFilter)
    }

    componentWillUnmount(){
        window.removeEventListener("click", this.hideFilter)
    }

    filter = (e) => {
        e.stopPropagation()
        this.setState({
            filterPop: true
        })
    }

    selectFilter = (id, name) => {
        let obj = {
            id: id,
            name: name
        }
        this.props.filterStatus(obj)
        this.setState({
            filterPop: false
        })
    }

    hideFilter = (event) => {
        let elmFilter = document.getElementById("bs-fltr-chs")
        let ref = elmFilter
        if(ref != null){
            if(ref && !ref.contains(event.target)){ 
                this.setState({
                    filterPop: false
                })   
            }
        }
    }

    setPagePosition = (type) => {
        let currPage = this.props.page
        if(type == "next"){
            if(currPage < size){
                currPage += 1
            }
        }else{
            if(currPage > 1){
                currPage -= 1
            }
        }
        
        /*redux props action*/
        this.props.setPage(currPage)
    }

    firstPage = () => {
        this.props.setPage(1)
    }

    lastPage = () => {
        this.props.setPage(size)
    }

    delete = (id) => {
        this.setState({
            popup: <ConfirmDelete no={() => this.setState({popup: ""})} yes={() => this.yesDelete(id)}/>
        })
    }

    yesDelete = (id) => {
        axios.delete("/applicant/cancelApplicant/"+id).then(response => {
            let resp = response.data
            if(resp.success){
                this.setDataAfterDeleteRedux(id)
                this.setState({
                    popup: ""
                })
            }
        })
    }

    setDataAfterDeleteRedux = (id) => {
        let dataSales = this.props.dataUserSales
        let data = [...dataSales.data]
        let idx = 0
        for(let i = 0;i<data.length;i++){
            if(id == data[i].id){
                idx = i
                break
            }
        }

        /*delete array index*/
        data.splice(idx, 1)

        /*set new data to redux*/
        let newData = {}
        let total = dataSales.total - 1
        newData.result = data
        newData.total = total

        /*parse data to redux*/
        this.props.setDataUserSales(newData)
    }

    searchName = (e) => {
        let v = e.target.value
        this.props.search(v)
    }

    previewImage = (data) => {
        this.setState({
            popup: <PreviewImage data={data} hideImage={() => this.setState({popup: ""})}/>
        })
    }

    setItemTable = (dt, index, seq) => {
        let isView = false
        let data = {seq: seq, row: null}
        let st = (this.props.page > 1) ? (this.props.page * 10) - 9 : this.props.page
        let ed = this.props.page * 10
            
        /*filtering by search*/
        let search = this.props.searchParam
        let userApplicant = (dt.userApplicant != null) ? dt.userApplicant.userProfile.name : ""
        let rsName = dt.formApplicant.hospital.name
        let contactPerson = dt.formApplicant.contactPerson
        let sales = dt.formApplicant.salesUser.name

        if(
            search == 0 || 
            sales.toLowerCase().match(search.toLowerCase()) ||
            rsName.toLowerCase().match(search.toLowerCase()) || 
            userApplicant.toLowerCase().match(search.toLowerCase()) ||
            contactPerson.toLowerCase().match(search.toLowerCase())    
        ){
            isView = true
        }

        let filterParam = this.props.filterStatusParam
        let idStatusFilter = filterParam.id
        let idStatus = dt.flagStatus.id
        if(idStatusFilter != "" && idStatusFilter != idStatus){
            isView = false
        }

        if(isView) {
            seq++
            data.seq = seq;
        }
        
        let idx = parseInt(index) + 1
        let len = this.props.data.length

        if(idx == len){
            size = Math.ceil(seq / 10)
        }

        if(seq >= st && seq <= ed && isView){
            data.row = <Row data={dt} delete={this.delete} previewImage={this.previewImage} />
        }

        return data
    }

    setItemTableDirector = (dt, index, seq) => {
        let isView = false
        let formApplicant = dt.formApplicant
        let userApplicant = dt.userApplicant
        let data = {seq: seq, row: null}
        
        let st = (this.props.page > 1) ? (this.props.page * 10) - 9 : this.props.page
        let ed = this.props.page * 10
            
        /*filtering by search*/
        let search = this.props.searchParam
        let rsName = formApplicant.hospital.name
        let contactPerson = formApplicant.contactPerson
        let applicantName = userApplicant.userProfile.name
        let sales = dt.formApplicant.salesUser.name

        if(
            search == 0 || 
            sales.toLowerCase().match(search.toLowerCase()) ||
            rsName.toLowerCase().match(search.toLowerCase()) || 
            applicantName.toLowerCase().match(search.toLowerCase()) ||
            contactPerson.toLowerCase().match(search.toLowerCase())    
        ){
            isView = true
        }

        let filterParam = this.props.filterStatusParam
        let idStatusFilter = filterParam.id
        let idStatus = dt.flagStatus.id

        if(idStatusFilter != "" && idStatusFilter != idStatus){
            isView = false
        }

        if(isView) {
            seq++
            data.seq = seq /*for cummulative seq data in render needs*/
        }
        
        let idx = parseInt(index) + 1
        let len = this.props.data.length

        if(idx == len){
            size = Math.ceil(seq / 10)
        }

        if(seq >= st && seq <= ed && isView){
            // console.log(seq+" >= "+st+" && "+seq +" <= "+ ed +" && "+isView)
            data.row = <RowDirector data={dt} delete={this.delete} previewImage={this.previewImage}/>
        }

        return data
    }

    render(){

        let seq = 0 /*loop untuk mengatahui data yang di tampikan, keperluar paging*/
        const data = this.props.data.map((dt, index) => {
            if(!isManagerApplicant()){
                let dataItem = this.setItemTable(dt, index, seq)
                seq = dataItem.seq /*akumuasi sequence data or row*/
                if(dataItem.row != null) return dataItem.row
            }else{
                let dataItem = this.setItemTableDirector(dt, index, seq)
                seq = dataItem.seq /*akumuasi sequence data or row*/
                if(dataItem.row != null) return dataItem.row
            }
        })

        return(
            <Base>
                {this.state.popup}
                <Header/>
                <div style={{padding: "40px 51px"}}>
                    <div style={{fontSize: "24px", 
                                color: "#1F4788",
                                display: "flex", 
                                justifyContent: "space-between", 
                                alignItems: 'center'}}>
                        <span>Dashboard</span>
                        {/* <Btn>+ Create New</Btn> */}
                        {
                            (getRoleName().toLowerCase() == "user sales")
                            ?
                                <Link to="/dashboard/user_sales/form">
                                    <Btn>+ Create New</Btn>
                                </Link>
                            :
                                ""
                        }
                    </div>
                    <div style={{display: "flex", marginTop: "50px"}}>
                        <SrcBase>
                            <img src={SrcIcon} style={{width: "15px"}}/>
                            <div>
                                <input type="text" 
                                    onChange={(e) => this.searchName(e)}
                                    value={this.props.searchParam}
                                    style={{width: "100%", 
                                            marginLeft: "10px", 
                                            border: "none", 
                                            outline: "none"}} 
                                    placeholder="Search by name"
                                />
                            </div>
                        </SrcBase>
                        <FilterBase>
                            {
                                 
                                    (this.props.filterStatusParam.id == "")
                                    ?   <FilterBase onClick={this.filter} style={{cursor: "pointer", border: "none", marginLeft: "0px", padding: "0px 10px"}}>
                                            <div  style={{color: '#717171', fontSize: "13px"}}>
                                                {
                                                    (this.props.filterStatusParam.id == "")
                                                    ? <span>Filter By</span>
                                                    : <span style={{color: "#000", fontWeight: "bold"}}>{this.props.filterStatusParam.name}</span>
                                                }
                                            </div>
                                            {chevron}
                                        </FilterBase>
                                    :   <FilterBase onClick={this.filter} style={{cursor: "pointer", border: "none", borderRadius: "0px 10px 10px 0px", marginLeft: "0px", padding: "0px 10px", borderLeft: "3px solid #577bac"}}>
                                            <div  style={{color: '#717171', fontSize: "13px"}}>
                                                {
                                                    (this.props.filterStatusParam.id == "")
                                                    ? <span>Filter By</span>
                                                    : <span style={{color: "#000", fontWeight: "bold"}}>{this.props.filterStatusParam.name}</span>
                                                }
                                            </div>
                                            {chevron}
                                        </FilterBase>
                                
                            }
                            {
                                (this.state.filterPop)
                                ?
                                    <div id="bs-fltr-chs" 
                                        style={{width: "100%", 
                                                background: "#FFF", 
                                                position: "absolute", 
                                                top: "38px",
                                                borderRadius: "5px",
                                                border: "1px solid #e7e7e7", 
                                                right: "0px"}}>
                                        
                                        <div onClick={() => this.selectFilter("")} 
                                            className="menu-hvr-item-item" 
                                            style={{padding: "5px 10px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                                        >
                                            <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px", color: "#868686"}}>All Data</div>
                                        </div>

                                        {
                                            DS.statusFlag().map(dt => {
                                                return <div onClick={() => this.selectFilter(dt.id, dt.name)} 
                                                            className="menu-hvr-item-item" 
                                                            style={{padding: "5px 10px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                                                        >
                                                            <img src={dt.icon} style={{width: "14px"}}/>
                                                            <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>{dt.name.toUpperCase()}</div>
                                                        </div>
                                            })
                                        }
                                        {/* <div onClick={() => this.selectFilter("00000000-0000-0000-0000-100000000050", "Approved")} 
                                            className="menu-hvr-item-item" 
                                            style={{padding: "5px 10px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                                        >
                                            <img src={ApprIcon} style={{width: "14px"}}/>
                                            <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>Approved</div>
                                        </div>
                                        
                                        <div onClick={() => this.selectFilter("00000000-0000-0000-0000-100000000070", "Done")} 
                                            className="menu-hvr-item-item" 
                                            style={{padding: "5px 10px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                                        >
                                            <img src={DoneIcon} style={{width: "14px"}}/>
                                            <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>Done</div>
                                        </div>

                                        <div onClick={() => this.selectFilter("00000000-0000-0000-0000-100000000060", "Deny")} 
                                            className="menu-hvr-item-item" 
                                            style={{padding: "5px 10px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                                        >
                                            <img src={DenyIcon} style={{width: "14px"}}/>
                                            <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>Deny</div>
                                        </div>

                                        <div onClick={() => this.selectFilter("00000000-0000-0000-0000-100000000030", "Sick")} 
                                            className="menu-hvr-item-item" 
                                            style={{padding: "5px 10px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                                        >
                                            <img src={SickIcon} style={{width: "14px"}}/>
                                            <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>Sick</div>
                                        </div>

                                        <div onClick={() => this.selectFilter("00000000-0000-0000-0000-100000000090", "Cenceled")} 
                                            className="menu-hvr-item-item" 
                                            style={{padding: "5px 10px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                                        >
                                            <img src={DenyIcon} style={{width: "14px"}}/>
                                            <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>Canceled</div>
                                        </div> */}
                                    </div>
                                :
                                    ""
                            }
                        </FilterBase>
                    </div>

                    <div id="tbl-bs" style={{marginTop: "50px"}}>
                        <table style={{width: "100%"}}>
                            <thead>
                                {
                                    (isManagerApplicant())
                                    ? <th>Id Request</th>
                                    : ""
                                }
                                <th>Applicant</th>
                                {/* <th>Email</th> */}
                                <th>RS Name</th>
                                {
                                    (!isUserSales())
                                    ? <th>Sales Name</th>
                                    : ""
                                }
                                <th>Contact Person</th>
                                <th>Start Date</th>
                                {
                                    (isManagerApplicant())
                                    ? <th>End Date</th>
                                    : ""
                                }
                                <th>Status</th>
                                <th>Completion</th>
                                <th>Action</th>
                            </thead>
                            <tbody>
                                {
                                    (!this.props.isLoaded) 
                                    ?
                                        <tr>
                                            {
                                                (isManagerApplicant())
                                                ?
                                                    <td colSpan="10" style={{textAlign: "center", padding: "50px"}}>
                                                        <img src={LoaderGif} style={{width: "35px"}}/>
                                                    </td>
                                                :
                                                    <td colSpan="7" style={{textAlign: "center", padding: "50px"}}>
                                                        <img src={LoaderGif} style={{width: "35px"}}/>
                                                    </td>
                                            }
                                        </tr>
                                    :
                                        (this.props.data.length == 0)
                                        ? 
                                            
                                            (!isUserSales())
                                            ? <tr><td colSpan="10" style={{color: "#CCC", textAlign: "center", fontSize: "12px", padding: "40px"}}>No data to display</td></tr>
                                            : <tr><td colSpan="10" style={{color: "#CCC", textAlign: "center", fontSize: "12px", padding: "40px"}}>No data to display</td></tr>
                                        : data
                                }
                            </tbody>
                        </table>
                        <div id="ft-tbl-dir" style={{marginTop: "50px",}}>
                            <span style={{fontSize: "14px",lineHeight: "28px", color: "#333333"}}>Showing {this.props.page} of {size} entries</span>
                            <div>
                                <button className="img-pgn" onClick={this.firstPage}>First</button>
                                <button className="img-pgn" onClick={() => this.setPagePosition("prev")}>Prev</button>
                                <button className="img-pgn">{this.props.page}</button>
                                <button className="img-pgn" onClick={() => this.setPagePosition("next")}>Next</button>
                                <button className="img-pgn" onClick={this.lastPage}>Last</button> 
                            </div>
                        </div>
                    </div>
                </div>
                {/* </BaseMain> */}
            </Base>
        )
    }
}

const radio = <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="13" cy="13" r="12.5" fill="white" fill-opacity="0.06" stroke="#08A0F7"/>
            </svg>

const chevron = <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.625 1.4375L7.5 7.5625L1.375 1.4375" stroke="#717171" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                
const mapStateToProps = state => {
    return{
        page: state.dataUserSales.page,
        searchParam: state.dataUserSales.searchName,
        dataUserSales: state.dataUserSales,
        filterStatusParam: state.dataUserSales.filterStatus
    }
}

const mapDispatchToProps = dispatch => {
    return{
        setPage: (data) => dispatch(setPageDataUserSales(data)),
        setDataUserSales: (data) => dispatch(setDataUserSales(data)),
        search: (data) => dispatch(searchDataUserSales(data)),
        filterStatus: (data) => dispatch(filterStatus(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (director_web)