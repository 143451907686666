import Raect, { Component } from 'react'
import Web from './techniciant_web'
import Mobile from './techniciant_mobile'
import axios from 'axios'
import Header from '../../header/header'
import Loader from '../../loader'
import HeaderMobile from '../../header/header_mobile'
import { setDataReport } from '../../../redux/action'
import { connect } from 'react-redux'
import PreviewImage from '../../privew_image'
import { baseUrl } from '../../../environment'
import { errorFetch } from '../../function/function'

class techniciant extends Component{

    constructor(){
        super()
        this.state = {
            windowWidth : window.innerWidth,
            data: "",
            fetchProcess: true,
            popup: ""
        }
    }

    componentDidMount(){
        window.addEventListener("resize", this.resize)
        this.fetchTechiciant()
    }

    fetchTechiciant = () => {
        let param = this.props.match.params.id
        if(param != "form"){
            axios.get("/report/"+param).then(response => {
                let resp = response.data
                this.setState({
                    data: resp.data,
                    fetchProcess: false
                })
            })
        }
    }

    fetchData = () => {
        try{
            axios.get("/report?size=10000").then(response => {
                let data = {}
                let result = response.data.data
                let total = response.data.result.total
                
                data.result = result
                data.total = total
                this.props.setReportData(data)
                this.setState({
                    isLoad: false
                })
            })
        }catch(error){
            let response = error.response
            errorFetch(response.status)
        }
    }

    resize = () => {
        let ww = window.innerWidth
        this.setState({
            windowWidth: ww
        })
    }

    selectFile = (data) => {
        let fname = data.name
        let li = fname.lastIndexOf(".")
        let ext = fname.substr(parseInt(li) + 1, fname.length)
        ext = ext.toLowerCase()

        let bseUrl = baseUrl
        let url = bseUrl+data.url
        if(ext == "jpg" || ext == "jpeg" || ext == "png"){
            this.setState({
                popup: <PreviewImage url={url} hideImage={() => this.setState({popup: ""})}/>
            })
        }else{
            window.open(url)
        }
    }

    render(){
        return(
            <>
            {this.state.popup}
            {
                (this.state.windowWidth > 1202)
                ?
                    <>
                        <Header/>
                        {
                            (this.props.match.params.id != "form" && this.state.fetchProcess)
                            ?
                                <Loader/>
                            :
                                <Web selectFile={this.selectFile}
                                    data={this.state.data} 
                                    fetchData={this.fetchData}/>

                        }
                    </>
                :
                    <>
                    <HeaderMobile title="Techniciant" enableSearch={false}/>
                    {
                        (this.props.match.params.id != "form" && this.state.fetchProcess)
                        ?
                            <Loader/>
                        :   
                            <Mobile selectFile={this.selectFile}
                                    data={this.state.data} 
                                    fetchData={this.fetchData}/>
                    }
                    </>
            }
            </>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return{
        setReportData : (data) => dispatch(setDataReport(data))
    }
}

export default connect('', mapDispatchToProps) (techniciant)