import React, { Component, createRef } from 'react'
import Styled, { ThemeConsumer } from 'styled-components'

const Base = Styled.div`
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0px;
    top: 0px;
`

const MainBase = Styled.div`
    width: 70%;
    max-width: 300px;
    background: #FFF;
    border-radius: 4px;
`

class Rejection extends Component {
    
    constructor(){
        super()
        this.state = {
            windowWidth: window.innerWidth,
            reason: ""
        }

        this.alert = createRef()
    }

    ok = () => {
        if(this.state.reason != ""){
            this.props.yes(this.state.reason)
        }else{
            this.alert.current.style.display = "block"
        }   
    }

    render(){
        return(
            <Base id="dialog-conf">
                <MainBase>
                    <div style={{padding: "10px", borderBottom: "1px solid #d4dbe0"}}>
                        {
                            (this.state.windowWidth > 1202)
                            ? <span>Reject Schedule</span>
                            : <span style={{fontSize: "12px", fontWeight: "bold"}}>Reject Schedule</span>
                        }
                    </div>
                    <div style={{padding: "10px", lineHeight: "1.2", height: "80px", background: "#eee"}}>
                        <textarea 
                            value={this.state.reason} 
                            onChange={(e) => this.setState({reason: e.target.value})}
                            style={{fontSize: "12px", width: "100%", border: "none", outline: "none", background: "none", height: "100%", resize: "none"}} placeholder="type your reason here" />
                    </div>
                    <div ref={this.alert} style={{padding: "10px", display: "none", fontSize: "10px", color: "#F00", borderTop: "1px solid #d4dbe0"}}>Reason must be filled !</div>
                    <div style={{display: "flex", justifyContent: "end", alignItems: "center", padding: "10px", borderTop: "1px solid #d4dbe0"}}>
                        <button onClick={this.ok} 
                            className="btn-primary" 
                            style={{padding: "5px", fontSize: "10px", width: "50px"}}
                        >
                            Yes
                        </button>
                        &nbsp;
                        <button onClick={this.props.cancel} 
                            className="btn-gray" 
                            style={{padding: "5px", fontSize: "10px", width: "50px"}}
                        >
                            Cancel
                        </button>
                    </div>
                </MainBase>
            </Base>
        )
    }
}

export default Rejection