import React, { Component, Fragment } from 'react'
import Styled from 'styled-components'
import Img from '../../images/Frame 184 5.png'
import Logo from '../../images/Beyond Logo.png'
import { Link } from 'react-router-dom'
import Client1 from '../../images/Group 257.png'
import Client2 from '../../images/Group 258.png'
import Client3 from '../../images/Group 259.png'
import Client1Card from '../../images/Group 254.png'
import Client2Card from '../../images/Group 255.png'
import Client3Card from '../../images/Group 256.png'
import PlusDotCircle from '../../images/Rectangle 2 Copy 7.png'

const Gray = Styled.div`
    position: fixed;
    width: 65%;
    height: 100%;
    left: 35%;
    top: 0px;
    background: #E9E9E9;
    z-index: -1;
    border-radius: 0px 0px 0px 297px;
`

const Header = Styled.div`
    display: flex;
    justify-content: space-between;
    padding: 24px 104px;
    align-items: center;
    margin-bottom: 100px;
`

const Register = Styled.button`
    width: 178px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #1F4788;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 18px;
    line-height: 22px;
    color: #1F4788;
`

const BoxServices = Styled.div`
    width: 317px;
    height: 142px;
    background: #FFFFFF;
    border-radius: 12px;
    padding-top: 19px; 
    padding-left: 57px;
    overflow: hidden;
    position: relative;
`

const CircleRed = Styled.div`
    width: 46px;
    height: 46px;
    background: #E34B64;
    margin-left: -20px;
    margin-top: 50px;
    z-index: 10;
    position: absolute;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const CircleRed1 = Styled.div`
    width: 28px;
    height: 28px;
    background: #E34B64;
    z-index: 10;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    margin-right: 8px;
    margin-top: -10px;
`

const TextHelperService = Styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-align: justify;
    color: #1F4788;
`

const GrayCircle = Styled.div`
    width: 97.34px;
    height: 97.34px;
    background: #FFF;
    border: 8px #C4C4C4 solid;
    border-radius: 100%;
    right: -25px;
    top: -20px;
    position: absolute;
`

const CardClients = Styled.div`
    width: 267px;
    height: 109px;
    margin-top: 40px;
    background: #FFFFFF;
    border-radius: 14px
`

const CardUser = Styled.div`
    width: 127px;
    height: 130px;
    background: #FFFFFF;
    border-radius: 14px;
    box-shadow: -3px 4px rgb(142, 142, 142, 0.2)
`

const Login = Styled.button`
    width: 281px;
    height: 49px;
    background: #1F4788;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #FFFFFF; 
    margin-top: 40px;
`
const BarClient = Styled.div`
    width: 238px;
    height: 5px;
    background: #34A770;
    opacity: 0.1;
    border-radius: 200px;
`

const BarClientProgress = Styled.div`
    width: 214px;;
    height: 5px;
    background: #34A770;
    border-radius: 200px;
    margin-top: -5px;
`

class index extends Component {
    render(){
        return(
            <Fragment>
                <Gray/>
                <Header>
                    <img src={Logo}/>
                    {/* <Link to="/register">
                        <Register>
                            Register
                        </Register>
                    </Link> */}
                </Header>

                <div style={{display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                    <div>
                        <p style={{fontWeight: 600, fontSize: "56px", lineHeight: "84px",color: "#1F4788"}}>
                            Beyond Medical<br/>
                            Service and Report
                        </p>
                        <p style={{fontWeight: 500, fontSize: "20px",lineHeight: "24px",color: "#333333"}}>
                            Welcome to Beyond Medical Service. If you don’t have,<br/>
                            ask administrator to create your account
                        </p>
                        <Link to="/login">
                            <Login>
                                Login
                            </Login>
                        </Link>
                    </div>
                    <div>
                        <div id="wlc-img-dsgn-100" style={{width: "536px", position: "relative", height: "546px"}}>
                            <div style={{width: "317px",height: "142px"}}>
                                <CircleRed>
                                    {pined}
                                </CircleRed>
                                <BoxServices style={{paddingTop: "19px", paddingLeft: "57px"}}>
                                    <div style={{width: "102px",
                                                height: "28px",
                                                background: "rgba(97, 100, 169, 0.3)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                borderRadius: "200px"}}>Services</div>
                                    <TextHelperService style={{marginTop: "28px"}}>
                                        Better Help and Services
                                    </TextHelperService>
                                    <GrayCircle/>
                                </BoxServices>
                            </div>

                            <CardUser style={{position: "absolute", marginLeft: "404px", marginTop: "-130px", zIndex: 2}}>
                                <div style={{padding: "8px", display: "flex", alignItems: "center"}}>
                                    <img src={Client1Card}/>
                                    &nbsp;&nbsp;&nbsp;
                                    <span style={{color: '#E34B64', fontSize: "13px"}}>Angela</span>
                                </div>
                                <div style={{padding: "8px", fontSize: '12px'}}>
                                    Recomended<br/>
                                    <span style={{color: "#41475E", opacity: "0.5", fontSize: "10px"}}>Aug 10</span>
                                </div>
                                <CircleRed1>
                                    {reactangle}
                                </CircleRed1>
                            </CardUser>

                            <CardUser style={{position: "absolute", marginLeft: "350px", marginTop: "-59px", zIndex: 1}}>
                                <div style={{padding: "8px", display: "flex", alignItems: "center"}}>
                                    <img src={Client2Card}/>
                                    &nbsp;&nbsp;&nbsp;
                                    <span style={{color: '#E34B64', fontSize: "13px"}}>Angela</span>
                                </div>
                                <div style={{padding: "8px", fontSize: '12px'}}>
                                    Good Job!<br/>
                                    <span style={{color: "#41475E", opacity: "0.5", fontSize: "10px"}}>Dec 20</span>
                                </div>
                                <CircleRed1>
                                    {reactangle}
                                </CircleRed1>    
                            </CardUser>

                            <CardUser style={{position: "absolute", marginLeft: "300px", marginTop: "5px"}}>
                                <div style={{padding: "8px", display: "flex", alignItems: "center"}}>
                                    <img src={Client3Card}/>
                                    &nbsp;&nbsp;&nbsp;
                                    <span style={{color: '#E34B64', fontSize: "13px"}}>Angela</span>
                                </div>
                                <div style={{padding: "8px", fontSize: '12px'}}>
                                    Nice Work!<br/>
                                    <span style={{color: "#41475E", opacity: "0.5", fontSize: "10px"}}>Dec 24</span>
                                </div>
                                <CircleRed1>
                                    {reactangle}
                                </CircleRed1>
                            </CardUser>

                            <img src={Img} style={{position: "absolute", right: "0px", marginTop: "40px",zIndex: -1}}/>
                            <CardClients>
                                <div style={{padding: "13px", fontSize: "12px"}}>Our Clients </div>
                                <div style={{padding: "0px 13px"}}>
                                    <BarClient/>
                                    <BarClientProgress/>
                                </div>
                                <div style={{padding: "13px", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                    <div>
                                        <img src={Client1}/>
                                        <img src={Client2} style={{marginLeft: "-3px"}}/>
                                        <img src={Client3} style={{marginLeft: "-3px"}}/>
                                    </div>
                                    <div>
                                        + 150 clients
                                    </div>
                                    <img src={PlusDotCircle}/>
                                </div>
                            </CardClients>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const pined = <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.83708 18.906C9.30636 18.9076 8.79712 18.6965 8.42308 18.32L0.586076 10.485C0.172548 10.0728 -0.0394513 9.50008 0.00607562 8.918L0.506076 2.352C0.577494 1.36368 1.36549 0.57867 2.35408 0.511003L8.92008 0.0110029C8.97208 1.42399e-06 9.02308 1.42613e-06 9.07508 1.42613e-06C9.60473 -0.000632853 10.1127 0.210331 10.4861 0.586003L18.3231 8.42C18.6983 8.7951 18.9091 9.30393 18.9091 9.8345C18.9091 10.3651 18.6983 10.8739 18.3231 11.249L11.2511 18.32C10.8769 18.6963 10.3678 18.9073 9.83708 18.906ZM5.59408 3.592C4.6889 3.59226 3.89674 4.20044 3.66267 5.07483C3.42861 5.94922 3.81099 6.87181 4.59499 7.32425C5.37898 7.77668 6.36911 7.64616 7.00908 7.006L7.01608 7L7.02308 6.993L7.01508 7C7.58351 6.42656 7.75138 5.56756 7.44064 4.82231C7.1299 4.07706 6.40151 3.59176 5.59408 3.592Z" fill="white"/>
            </svg>

const reactangle = <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.833496 0.833252H11.5002V8.83325H2.61127L0.833496 10.611V0.833252Z" fill="white"/>
                    </svg>
export default index