import React, { Component } from "react"
import Approval from "./approval"
import { Route } from 'react-router-dom'
import Logo from "../../images/Beyond Logo.png"
import Deny from './deny'

class index extends Component {
    render(){
        return(
            <div style={{display: "flex", 
                        alignItems:"center", 
                        flexDirection: "column", 
                        height: "100%", 
                        justifyContent: "center",
                        background: "#f3f3f3",
                        position: "fixed", 
                        width: "100%"}}>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <img src={Logo}/>
                </div>
                <Route exact path="/email/approval/:id" component={Approval}/>

                <Route exact path="/email/deny/:id" component={Deny}/>
            </div>
        )
    }
}

export default index