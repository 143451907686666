import axios from 'axios'
import React, { Component } from 'react'
import Web from './applicant-point-web'

class applicant_point extends Component {
    
    constructor(){
        super()
        this.state = {
            data: []
        }
    }

    componentDidMount(){
        this.fetchPoint()
    }

    fetchPoint = async () => {
        let response = await axios.get("/user/getApplicantUsers?size=1000")
        let data = response.data
        this.setState({
            data: data.data
        })
    }

    render(){
        return(
            <>
            <Web data={this.state.data}/>
            </>
        )
    }
}

export default applicant_point