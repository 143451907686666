import { Portal } from '@material-ui/core'
import axios from 'axios'
import React, { Component } from 'react'
import { getRoleId } from '../../function/read_token'
import Web from './leave_sick_web'
import Mobile from './leave_sick_mobile'
import { convertDate } from '../../function/function'

class leave_sick extends Component{
    
    constructor(){
        super()
        this.state = {
            dataLeave: [],
            dataSick: [],
            isLoadLeave: true,
            isLoadSick: true,
            windowWidth: window.innerWidth
        }
    }

    componentDidMount(){
        this.fetchLeave()
        this.fetchSick()
        window.addEventListener("resize", this.resize)
    }

    resize = () => {
        let ww = window.innerWidth
        this.setState({
            windowWidth: ww
        })
    }

    fetchLeave = async () => {
        let url = ""
        if(getRoleId() == "00000000-0000-0000-0000-000000000010") {
            url = "/superior/getApplicantLeave?size=1000"
        }else if(getRoleId() == "00000000-0000-0000-0000-000000000013"){
            url = "/applicant/getLeaveSchedules?size=1000"
        }
        
        let resp = await axios.get(url)
        let data = resp.data
        let list = data.data
        
        this.setState({
            dataLeave: list,
            isLoadLeave: false
        })
    }

    fetchSick = async () => {
        let url = ""
        if(getRoleId() == "00000000-0000-0000-0000-000000000010") {
            url = "/superior/getScheduleSick?size=1000"
        }else if(getRoleId() == "00000000-0000-0000-0000-000000000013"){
            url = "/applicant/getSickSchedules?size=1000"
        }

        let resp = await axios.get(url)
        let data = resp.data
        let list = data.data
        this.setState({
            dataSick: list,
            isLoadSick: false
        })
    }
    
    render(){
        return(
            <>
            {
                (this.state.windowWidth > 1202)
                ? <Web fetchLeave={this.fetchLeave}
                        dataLeave={this.state.dataLeave} 
                        isLoadLeave={this.state.isLoadLeave} 
                        isLoadSick={this.state.isLoadSick} 
                        dataSick={this.state.dataSick}/>
                : <Mobile fetchLeave={this.fetchLeave} 
                        dataLeave={this.state.dataLeave} 
                        isLoadLeave={this.state.isLoadLeave} 
                        isLoadSick={this.state.isLoadSick} 
                        dataSick={this.state.dataSick}/>
            }
            </>
        )
    }
}

export default leave_sick