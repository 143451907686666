import React, { Component } from 'react'
import Styled from 'styled-components'
import SrcIcon from '../../../images/search-interface-symbol.svg'
import IconHsp from '../../../images/hospital-buildings-64.png'
import axios from 'axios'
import AddIcon from '../../../images/addition.png'

const Base = Styled.div`
    position: fixed;
    background: rgba(0,0,0,0.4);
    height: 100%;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
`
const ContainerSelect = Styled.div`
    background: #FFF;
    width: 80%;
    max-width: 400px;
    height: auto;
    min-height: 200px;
    max-height: 400px;
    border-radius: 5px;
    overflow-y: scroll;
`
const ItemSrc = Styled.div`
  padding: 8px 22px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #e4e4e4;
`

class pop_select_rs_mobile extends Component{

    constructor(){
        super()
        this.state = {
            srcRs: [],
            popup: ""
        }
    }

    componentDidMount(){
        this.searchRs("")
    }

    searchRs = (val) => {
        let src = val
        let url = (src == "") ? "/hospital/getHospitals" : "/hospital/getHospitals?q="+src
        this.setState({isSearcingRs: true})
        axios.get(url).then(resp => {
            let result = resp.data.data
            this.setState({
                srcRs: result
            })
        })
    }

    selectRs = (data) => {
        this.props.select(data)
    }

    searchingRs = (e) => {
        let val = e.target.value
        this.searchRs(val)
    }

    render(){
        return(
            <Base onClick={this.props.hide}>
                {this.state.popup}
                <ContainerSelect onClick={(e) => {e.stopPropagation()}}>
                    <div id="hdr-ipt-hsptl" style={{display: "flex", alignItems: "center", padding: "10px", flexDirection: "column", background: "#CCC", justifyContent: "space-between"}}>
                        <div style={{fontSize: "12px", fontWeight: "bold", marginBottom: "10px"}}>Choose Hospital</div>
                        <div id="bs-ipt-src-mbl" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div id="bs-ipt-src-hsptl" 
                                style={{display: "flex", alignItems: "center", border: "1px solid #CCC", borderRadius: "4px", padding: "0px 8px", height: "25px", background: "#FFF"}}>
                                <img src={SrcIcon} style={{width: "12px"}}/>&nbsp;
                                <input type="text" onChange={this.searchingRs} placeholder="Search hospital" style={styles.inptSeactHsp}/>
                            </div>
                            <button id="btn-add-hsptl-mbl" 
                                onClick={this.props.addHospitals}
                                style={{marginLeft: "10px", background: "none", border: "none", height: "22px"}}>
                                <img src={AddIcon} style={{width: "22px", height: "22px"}}/>
                            </button>
                        </div>
                    </div>
                    <div>
                        {
                            this.state.srcRs.map(dt => {
                                return  <a onClick={() => this.selectRs(dt)}><ItemSrc className="select">
                                            <img src={IconHsp} style={{width: "18px", marginRight: "10px"}}/>
                                            <div style={{lineHeight: "1.2"}}>
                                                <div style={{fontSize: "10px", fontWeight: "bold", color: "#000"}}>{dt.name}</div>
                                                <div style={{fontSize: "8px", fontWeight: "bold", color: "#8b8b8b"}}>{dt.region.name}</div>
                                            </div>
                                        </ItemSrc></a>
                            })
                        }
                    </div>
                </ContainerSelect>
            </Base>
        )
    }
}

const styles = {
    inptSeactHsp : {
        padding: "5px", borderRadius: "3px", fontSize: "10px", width: "150px", border: "none", outline: "none"
    }
}

export default pop_select_rs_mobile