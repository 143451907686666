import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// import Logo from '../images'
import Logo from "../images/Beyond Logo.png";

export class NotFound extends Component {

    render() {
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '95vh'}}>
                <Link to='/'><img src={Logo} alt='logo' style={{ height: '60px' }} /></Link>
                <h3>404</h3>
                <p>Page Not Found</p>
            </div>
        )
    }
}

export default NotFound
