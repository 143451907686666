import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import SignAnim from '../sign-anim/SignAnim';
import Logo from '../../images/Beyond Logo.png';
import './Login.css';
import Gif from '../../images/Pulse-1s-51px.gif'

export default class Login_web extends Component {

    constructor(){
        super()
        this.state = {
            email: "",
        }
    }

    handleEmail = (e) => {
        let val = e.target.value
        this.setState({
            email: val
        })
    }

    forgot = () => {
        let elm = document.getElementById("alt-eml")
        if(this.state.email == 0){
            elm.style.display = "block"
        }else{
            elm.style.display = "none"
            this.props.forgot(this.state.email)
        }
    }

    render() {
        return (
            <div className='lgn lgn-root'>
                <div className='lgn lgn-section'>
                    <div style={{ margin: '1em' }}>
                        <Link to="/">
                            <img src={Logo} alt='logo' style={{ height: '40px' }} />
                        </Link>
                        {/* <img src={process.env.PUBLIC_URL + 'assets/images/logo.png'} alt='logo' style={{ height: '40px' }} /> */}
                    </div>
                    <div className='lgn form' style={{alignItems: "flex-start"}}>
                        <p className='title' style={{ marginBottom: '20px', fontSize: "38px" }}>
                            Forgot your password ?
                        </p>
                        <p style={{fontSize: "34px", lineHeight: "51px",color: "#763568", marginBottom: "20px"}}>Don’t worry</p>
                        <p style={{fontSize: "18px",lineHeight: "22px", textAlign: "justify"}}>
                            We are here to help you to recover your password. Enter the email address you used when you joined and we’ll send you instructions to reset your password.
                        </p>
                        <div className='input' style={{ marginBottom: '5px', width: '100%', marginTop: "40px"}}>
                            <input type='text' onChange={this.handleEmail} placeholder='Enter your E-mail' style={{ background: 'transparent', border: 'none', width: '100%', boxSizing: "border-box" }} />
                        </div>
                        <div id="alt-eml" style={{color: "#F00", fontSize: "11px", marginLeft: '10px', display: "none"}}>Email is empty</div>

                        <div style={{display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center", marginTop: "40px"}}>
                            <a style={{alignItems: "center", display: "flex"}}>
                                {chevron}&nbsp;&nbsp;&nbsp;<span>Back</span>
                            </a>
                            {
                                (!this.props.isProcess)
                                ?
                                    <button onClick={this.forgot} className="button">Send</button>
                                :
                                    <button className="button" style={{background: "#FFF", border: "1px solid #CCC", height: "39px"}}>
                                        <img src={Gif} style={{width: "20px"}}/>
                                    </button>
                            }
                        </div>
                    </div>
                    <div className='lgn lgn-footer'>
                        <p className='caption' style={{ color: '#949494' }}>2021@BeyondMedical</p>
                        <p className='caption'>Need Help?</p>
                    </div>
                </div>
                <div className='lgn lgn-section anim'>
                    <SignAnim />
                </div>
            </div>
        );
    }
}

const chevron = <svg width="11" height="21" viewBox="0 0 11 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.74873 18.3727C9.92354 18.5717 10.0122 18.832 9.99536 19.0963C9.97849 19.3607 9.85742 19.6075 9.65873 19.7827C9.45971 19.9575 9.19947 20.0462 8.93512 20.0294C8.67077 20.0125 8.42391 19.8914 8.24873 19.6927L0.248734 10.6927C0.0884152 10.5102 0 10.2756 0 10.0327C0 9.78981 0.0884152 9.55521 0.248734 9.37272L8.24873 0.372723C8.33341 0.267592 8.4385 0.180694 8.55766 0.117265C8.67682 0.0538363 8.80759 0.0151905 8.94209 0.0036568C9.07659 -0.00787695 9.21203 0.00794005 9.34025 0.0501546C9.46847 0.0923692 9.58682 0.160107 9.68816 0.249287C9.7895 0.338466 9.87173 0.44724 9.92991 0.569053C9.98808 0.690867 10.021 0.823197 10.0267 0.95807C10.0323 1.09294 10.0106 1.22757 9.96284 1.35383C9.91508 1.48008 9.84225 1.59537 9.74873 1.69272L2.33873 10.0327L9.74873 18.3727Z" fill="#333333"/>
                </svg>

