import React, { Component, Fragment } from 'react'
import Header from '../header_mobile_transparant'
import handsUp from './shape/ShapeHandsUp.svg'
import flag from './shape/ShapeFlag.svg'
import box from './shape/ShapeBox.svg'
import Chat from './shape/Chat.png'
import { Link } from 'react-router-dom'

export default class Register_mobile extends Component{
    constructor(){
        super()
        this.state = {
            usreName: "",
            fname: "",
            lname: "",
            email: "",
            regAs: "",
            region: "",
            password: ""
        }
    }

    componentDidMount() {
        document.title = 'Login';
    }

    doLogin(event) {
        event.preventDefault();
    }

    handleFname = (e) => {
        let v = e.target.value
        this.setState({
            fname: v
        })
    }

    handleLname = (e) => {
        let v = e.target.value
        this.setState({
            lname: v
        })
    }

    handleEmail = (e) => {
        let v = e.target.value
        this.setState({
            email: v
        })
    }

    handlePassword = (e) => {
        let v = e.target.value
        this.setState({
            password: v
        })
    }

    handleRegAS = (e) => {
        let v = e.target.value
        this.setState({
            regAs: v
        })
    }

    handleRegion = (e) => {
        let v = e.target.value
        this.setState({
            region: v
        })
    }

    handleUserName = (e) => {
        let v = e.target.value
        this.setState({
            usreName: v
        })
    }

    register = () => {
        this.props.register(
            this.state.fname,
            this.state.password,
            this.state.lname,
            this.state.email,
            this.state.regAs,
            this.state.usreName,
            this.state.region
        )
    }

    render(){
        return(
            <Fragment>
                <Header/>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "flex-end"}}>
                    {/* <div style={styles.circleImage}> */}
                        {halfPink}
                    {/* </div> */}
                    <div className="circle-image" style={{marginRight: "100px"}}>
                        <img src={handsUp}/>    
                    </div>
                </div>
                <div style={{marginTop: "20px"}}>
                    <div className="circle-image" style={{marginLeft: "80px"}}>
                        <img src={flag}/>
                    </div>
                </div>
                <div id="dot-dot" style={{overflow: "hidden"}}>
                    <div style={{float: "right", overflow: "hidden", width: "36px"}}>
                        <div style={styles.dot}/>
                        <div style={styles.dot}/>
                        <div style={styles.dot}/>
                        <div style={styles.dot}/>
                        
                        <div style={styles.dot}/>
                        <div style={styles.dot}/>
                        <div style={styles.dot}/>
                        <div style={styles.dot}/>

                        <div style={styles.dot}/>
                        <div style={styles.dot}/>
                        <div style={styles.dot}/>
                        <div style={styles.dot}/>

                        <div style={styles.dot}/>
                        <div style={styles.dot}/>
                        <div style={styles.dot}/>
                        <div style={styles.dot}/>

                        <div style={styles.dot}/>
                        <div style={styles.dot}/>
                        <div style={styles.dot}/>
                        <div style={styles.dot}/>

                        <div style={styles.dot}/>
                        <div style={styles.dot}/>
                        <div style={styles.dot}/>
                        <div style={styles.dot}/>
                    </div>
                </div>
                <div style={{marginTop: "47px"}}>
                    <div className="color1" style={{fontSize: "20px", fontWeight: "500", textAlign: "center"}}>
                        <div style={{fontSize: "20px",lineHeight: "30px",textAlign: "center",color: "#1F4788", fontWeight: "600"}}>
                            Register
                        </div>
                    </div>
                    <div style={{padding: "25px"}}>
                        {/* <input onChange={this.handleUserName} className="input-bs-border" type="text" placeholder="Username" style={styles.input}/> */}
                        <input onChange={this.handleFname} className="input-bs-border" type="text" placeholder="Firts name" style={styles.input}/>
                        <input onChange={this.handleLname} className="input-bs-border" type="text" placeholder="Last name" style={styles.input}/>
                        <input onChange={this.handleEmail} className="input-bs-border" type="text" placeholder="E-mail" style={styles.input}/>
                        <input onChange={this.handlePassword} className="input-bs-border" type="password" placeholder="Password" style={styles.input}/>
                        <select onChange={this.handleRegAS} style={styles.input} className="input-bs-border">
                            <option value="" style={{display: "none"}}>Register as</option>
                            {
                                this.props.roles.map((dt, key) => {
                                    return <option key={key} value={dt.id}>{dt.name}</option>
                                })
                            }
                        </select>
                        
                        {/*dropdown region for reg as applicant*/}
                        {
                            (this.state.regAs == "00000000-0000-0000-0000-000000000013")
                            ?
                                <div className='input'>
                                    <select onChange={this.handleRegion} className="input-bs-border" type='password' style={styles.input}>
                                        <option value="" style={{display: "none"}}>Region</option>
                                        {
                                            this.props.region.map(dt => {
                                                return <option value={dt.id}>{dt.name}</option>
                                            })
                                        }
                                        
                                    </select>
                                </div>
                            :
                                ""
                        }

                        <div style={{textAlign: "center"}}>
                        {
                            (
                                this.state.fname == 0 || this.state.usreName == 0 || 
                                this.state.password == 0 || this.state.lname == 0 || 
                                this.state.email == 0 || this.state.regAs == 0
                            )
                            ?
                                <button className='button-disable' style={{ marginBottom: '40px',  width: "100%" }}>Create Account</button>
                            :
                                (!this.props.inProcess)
                                ?
                                    <button className='button' 
                                        onClick={this.register}
                                        style={{ marginBottom: '40px',  width: "100%" }}
                                    >
                                        Create Account
                                    </button>
                                :
                                    <button className='button-process' style={{height: "35px", width: "100%"}}/>
                        }
                        </div>
                        {/* <div style={{fontSize: "12px", lineHeight: "15px", textAlign: "center", marginTop: "22px"}}>
                            Already have an account ? <Link to="/login" className="color1" style={{textDecoration: "underline"}}>Log in now</Link>
                        </div> */}
                    </div>
                </div>
                <div style={{marginTop: "90px"}}>
                    <div style={{overflow: "hidden"}}>
                        <div style={{float: "right", marginRight: "90px"}}>
                            {circleOutline}
                        </div>
                        
                    </div>
                    <div style={{overflow: "hidden"}}>
                        <div className="circle-image" style={{marginLeft: "68px"}}>
                            <img src={box}/>
                        </div>
                    </div>
                    <div style={{overflow: "hidden"}}>
                        <img src={Chat} style={{width: "40px", height: "40px", float: "right", marginRight: "130px"}}/>
                    </div>
                </div>
                <div style={{textAlign: "center", fontSize: "10px", lineHeight: "12px",color: "#949494", padding: "28px"}}>
                    2021@BeyondMedical
                </div>
            </Fragment>
        )
    }
}

const styles = {
    input: {
        background: "#F5F5FD",
        borderRadius: "4px",
        width: "100%",
        boxSizing: "border-box",
        border: "none", 
        outline: "none",
        padding: "8px",
        marginTop: "11px",
        marginBottom: "11px",
        fontSize: "12px"
    },
    btn: {
        color: "#FFF", 
        fontSize: "12px", 
        width: "94px", 
        height: "27px", 
        border: "none", 
        outline: "none", 
        borderRadius: "4px", 
        marginTop: "30px"
    },
    dot: {
        width: "4px",
        height: "4px",
        borderRadius: "100%",
        background: "rgba(189, 189, 202, 0.7)",
        float: "left",
        margin: "2px"
    }
}

const halfPink = <svg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 0C24 1.96983 23.612 3.92037 22.8582 5.74025C22.1044 7.56013 20.9995 9.21372 19.6066 10.6066C18.2137 11.9995 16.5601 13.1044 14.7403 13.8582C12.9204 14.612 10.9698 15 9 15C7.03017 15 5.07963 14.612 3.25975 13.8582C1.43986 13.1044 -0.213724 11.9995 -1.6066 10.6066C-2.99948 9.21372 -4.10437 7.56013 -4.85819 5.74025C-5.61201 3.92037 -6 1.96983 -6 -9.53674e-07L9 0H24Z" fill="#FD929A"/>
                 </svg>

const circle = <svg width="14" height="14" viewBox="-3 -3 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <circle cx="7.00033" cy="7.00002" r="6.33333" fill="#FAC861"/>
               </svg>

const circleOutline = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="9" cy="9" r="8.5" stroke="#FAC861" strokeOpacity="0.6"/>
                        {circle}
                      </svg>

