import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { convertDate, isUserSales } from '../../function/function'
import View from '../../../images/view-details.png'
import ApprIcon from '../../../images/quality.png'
import DenyIcon from '../../../images/multiply.png'
import DoneIcon from '../../../images/check-blue.png'
import SickIcon from '../../../images/medicine.png'
import reasonIcon from '../../../images/question.png'
import { baseUrl } from '../../../environment'

class row_director_web extends Component{
    initialName = (name) => {
        if(name != null && name !== undefined && name != ""){
            let l = name.split(" ")
            let init = ""
            
            for(let i = 0;i<l.length;i++){
                if(i<2){
                    init += l[i][0]
                }else{
                    break
                }
            }

            return init.toUpperCase()
        }else{
            return ""
        }
    }

    colorKindAct = (kind) => {
        if(kind == "demo"){
            return "#1778B0"
        }else if(kind == "training"){
            return "#34A770"
        }else{
            return "#000"
        }
    }

    getApplicant = (data) => {
        if(data != null){
            if(data.user != null){
                if(data.user.profile != null){
                    let fname = data.user.profile.firstName
                    let lname = data.user.profile.lastName
                    let fullName = fname+" "+lname
                    return fullName
                }else{
                    return ""
                }
            }else{
                return ""
            }
        }else{
            return ""
        }
    }

    getSalesName = () => {
        let data = this.props.data
        if(data.salesUser){
            let sales = data.salesUser
            let profile = sales.profile
            let fn = profile.firstName
            let ln = profile.lastName
            let full = fn+" "+ln
            return full
        }else{
            return ""
        }
    }

    previewImage = (data) => {
        this.props.previewImage(data)
    }

    render(){

        const data = this.props.data
        let reqNumber = data.reqNumber
        let formApplicant = data.formApplicant
        let formId = formApplicant.formId
        let nameHospital = formApplicant.hospital.name
        let contact = formApplicant.contactPerson

        /*set startDate*/
        let date = data.startDate.split(" ")[0]
        let time = data.startDate.split(" ")[1]
        let starTime = time.substring(0, 5)

        let dataDate = date.split("-")
        let day = dataDate[2]
        let mon = dataDate[1]
        let year = dataDate[0]
        let startDate = mon+"/"+day+"/"+year

        /*set endDate*/
        let finishDateData = data.finishDate.split(" ")[0]
        // let time = data.startDate.split(" ")[1]
        let dataDateFinish = finishDateData.split("-")
        let dayF = dataDateFinish[2]
        let monF = dataDateFinish[1]
        let yearF = dataDateFinish[0]
        let finishDate = monF+"/"+dayF+"/"+yearF
     
        let dataApplicant = data.userApplicant
        let profileAppicant = dataApplicant.userProfile
        let nameApplicant = profileAppicant.name
        let emailApplicant = profileAppicant.email

        let salesUser = formApplicant.salesUser
        let nameSales = salesUser.name

        let confirmStatus = data.flagStatus
        let idStatus = confirmStatus.id
        // console.log(idStatus)
        let completionFile = data.uploadCompletions
        let sizeCompletion = (completionFile) ? completionFile.length : 0

        let reasondeny = ""
        let deniedReasons = data.deniedReasons
        let countReason = deniedReasons.length
        if(countReason > 0)  reasondeny = data.deniedReasons[0].reason

        let lastDenied = data.lastDenied
        let confirmReason = (lastDenied) ? lastDenied.confirmReason : ""
        console.log(lastDenied)
        console.log(confirmReason)
        console.log("==================================")

        return(
            <tr>
                <td valign="center" style={{textAlign: "center"}}>
                    {reqNumber}
                </td>
                <td>
                    {
                        (nameApplicant == 0) 
                        ?
                            <div style={{textAlign: "center", color: "#CCC", fontSize: '12px'}}>Not Applicant Set</div> 
                        :
                            <>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <div className="pic-dt-td">
                                    {this.initialName(nameApplicant)}
                                </div>
                                <div>
                                    {nameApplicant}
                                    <div style={{fontSize: "11px", fontWeight: "bold"}}>{emailApplicant}</div>
                                </div>    
                               
                            </div>
                            </>
                    }
                </td>
                {/* <td style={{textAlign: "center"}}>
                    {
                        (applicant == "")
                        ? <div style={{textAlign: "center", color: "#CCC", fontSize: '12px'}}>Not Applicant Set</div> 
                        : email
                    }
                </td> */}
                <td valign="center" style={{textAlign: "center"}}>
                    {nameHospital}
                </td>
                {
                    (!isUserSales())
                    ?   <td valign="center" style={{textAlign: "center"}}>
                            {nameSales}<br/>
                        </td>
                    : ""
                }
                <td valign="center" style={{textAlign: "center"}}>{contact}</td>
                <td style={{textAlign: "center"}}>
                    {convertDate(startDate)}
                    {
                        (starTime != "00:00")
                        ? <><br/>{starTime}</>
                        : ""
                    }
                </td>
                <td style={{textAlign: "center"}}>
                    {convertDate(finishDate)}
                </td>
                <td id="bs-sts-rw">
                    {
                        (idStatus == "00000000-0000-0000-0000-100000000050" || idStatus == "00000000-0000-0000-0000-aa0000000020")
                        ? 
                            <div style={{color: "#126A65", fontSize: "12px", borderRadius: "5px", fontWeight: "bold", display: "flex", alignItems: "center"}}>
                                <img src={ApprIcon} style={{width: "20px"}}/>&nbsp;&nbsp;{confirmStatus.name}
                            </div>
                        :   ""
                    }
                    {
                        (idStatus == "00000000-0000-0000-0000-100000000060" || idStatus == "00000000-0000-0000-0000-aa0000000030")
                        ?  
                            <div style={{color: "#E21B1B", fontSize: "12px", borderRadius: "5px", fontWeight: "bold", display: "flex", alignItems: "center"}}>
                                <img src={DenyIcon} style={{width: "20px"}}/>&nbsp;&nbsp;{confirmStatus.name}
                            </div>
                        :   ""
                    }
                    {
                        (idStatus == "00000000-0000-0000-0000-100000000030")
                        ?   <div style={{color: "#F00", fontSize: "12px", borderRadius: "5px", fontWeight: "bold", display: "flex", alignItems: "center"}}>
                                <img src={SickIcon} style={{width: "20px"}}/>&nbsp;&nbsp;{confirmStatus.name}
                            </div>
                        :   ""
                    }
                    {
                        (idStatus == "00000000-0000-0000-0000-100000000070" || idStatus == "00000000-0000-0000-0000-aa0000000040")
                        ?   <div style={{color: "rgb(76 110 186)", fontSize: "12px", borderRadius: "5px", fontWeight: "bold", display: "flex", alignItems: "center"}}>
                                <img src={DoneIcon} style={{width: "20px"}}/>&nbsp;&nbsp;{confirmStatus.name}
                            </div>
                        :   ""
                    }
                    {
                        (idStatus == "00000000-0000-0000-0000-100000000090")
                        ?   <div style={{color: "#E21B1B", fontSize: "12px", borderRadius: "5px", fontWeight: "bold", display: "flex", alignItems: "center"}}>
                                <img src={DenyIcon} style={{width: "20px"}}/>&nbsp;&nbsp;{confirmStatus.name}
                            </div>
                        :   ""
                    }
                </td>
                <td style={{textAlign: "center"}}>
                    {
                        ((idStatus == "00000000-0000-0000-0000-100000000060" || idStatus == "00000000-0000-0000-0000-aa0000000030") && confirmReason != "")
                        ?
                            <div id="bs-rsn-rw" style={{maxWidth: "120px", 
                                    fontSize: "11px", 
                                    fontWeight: "bold",
                                    padding: "5px", 
                                    border: "1px solid #CCC", 
                                    textAlign: "left",
                                    position: "relative", 
                                    margin: "auto",
                                    wordBreak: "break-word", 
                                    borderRadius: "5px"}}>
                                <img src={reasonIcon} style={{width: "15px", position: "absolute", marginLeft: "-15px", marginTop: "-10px"}}/>
                                {(confirmReason != "") ? <span style={{color: "#000"}}>{confirmReason}</span> : "" /*<span style={{color: "#CCC"}}>No reason</span>*/}
                                {
                                    ((countReason - 1) > 0) 
                                    ? 
                                        <div id="ct-exp-rsn-rd" style={{width: "20px", height: "20px", borderRadius: "100%", border: "1px solid #CCC", 
                                                    background: "#f1f1f1", position: "absolute", right: "0px", 
                                                    marginTop: "-10px", marginRight: "-10px", display: "flex", 
                                                    alignItems: "center", justifyContent: "center"}}>
                                            {countReason - 1}+
                                        </div> 
                                    : ""
                                }
                            </div>
                        :
                            (sizeCompletion > 0)
                            ?   <a onClick={() => this.previewImage(completionFile)} style={{display: "flex", justifyContent: "center"}}>
                                    {/* <span style={{fontSize: "12px", color: "blue"}}>{completionFile[0].name}</span> */}
                                    <div style={{display: "flex"}}>
                                        <img style={{maxWidth: "40px", maxHeight: "25px", borderRadius: "5px"}} src={baseUrl+completionFile[0].url}/>
                                        {
                                            (sizeCompletion > 1)
                                            ?
                                                <div id="tip-sz-rpt-up" style={{border: "2px solid #CCC", 
                                                            background: "#FFF", 
                                                            fontSize: "11px",
                                                            marginLeft: "-5px",
                                                            marginTop: "-5px", 
                                                            height: "20px", 
                                                            width: "20px", 
                                                            display: "flex", 
                                                            alignItems: "center", 
                                                            borderRadius: "100%",
                                                            justifyContent: "center"}}
                                                >
                                                    {sizeCompletion - 1}+
                                                </div>
                                            :
                                                ""
                                        }
                                    </div>
                                </a>
                            :   ""
                    }
                </td>
                <td style={{textAlign: "center"}}>
                    <Link style={{textDecoration: "underline"}} to={"/dashboard/user_sales/"+formId}>
                        <img src={View} style={{width: "20px"}}/>
                    </Link>
                    {
                        // (isManagerApplicant())
                        // ? <>&nbsp;&nbsp;<a onClick={() => this.props.delete(formId)}><img src={Remove} style={{width: "20px"}}/></a></>
                        // : ""
                    }
                </td>
            </tr>
        )
    }
}

const radio = <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="13" cy="13" r="12.5" fill="white" fill-opacity="0.06" stroke="#08A0F7"/>
            </svg>

const chevron = <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.625 1.4375L7.5 7.5625L1.375 1.4375" stroke="#717171" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                
export default row_director_web