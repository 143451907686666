import React, { Component } from 'react'
import Header from '../../header/header'
import Styled from 'styled-components'
import SrcIcon from '../../../images/search-interface-symbol.svg'
import LoaderGif from '../../../images/Pulse-1s-51px.gif'
import Row from './row_web'
import Rejection from './rejection'
import Service from '../../../service/applicant_service'
import { connect } from 'react-redux'
import { filterStatusApplicant, seacrhApplicantByName, seacrhRsApplicantByName, setPageDataApplicant } from '../../../redux/action'
import Finish from './finish'
import ApprIcon from '../../../images/quality.png'
import DenyIcon from '../../../images/multiply.png'
import DoneIcon from '../../../images/check-blue.png'
import SickIcon from '../../../images/medicine.png'
import PreviewImage from '../../preview_image_appl_report'

const Base = Styled.div`
    position: relative;
`
const SrcBase = Styled.div`
    width: 223px;
    padding: 0px 10px;
    height: 37px;
    background: #FFF;
    border-radius: 8px;
    display: flex;
    align-items: center;
`

// const FilterBase = Styled.div`
//     width: 124px;
//     padding: 0px 10px;
//     height: 37px;
//     background: #FFF;
//     border-radius: 8px;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     margin-left: 25px;
// `

const FilterBase = Styled.div`
    width: 124px;
    position: relative;
    height: 37px;
    background: #FFF;
    border: 1px solid #e7e7e7;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 25px;
`

const Btn = Styled.button`
    width: 226px;
    height: 42px;
    background: #7784EE;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    color: #FFF;
    border: none;
    outline: none;
`
let size = 0

class applicant_web extends Component{

    constructor(){
        super()
        this.state = {
            popup: "",
            filterPop: false
        }
    }

    componentDidMount(){
        window.addEventListener("click", this.hideFilter)
    }

    componentWillUnmount(){
        window.removeEventListener("click", this.hideFilter)
    }

    filter = (e) => {
        e.stopPropagation()
        this.setState({
            filterPop: true
        })
    }

    hideFilter = (event) => {
        let elmFilter = document.getElementById("bs-fltr-chs-apl")
        let ref = elmFilter
        if(ref != null){
            if(ref && !ref.contains(event.target)){ 
                this.setState({
                    filterPop: false
                })   
            }
        }
    }

    selectFilter = (id, name) => {
        let obj = {
            id: id,
            name: name
        }
        this.props.filterStatus(obj)
        this.setState({
            filterPop: false
        })
    }

    setPagePosition = (type) => {
        let currPage = this.props.dataApplicant.page
        if(type == "next"){
            if(currPage < size){
                currPage += 1
            }
        }else{
            if(currPage > 1){
                currPage -= 1
            }
        }
        
        /*redux props action*/
        this.props.setPage(currPage)
    }

    firstPage = () => {
        this.props.setPage(1)
    }

    lastPage = () => {
        this.props.setPage(size)
    }

    reject = (id) => {
        this.setState({
            selectedId: id,
            popup: <Rejection 
                        cancel={() => this.setState({popup: ""})}
                        yes={this.yesConfirm}/>
        })
    }

    finish = (id) => {
        this.setState({
            selectedId: id,
            popup: <Finish
                        id={id}
                        refreshApplicant={this.refreshApplicant} 
                        cancel={() => this.setState({popup: ""})}/>
        })
    }

    yesConfirm = async (reason) => {
        let serv = new Service()
        await serv.doDeny(this.state.selectedId, reason)
        
        let resp = await serv.fetchDataApi()
        this.props.setDataApplicantRdx(resp)

        this.setState({
            popup: ""
        })
    }

    // yesConfirmFinish = async (form) => {
    //     let respFinish = await axios.post("/applicant/scheduleCompletion/"+this.state.selectedId, form)
    //     let data = respFinish.data
    //     if(data.success){
    //         let serv = new Service()
    //         let resp = await serv.fetchDataApi()
    //         this.props.setDataApplicantRdx(resp)
    //         this.setState({
    //             popup: ""
    //         })
    //     }
    // }

    refreshApplicant = async () => {
        let serv = new Service()
        let resp = await serv.fetchDataApi()
        this.props.setDataApplicantRdx(resp)
    }

    // previewImage = (url) => {
    //     this.setState({
    //         popup: <PreviewImage url={url} hideImage={() => this.setState({popup: ""})}/>
    //     })
    // }

    previewImage = (data) => {
        this.setState({
            popup: <PreviewImage data={data} hideImage={() => this.setState({popup: ""})}/>
        })
    }

    render(){
        let result = this.props.dataApplicant
        let searchParam = result.searchName
        let dataApplicant = result.data
        let seq = 0

        const data = dataApplicant.map((dt, index) => {
            let isView = false
            let st = (result.page > 1) ? (result.page * 10) - 9 : result.page
            let ed = result.page * 10
                
            /*filtering by search*/
            let confirmStatus = dt.confirmStatus

            /*leave status*/
            if(confirmStatus.id != "00000000-0000-0000-0000-100000000040"){
                if(dt.formApplicant){
                    // console.log(dt.formApplicant)
                    let rsName = (dt.formApplicant.hospital) ? dt.formApplicant.hospital.name : dt.formApplicant.hospitalName
                    let search = this.props.searchParam
                    let contactPerson = dt.formApplicant.contactPerson
        
                    if(
                        search == 0 || 
                        rsName.toLowerCase().match(searchParam.toLowerCase()) ||
                        contactPerson.toLowerCase().match(searchParam.toLowerCase())
                    ){
                        isView = true
                    }
                }
            }

            let filterParam = this.props.filterStatusParam
            let idStatusFilter = filterParam.id
            let idStatus = dt.confirmStatus.id
            if(idStatusFilter != "" && idStatusFilter != idStatus){
                isView = false
            }

            if(isView) seq++

            /*set pagination FE*/
            let idx = parseInt(index) + 1
            let len = result.data.length
            if(idx == len){
                size = Math.ceil(seq / 10)
                // console.log(seq+" / "+10)
            }  

            if(seq >= st && seq <= ed && isView){
                return <Row data={dt} reject={this.reject} previewImage={this.previewImage} finish={this.finish}/>
            }
        })

        return(
            <>
                {this.state.popup}
                <Header/>
                <div style={{padding: "40px 51px"}}>
                    <div style={{fontSize: "24px", 
                                color: "#1F4788",
                                display: "flex", 
                                justifyContent: "space-between", 
                                alignItems: 'center'}}>
                        <span>Dashboard Applicant</span>
                    </div>
                    <div style={{display: "flex", marginTop: "50px"}}>
                        <SrcBase>
                            <img src={SrcIcon} style={{width: "15px"}}/>
                            <div>
                                <input type="text"
                                    onChange={(e) => this.props.searchRsApplicant(e.target.value)} 
                                    value={this.props.searchParam}
                                    style={{width: "100%", 
                                            marginLeft: "10px", 
                                            border: "none", 
                                            outline: "none"}} 
                                    placeholder="Search by name"
                                />
                            </div>
                        </SrcBase>
                            <FilterBase>
                            {
                                    
                                (this.props.filterStatusParam.id == "")
                                ?   <FilterBase onClick={this.filter} style={{cursor: "pointer", border: "none", marginLeft: "0px", padding: "0px 10px"}}>
                                        <div  style={{color: '#717171', fontSize: "13px"}}>
                                            {
                                                (this.props.filterStatusParam.id == "")
                                                ? <span>Filter By</span>
                                                : <span style={{color: "#000", fontWeight: "bold"}}>{this.props.filterStatusParam.name}</span>
                                            }
                                        </div>
                                        {chevron}
                                    </FilterBase>
                                :   <FilterBase onClick={this.filter} style={{cursor: "pointer", border: "none", borderRadius: "0px 10px 10px 0px", marginLeft: "0px", padding: "0px 10px", borderLeft: "3px solid #577bac"}}>
                                        <div  style={{color: '#717171', fontSize: "13px"}}>
                                            {
                                                (this.props.filterStatusParam.id == "")
                                                ? <span>Filter By</span>
                                                : <span style={{color: "#000", fontWeight: "bold"}}>{this.props.filterStatusParam.name}</span>
                                            }
                                        </div>
                                        {chevron}
                                    </FilterBase>
                                
                            }
                            {
                                (this.state.filterPop)
                                ?
                                    <div id="bs-fltr-chs-apl" 
                                        style={{width: "100%", 
                                                background: "#FFF", 
                                                position: "absolute", 
                                                top: "38px",
                                                borderRadius: "5px",
                                                border: "1px solid #e7e7e7", 
                                                right: "0px"}}>
                                        
                                        <div onClick={() => this.selectFilter("")} 
                                            className="menu-hvr-item-item" 
                                            style={{padding: "5px 10px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                                        >
                                            <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px", color: "#868686"}}>All Data</div>
                                        </div>
                                        <div onClick={() => this.selectFilter("00000000-0000-0000-0000-100000000050", "Approved")} 
                                            className="menu-hvr-item-item" 
                                            style={{padding: "5px 10px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                                        >
                                            <img src={ApprIcon} style={{width: "14px"}}/>
                                            <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>Approved</div>
                                        </div>
                                        
                                        <div onClick={() => this.selectFilter("00000000-0000-0000-0000-100000000070", "Done")} 
                                            className="menu-hvr-item-item" 
                                            style={{padding: "5px 10px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                                        >
                                            <img src={DoneIcon} style={{width: "14px"}}/>
                                            <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>Done</div>
                                        </div>

                                        <div onClick={() => this.selectFilter("00000000-0000-0000-0000-100000000060", "Deny")} 
                                            className="menu-hvr-item-item" 
                                            style={{padding: "5px 10px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                                        >
                                            <img src={DenyIcon} style={{width: "14px"}}/>
                                            <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>Deny</div>
                                        </div>

                                        <div onClick={() => this.selectFilter("00000000-0000-0000-0000-100000000030", "Sick")} 
                                            className="menu-hvr-item-item" 
                                            style={{padding: "5px 10px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                                        >
                                            <img src={SickIcon} style={{width: "14px"}}/>
                                            <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>Sick</div>
                                        </div>
                                        {/* {
                                            status.map(dt => {
                                                return <div onClick={() => this.selectFilter(dt.id, dt.name)} 
                                                            className="menu-hvr-item-item" 
                                                            style={{padding: "5px 10px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                                                        >
                                                            <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px", color: "#868686"}}>{dt.name}</div>
                                                        </div>
                                            })
                                        }                 */}
                                    </div>
                                :
                                    ""
                            }
                        </FilterBase>
                    </div>
                    <div id="tbl-bs" style={{marginTop: "50px"}}>
                        <table style={{width: "100%"}}>
                            <thead>
                                <th>Sales Name</th>
                                <th>RS Name</th>
                                <th>Contact Person</th>
                                <th>Start Date</th>
                                <th>Status</th>
                                <th>Completion</th>
                                <th>Action</th>
                            </thead>
                            <tbody>
                                {
                                    (!this.props.isLoaded)
                                    ?
                                        <tr>
                                            <td colSpan="7" style={{textAlign: "center"}}>
                                                <img src={LoaderGif} style={{width: "35px", margin: "40px"}}/>
                                            </td>
                                        </tr>
                                    :
                                        (dataApplicant.length > 0)
                                        ?
                                            data
                                        :
                                            <tr>
                                                <td colSpan="7" style={{textAlign: "center"}}>
                                                    <div style={{padding: "50px", textAlign: "center", fontWeight: "bold", color: "#878787"}}>
                                                        You don't have<br/>any schedule for currently
                                                    </div>
                                                </td>
                                            </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    <div id="ft-tbl-dir" style={{marginTop: "50px",}}>
                            <span style={{fontSize: "14px",lineHeight: "28px", color: "#333333"}}>Showing {this.props.page} of {size} entries</span>
                            <div>
                                <button className="img-pgn" onClick={this.firstPage}>First</button>
                                <button className="img-pgn" onClick={() => this.setPagePosition("prev")}>Prev</button>
                                <button className="img-pgn">{this.props.page}</button>
                                <button className="img-pgn" onClick={() => this.setPagePosition("next")}>Next</button>
                                <button className="img-pgn" onClick={this.lastPage}>Last</button> 
                            </div>
                        </div>
                </div>
            </>
        )
    }
}

const chevron = <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.625 1.4375L7.5 7.5625L1.375 1.4375" stroke="#717171" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

const mapDispatch = dispatch => {
    return{
        searchRsApplicant: (data) => dispatch(seacrhRsApplicantByName(data)),
        filterStatus: (data) => dispatch(filterStatusApplicant(data))
    }
}

const mapStateToProps = state => {
    return{
        searchParam: state.dataApplicant.searchName,
        page: state.dataApplicant.page,
        filterStatusParam: state.dataApplicant.filterStatus
    }
}

export default connect(mapStateToProps, mapDispatch) (applicant_web)