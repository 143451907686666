import React, { Component } from 'react'
import Logo from  '../images/Beyond Logo.png'

class ErrorPage extends Component {
    render(){
        return(
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "100%", width: "100%", position: "fixed"}}>
                <img src={Logo}/>
                <div style={{fontSize: "100px", fontWeight: "bold"}}>{this.props.statusCode}</div>
                <div style={{opacity: "0.5", fontWeight: "bold", marginTop: "-20px"}}>Something went wrong</div>
            </div>
        )
    }
}

export default ErrorPage