import React, { Component } from 'react'
import Header from '../../header/header'
import CalendarIcon from '../../../images/calendar.png'
import ReasonIcon from '../../../images/question.png'
import UserIcon from '../../../images/user-3-color-64px.png'
import PointGold from '../../../images/points-64-gold.png'

class applicant_point_web extends Component{
    render(){
        return(
            <>
            <Header/>
            <div style={{padding: "40px 51px"}}>
                <div style={{fontSize: "24px", 
                            color: "#1F4788",
                            display: "flex", 
                            justifyContent: "space-between", 
                            alignItems: 'center'}}>
                    <span style={{fontWeight: "bold"}}>Applicant Point</span>
                </div>
            

            <table id="tbl-appl-pnt" style={{width: "80%", marginTop: "20px"}}>
                <thead style={{border: "none"}}>
                    <th style={{width: "250px", textAlign: "left", padding: "10px 5px", background: "none", fontWeight: "bold", border: "none", fontSize: "12px", borderBottom: "rgb(159 159 159 / 20%) 1px solid"}}>Aplicant</th>
                    <th style={{textAlign: "left", padding: "10px 0px", background: "none", border: "none", fontWeight: "bold", fontSize: "12px", borderBottom: "rgb(159 159 159 / 20%) 1px solid"}}>Region</th>
                    <th style={{width: "50px", textAlign: "left", padding: "10px 0px", background: "none", fontWeight: "bold", border: "none", fontSize: "12px", borderBottom: "rgb(159 159 159 / 20%) 1px solid"}}>Point</th>
                </thead>
                <tbody>
                    {
                        this.props.data.map((dt, index) => {
                            let profile = dt.userProfile
                            let region = dt.region
                            let background = (index%2==0) ? "none" : "#eff1f8"

                            return  <tr style={{background: background}}>
                                        <td style={{padding: "10px 5px", background: "none", border: "none"}}>
                                            <div style={{display: "flex", alignItems: "center"}}>
                                                <img src={UserIcon} style={{width: "18px"}}/>
                                                <div style={{marginLeft: "5px", fontSize: "14px"}}>{profile.name}</div>
                                            </div>
                                        </td>
                                        <td style={{padding: "10px 0px", background: "none", border: "none"}}>
                                            <div style={{display: "flex", alignItems: "center"}}>
                                                <img src={ReasonIcon} style={{width: "16px"}}/>
                                                <div style={{marginLeft: "5px", fontSize: "14px"}}>{region.name}</div>
                                            </div>
                                        </td>
                                        <td style={{padding: "10px 0px", background: "none", border: "none", width: "70px"}}>
                                            <div style={{display: "flex", alignItems: "center"}}>
                                                <img src={PointGold} style={{width: "16px"}}/>
                                                <div style={{marginLeft: "5px", fontSize: "14px", fontWeight: "bold"}}>{dt.point}</div>
                                            </div>
                                        </td>
                                    </tr>
                        })
                    }
                </tbody>
            </table>
            </div>
            </>
        )
    }
}

export default applicant_point_web