import { baseUrl } from '../../environment/index'
const token = JSON.parse(localStorage.getItem("token"))

export const getRoleName = () => {
    if(token == null){
        return ""
    }else{
        return token.userType.name
    }
}

export const getRoleId = () => {
    if(token == null){
        return ""
    }else{
        return token.userType.id
    }
}

export const getFullName = () => {
    if(token == null){
        return ""
    }else{
        return token.profile.name
    }
}

export const getAvatar = () => {
    if(token == null){
        return ""
    }else{
        let v = token.profile.uriAvatar
        return baseUrl+v
    }
}

export const getUserName = () => {
    if(token == null){
        return ""
    }else{
        let v = token.username
        return v
    }
}