import React, { Component } from 'react';
import ForgotMobile from './Forgot_password_mobile'
import ForgotWeb from './Forget_password_web'
import Swal from 'sweetalert2'
import axios from 'axios';

export default class Forget_password extends Component {

    constructor(){
        super()
        this.state = {
            windowWidth: window.innerWidth,
            isProcess: false
        }
    }

    componentDidMount(){
        document.title = 'Forgot Password';
        this.setState({windowWidth: window.innerWidth})
        window.addEventListener("resize", () => {
            this.setState({windowWidth: window.innerWidth})
        })
    }

    forgot = (email) => {
        this.setState({ isProcess: true })
        let param = {"email": email}
        axios.post("/auth/forgot-password", param).then(response => {
            let resp = response.data
            let msg = resp.data
            this.setState({ isProcess: false })
            if(resp.success){
                Swal.fire(
                    "Success",
                    msg,
                    "success"
                )
            }
        }).catch(error => {
            let resp = error.response.data
            let msg = resp.message
            this.setState({ isProcess: false })
            Swal.fire(
                "Failed",
                msg,
                "error"
            )
        })
    }

    render() {
        return (
            (this.state.checking)
            ?
                <div>please wait..</div>
            :
                (!this.state.isLogedIn)
                ?
                    (this.state.windowWidth > 1202)
                    ?
                        <ForgotWeb isProcess={this.state.isProcess}
                                forgot={this.forgot}/>
                    :
                        <ForgotMobile isProcess={this.state.isProcess}
                                    forgot={this.forgot}/>
                :
                    <div>please wait..</div>
        );
    }
}
