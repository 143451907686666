import React, { Component } from 'react'
import UserIcon from '../../../images/user-3-color-64px.png'
import { convertDate } from '../../function/function'
import Styled from 'styled-components'
import Chevron from '../../../images/arrow-down.png'
import MoreMenu from './more_menu'
import Deny_leave_confirm from './deny_leave_confirm'
import axios from 'axios'
import ApproveIcon from '../../../images/check-blue.png'
import RejectIcon from '../../../images/remove.png'
import LoaderGif from '../../../images/Pulse-1s-51px.gif'

const Card = Styled.div`
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #e4e4e4;
    margin-bottom: 10px;
    box-shadow: 1px 1px 1px #ccc;
`
const PageButton = Styled.button`
    padding: 4px;
    border: 1px solid #dedede;
    background: rgb(239, 241, 248);
`

class leave_mobile extends Component{

    constructor(){
        super()
        this.state = {
            page: 1,
            size: 1,
            denyConfirm: ""
        }
    }

    componentDidMount(){
        this.setSize()
    }

    componentDidUpdate(prevProps){
        if(prevProps != this.props){
            this.setSize()
        }
    }

    setSize = () => {
        let data = this.props.dataLeave
        let size = data.length / 10
        this.setState({
            size: Math.ceil(size)
        })
    }

    reject = (id) => {
        this.setState({
            denyConfirm: <Deny_leave_confirm id={id} submit={this.submitReject} cancel={() => this.setState({denyConfirm: ""})}/>
        })
    }

    submitReject = async (id, reason) => {
        let param = {reason: reason}
        let url = "/superior/denyApplicantLeave/"+id
        let resp = await axios.post(url, param)
        let data = resp.data
        if(data.success){
            this.props.fetchLeave()
            this.setState({
                denyConfirm: ""
            })
        }
    }

    next = () => {
        let currPage = this.state.page
        if(currPage < this.state.size)
        {
            currPage++;
            this.setState({
                page: currPage
            })
        }
    }

    prev = () => {
        let currPage = this.state.page
        if(currPage > 1){
            currPage--;
            this.setState({
                page: currPage
            })
        }
    }

    last = () => {
        this.setState({
            page: this.state.size
        })
    }

    first = () => {
        this.setState({
            page: 1
        })
    }

    render(){
        return(
            <>
            <div id="base-lv-mbl" style={{padding: "20px"}}>
                {this.state.denyConfirm}
                {
                    this.props.dataLeave.map((dt, index) => {
                        let userApplicant = dt.userApplicant
                        let name = userApplicant.userProfile.name

                        let seq = parseInt(index) + 1
                        // let userApplicant = dt.userApplicant
                        // let profile = userApplicant.userProfile
                        // let date =  convertDate(dt.bookingDate)
                        // let background = (index%2==0) ? "none" : "#eff1f8"

                        let start = 10 * this.state.page - 10 + parseInt(1)
                        let finish = 10 * this.state.page
            
                        if(seq >= start && seq <= finish){

                            const flag = dt.flagStatus
                            let isApprove = false
                            let flagId = ""

                            if(flag){
                                flagId = flag.id
                                if(flagId != "00000000-0000-0000-0000-aa0000000010") isApprove = true
                            }

                            return <Card>
                                    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <img src={UserIcon} style={{width: "20px"}}/>
                                            <div style={{marginLeft: "10px"}}>
                                                <div style={{fontWeight: "bold", fontSize: "12px"}}>{name}</div>
                                                <div style={{fontSize: "10px", color: "#9a9a9a", fontWeight: "bold"}}>
                                                    {convertDate(dt.bookingDate)}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <a className="menu-hvr">
                                            <img src={Chevron} style={{width: "16px"}}/>
                                            <MoreMenu reject={this.reject} 
                                                    scheduleId={dt.scheduleId} 
                                                    refresh={this.props.fetchLeave} style={{marginTop: "-5px"}}/>
                                        </a> */}
                                        {
                                            (!isApprove)
                                            ?   <a className="menu-hvr">
                                                    <img src={Chevron} style={{width: "18px"}}/>
                                                    <MoreMenu reject={this.reject} 
                                                            scheduleId={dt.scheduleId} 
                                                            refresh={this.props.fetchLeave} style={{marginTop: "-5px"}}/>
                                                </a>
                                            :   (flagId == "00000000-0000-0000-0000-aa0000000020")
                                                ? <img src={ApproveIcon} style={{width: "14px"}}/>
                                                : <img src={RejectIcon} style={{width: "14px"}}/>
                                        }
                                    </div>
                                    <div style={{marginTop: "5px", fontSize: "14px", paddingLeft: "30px"}}>
                                        {
                                            (dt.reasonLeave != "")
                                            ? dt.reasonLeave
                                            : <span style={{color: "#9a9a9a"}}>No Description</span>
                                        }
                                    </div>
                                </Card>
                        }
                    })
                }
            </div>
            {
                (this.props.dataLeave.length > 10)
                ?
                    <div id="ft-l-s-tbl" style={{width: "100%"}}>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "30px"}}>
                            <div style={{fontSize: "11px"}}>Showing {this.state.page} of {this.state.size} pages</div>
                            <div style={{marginTop: "10px"}}>
                                <PageButton onClick={this.first}>First</PageButton>
                                <PageButton onClick={this.prev}>Prev</PageButton>
                                <PageButton style={{minWidth: "40px"}}>{this.state.page}</PageButton>
                                <PageButton onClick={this.next}>Next</PageButton>
                                <PageButton onClick={this.last}>Last</PageButton>
                            </div>
                        </div>
                    </div>
               :   ""
           }
           {
                (this.props.isLoadLeave)
                ?   <div style={{textAlign: "center"}}>
                        <img src={LoaderGif} style={{width: "30px"}}/>
                        <div style={{fontSize: "12px", color: "#CCC"}}>Loading..</div>
                    </div>
                :   (this.props.dataLeave.length == 0)
                    ?
                        <div style={{textAlign: "center", color: "#CCC", fontSize: "14px", fontWeight: "bold"}}>
                            No data leave<br/>to display
                        </div>
                    :   ""
           }
           </>
        )
    }
}

export default leave_mobile