import React, { Component } from 'react'
import Styled from 'styled-components'
import { baseUrl } from '../environment'
import LoaderGif from '../images/Spinner-0.7s-200px.gif'
import LeftChevron from '../images/chevron-left.png'
import RightChevron from '../images/chevron-right.png'

const Base = Styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10000;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(0,0,0,0.5);
    left: 0;
`

let itv = null
class preview_image_appl_report extends Component{

    constructor(){
        super()
        this.state = {
            selectedImage: "",
            pos: 0,
            imgH: "",
            windowWidth: window.innerWidth
        }
    }

    componentDidMount(){
        let wh = window.innerHeight - 50
        this.setState({
            imgH: wh+"px",
            windowWidth: window.innerWidth
        })
        this.setSelectedImage(this.state.pos)
        this.loadImage()
    }

    componentWillUnmount(){
        // console.log(this.props.data)
        clearInterval(itv)
    }

    setSelectedImage = (n) => {
        let elm = document.getElementById("pv-img-fl")
        elm.setAttribute("src", "")
        this.loadImage()

        let data = this.props.data[n]
        this.setState({
            selectedImage: data
        })
    }

    loadImage = () => {
        let itv2 = setInterval(() => {
            let elm = document.getElementById("pv-img-fl")
            if(elm){
                let w = elm.offsetHeight
                if(w > 0){
                    elm.style.opacity = 1
                    clearInterval(itv2)
                }
            }
        }, 1)
    }

    imgClick = (e) => {
        e.stopPropagation()
    }

    right = (e) => {
        e.stopPropagation()
        let l = this.props.data.length - 1
        let p = this.state.pos
        
        if(l == 0) return false

        if(p < l){
            p = parseInt(this.state.pos) + 1
        }else{
            p = 0
        }

        this.setState({
            pos: p
        })

        this.setSelectedImage(p)
    }

    left = (e) => {
        e.stopPropagation()
        let l = this.props.data.length - 1
        let p = this.state.pos
        
        if(l == 0) return false
        
        if(p == 0){
            p = l
        }else{
            p = this.state.pos - 1
        }

        this.setState({
            pos: p
        })

        this.setSelectedImage(p)
    }

    selectImage = (e, index) => {
        e.stopPropagation()
        this.setState({
            pos: index
        })
        this.setSelectedImage(index)
    }

    render(){
        return(
            <Base onClick={this.props.hideImage}>
                {
                    (this.state.windowWidth > 1202)
                    ? <a onClick={this.left}><img src={LeftChevron} style={{width: "50px", marginLeft: "10px"}}/></a>
                    : <a onClick={this.left}><img src={LeftChevron} style={{width: "15px", marginLeft: "5px"}}/></a>
                }
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <img id="ldr-img-zxr" src={LoaderGif} style={{width: "40px", position: "absolute"}}/>
                    <img id="pv-img-fl" onClick={this.imgClick} 
                        src={baseUrl+this.state.selectedImage.url} 
                        style={{maxWidth: "90%", maxHeight: this.state.imgH, borderRadius: "4px", opacity: "0", zIndex: 1}}
                    />
                    
                    <div style={{display: "flex", 
                                justifyContent: "center", 
                                alignItems: "center", 
                                background: "rgba(0,0,0,0.7)", 
                                borderRadius: "5px",
                                marginTop: (this.state.windowWidth > 1202) ? "5px" : "10px"}}
                    >
                        {
                            (this.state.windowWidth > 1202)
                            ?
                                this.props.data.map((dt, index) => {
                                    let border = (this.state.pos == index) ? "3px solid #FFF" : "1px solid #999" 
                                    return <a onClick={(e) => this.selectImage(e, index)}><div style={{maxWidth: "80px", maxHeight: "80px", margin: "5px", border: border, borderRadius: "5px", overflow: "hidden"}}>
                                                <img id="bs-img-pvw"
                                                    src={baseUrl+dt.url} 
                                                    style={{maxWidth: "80px", maxHeight: "80px", verticalAlign: "middle"}}
                                                />
                                            </div></a>
                                })
                            :
                                this.props.data.map((dt, index) => {
                                    let border = (this.state.pos == index) ? "3px solid #FFF" : "1px solid #999" 
                                    return <a onClick={(e) => this.selectImage(e, index)}><div style={{maxWidth: "30px", maxHeight: "30px", margin: "5px", border: border, borderRadius: "5px", overflow: "hidden"}}>
                                                <img id="bs-img-pvw"
                                                    src={baseUrl+dt.url} 
                                                    style={{maxWidth: "30px", maxHeight: "30px", verticalAlign: "middle"}}
                                                />
                                            </div></a>
                                })
                        }
                    </div>
                </div>
                {
                    (this.state.windowWidth > 1202)
                    ?   <a onClick={this.right}><img src={RightChevron} style={{width: "50px", marginRight: "10px"}}/></a>
                    :   <a onClick={this.right}><img src={RightChevron} style={{width: "15px", marginRight: "5px"}}/></a>
                }
            </Base>
        )
    }
}

export default preview_image_appl_report