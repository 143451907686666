import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import SignAnim from '../sign-anim/SignAnim';
import Logo from '../../images/Beyond Logo.png';
import './Login.css';

export default class Login_web extends Component {

    constructor(){
        super()
        this.state = {
            username: "",
            password: ""
        }

        this.handlePassword = this.handlePassword.bind(this)
        this.handleUsername = this.handleUsername.bind(this)
        this.login = this.login.bind(this)
    }

    componentDidMount() {
        document.title = 'Login';
    }

    doLogin(event) {
        event.preventDefault();
    }

    handleUsername(e){
        let val = e.target.value
        this.setState({
            username: val
        })
    }

    handlePassword(e){
        let val = e.target.value
        this.setState({
            password: val
        })
    }

    login(){
        this.props.login(this.state.username, this.state.password)
    }

    enter = (e) => {
        let key = e.keyCode
        if(key == 13){
            if(this.state.username != "" && this.state.password != ""){
                this.login()
            }
        }
    }

    render() {
        return (
            <div className='lgn lgn-root'>
                <div className='lgn lgn-section'>
                    <div style={{ margin: '1em' }}>
                        <Link to="/">
                            <img src={Logo} alt='logo' style={{ height: '40px' }} />
                        </Link>
                        {/* <img src={process.env.PUBLIC_URL + 'assets/images/logo.png'} alt='logo' style={{ height: '40px' }} /> */}
                    </div>
                    <div className='lgn form'>
                        <p className='title' style={{ marginBottom: '50px' }}>Login</p>
                        <div className='input' style={{ marginBottom: '20px', width: '100%' }}>
                            <input type='text' 
                                onChange={this.handleUsername} 
                                onKeyUp={this.enter}
                                placeholder='Enter your E-mail' 
                                style={{ background: 'transparent', border: 'none', width: '100%' }} />
                        </div>
                        <div className='input' style={{ marginBottom: '20px', width: '100%' }}>
                            <input type='password' 
                                onChange={this.handlePassword} 
                                onKeyUp={this.enter}
                                placeholder='Enter your password' 
                                style={{ background: 'transparent', border: 'none', width: '100%' }} />
                        </div>
                        
                        <div style={{textAlign: "right", width: "100%", marginBottom: "40px"}}>
                            <Link to="/forgot_password">Forgot your password</Link>
                        </div>

                        {
                            (this.state.username == 0 || this.state.password == 0)
                            ?
                                <button className='button-disable' style={{ marginBottom: '40px' }}>Login</button>
                            :
                                (!this.props.isLoadLogin)
                                ?
                                    <button className='button' style={{ marginBottom: '40px' }} onClick={this.login}>Login</button>
                                :
                                    <button className='button-process' style={{ marginBottom: '40px', height: "35px", width: "95px" }}/>
                        }   

                        {/* <div className='lgn lgn-footer' style={{ marginBottom: '40px' }}>
                            <div>Don’t have an account? <Link to='/register'><span style={{color: '#1F4788'}}><u>Register now!</u></span></Link></div>
                        </div> */}
                    </div>
                    <div className='lgn lgn-footer'>
                        <p className='caption' style={{ color: '#949494' }}>2021@BeyondMedical</p>
                        <p className='caption'>Need Help?</p>
                    </div>
                </div>
                <div className='lgn lgn-section anim'>
                    <SignAnim />
                </div>
            </div>
        );
    }
}
