import React, { Component } from 'react'
import DenyIcon from '../../images/remove.png'
import EmailService from '../../service/email_service'
import Loader from '../../images/Pulse-1s-51px.gif'
import { data } from 'jquery'

class deny extends Component {
    
    constructor(){
        super()
        this.state = {
            message: "",
            success: true,
            data : [],
            isProcess: true
        }
    }

    componentDidMount(){
        let confirmId = this.props.match.params.id
        this.fetch(confirmId);
    }

    fetch = async (id) => {
        let serv = new EmailService()
        let resp = await serv.denySchedules(id)

        if(resp.status == 400){
            let msg = resp.data.message
            console.log(resp)
            this.setState({
                message: msg,
                success: false,
                isProcess: false
            })
        }else{
            let msg = resp.data.message
            let data = resp.data.data
            console.log(resp)
            this.setState({
                message: msg,
                success: true,
                data : data,
                isProcess: false
            })
        }
    }

    render(){
        return(
                (this.state.isProcess)
                ? 
                    <div style={{textAlign: "center", marginTop: "30px"}}>
                        <img src={Loader} style={{width: "40px"}}/>
                        <div style={{fontSize: "12px", color: "#767676", fontWeight: "bold"}}>Loading..</div>
                    </div>
                : 
                    (this.state.success)
                    ?   
                        <div style={{marginTop: "50px", display: "flex", alignItems: "center", flexDirection: "column"}}>
                            <img src={DenyIcon} style={{width: "80px"}}/>
                            <div style={{fontWeight: "bold", fontSize: "20px", textAlign: "center", marginTop: "10px"}}>
                                <div style={{color: "#126A65"}}>Success</div>
                                <div style={{fontSize: "16px", color: "#000"}}>Deny Schedules is successfully</div>
                                <div style={{padding: "10px", 
                                            width: "80%", 
                                            maxWidth: "300px", 
                                            fontSize: "12px", 
                                            fontWeight: "bold",
                                            background: "#FFF",
                                            borderRadius: "5px",
                                            margin: "auto",
                                            marginTop: "10px",
                                            color: "#767676", 
                                            border: "1px solid #CCC"}}>
                                    {this.state.data}
                                </div>
                            </div>
                            <a href='/dashboard'>
                                <button className='btn-primary' style={{padding: "10px", borderRadius: "5px", marginTop: "40px"}}>View Dashboard</button>
                            </a>
                        </div>
                    :
                        <div style={{marginTop: "50px", display: "flex", alignItems: "center", flexDirection: "column"}}>
                            <img src={DenyIcon} style={{width: "80px"}}/>
                            <div style={{fontWeight: "bold", fontSize: "20px", textAlign: "center", marginTop: "10px"}}>
                                <div style={{color: "#F00"}}>Failed</div>
                                <div style={{fontSize: "16px", color: "#000"}}>{this.state.message}</div>
                                <div style={{fontSize: "12px", color: "#767676"}}>This schedule already approved or denied</div>
                            </div>
                            <a href='/dashboard'>
                                <button className='btn-primary' style={{padding: "10px", borderRadius: "5px", marginTop: "40px"}}>View Dashboard</button>
                            </a>
                        </div>
        )
    }
}

export default deny