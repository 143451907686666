import axios from 'axios'
import React, { Component } from 'react'
import Styled from 'styled-components'
import ApproveIcon from '../../../images/check-blue.png'
import RejectIcon from '../../../images/remove.png'

const Base = Styled.div`
    width: 100px;
    background: none;
    border-radius: 3px;
    position: absolute;
`
const MenuItem = Styled.div`
    padding: 5px 10px;
    display: flex;
    background: #FFF;
    align-items: center;
    border-bottom: 1px solid #ddedf9;
`
class more_menu extends Component{
    approve = async (id) => {
        let resp = await axios.post("/superior/approveApplicantLeave/"+id)
        this.props.refresh()
    }

    reject = (id) => {
        this.props.reject(id)
    }

    render(){
        let windowWidth = window.innerWidth
        return(
            <>
                {
                    (windowWidth > 1202)
                    ?
                        <Base id="bs-mr-mn" className="menu-hvr-item" style={{marginTop: "-20px"}}>
                            <div style={{boxShadow: "1px 1px 1px #CCC", border: "1px solid #f1f1f1", marginTop: "15px"}}>
                                <a onClick={() => this.approve(this.props.scheduleId)}>
                                    <MenuItem className="menu-hvr-item-item">
                                        <img src={ApproveIcon} style={{width: "14px"}}/>
                                        <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px", color: "rgb(31,71,136)"}}>Aprove</div>
                                    </MenuItem>
                                </a>
                                <a onClick={() => this.reject(this.props.scheduleId)}>
                                    <MenuItem className="menu-hvr-item-item">
                                        <img src={RejectIcon} style={{width: "14px"}}/>
                                        <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px", color: "red"}}>Reject</div>
                                    </MenuItem>
                                </a>
                            </div>
                        </Base>
                    :
                        <Base id="bs-mr-mn" className="menu-hvr-item" style={{right: "18px", border: "1px solid #f1f1f1", boxShadow: "1px 1px 1px #CCC"}}>
                            <a onClick={() => this.approve(this.props.scheduleId)}>
                                <MenuItem className="menu-hvr-item-item">
                                    <img src={ApproveIcon} style={{width: "14px"}}/>
                                    <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px", color: "rgb(31,71,136)"}}>Aprove</div>
                                </MenuItem>
                            </a>
                            <a onClick={() => this.reject(this.props.scheduleId)}>
                                <MenuItem className="menu-hvr-item-item">
                                    <img src={RejectIcon} style={{width: "14px"}}/>
                                    <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px", color: "red"}}>Reject</div>
                                </MenuItem>
                            </a>
                        </Base>
                }
            </>
        )
    }
}

export default more_menu