import React, { Component, Fragment } from 'react'
import Techniciant from './techniciant/techniciant'
import SalesUser from './user_sales/user_sales'
import Director from './director/director'
import Manager from './manager/manager'
import Sidebar from '../menu/sidebar'
import Applicant from './applicant/applicant'
import Admin from './admin/admin'
import { getRoleId, getRoleName } from '../function/read_token'
import {Route, Redirect} from 'react-router-dom'
import Calendar from '../calendar'
import { connect } from 'react-redux'
import Header from "../header/header";
import HeaderMobile from '../header/header_mobile'
import NotAvailable from '../not_available'
import LeaveSick from './leave_sick/leave_sick'
import ApplicantPoint from './applicant-point/applicant-point'
import Hospitals from './hospitals/hospitals'

class dashboard extends Component{

    constructor(){
        super()
        this.state = {
            windowWidth: window.innerWidth,
            role: "",
        }
    }

    componentDidMount(){
        window.addEventListener("resize", this.resize)
        let role = getRoleName().toLowerCase()
        this.setState({role: role})
    }

    resize = () => {
        let ww = window.innerWidth
        this.setState({
            windowWidth: ww
        })
    }

    redirect = () => {
        let roleId = getRoleId()
        if(roleId == "00000000-0000-0000-0000-000000000011"){
            return <Redirect to="/dashboard/techniciant"/>
        }else if(roleId == "00000000-0000-0000-0000-000000000012"){
            return <Redirect to="/dashboard/techniciant"/>
        }else if(roleId == "00000000-0000-0000-0000-000000000010"){
            return <Redirect to="/dashboard/user_sales"/>
        }else if(roleId == "00000000-0000-0000-0000-000000000014"){
            return <Redirect to="/dashboard/user_sales"/>
        }else if(roleId == "00000000-0000-0000-0000-000000000013"){
            return <Redirect to="/dashboard/applicant"/>
        }
    }

    render(){
        return(
            <Fragment>
                {
                    (this.state.windowWidth > 1202)
                    ? <Sidebar/>
                    : ""
                }
                <div id="bs-content">
                    {   
                        (this.props.isAvailable)
                        ?
                            <>
                            <Route exact path="/dashboard/">
                            {
                                getRoleName() != "admin"
                                ? this.redirect()
                                : <Admin/>
                            }
                            </Route>
                            <Route exact path="/dashboard/techniciant">
                            {
                                (getRoleId() != "00000000-0000-0000-0000-000000000011" && getRoleId() != "00000000-0000-0000-0000-000000000012")
                                ? this.redirect()
                                : <Manager/>
                            }     
                            </Route>
                            <Route exact path="/dashboard/techniciant/:id" component={Techniciant}/>
                            <Route exact path="/dashboard/applicant">
                            {
                                (getRoleId() != "00000000-0000-0000-0000-000000000013")
                                ? this.redirect()
                                : <Applicant/>
                            } 
                            </Route>
                            <Route exact path="/dashboard/applicant-point">
                                <ApplicantPoint/>
                            </Route>
                            <Route exact path="/dashboard/user_sales/:id" component={SalesUser}/>
                            <Route exact path="/dashboard/leave-sick" component={LeaveSick}/>
                            <Route exact path="/dashboard/hospitals" component={Hospitals}/>
                            <Route exact path="/dashboard/user_sales">
                            {
                                (getRoleId() != "00000000-0000-0000-0000-000000000014" && getRoleId() != "00000000-0000-0000-0000-000000000010")
                                ? this.redirect()
                                : <Director/>
                            }   
                            </Route>
                            </>
                        :
                            <>
                                {
                                    (this.state.windowWidth > 1201)
                                    ? <Header/>
                                    : <HeaderMobile title="Applicant"/>
                                }
                                <NotAvailable/>
                            </>   
                    }
                </div>
                {
                    /*show calender schedules*/
                    (this.state.role == "director" || this.state.role == "user sales")
                    ? <Calendar/>
                    : ""
                }
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return{
        isAvailable: state.isAvailable
    }
}

export default connect(mapStateToProps) (dashboard)