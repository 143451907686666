import React, { Component } from 'react'
import Styled, { ThemeConsumer } from 'styled-components'

const Base = Styled.div`
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
`

const MainBase = Styled.div`
    width: 70%;
    max-width: 300px;
    background: #FFF;
    border-radius: 4px;
`

class Confirmation extends Component {
    
    constructor(){
        super()
        this.state = {
            windowWidth: window.innerWidth,
            isProcess: false
        }
    }

    yesDelete = () => {
        this.setState({
            isProcess: true,
        })

        this.props.yes()
    }

    render(){
        return(
            <Base id="dialog-conf">
                <MainBase>
                    <div style={{padding: "10px", borderBottom: "1px solid #d4dbe0"}}>
                        <span style={{fontSize: "14px", fontWeight: "bold"}}>{this.props.title}</span>
                    </div>
                    <div style={{padding: "10px", lineHeight: "1.2", width: "220px"}}>
                        <span style={{fontSize: "14px"}}>{this.props.description}</span>
                    </div>
                    <div style={{display: "flex", justifyContent: "end", alignItems: "center", padding: "10px"}}>
                        {/* {
                            (!this.state.isProcess)
                            ?
                                <button onClick={this.yesDelete} 
                                    className="btn-primary" 
                                    style={{padding: "5px", fontSize: "10px", width: "50px"}}
                                >
                                    Yes
                                </button>
                            :
                                <button onClick={this.props.yes} 
                                    className="btn-primary" 
                                    style={{padding: "5px", fontSize: "10px", width: "60px", opacity: "0.5"}}
                                >
                                    Deleting...
                                </button>
                        }
                        &nbsp;
                        <button onClick={this.props.cancel} 
                            className="btn-gray" 
                            style={{padding: "5px", fontSize: "10px", width: "50px"}}
                        >
                            Cancel
                        </button> */}
                        

                        <div style={{padding: "10px", paddingBottom: "0px", fontWeight: "bold", display: "flex", justifyContent: "flex-end"}}>
                            {
                                (!this.state.isProcess)
                                ?
                                    <>
                                    <button onClick={this.props.cancel}
                                        className="btn-sv" 
                                        id="cl-ad-pp"
                                        style={{background: "#e67272", height: "auto", padding: "5px", margin: "5px", borderRadius: "4px", fontSize: "12px", width: "50px"}}>No
                                    </button>
                                    <button onClick={this.yesDelete}
                                        className="btn-sv" 
                                        id="sv-ad-pp"
                                        style={{background: "#7784EE", height: "auto", padding: "5px", margin: "5px", borderRadius: "4px", fontSize: "12px", width: "50px"}}>Yes
                                    </button>
                                    </>
                                :
                                    <>
                                    <button
                                        className="btn-sv" 
                                        id="cl-ad-pp"
                                        style={{background: "#e67272", height: "auto", padding: "5px", margin: "5px", borderRadius: "4px", fontSize: "12px", width: "50px", opacity: "0.5"}}>No
                                    </button>
                                    <button
                                        className="btn-sv" 
                                        id="sv-ad-pp"
                                        style={{background: "#7784EE", height: "auto", padding: "5px", margin: "5px", borderRadius: "4px", fontSize: "12px", width: "70px",opacity: "0.5"}}>Deleting..
                                    </button>
                                    </>
                            }
                        </div>
                    </div>
                </MainBase>
            </Base>
        )
    }
}

export default Confirmation