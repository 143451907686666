import axios from 'axios'
import React, { Component } from 'react'
import Header from '../../header/header_mobile'
import Loader from "../../loader"
import Item from './item_mobile'
import Rejection from './rejection'
import Service from '../../../service/applicant_service'
import Finish from './finish'
import { connect } from 'react-redux'
import FilterDsb from '../../../images/filter-dsb.png'
import FilterAtv from '../../../images/filter-atv.png'
import FilterMobile from './filter_mobile'
import PreviewImage from '../../preview_image_appl_report'

let size = 0

class applicant_mobile extends Component{

    constructor(){
        super()
        this.state = {
            popup: "",
            selectedId: ""
        }
    }

    setPagePosition = (type) => {
        let currPage = this.props.dataApplicant.page
        if(type == "next"){
            if(currPage < size){
                currPage += 1
            }
        }else{
            if(currPage > 1){
                currPage -= 1
            }
        }

        /*redux props action*/
        this.props.setPage(currPage)
    }

    firstPage = () => {
        this.props.setPage(1)
    }

    lastPage = () => {
        this.props.setPage(size)
    }

    reject = (id) => {
        this.setState({
            selectedId: id,
            popup: <Rejection 
                        cancel={() => this.setState({popup: ""})}
                        yes={this.yesConfirm}/>
        })
    }

    yesConfirm = async (reason) => {
        let serv = new Service()
        await serv.doDeny(this.state.selectedId, reason)
        
        let resp = await serv.fetchDataApi()
        this.props.setDataApplicantRdx(resp)

        this.setState({
            popup: ""
        })
    }

    finish = (id) => {
        this.setState({
            selectedId: id,
            popup: <Finish 
                        id={id}
                        refreshApplicant={this.refreshApplicant} 
                        cancel={() => this.setState({popup: ""})}/>
        })
    }

    refreshApplicant = async () => {
        let serv = new Service()
        let resp = await serv.fetchDataApi()
        this.props.setDataApplicantRdx(resp)
    }

    // yesConfirmFinish = async (form) => {
    //     let respFinish = await axios.post("/applicant/scheduleCompletionf/"+this.state.selectedId, form)
    //     let data = respFinish.data
    //     if(data.success){
    //         let serv = new Service()
    //         let resp = await serv.fetchDataApi()
    //         this.props.setDataApplicantRdx(resp)
    //         this.setState({
    //             popup: ""
    //         })
    //     }
    // }

    filter = () => {
        this.setState({
            popup: <FilterMobile hideFilter={() => this.setState({popup: ""})}/>
        })
    }

    previewImage = (data) => {
        this.setState({
            popup: <PreviewImage data={data} hideImage={() => this.setState({popup: ""})}/>
        })
    }

    render(){
        
        let result = this.props.dataApplicant
        let isLoaded = result.isLoaded
        let page = result.page

        let searchParam = result.searchName
        let dataApplicant = result.data
        let seq = 0
        
        let filterParam = this.props.dataApplicant.filterStatus
        let idFilterParam = filterParam.id
        let filterName = filterParam.name

        const data = dataApplicant.map((dt, index) => {
            let isView = false
            let st = (result.page > 1) ? (result.page * 10) - 9 : result.page
            let ed = result.page * 10
                
            /*filtering by search*/
            let confirmStatus = dt.confirmStatus

            /*leave status*/
            if(confirmStatus.id != "00000000-0000-0000-0000-100000000040"){
                if(dt.formApplicant){
                    // let rsName = dt.formApplicant.hospital.name
                    let rsName = (dt.formApplicant.hospital) ? dt.formApplicant.hospital.name : dt.formApplicant.hospitalName
                    let search = this.props.searchParam
                    let contactPerson = dt.formApplicant.contactPerson
        
                    if(
                        search == 0 || 
                        rsName.toLowerCase().match(searchParam.toLowerCase()) ||
                        contactPerson.toLowerCase().match(searchParam.toLowerCase())
                    ){
                        isView = true
                    }
                }
            }

            let filterParam = this.props.filterStatusParam
            let idStatusFilter = filterParam.id
            let idStatus = dt.confirmStatus.id
            if(idStatusFilter != "" && idStatusFilter != idStatus){
                isView = false
            }

            if(isView) seq++

            /*set size pagination FE*/
            let idx = parseInt(index) + 1
            let len = result.data.length
            if(idx == len){
                size = Math.ceil(seq / 10)
            } 

            if(seq >= st && seq <= ed && isView){
                return <Item data={dt} reject={this.reject} previewImage={this.previewImage} finish={this.finish}/>
            } 
        })

        return(
            <>
                {this.state.popup}
                <Header title="Applicant" enableSearch={true}/>
                <div id="bs-flt-hd" 
                    style={{width: "100%", 
                            top: "64px", 
                            position: "fixed", 
                            background: "#FFF",
                            borderTop: "1px solid #CCC",
                            boxShadow: "rgb(237 237 237) 2px 2px 2px", 
                            height: "55px", 
                            zIndex: "1"}}>
                    <div style={{padding: "16px", 
                                fontSize: "14px", 
                                paddingBottom: "5px", 
                                display: "flex",
                                // marginTop: "70px",
                                justifyContent: "space-between", 
                                alignItems: "center"}}>
                        Showing {this.props.page} of {size} entries
                        <div style={{display: "flex", alignItems: "center"}}>
                            <a onClick={this.filter}>
                                {
                                    (idFilterParam == "")
                                    ?
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <img src={FilterDsb} style={{width: "14px", opacity: "0.5"}}/>
                                            &nbsp;
                                            <div style={{fontSize: "12px", fontWeight: "bold", color: "#878787"}}>Filter</div>
                                        </div>
                                    :
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <img src={FilterAtv} style={{width: "14px"}}/>
                                            &nbsp;
                                            <div style={{fontSize: "12px", fontWeight: "bold", color: "#000"}}>
                                                {filterName}
                                            </div>
                                        </div>
                                }
                                
                            </a>
                        </div>
                        
                    </div>
                </div>
                {
                    (!isLoaded)
                    ?
                        <Loader/>
                    : 
                        (dataApplicant.length <= 0)
                        ?
                            <div style={{padding: "50px 20px", textAlign: "center", fontSize: "14px", fontWeight: "bold", color: "#878787", marginTop: "50px"}}>
                                You don't have<br/>any schedule for currently
                            </div>
                        :
                            <>
                            <div id="bs-content-mobile">
                                <div id="bs-ctn-lst-apl" style={{marginTop: "115px"}}>
                                    {data}
                                </div>
                                <div style={{textAlign: "center", padding: "30px 0px"}}>
                                    <button className="img-pgn" onClick={this.firstPage}>First</button>
                                    <button className="img-pgn" onClick={() => this.setPagePosition("prev")}>Prev</button>
                                    <button className="img-pgn">{page}</button>
                                    <button className="img-pgn" onClick={() => this.setPagePosition("next")}>Next</button>
                                    <button className="img-pgn" onClick={this.lastPage}>Last</button> 
                                </div>
                            </div>
                            </>
                }
            </>
        )
    }
}

const mapStateToProps = state => {
    return{
        searchParam: state.dataApplicant.searchName,
        page: state.dataApplicant.page,
        filterStatusParam: state.dataApplicant.filterStatus
    }
}

export default connect(mapStateToProps) (applicant_mobile)