import React, { Component, Fragment } from 'react'
import Header from '../header_mobile_transparant'
import handsUp from './shape/ShapeHandsUp.svg'
import flag from './shape/ShapeFlag.svg'
import box from './shape/ShapeBox.svg'
import Chat from './shape/Chat.png'
import { Link } from 'react-router-dom'
import './Login.css'

export default class login_mobile extends Component{
    constructor(){
        super()
        this.state = {
            username: "",
            password: ""
        }

        this.handlePassword = this.handlePassword.bind(this)
        this.handleUsername = this.handleUsername.bind(this)
        this.login = this.login.bind(this)
    }

    componentDidMount() {
        document.title = 'Login';
    }

    doLogin(event) {
        event.preventDefault();
    }

    handleUsername(e){
        let val = e.target.value
        this.setState({
            username: val
        })
    }

    handlePassword(e){
        let val = e.target.value
        this.setState({
            password: val
        })
    }

    login(){
        this.props.login(this.state.username, this.state.password)
    }

    render(){
        return(
            <Fragment>
                <Header/>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "flex-end"}}>
                    {/* <div style={styles.circleImage}> */}
                        {halfPink}
                    {/* </div> */}
                    <div className="circle-image" style={{marginRight: "100px"}}>
                        <img src={handsUp}/>    
                    </div>
                </div>
                <div style={{marginTop: "20px"}}>
                    <div className="circle-image" style={{marginLeft: "80px"}}>
                        <img src={flag}/>
                    </div>
                </div>
                <div id="dot-dot" style={{overflow: "hidden"}}>
                    <div style={{float: "right", overflow: "hidden", width: "36px"}}>
                        <div style={styles.dot}/>
                        <div style={styles.dot}/>
                        <div style={styles.dot}/>
                        <div style={styles.dot}/>
                        
                        <div style={styles.dot}/>
                        <div style={styles.dot}/>
                        <div style={styles.dot}/>
                        <div style={styles.dot}/>

                        <div style={styles.dot}/>
                        <div style={styles.dot}/>
                        <div style={styles.dot}/>
                        <div style={styles.dot}/>

                        <div style={styles.dot}/>
                        <div style={styles.dot}/>
                        <div style={styles.dot}/>
                        <div style={styles.dot}/>

                        <div style={styles.dot}/>
                        <div style={styles.dot}/>
                        <div style={styles.dot}/>
                        <div style={styles.dot}/>

                        <div style={styles.dot}/>
                        <div style={styles.dot}/>
                        <div style={styles.dot}/>
                        <div style={styles.dot}/>
                    </div>
                </div>
                <div style={{marginTop: "47px"}}>
                    <div className="color1" style={{fontSize: "20px", fontWeight: "500", textAlign: "center"}}>
                        <div style={{fontSize: "20px",lineHeight: "30px",textAlign: "center",color: "#1F4788", fontWeight: "600"}}>
                            Login
                        </div>
                    </div>
                    <div style={{padding: "25px"}}>
                        <input onChange={this.handleUsername} className="input-bs-border" type="text" placeholder="Enter your E-mail" style={styles.input}/>
                        <input onChange={this.handlePassword} className="input-bs-border" type="password" placeholder="Enter your Password" style={styles.input}/>
                        <div style={{textAlign: "right", padding: "15px 0px", paddingBottom: "30px"}}>
                            <Link to="/forgot_password" style={{fontSize: "12px",lineHeight: "15px",color: "#1F4788"}}>Forgot password</Link>
                        </div>
                        <div style={{textAlign: "center"}}>
                        {
                            (this.state.username == 0 || this.state.password == 0)
                            ?
                                <button className='button-disable' style={{ marginBottom: '40px',  width: "100%" }}>Login</button>
                            :
                                (!this.props.isLoadLogin)
                                ?
                                    <button onClick={this.login}
                                            style={{ marginBottom: '40px', 
                                                    background: "#1F4788",
                                                    color: "#ffffff",
                                                    width: "100%",
                                                    borderStyle: "none",
                                                    borderRadius: "8px",
                                                    padding: "8px 30px" }}
                                    >Login</button>
                                :
                                    <button onClick={this.login}
                                        className="button-process"    
                                        style={{ marginBottom: '40px',
                                                width: "100%",
                                                height: "35px",
                                                borderRadius: "8px"}}
                                    />
                        }

                        </div>
                        {/* <div style={{fontSize: "12px", lineHeight: "15px", textAlign: "center", marginTop: "22px"}}>
                            Don't have an account ? <Link to="/register" className="color1" style={{textDecoration: "underline"}}>Register now</Link>
                        </div> */}
                    </div>
                </div>
                <div style={{marginTop: "90px"}}>
                    <div style={{overflow: "hidden"}}>
                        <div style={{float: "right", marginRight: "90px"}}>
                            {circleOutline}
                        </div>
                        
                    </div>
                    <div style={{overflow: "hidden"}}>
                        <div className="circle-image" style={{marginLeft: "68px"}}>
                            <img src={box}/>
                        </div>
                    </div>
                    <div style={{overflow: "hidden"}}>
                        <img src={Chat} style={{width: "40px", height: "40px", float: "right", marginRight: "130px"}}/>
                    </div>
                </div>
                <div style={{textAlign: "center", fontSize: "10px", lineHeight: "12px",color: "#949494", padding: "28px"}}>
                    2021@BeyondMedical
                </div>
            </Fragment>
        )
    }
}

const styles = {
    input: {
        background: "#F5F5FD",
        borderRadius: "4px",
        width: "100%",
        boxSizing: "border-box",
        border: "none", 
        outline: "none",
        padding: "8px",
        marginTop: "11px",
        marginBottom: "11px",
        fontSize: "12px"
    },
    btn: {
        color: "#FFF", 
        fontSize: "12px", 
        width: "94px", 
        height: "27px", 
        border: "none", 
        outline: "none", 
        borderRadius: "4px", 
        marginTop: "30px"
    },
    dot: {
        width: "4px",
        height: "4px",
        borderRadius: "100%",
        background: "rgba(189, 189, 202, 0.7)",
        float: "left",
        margin: "2px"
    }
}

const halfPink = <svg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 0C24 1.96983 23.612 3.92037 22.8582 5.74025C22.1044 7.56013 20.9995 9.21372 19.6066 10.6066C18.2137 11.9995 16.5601 13.1044 14.7403 13.8582C12.9204 14.612 10.9698 15 9 15C7.03017 15 5.07963 14.612 3.25975 13.8582C1.43986 13.1044 -0.213724 11.9995 -1.6066 10.6066C-2.99948 9.21372 -4.10437 7.56013 -4.85819 5.74025C-5.61201 3.92037 -6 1.96983 -6 -9.53674e-07L9 0H24Z" fill="#FD929A"/>
                 </svg>

const circle = <svg width="14" height="14" viewBox="-3 -3 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <circle cx="7.00033" cy="7.00002" r="6.33333" fill="#FAC861"/>
               </svg>

const circleOutline = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="9" cy="9" r="8.5" stroke="#FAC861" strokeOpacity="0.6"/>
                        {circle}
                      </svg>

