import React, { Component, createRef } from 'react'
import { Link } from 'react-router-dom'
import Styled from 'styled-components'
import PicProfile from '../../images/Rectangle 2 Copy 9.png'
import { getRoleName, getFullName, getAvatar, getRoleId } from '../function/read_token'
import Logout from '../../images/power.svg'
import { connect } from 'react-redux'
import { setAvailable } from '../../redux/action'
import Availability from '../availability'

const Menu = Styled.div`
    position: fixed;
    background: #FFF;
    width: 293px;
    margin-left: -293px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const Block = Styled.div`
    background: rgba(0,0,0, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0px;
`

const Line = Styled.div`
    background: #CAD2E8;
    height: 1px
`

const MenuItem = Styled.div`
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    padding: 8px;
    color: #949494;
    display: flex;
    align-items: center;
`

const BarAvailable = Styled.div`
    background: #CCC;
    width: 30px;
    height: 15px;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 2px;
`

const CircleAvailable = Styled.div`
    width: 13px;
    height: 13px;
    background: #000;
    border-radius: 100%;
`

class menu extends Component{
    constructor(){
        super()
        this.state = {
            popup: ""
        }

        this.menu = createRef()
    }
    componentDidMount(){
        this.animateMenu()
    }

    animateMenu = () => {
        let elm = this.menu.current
        let seq = 0
        let itv = setInterval(() => {
            seq = parseInt(seq) + 10
            let ml = parseInt(-293) + seq
            // console.log(ml)
            elm.style.marginLeft = ml+"px"
            if(seq >= 293){
                clearInterval(itv)
                elm.style.marginLeft = "0px"
            }
        }, 1)
    }
    
    dashboarLink = () => {
        return "/dashboard"
    }

    logout = () => {
        window.localStorage.removeItem("token")
        window.location.reload()
    }

    setNotAvailability = () => {
        this.setState({
            popup: <Availability setAvailable={false} cancel={() => this.setState({popup: ""})}/>
        })
    }

    setAvailable = async () => {
        this.setState({
            popup: <Availability setAvailable={true} cancel={() => this.setState({popup: ""})}/>
        })
    }

    render(){
        return(
            <>
                {this.state.popup}
                <Block id="mbl-mn-cxl" onClick={this.props.hideMenu}>
                    <Menu id="mbl-mn-cxl-main" onClick={(e) => {e.stopPropagation()}} ref={this.menu}>
                        <div>
                            <div style={{padding: "16px"}}>
                                <img src={getAvatar()} style={{width: "40px", height: "40px", borderRadius: "100%", objectFit: "contain", border: "1px solid #CCC"}}/>
                                <div id="nm-lgn-usr">
                                    <span style={{fontSize: "16px", color: "#1F4788"}}>
                                        {getFullName()}
                                    </span>
                                </div>

                                {
                                    (getRoleName().toLowerCase() == "applicant")
                                    ?
                                        <div id="bs-slider-btn-avb" 
                                            style={{marginTop: "10px", display: "flex", alignItems: "center"}}>
                                            {
                                                (!this.props.isAvailable)
                                                ?   <a onClick={this.setAvailable}><BarAvailable>
                                                        <CircleAvailable/>
                                                    </BarAvailable></a>
                                                :   <a onClick={this.setNotAvailability}><BarAvailable style={{background: "blue", justifyContent: "end"}}>
                                                        <CircleAvailable style={{background: "white"}}/>
                                                    </BarAvailable></a>
                                            }
                                            &nbsp;&nbsp;
                                            <span style={{fontSize: "12px", fontWeight: "bold", color: "#CCC"}}>Available</span>
                                        </div>
                                    :
                                        ""
                                }
                                
                                
                                <Line style={{marginTop: "11px"}}/>
                            </div>
                            <div style={{padding: "0px 16px"}}>
                                <Link to={this.dashboarLink()}>
                                    <MenuItem>
                                        {home}&nbsp;&nbsp;&nbsp;Dashboard
                                    </MenuItem>
                                </Link>
                                {/* <MenuItem>
                                    {paper}&nbsp;&nbsp;&nbsp;Download File
                                </MenuItem> */}
                                {
                                    // (getRoleId() == "00000000-0000-0000-0000-000000000013" || getRoleId() == "00000000-0000-0000-0000-000000000010")
                                    (getRoleId() == "00000000-0000-0000-0000-000000000010")
                                    ?
                                        <Link to={"/dashboard/leave-sick"}>
                                            <MenuItem className="sb-menu">
                                                {home}&nbsp;&nbsp;&nbsp;Leave & Sick
                                            </MenuItem>
                                        </Link>
                                    :   ""
                                }
                                <Link to="/profile">
                                    <MenuItem>
                                        {profile}&nbsp;&nbsp;&nbsp;Profile
                                    </MenuItem>
                                </Link>
                            </div>
                        </div>
                        <div style={{padding: "16px"}}>
                            <MenuItem onClick={this.logout}>
                                <img src={Logout} style={{width: "18px"}}/>
                                &nbsp;&nbsp;&nbsp;Log out
                            </MenuItem>
                        </div>
                    </Menu>
                </Block>
            </>
        )
    }
}

const home = <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.86791 15.0786V12.7785C5.8679 12.1935 6.34484 11.7181 6.93576 11.7142H9.10032C9.69406 11.7142 10.1754 12.1907 10.1754 12.7785V12.7785V15.0857C10.1752 15.5824 10.5757 15.9884 11.0773 16H12.5203C13.9588 16 15.125 14.8455 15.125 13.4214V13.4214V6.87838C15.1173 6.31812 14.8516 5.79201 14.4035 5.44977L9.46829 1.51398C8.6037 0.828675 7.37465 0.828675 6.51006 1.51398L1.59652 5.45692C1.14669 5.79777 0.88054 6.32475 0.875 6.88552V13.4214C0.875 14.8455 2.04116 16 3.47968 16H4.92272C5.43677 16 5.85348 15.5875 5.85348 15.0786V15.0786" stroke="#1F4788" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

const profile = <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.9887 11.0096C4.08798 11.0096 1.61084 11.4482 1.61084 13.2046C1.61084 14.9611 4.07227 15.4154 6.9887 15.4154C9.88941 15.4154 12.3658 14.9761 12.3658 13.2204C12.3658 11.4646 9.90513 11.0096 6.9887 11.0096Z" stroke="#949494" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.98867 8.50441C8.89224 8.50441 10.4351 6.96084 10.4351 5.05727C10.4351 3.1537 8.89224 1.61084 6.98867 1.61084C5.0851 1.61084 3.54152 3.1537 3.54152 5.05727C3.5351 6.95441 5.06795 8.49798 6.96438 8.50441H6.98867Z" stroke="#949494" stroke-width="1.42857" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

const paper = <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.05344 1.57137H4.06369C2.50369 1.56537 1.22494 2.80887 1.18819 4.36812V12.4029C1.15369 13.9876 2.40994 15.3009 3.99469 15.3361C4.01794 15.3361 4.04044 15.3369 4.06369 15.3361H10.0554C11.6259 15.2724 12.8634 13.9749 12.8522 12.4029V5.52837L9.05344 1.57137Z" stroke="#949494" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.85645 1.5625V3.74425C8.85645 4.80925 9.71745 5.6725 10.7824 5.6755H12.8487" stroke="#949494" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.71626 11.0189H4.66626" stroke="#949494" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.18253 8.20453H4.66553" stroke="#949494" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

const icon1 = <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.15722 19.7714V16.7047C8.1572 15.9246 8.79312 15.2908 9.58101 15.2856H12.4671C13.2587 15.2856 13.9005 15.9209 13.9005 16.7047V16.7047V19.7809C13.9003 20.4432 14.4343 20.9845 15.103 21H17.0271C18.9451 21 20.5 19.4607 20.5 17.5618V17.5618V8.83784C20.4898 8.09083 20.1355 7.38935 19.538 6.93303L12.9577 1.6853C11.8049 0.771566 10.1662 0.771566 9.01342 1.6853L2.46203 6.94256C1.86226 7.39702 1.50739 8.09967 1.5 8.84736V17.5618C1.5 19.4607 3.05488 21 4.97291 21H6.89696C7.58235 21 8.13797 20.4499 8.13797 19.7714V19.7714" stroke="#1F4788" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

const mapStateToProps = state => {
    return{
        isAvailable: state.isAvailable
    }
}

const mapDispatchToProps = dispatch => {
    return{
        setAvailable: () => dispatch(setAvailable())
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (menu)