import React from 'react'
import Styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { setSortReport } from '../../../redux/action'
// import AzSort from '../../../images/sort-az.png'
// import ZaSort from '../../../images/sort-za.png'
import AzSort from '../../../images/fast-forward-asc.png'
import ZaSort from '../../../images/fast-forward-desc.png'

const Base = Styled.div`
    background: rgba(0,0,0, 0.4);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
`

const SortMobile = (props) => {

    let dispatch = useDispatch();

    const selectSort = (e, sort, type) => {
        dispatch(setSortReport(sort, type))
        props.fetchData(sort, type)
        props.hideSorting()
    }
    
    const hidePopup = (e) => {
        let elm = document.getElementById("flt-mbl-apl-srt")
        if(elm && !elm.contains(e.target)){
            props.hideSorting()
        }
    }

    return(
        <Base onClick={hidePopup}>
            <div id="flt-mbl-apl-srt" style={{background: "#FFF", width: "200px"}}>
                {/* <div style={{display: "flex", 
                            alignItems: "center", 
                            padding: "10px 20px", 
                            borderBottom: "1px solid #e5e5e5", 
                            background: "#f5f5f5"}}
                >
                    <img src={FilterAtv} style={{width: "14px"}}/>
                    &nbsp;
                    <div style={{fontWeight: "bold", fontSize: "14px"}}>Filter Status</div>
                </div> */}
                <div onClick={(e) => selectSort(e, "customerName", "asc")} 
                    className="menu-hvr-item-item" 
                    style={{padding: "10px 20px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                >
                    <img src={AzSort} style={{width: "14px"}}/>
                    <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>Customer Name</div>
                </div>
                <div onClick={(e) => selectSort(e, "customerName", "desc")} 
                    className="menu-hvr-item-item" 
                    style={{padding: "10px 20px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                >
                    <img src={ZaSort} style={{width: "14px"}}/>
                    <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>Customer Name</div>
                </div>

                <div onClick={(e) => selectSort(e, "user.profile.name", "asc")} 
                    className="menu-hvr-item-item" 
                    style={{padding: "10px 20px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                >
                    <img src={AzSort} style={{width: "14px"}}/>
                    <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>Techniciant</div>
                </div>
                <div onClick={(e) => selectSort(e, "user.profile.name", "desc")} 
                    className="menu-hvr-item-item" 
                    style={{padding: "10px 20px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                >
                    <img src={ZaSort} style={{width: "14px"}}/>
                    <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>Techniciant</div>
                </div>
                
                <div onClick={(e) => selectSort(e, "startDate", "asc")} 
                    className="menu-hvr-item-item" 
                    style={{padding: "10px 20px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                >
                    <img src={AzSort} style={{width: "14px"}}/>
                    <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>Start Date</div>
                </div>
                <div onClick={(e) => selectSort(e, "startDate", "desc")} 
                    className="menu-hvr-item-item" 
                    style={{padding: "10px 20px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                >
                    <img src={ZaSort} style={{width: "14px"}}/>
                    <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>Start Date</div>
                </div>

                <div onClick={(e) => selectSort(e, "endDate", "asc")} 
                    className="menu-hvr-item-item" 
                    style={{padding: "10px 20px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                >
                    <img src={AzSort} style={{width: "14px"}}/>
                    <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>End Date</div>
                </div>
                <div onClick={(e) => selectSort(e, "endDate", "desc")} 
                    className="menu-hvr-item-item" 
                    style={{padding: "10px 20px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                >
                    <img src={ZaSort} style={{width: "14px"}}/>
                    <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>End Date</div>
                </div>

                <div onClick={(e) => selectSort(e, "createDate", "asc")} 
                    className="menu-hvr-item-item" 
                    style={{padding: "10px 20px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                >
                    <img src={AzSort} style={{width: "14px"}}/>
                    <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>Created Date</div>
                </div>
                <div onClick={(e) => selectSort(e, "createDate", "desc")} 
                    className="menu-hvr-item-item" 
                    style={{padding: "10px 20px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                >
                    <img src={ZaSort} style={{width: "14px"}}/>
                    <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>Created Date</div>
                </div>
            </div>
        </Base>
    )
}

export default SortMobile