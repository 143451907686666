import React, { Component } from 'react';
import RegisterWeb from './Register_web'
import RegisterMobile from './Register_mobile'
import axio from 'axios'
import axios from 'axios';
import Swal from 'sweetalert2';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { setLoadedFalseUser } from '../../redux/action';

class register extends Component {

    constructor(){
        super()
        this.state = {
            windowWidth: window.innerWidth,
            isLogedIn : false,
            checking : true,
            roles : [],
            inProcess: false,
            region: [],
            isSuccess: false
        }
    
        // this.adminService = new AdminService()
        // this.login = this.login.bind(this) 
    }

    componentDidMount(){
        this.fetchRole()
        this.fetchRegion()

        this.setState({windowWidth: window.innerWidth})
        window.addEventListener("resize", () => {
            this.setState({windowWidth: window.innerWidth})
        })
    }

    fetchRole = () => {
        axio.get("/role/getRolesRegister").then(resp => {
            let roles = resp.data.data
            this.setState({
                roles: roles
            })
        })
    }

    fetchRegion = () => {
        axios.get("/hospital/getRegions").then(response => {
            let result = response.data
            let regions = result.data
            this.setState({
                region: regions
            })
        })
    }

    register = (name, password, email, regAs, region) => {
        let obj = {
            "email": email,
            "fullName": name,
            "password": password,
            "phone": "",
            "typeId": regAs,
            "regionId": region
          }
         
        this.setState({
            inProcess: true
        })
        
        axios.post("/auth/register", obj).then(response => {
            let resp = response.data
            if(resp.success){
                this.props.setFalseLoaded()
                this.setState({
                    isSuccess: true,
                    inProcess: false
                })
            }
        }).catch(error => {
            let resp = error.response
            let msg = resp.data.message
            this.setState({
                inProcess: false
            })
            Swal.fire(
                "",
                msg,
                "error"
            )
        })
    }

    login = (email, password) => {
        let obj = {}
        obj.username = email
        obj.password = password
        
        this.setState({
            isLoadLogin: true
        })

        axios.post("/auth/login", obj).then(result => {
            let dataLogin = result.data.data
            Swal.fire(
                'Success',
                "Add or register user is successfully",
                'success'
            );
            /*set data login to locastorage*/
        }).catch(error => {
            let resp = error.response
            if(resp){
                let msg = resp.data.message
                Swal.fire(
                    'Failed',
                    msg,
                    'error'
                );
            }else{
                Swal.fire(
                    'Failed',
                    "Network error, please check your connection",
                    'error'
                );
            }

            this.setState({
                isLoadLogin: false
            })
        })
    }

    render() {
        return (
            <>
            {   (this.state.isSuccess) 
                ? <Redirect to="/dashboard"/> 
                : null
            }
            {
                (this.state.windowWidth > 1202)
                ?
                    <RegisterWeb inProcess={this.state.inProcess} 
                                roles={this.state.roles} 
                                region={this.state.region}
                                register={this.register}/>
                :
                    <RegisterMobile roles={this.state.roles}
                                inProcess={this.state.inProcess} 
                                region={this.state.region}
                                register={this.register}/>
                }
            </>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return{
        setFalseLoaded: () => dispatch(setLoadedFalseUser())
    }
}

export default connect(null, mapDispatchToProps) (register)
