import React, { Component } from 'react'
import Logo from '../../images/Beyond Logo.png'
import Styled from 'styled-components'
import { Link } from 'react-router-dom'
import Power from '../../images/power.svg'
import { getRoleId, getRoleName } from '../function/read_token'
import Points from '../../images/points-256px-blue.png'

const Sidebar = Styled.div`
    width: 222px;
    height: 100%;
    position: fixed;
    background: #FFF;
    z-index: 1000;
    box-shadow: 2px 2px 4px rgba(51, 51, 51, 0.1);
    border-radius: 0px 8px 8px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Menu = Styled.div`
    padding: 18px 26px;
    display: flex;
`;

const TextMenu = Styled.span`
    font-size: 16px;
    margin-left: 18px;
`

class sidebar extends Component{

    constructor(){
        super()
        this.state = {
            roleId: ""
        }
    }

    logout = () => {
        window.localStorage.removeItem("token")
        window.location.reload()
    }

    render(){
        return(
            <Sidebar id="sdbr-cxl">
                <div>
                    <img src={Logo} style={{margin: "16px 40px"}}/>
                    <hr style={{width: "190px", height: "1px",background: "rgba(31, 71, 136, 0.2)", border: "none", margin: "auto"}}/>
                    <Link to={"/dashboard"}>
                        <Menu className="sb-menu">
                            {icon1}
                            <TextMenu>Dashboard</TextMenu>
                        </Menu>
                    </Link>
                    {
                        // getRoleId() == "00000000-0000-0000-0000-000000000001"
                        // ?
                        //     <Link to={"/dashboard/hospitals"}>
                        //         <Menu className="sb-menu">
                        //             {icon1}
                        //             <TextMenu>Hospitals</TextMenu>
                        //         </Menu>
                        //     </Link>
                        // :
                        //     ""
                    }
                    {
                        (getRoleId() == "00000000-0000-0000-0000-000000000010")
                        ?
                            <Link to={"/dashboard/applicant-point"}>
                                <Menu className="sb-menu">
                                    <img src={Points} style={{width: "25px", height: "25px", marginLeft: "-2px"}}/>
                                    <TextMenu>Applicant Point</TextMenu>
                                </Menu>
                            </Link>
                        :   ""
                    }
                    {
                        // (getRoleId() == "00000000-0000-0000-0000-000000000013" || getRoleId() == "00000000-0000-0000-0000-000000000010")
                        (getRoleId() == "00000000-0000-0000-0000-000000000010")
                        ?
                            <Link to={"/dashboard/leave-sick"}>
                                <Menu className="sb-menu">
                                    {icon1}
                                    <TextMenu>Leave & Sick</TextMenu>
                                </Menu>
                            </Link>
                        :   ""
                    }
                    <Link to="/profile">
                        <Menu className="sb-menu">
                            <div style={{width: "23px", textAlign: "center"}}>
                                {circle}
                                <div style={{marginTop: "-7px"}}>
                                    {oval}
                                </div>
                            </div>
                            <TextMenu>Profile</TextMenu>
                        </Menu>
                    </Link>
                </div>
                <div>
                    <Menu onClick={this.logout} className="sb-menu">
                        <img style={{width: "20px"}} src={Power}/>
                        <TextMenu>Log Out</TextMenu>
                    </Menu>
                </div>
            </Sidebar>
        )
    }
}

const icon1 = <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.15722 19.7714V16.7047C8.1572 15.9246 8.79312 15.2908 9.58101 15.2856H12.4671C13.2587 15.2856 13.9005 15.9209 13.9005 16.7047V16.7047V19.7809C13.9003 20.4432 14.4343 20.9845 15.103 21H17.0271C18.9451 21 20.5 19.4607 20.5 17.5618V17.5618V8.83784C20.4898 8.09083 20.1355 7.38935 19.538 6.93303L12.9577 1.6853C11.8049 0.771566 10.1662 0.771566 9.01342 1.6853L2.46203 6.94256C1.86226 7.39702 1.50739 8.09967 1.5 8.84736V17.5618C1.5 19.4607 3.05488 21 4.97291 21H6.89696C7.58235 21 8.13797 20.4499 8.13797 19.7714V19.7714" stroke="#1F4788" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>


const circle = <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.98464 10.0059C8.52274 10.0059 10.5799 7.94782 10.5799 5.40972C10.5799 2.87163 8.52274 0.814484 5.98464 0.814484C3.44655 0.814484 1.38845 2.87163 1.38845 5.40972C1.37988 7.93925 3.42369 9.99734 5.95226 10.0059H5.98464Z" stroke="#1F4788" stroke-width="1.42857" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

const oval = <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.98468 1.34619C4.11707 1.34619 0.814209 1.93095 0.814209 4.27286C0.814209 6.61476 4.09611 7.22048 7.98468 7.22048C11.8523 7.22048 15.1542 6.63476 15.1542 4.29381C15.1542 1.95286 11.8733 1.34619 7.98468 1.34619Z" stroke="#1F4788" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

export default sidebar