import React from 'react'
import Styled from 'styled-components'
import Calendar from 'react-calendar'
import Next from '../images/next-white.png'
import LoaderGif from '../images/Pulse-1s-51px.gif'
import '../style/calendar.css'
import { connect } from 'react-redux'
import { setDataCalendar } from '../redux/action'
import SalesService from '../service/sales_service'

const BaseCalendar = Styled.div`
    position: fixed;
    height: 100%;
    background: #FFF;
    width: 450px;
    top: 0;
    right: -451px;
    z-index: 10;
    box-shadow: -2px 2px 4px rgb(51 51 51 / 10%);
`

const BaseButtonSlider = Styled.div`
    position: fixed;
    transform: rotate(-90deg);
    padding: 5px 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: rgb(31, 71, 136);
    color: #FFF;
    margin-left: -69px;
    border: 1px solid #C8DDEA;
    top: 100px;
    display: flex;
    align-items: center;
`

class calendar extends React.Component {

    constructor(){
        super()
        this.state = {
            isShow: false,
            windowWith: window.innerWidth,
            availableDate:[],
            paramSchedule: [],
            region: "00000000-0000-0000-0000-000000002001"
        }
    }

    componentDidMount(){
        window.addEventListener("resize", this.resize())
        document.addEventListener("click", this.clickCalender)

        /*set param schedule*/
        let now = new Date()
        let m = parseInt(now.getMonth()) + 1
        m = (m < 10) ? "0"+m : m
        let y = now.getFullYear()
        
        let param = {
            year: y.toString(),
            month: m.toString(),
            regionTypeId: this.state.region
        }

        this.setState({
            paramSchedule: param
        })

        this.getDataCalender(param, 1);

        /*load data calendar and set view calendar*/
        this.setCalendarView(this.props.dataCalendar)
        this.disableDate()
    }

    componentDidUpdate(prevprops, prevstate){
        if(prevprops.dataCalendar != this.props.dataCalendar){
            this.setCalendarView(this.props.dataCalendar)
        }

        if(prevstate.availableDate != this.state.availableDate){
            this.disableDate()
        }
    }

    getDataCalender = async (param) => {
        let containerCalender = document.getElementsByClassName("react-calendar__viewContainer")[0]
        containerCalender.style.height = "150px"
        
        /*create loader calendar*/
        let elm = document.getElementById("wrp-ld-cldr-xhb")
        if(elm) elm.remove()

        let wrappLoad = document.createElement("div")
        wrappLoad.setAttribute("id", "wrp-ld-cldr-xhb")
        wrappLoad.style.display = "flex"
        wrappLoad.style.flexDirection = "column"
        wrappLoad.style.justifyContent = "center"
        wrappLoad.style.alignItems = "center"
        wrappLoad.style.marginTop = "60px"

        let load = document.createElement("div")
        load.innerText = "load calendar..."
        load.style.fontSize = "12px"
        load.style.color = "#CCC"

        let img = document.createElement("img")
        img.src = LoaderGif
        img.style.width = "35px"
        wrappLoad.append(img)
        wrappLoad.appendChild(load)
        containerCalender.appendChild(wrappLoad)
        
        let calendarContent = document.getElementsByClassName("react-calendar__month-view")[0]
        calendarContent.style.display = "none"
        
        
        /*load data calender schedule here*/
        let salesService = new SalesService()
        let data = await salesService.fetchScheduleCalendar(param)
        this.props.setDataCalendarRdx(data)
       
        calendarContent.style.display = "block"
        containerCalender.style.height = "auto"

        /*remove loader calendar after load data*/
        wrappLoad.remove()
    }
   

    clickCalender = (e) =>  {
        // console.log(1)
        // let t = e.target
        // let elm = t.getAttribute("class")
        // if(elm == null) return false

        /*deteksi terjadinya perubahan bulan yang dipilih user pada calender*/
        // if(elm.match("react-calendar__navigation__arrow")){
        let elm = document.getElementsByClassName("react-calendar__navigation__label__labelText")
        let stringMY = elm[0].innerText
    
        let m = stringMY.split(" ")[0]
        let month = this.mmToNum(m)
        let year = stringMY.split(" ")[1]
        let oldParam = this.state.paramSchedule
        
        let param = {
            year: year,
            month: month,
            regionTypeId: this.state.region
        }

        let prevYear = oldParam.year
        let prevMonth = oldParam.month
        let regionTypeId = oldParam.regionTypeId

        let currYear = (year) ? (year.match("–")) ? prevYear : year : prevYear
        let currMonth = (month) ? month : prevMonth

        let concatPrev = prevYear+" "+prevMonth+" "+regionTypeId /*concat param calendar*/
        let concatCurr = currYear+" "+currMonth+" "+this.state.region /*concat previous parameter calender*/


        /*if there is different of (concatPrev != concatCurr) that mean, there is indication that user change month
        of calendar. then the system will refresh calendar view*/
        if(concatPrev != concatCurr) {
            /*set param schedule to state for impacting of 
            action selectRegion() in calendar*/
            this.setState({
                paramSchedule: param
            }) 

            this.getDataCalender(param)
        }
    }

    setCalendarView = async (dataCalendar) => {
        let availableDate = []
        for(let i = 0;i<dataCalendar.length;i++){
            let dataSchedule = dataCalendar[i]
            let dateTimes = dataSchedule.dateTimes
            let year = dataSchedule.year
            let month = dataSchedule.month
            let keys = Object.keys(dateTimes)

            for(let i = 0;i<keys.length;i++){
                let d = keys[i]
                let m = month
                let y = year
                let dt = d+"/"+m+"/"+y

                let data = dateTimes[d]
                let avb = data.isAvailable
                // console.log(dt)
                // console.log(data.isAvailable)
                // console.log("")
                if(avb) {
                    availableDate.push(dt)
                }
            }
        }

        this.setState({
            availableDate: availableDate
        })
    }

    resize = () => {
        let ww = window.innerWidth
        this.setState({
            windowWith: ww
        })

        if(ww < 1202){
            let elm = document.getElementById("bs-calendar-xsd")
            elm.style.width = "85%"
            elm.style.right = "-85%"
        }
    }

    /*dibaled specific date in calendar*/
    disableDate(){
        let availableDate = [...this.state.availableDate]
        console.log(availableDate)
        let elm = document.getElementsByClassName("react-calendar__tile")
        let dc = ""
        let dOri = ""
        for(var i = 0;i<elm.length;i++){
            let curr = elm[i]
            let date = curr.children[0].getAttribute("aria-label")
            dOri += date+", "
            // console.log(date)
            let dateConvert = this.convertDateCalender(date).toString()
            dc += dateConvert+", "
            // console.log(dateConvert)
            let idx = availableDate.indexOf(dateConvert)
            // console.log({availableDate, dateConvert})

            if(idx == -1){
                curr.setAttribute("disabled", true)
                curr.style.background = "#ececec"
                curr.style.color = "#c2c2c2" 
                curr.setAttribute("style", "flex-basis: 14.2857%; max-width: 14.2857%; overflow: hidden;background: #ececec; color: #c2c2c2")
            }else{
                curr.removeAttribute("disabled")
                curr.setAttribute("style", "flex-basis: 14.2857%; max-width: 14.2857%; overflow: hidden")
            }
        }
        // console.log(dc)
        // let elmDc = document.getElementById("bs-date-convert")
        // elmDc.innerText = dc

        // let elmOri = document.getElementById("bs-date-ori")
        // elmOri.innerText = dOri
    }

    convertDateCalender(date){
        let dates = new Date(date)
        // let char = date.split(" ")

        let dy = dates.getDate()
        let mon = dates.getMonth() + 1
        mon = (mon < 10) ? "0"+mon : mon
        let yr = dates.getFullYear()

        // let day = char[1].replace(",", "");
        // console.log(day)
        // let year = char[2]
        // let month = char[0].toLowerCase()
        // let numMonth = ""

        // /*month*/
        // if(month == "january") numMonth = "01"
        // if(month == "february") numMonth = "02"
        // if(month == "march") numMonth = "03"
        // if(month == "april") numMonth = "04"
        // if(month == "may") numMonth = "05"
        // if(month == "june") numMonth = "06"
        // if(month == "july") numMonth = "07"
        // if(month == "august") numMonth = "08"
        // if(month == "september") numMonth = "09"
        // if(month == "october") numMonth = "10"
        // if(month == "november") numMonth = "11"
        // if(month == "december") numMonth = "12"

        // return day+"/"+numMonth+"/"+year
        return dy+"/"+mon+"/"+yr
    }

    mmToNum = (m) => {
        let month = m.toLowerCase()
        let numMonth = ""
        if(month == "january") numMonth = "01"
        if(month == "february") numMonth = "02"
        if(month == "march") numMonth = "03"
        if(month == "april") numMonth = "04"
        if(month == "may") numMonth = "05"
        if(month == "june") numMonth = "06"
        if(month == "july") numMonth = "07"
        if(month == "august") numMonth = "08"
        if(month == "september") numMonth = "09"
        if(month == "october") numMonth = "10"
        if(month == "november") numMonth = "11"
        if(month == "december") numMonth = "12"

        return numMonth
    }

    montInaToEng(month){
        month = month.toLowerCase()
    }

    slideCalender = () => {
        let elm = document.getElementById("bs-calendar-xsd")
        if(this.state.windowWith > 1202){
            if(!this.state.isShow){
                let a = -400;
                let itvAnimate = setInterval(() => {
                    a += 10;
                    elm.style.right = a+"px"
                    if(a > 0){
                        elm.style.right = "0px"
                        clearInterval(itvAnimate)
                    }
                }, 1)
            }else{
                elm.style.right = "-451px"
            }
        }else{
            if(!this.state.isShow){
                let a = -85;
                let itvAnimate = setInterval(() => {
                    a += 2;
                    elm.style.right = a+"%"
                    if(a > 0){
                        elm.style.right = "0%"
                        clearInterval(itvAnimate)
                    }
                }, 1)
            }else{
                elm.style.right = "-85%"
            }
        }
        
        this.setState({
            isShow: !this.state.isShow
        })
    }

    selectRegion = (val) => {
        let param = this.state.paramSchedule
        param.regionTypeId = val

        this.getDataCalender(param)

        this.setState({
            region: val
        })
    }

    render(){
        return(
            <BaseCalendar id="bs-calendar-xsd">
                <a onClick={this.slideCalender}>
                    {
                        (this.state.windowWith > 1202)
                        ?
                            <BaseButtonSlider id="bs-btn-slider">
                                <img src={Next} style={{transform: "rotate(-265deg)", width: "12px"}}/>
                                &nbsp;
                                <span style={{fontSize: "12px"}}>calendar</span>
                            </BaseButtonSlider>
                        : 
                            <BaseButtonSlider id="bs-btn-slider" style={{padding: "2px 10px", borderRadius: "10px 10px 0px 0px", marginLeft: "-50px", top: "150px"}}>
                                <img src={Next} style={{transform: "rotate(-265deg)", width: "8px", marginRight: "4px"}}/>
                                <div style={{fontSize: "10px"}}>calendar</div>
                            </BaseButtonSlider>
                    }
                   
                </a>

                <div style={{padding: "10px"}}>
                    <div style={{padding: "0px 15px", borderLeft: "4px solid rgb(31,71,136)", marginBottom: "15px", borderRadius: "4px", marginTop: "5px"}}>
                        <div style={{fontSize: "14px", fontWeight: "bold"}}>Select Region</div>
                        <div style={{display: "flex", alignItems: "center"}}>
                            {
                                (this.state.region == "00000000-0000-0000-0000-000000002001")
                                ? <a style={{display: "flex", alignItems: "center"}}>{check}&nbsp;<span style={{fontSize: "12px"}}>Jawa</span></a>
                                : <a onClick={() => this.selectRegion("00000000-0000-0000-0000-000000002001")} style={{display: "flex", alignItems: "center"}}>{radio}&nbsp;<span style={{fontSize: "12px"}}>Jawa</span></a>
                            }
                            &nbsp;&nbsp;&nbsp;
                            {
                                (this.state.region == "00000000-0000-0000-0000-000000002002")
                                ? <a style={{display: "flex", alignItems: "center"}}>{check}&nbsp;<span style={{fontSize: "12px"}}>Sumatra</span></a>
                                : <a onClick={() => this.selectRegion("00000000-0000-0000-0000-000000002002")} style={{display: "flex", alignItems: "center"}}>{radio}&nbsp;<span style={{fontSize: "12px"}}>Sumatra</span></a>
                            }
                        </div>
                    </div>
                    <Calendar locale="en-GB" className="rpt-calendar"/>
                    {/* <div style={{fontSize: "10px", wordBreak: "break-word"}}>{this.state.availableDate.toString()}</div>  */}
                    {/* <div id="bs-date-ori" style={{fontSize: "12px", marginTop: "10px"}}></div>
                    <div id="bs-date-convert" style={{fontSize: "12px", marginTop: "10px"}}></div> */}
                </div>
            </BaseCalendar>
        )
    }
}

const styles = {
    pic: {
        background: "rgb(70 121 184)", 
        height: "20px", 
        width: "20px", 
        borderRadius: "100%", 
        color: "#FFF", 
        fontSize: "9px", 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center"
    },
    picMobile: {
        background: "rgb(70 121 184)", 
        height: "16px", 
        width: "16px", 
        borderRadius: "100%", 
        color: "#FFF", 
        fontSize: "8px", 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center"
    }
}

const radio = <svg width="15" height="15" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="13" cy="13" r="12.5" fill="white" fill-opacity="0.06" stroke="#08A0F7"/>
            </svg>


const check = <svg width="15" height="15" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="13" cy="13" r="12.5" fill="#08A0F7" stroke="#08A0F7"/>
                <path d="M9.37501 13.4578L12.062 16.1448L17.436 10.7707" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

const mapStateToProps = (state) => {
    return {
        dataCalendar : state.dataCalendar
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        setDataCalendarRdx:  (data) => dispatch(setDataCalendar(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (calendar)