import React, { useEffect, useState } from 'react'
import Styled from 'styled-components'
import LoaderGif from '../../../images/Pulse-1s-51px.gif'

const Base = Styled.div`
    width: 100%;
    height: 100%;
    top: 0px;
    position: fixed;
    z-index: 100000;
    background: rgba(0,0,0,0.4);
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
`
const Popup = Styled.div`
    width: 300px;
    background: #FFF;
    border-radius: 5px;
`
const Delete_confirm = (props) => {

    const [deleting, setDeleting] = useState(false)

    const yes = () => {
        setDeleting(true)
        props.yes()
    }

    useEffect(() => {
        let w = window.innerWidth
        if(w < 1202){
            let elm = document.getElementById("p-bs-conf-del")
            if(elm) elm.style.width = "270px"
        }
    })

    return(
        <Base id="bs-conf-del">
            <Popup id="p-bs-conf-del">
                <div style={{padding: "10px 20px", fontSize: "18px", fontWeight: "600", borderBottom: "1px solid #e6e3e3"}}>
                    {props.title}
                </div>
                <div style={{padding: "10px 20px", fontSize: "14px", fontWeight: "500"}}>
                    {props.desc}
                </div>
                <div style={{padding: "10px 20px", display: "flex", justifyContent: "flex-end"}}>
                    {
                        (deleting)
                        ?
                            <button className="button" style={{width: "48px", padding: "0px", background: "#FFF", border: "1px solid #CCC"}}>
                                <img src={LoaderGif} style={{width: "30px", marginTop: "5px"}}/>
                            </button>
                        :
                            <button className="button" onClick={yes} style={{width: "48px", padding: "8px"}}>Yes</button>
                    }
                    &nbsp;&nbsp;
                    <button className="button-disable" onClick={props.no} style={{width: "48px", padding: "8px"}}>No</button>
                </div>
            </Popup>
        </Base>
    )
}

export default Delete_confirm