import React, { Component } from 'react'
import Styled from 'styled-components'
import { dropDownTypeOfTools, dropDownDemoTerms } from '../data/data'
import { getFullName, getRoleName } from '../../function/read_token'
import DateFnsUtils from '@date-io/date-fns';
import GreenCheks from '../../../images/green-check-mark.png'
import SrcIcon from '../../../images/search-interface-symbol.svg'
import 'react-calendar/dist/Calendar.css'; /*css of calendar*/
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
    TimePicker
  } from '@material-ui/pickers';
import { getTime, getDate as getDateFunc } from '../../function/function'
import axios from 'axios';
import { baseUrl, baseUrlUpload } from '../../../environment';
import IconHsp from '../../../images/hospital-buildings-64.png'
import AddIcon from '../../../images/add_crc.png'
import MinusIcon from '../../../images/minus_crc.png'
import Spinner from '../../../images/Spinner-0.7s-200px.gif'
import InfoRed from '../../../images/info-red.png'
import Addition from '../../../images/addition.png'
import People from '../../../images/user-3-color-64px.png'
import info from '../../../images/question.png'
import DenyIcon from '../../../images/remove.png'
import PopAddHospital from "../hospitals/popup_add_hospital"


const Base = Styled.div`
    position: relative;
`

const FormBase = Styled.div`
    background: #FFFFFF;
    border-radius: 10px;
    padding: 20px 47px;
    margin-top: 25px;
`

const SrcBaseMain = Styled.div`
  background: #FFF;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  position: absolute;
  width: 100%;
  box-shadow: 2px 2px 1px #f3f3f3;
  max-height: 250px;
  overflow-y: auto;
`
const ItemSrc = Styled.div`
  padding: 8px 22px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #e4e4e4;
`

const BarLoadUpload = Styled.div`
    width: 140px; 
    height: 5px;
    background: #CCC;
    overflow: hidden;
    border-radius: 5px;
    margin-top: 3px;
    position: relative;
`
const PercentUploaded = Styled.div`
    background: #4a7c8f;
    height: 100%;
    border-radius: 5px;
    width: 0%;
`

class user_sales_web extends Component{
    constructor(){
        super()
        this.state = {
            popup: "",
            salesName: getFullName(),
            rsName: "",
            addressRs: "",
            contactPerson: "",
            start: [],
            applicantSchedules: [],
            applicantSchedulesDenied: [],
            startDate: new Date(),
            startDateDenied: new Date(),
            endDate: new Date(),
            kindAct: "demo",
            describe: "",
            upload: "",
            fileName: "",
            typeTools: "",
            demo: "",
            regionId: "",
            training: "",
            isTricefy: "yes",
            additionalAcc: "",
            employeeId:"",
            srcRs: [],
            rsSelected: null,
            isSearcingRs: false,
            proofAccDocumentId: "",
            successUpload: false,
            visibilityType: [],
            isDownloadProof: false,
            typeDate: "single",
            dateAgg: []
        }

        this.inputFile = React.createRef()
        this.selectHospBs = React.createRef()
        this.barPercentUpload = React.createRef()
    }

    componentDidMount(){

        /*init data start*/
        let obj = this.setDateUserSales()
        let dataStart = []
        dataStart.push(obj)

        this.setVisibilityTypeId()

        this.setState({
            typeTools: dropDownTypeOfTools[0],
            demo: dropDownDemoTerms[0],
            training: dropDownDemoTerms[0],
            start: dataStart
        })

        
        /*for hide choices hospital*/
        document.addEventListener("click", this.handleClickOutSide);
        document.addEventListener("click", this.clickCalender)
        this.disableInput()
        this.setValue()
    }


    componentWillUnmount(){
        document.removeEventListener("click", this.handleClickOutSide)
    }

    setVisibilityTypeId = async () => {
        let resp = await axios.get("/file/getVisibilityType")
        let result = resp.data
        let data = result.data
        this.setState({
            visibilityType: data
        })
    }

    /*for hide popup select hospitals when click outsode box select*/
    handleClickOutSide = (event) => {
        let ref = this.selectHospBs
        if(ref != null){
            if(ref.current != null){
                if(ref && !ref.current.contains(event.target)){
                    this.setState({
                        isSearcingRs: false
                    })
                }
            }
        }
    }
    
    componentDidUpdate(prevProps, prevState){
        if(prevProps != this.props){
            // this.clearState()
            // this.setValue()
            this.disableInput()
        }
    }

    setDateUserSales = (date) => {
        let now = new Date()
        if(date){
            now = new Date(date)
        }
        
        let mm = parseInt(now.getMonth()) + 1
        let year = now.getFullYear()
        let month = (mm < 10) ? "0"+mm : mm.toString()
        let day = (now.getDate() < 10) ? "0"+now.getDate() : now.getDate().toString();
     
        let obj = {
            date: day,
            endTime: "00:00",
            isFullTime: true,
            month: month,
            startTime: getTime(now).substr( 0, 5),
            year: year.toString()
        }
        /*add obj to array start*/

        return obj
    }

    disableInput = () => {
        let id = this.props.id
        let input = document.getElementsByClassName("frm-input")
        
        for(let i = 0;i<input.length;i++){
            if(id != "form"){
                let idElement = input[i].getAttribute("id")
                if(idElement != "apl-ipt"){
                    input[i].disabled = true
                    input[i].style.border = "1px solid #CCC"
                }else{
                    if(!this.isManager()){
                        input[i].disabled = true
                        input[i].style.border = "1px solid #CCC"
                    }
                }
            }
        }
    }

    setValue = () => {
        let id = this.props.id
        let data = this.props.data
        console.log(data)
        // let empId = this.getEmpId(data.userEmpSvc)

        if(id != "form" && data != ""){
            /*set data start date or schedule*/
            let start = []
            let formApplicant = data
            let salesUser = formApplicant.salesUser
            let salesName = salesUser.name
            let hospital = formApplicant.hospital
            let kindAct = formApplicant.kindActivity
            let contact = formApplicant.contactPerson
            let typeTools = formApplicant.typeTools
            let additionalAcc = formApplicant.additionalAccessories
            let includeTricefy = formApplicant.includeTricefy
            let describe = formApplicant.describeOther
            let address = formApplicant.address
            let rsName = formApplicant.hospitalName
            
            let region = formApplicant.hospital.region
            let regionId = (region != null) ? region.id : ""

            let fileName = "" 
            let fileId = ""
            let proofAccDocument = data.proofAccDocument
            if(proofAccDocument){
                fileName = proofAccDocument.name
                fileId = proofAccDocument.id
            }
            

            /*set start activity data, data hanya diambil dengan schedule status bukan deny*/
            let applicantSchedules = formApplicant.applicantSchedules
            let applicantSchedulesDenied = formApplicant.applicantSchedulesDenied
            let startDateDenied = this.setStartDateDenied(applicantSchedulesDenied)

            applicantSchedules.map(dt => {
                let confirmStatus = dt.confirmStatus
                if(confirmStatus.name.toLowerCase() != "deny"){
                    let startTime = (dt.startTime) ? dt.startTime : "07:00" /*default start time : 07:00*/
                    let date = new Date(dt.year+"-"+dt.month+"-"+dt.date+" "+startTime+":00")
                    let obj = this.setDateUserSales(date)
                    start.push(obj)
                }
            })
            
            this.setState({
                salesName: salesName,
                rsSelected: hospital,
                rsName: rsName,
                addressRs: address,
                contactPerson: contact,
                start: start,
                startDateDenied: startDateDenied,
                applicantSchedules: applicantSchedules,
                applicantSchedulesDenied: applicantSchedulesDenied,
                regionId: regionId,
                kindAct: kindAct,
                describe: describe,
                fileName: fileName,
                typeTools: typeTools,
                isTricefy: includeTricefy,
                additionalAcc: additionalAcc,
                proofAccDocumentId: fileId
            })
        }else{
            /*set data start date or schedule*/
            let obj = this.setDateUserSales()
            let dataStart = []
            dataStart.push(obj)

            this.setState({
                rsSelected: null,
                contactPerson: "",
                start: dataStart,
                addressRs: "",
                additionalAcc: "",
                describe: "",
                typeTools: dropDownTypeOfTools[0],
                isTricefy: "yes"
            })
        }
    }

    setStartDateDenied = (schedules) => {
        let start = []
        schedules.map(dt => {
            let startTime = (dt.startTime) ? dt.startTime : "07:00" /*default start time : 07:00*/
            let date = new Date(dt.year+"-"+dt.month+"-"+dt.date+" "+startTime+":00")
            let obj = this.setDateUserSales(date)
            start.push(obj)
        })
        return start
    }

    getSalesName = () => {
        let data = this.props.data
        if(data.salesUser){
            let sales = data.salesUser
            let profile = sales.profile
            let fn = profile.firstName
            let ln = profile.lastName
            let full = fn+" "+ln
            return full
        }else{
            return ""
        }
    }

    selectActivity = (val) => {
        let id = this.props.id
        if(id == "form"){
            this.setState({
                kindAct: val
            })
        }
    }

    selectFile = () => {
        if(this.props.id == "form"){
            this.inputFile.current.click()
        }
    }

    selectTricefy = (val) => {
        let id = this.props.id
        if(id == "form"){
            this.setState({
                isTricefy: val
            })
        }
    }

    getTypeId = (type) => {
        let id = ""
        let data = this.state.visibilityType
        for(let i = 0;i<data.length;i++){
            if(type == data[i].name){
                id = data[i].id
            }
        }

        return id
    }

    selectFileInput = async (e) => {
        let file = e.target.files[0]
        let fname = file.name
        let typeId = this.getTypeId("public")
        this.setState({
            fileName: fname,
            upload: file
        })


        let form = new FormData()
        form.append("file", file)
        form.append("visibilityTypeId", typeId)
        let url = baseUrlUpload
        let dataLogin = await window.localStorage.getItem("token")
        let token;
        
        if (dataLogin != null) {
            dataLogin = JSON.parse(window.localStorage.getItem("token"))
            token = dataLogin.token
        }

        const requestOptions = {
            method: "POST",
            headers: { 'Authorization': "Bearer "+token },
            body: form
        }

        const xhr = new XMLHttpRequest();
        xhr.upload.onload = () => {
            console.log("Completed upload")
        }
        
        xhr.upload.onerror = () => {
            console.error("upload failed")
        }

        xhr.upload.onabort = () => {
            console.error('Upload cancelled.');
        }

        xhr.upload.onprogress = (event) => {
            // event.loaded returns how many bytes are downloaded
            // event.total returns the total number of bytes
            // event.total is only available if server sends `Content-Length` header
            let percent = (event.loaded / event.total) * 100
            let barLoadUpload = this.barPercentUpload.current
            barLoadUpload.style.width = percent+"%"
        }

        xhr.onreadystatechange = () => {
            if (xhr.readyState == XMLHttpRequest.DONE) {
                let resp = JSON.parse(xhr.responseText)
                if(resp.success){
                    let data = resp.data
                    let idFile = data.id
                    let fileName = data.name
                    this.setState({
                        proofAccDocumentId: idFile,
                        fileName: fileName,
                        successUpload: true
                    })
                }
            }
        }

        xhr.open("POST", url+"/file/uploadFile")
        xhr.setRequestHeader("Authorization", "Bearer "+token)
        xhr.send(form)
    }

    save = async () => {
        let isValid = this.inputValidation()
        let isValid2 = this.inputFileValidation()
        let isValid3 = this.validateDateActivity()
        if(!isValid || !isValid2 || !isValid3) return false

        let ste = this.state
        let date = ste.startDate
        let endDate = ste.endDate
        let strDate = getDateFunc(date)
        let strTime = getTime(date)
        let strEndDate = getDateFunc(endDate)
        let dateTimeStr = strDate+" "+strTime

        // return false;
        let typeDate = ste.typeDate
        let hasSameApplicant = (typeDate == "single") ? false : true;
        
        let bookDate = [] /*param date for single date type*/ 
        let bookMultipleStart = ""
        let bookMultipleEnd = ""
        if(this.state.typeDate == "single") bookDate = ste.start
        if(this.state.typeDate == "multiple") {
            bookMultipleStart = dateTimeStr
            bookMultipleEnd = strEndDate+" 23:59:00"
        }
        this.props.save(
            ste.rsSelected,
            // ste.rsName,
            ste.contactPerson,
            bookDate,
            ste.kindAct,
            ste.describe,
            ste.typeTools,
            ste.additionalAcc,
            ste.proofAccDocumentId,
            ste.isTricefy,
            ste.addressRs,
            bookMultipleStart,
            bookMultipleEnd,
            // ste.endDate,
            hasSameApplicant,
            ste.regionId
        ).then(e => {
            if(e == "success"){
                this.setValue()
            }
        })
    }

    inputValidation = () => {
        let isValid = true
        let elm =  document.getElementsByClassName("frm-input")
        for(let i = 0;i<elm.length;i++){
            let itemElm = elm[i]
            let isMandatory = itemElm.getAttribute("isMandatory")
            
            let v = itemElm.value
            if(v == 0 && isMandatory){
                if(isMandatory == "true"){
                    let attr = itemElm.getAttribute("attr")
                    if(attr != "address-rs"){
                        itemElm.style.border = "1px solid #F00"
                        isValid = false
                    }
                }
            }else{
                itemElm.style.border = "1px solid #1F4788"
            }
        }


        /*validasi RS NAME*/
        let elmRs = document.getElementsByClassName("frm-input-rs");
        let ste = this.state.rsSelected
        if(ste == null){
            elmRs[0].style.border = "1px solid #F00"
            isValid = false
        }else{
            elmRs[0].style.border = "1px solid #1F4788"
        }
        // let elm2 = document.getElementsByClassName("frm-input-rs")[0]
        // if(this.state.rsSelected == null) {
        //     if(elm2){
        //         elm2.style.border = "1px solid #F00"
        //         isValid = false
        //     }
        // }else{
        //     if(elm2) elm2.style.border = "1px solid #1F4788"
        // }

        return isValid
    }

    inputFileValidation = () => {
        let kinds = this.state.kindAct
        let eId = document.getElementById("ipt-upl-proof")
        let elm = this.inputFile.current
        let v = elm.value
        if(v == 0 && kinds == "others"){
            eId.style.border = "1px solid #F00"
            return false
        }else{
            eId.style.border = "1px solid #1F4788"
            return true
        }
    }

    clearState = () => {
        if(this.props.clearState){
            this.inputFile.current.value = ""
            this.setState({
                salesName: "",
                rsName: "",
                addressRs: "",
                contactPerson: "",
                start: "",
                finish: "",
                kindAct: "demo",
                describe: "",
                upload: "",
                fileName: "",
                typeTools: "",
                demo: "",
                regionId: "",
                training: "",
                isTricefy: "yes",
                additionalAcc: "",
                region: "Jabodetabek",
            })
            this.props.falseClearState()
        }
    }

    isManager = () => {
        if(getRoleName().toLowerCase() == "director / manager applicant"){
            return true
        }else{
            return false
        } 
    }
    
    getEmpId = (data) => {
        if(data){
            return data.id
        }else{
            return ""
        }
    }

    setEmployee = () => {
        this.props.setEmployee(this.state.employeeId)
    }

    handleDateChange = (date, index) => {
        let state = [...this.state.start]
        let obj = this.setDateUserSales(date)
        
        /*param for keep value startTime*/
        let startTime = state[index].startTime

        state[index] = obj
        state[index].startTime = startTime
        this.setState({
            start: state
        })
    }

    handleDateChangeMultiple = (date) => {
        this.setState({
            startDate: date
        })
    }

    handleEndDateChangeMultiple = (date) => {
        this.setState({
            endDate: date
        })
    }

    handleStartTimeChange = (time, index) => {
        let state = [...this.state.start]
        let tm = getTime(time)
        tm = tm.substr(0,5)
        state[index].startTime = tm

        this.setState({
            start: state
        })
    }

    addStartActivity = () => {
        let newObj = this.setDateUserSales()
        let state = this.state.start
        state.push(newObj);
        
        this.setState({
            start: state
        })

        this.validateDateActivity()
    }

    validateDateActivity = () => {
        let isValidDate = true
        let dateAgg = []
        let dateData = this.state.start
        
        for(let i = 0;i<dateData.length;i++){
            let d = dateData[i].date
            let m = dateData[i].month
            let y = dateData[i].year

            let idx = dateAgg.indexOf(d+m+y)
            if(idx == -1){
                dateAgg.push(d+m+y)
            }else{
                isValidDate = false
                break
            }
        }

        return isValidDate
    }

    removeStartAcitivity = (index) => {
        let data = this.props.data
        let status = data.statusReportForm

        let isM = this.isManager()
        if(isM || status == "finished") return false

        let state = [...this.state.start]
        state.splice(index, 1)

        this.setState({
            start: state
        })
    }

    searchRs = (val) => {
        let src = val
        let url = (src == "") ? "/hospital/getHospitals" : "/hospital/getHospitals?q="+src
        this.setState({isSearcingRs: true})
        axios.get(url).then(resp => {
            let result = resp.data.data
            this.setState({
                srcRs: result
            })
        })
    }

    searchingRs = (e) => {
        let src = e.target.value
        this.searchRs(src)
    }

    selectRs = (e) => {
        e.stopPropagation()
        let id = this.props.id
        if(id == "form"){
            this.setState({isSearcingRs: true})
            this.searchRs("")
        }
    }

    selectingRs = (dt) => {
        let rsName = dt.name
        let address = (dt.address != null) ? dt.address : ""
        this.setState({
            rsSelected: dt,
            rsName: rsName,
            addressRs: address,
            isSearcingRs: false 
        })
    }

    downloadFile = async () => {
        let data = this.state.proofAccDocumentId
        let fileName = this.state.fileName
        const FileDownload = require('js-file-download');
        
        this.setState({
            isDownloadProof: true
        })

        axios({
            url: "/file/"+data, //your url
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            FileDownload(response.data, fileName);
            setTimeout(() => { 
                this.setState({
                    isDownloadProof: false
                })
            }, 500)
        });
    }

    addRs = () => {
        this.setState({
            popup: <PopAddHospital regions={this.props.region} 
                        save={this.saveHospital}
                        cancel={() => this.setState({popup: ""})}/>
        })
    }

    saveHospital = (resp) => {
        let data = resp.data
        this.setState({
            rsSelected: data,
            addressRs: data.address
        })
    }

    render(){
        return(
            <Base>
                {this.state.popup}
                <div style={{padding: "40px 51px"}}>
                    <div style={{fontSize: "24px", color: "#1F4788"}}>
                        Dashboard User Sales
                        <FormBase>
                            <div className="bs-frm-ip">
                                <div className="bs-bs-frm-ip">
                                    <div className="ttl-ip">Name / Sales Marketing</div>
                                    <input placeholder="Name / Sales Marketing"
                                        disabled="true"
                                        style={{border: "1px solid #CCC"}}
                                        value={this.state.salesName}
                                        onChange={(e) => this.setState({salesName: e.target.value})}
                                        className="frm-input" 
                                        isMandatory="true"
                                        type="text"/>
                                </div>
                                <div className="bs-bs-frm-ip">
                                    <div className="ttl-ip">RS Name / Clinic / Customer</div>
                                    {/* <input placeholder="RS Name / Clinic / Customer" 
                                        value={this.state.rsName}
                                        // onChange={this.searchRs}
                                        onChange={(e) => this.setState({rsName: e.target.value})}
                                        className="frm-input" type="text"/> */}

                                    {
                                        (this.props.id == "form")
                                        ?
                                            <a onClick={this.selectRs}>
                                                <div className="frm-input-rs" 
                                                    style={{display: "flex", alignItems: "center", border: "1px solid #1F4788", borderRadius: "5px", padding: "0px 20px", height: "46px"}}> 
                                                    <img src={IconHsp} style={{width: "25px", marginRight: "10px"}}/>
                                                    <div style={{lineHeight: "1.2"}}>
                                                        {
                                                            (this.state.rsSelected == null)
                                                            ?
                                                                <>
                                                                <div style={{fontSize: "12px", fontWeight: "bold", color: "#CCC"}}>{"Select Hospital"}</div>
                                                                </>
                                                            :
                                                                <>
                                                                <div style={{fontSize: "12px", fontWeight: "bold", color: "#000"}}>{this.state.rsSelected.name}</div>
                                                                <div style={{fontSize: "10px", fontWeight: "bold", color: "#8b8b8b"}}>{this.state.rsSelected.region.name}</div>
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                            </a>
                                        :
                                            <div className="frm-input-rs" 
                                                style={{display: "flex", alignItems: "center", border: "1px solid #CCC", borderRadius: "5px", padding: "0px 20px", height: "46px"}}> 
                                                <img src={IconHsp} style={{width: "25px", marginRight: "10px"}}/>
                                                <div style={{lineHeight: "1.2"}}>
                                                    {
                                                        (this.state.rsSelected == null)
                                                        ?
                                                            <>
                                                            <div style={{fontSize: "12px", fontWeight: "bold", color: "#CCC"}}>{"Select Hospital"}</div>
                                                            </>
                                                        :
                                                            <>
                                                            <div style={{fontSize: "12px", fontWeight: "bold", color: "#000"}}>{this.state.rsSelected.name}</div>
                                                            <div style={{fontSize: "10px", fontWeight: "bold", color: "#8b8b8b"}}>{this.state.rsSelected.region.name}</div>
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                    } 
                                    {
                                        (this.state.isSearcingRs)
                                        ?   <div id="src-rs-bs" ref={this.selectHospBs} style={{position: "relative"}}>
                                                <SrcBaseMain id="src-rs-bs-mn" className="shadow">
                                                    <div style={{display: "flex", 
                                                                justifyContent: "space-between", 
                                                                padding: "5px 20px", 
                                                                alignItems: "center",
                                                                background: "#CCC", 
                                                                borderBottom: "1px solid #CCC"}}>
                                                        <div style={{fontSize: "12px", color: "#000", fontWeight: "600"}}>Choose Hospital</div>
                                                        <div id="bs-nv-src-hsp-bs" style={{display: "flex",alignItems: "center"}}>
                                                            <button id="btn-psl-hsp-us"
                                                                onClick={this.addRs} 
                                                                style={{background: "none", border: "none", height: "24px", marginRight: "10px"}}>
                                                                <img src={Addition} style={{width: "24px"}}/>
                                                            </button>
                                                            <div id="src-ipt-hsp-bs" 
                                                                style={{display: "flex", 
                                                                        alignItems: "center", 
                                                                        border: "1px solid #CCC", 
                                                                        padding: "0px  8px", 
                                                                        height: "25px", 
                                                                        background: "#FFF", borderRadius: "4px"}}>
                                                                <img src={SrcIcon} style={{width: "12px"}}/>&nbsp;
                                                                <input type="text" onChange={this.searchingRs} placeholder="Search hospital" style={styles.inptSeactHsp}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                   {
                                                       this.state.srcRs.map((dt, index) => {
                                                           return  <a onClick={() => this.selectingRs(dt)}><ItemSrc className="select">
                                                                        <img src={IconHsp} style={{width: "25px", marginRight: "10px"}}/>
                                                                        <div style={{lineHeight: "1.2"}}>
                                                                            <div style={{fontSize: "12px", fontWeight: "bold", color: "#000"}}>{dt.name}</div>
                                                                            <div style={{fontSize: "10px", fontWeight: "bold", color: "#8b8b8b"}}>{dt.region.name}</div>
                                                                        </div>
                                                                    </ItemSrc></a>
                                                       })
                                                   }
                                                </SrcBaseMain>
                                            </div>
                                        :   ""
                                    }
                                </div>
                            </div>
                            <div className="bs-frm-ip">
                                <div className="bs-bs-frm-ip">
                                    <div className="ttl-ip">Address RS / Clinic</div>
                                    <input attr="address-rs" placeholder="Address RS / Clinic" 
                                        disabled
                                        value={this.state.addressRs}
                                        style={{border: "1px solid #CCC"}}
                                        onChange={(e) => this.setState({addressRs: e.target.value})}
                                        className="frm-input" 
                                        isMandatory="true"
                                        type="text"/>
                                </div>
                                <div className="bs-bs-frm-ip">
                                    <div className="ttl-ip">Contact Person</div>
                                    <input placeholder="Contact Person" 
                                        value={this.state.contactPerson}
                                        onChange={(e) => this.setState({contactPerson: e.target.value})}
                                        className="frm-input" 
                                        isMandatory="true"
                                        type="text"/>
                                </div>
                            </div>

                            <div className="bs-frm-ip" style={{textAlign: "center"}}>
                                
                                {/* <div className="bs-bs-frm-ip">
                                    <div className="ttl-ip">Address RS / Clinic</div>
                                    <input placeholder="Address RS / Clinic" 
                                        value={this.state.addressRs}
                                        onChange={(e) => this.setState({addressRs: e.target.value})}
                                        className="frm-input" type="text"/>
                                </div>
                                <div className="bs-bs-frm-ip">
                                    <div className="ttl-ip">Contact Person</div>
                                    <input placeholder="Contact Person" 
                                        value={this.state.contactPerson}
                                        onChange={(e) => this.setState({contactPerson: e.target.value})}
                                        className="frm-input" type="text"/>
                                </div> */}
                            </div>

                            <div className="bs-frm-ip" style={{borderTop: "1px solid #ededed", borderBottom: "1px solid #ededed"}}>
                                <div id="bs-pick-act">
                                {/* <div style={{paddingLeft: "20px"}}>
                                    <Calendar className="rpt-calendar"/>
                                </div> */}
                                    <div style={{padding: "20px"}}>
                                        <div className="ttl-ip">
                                            Start Activity
                                            {
                                                (this.props.id == "form")
                                                ?
                                                    <select
                                                        value={this.state.typeDate}
                                                        onChange={(e) => this.setState({typeDate: e.target.value})}
                                                        style={{borderRadius: "5px", 
                                                                padding: "5px", 
                                                                border: "1px solid #CCC", 
                                                                marginLeft: "20px"}}
                                                    >
                                                        <option value={"single"}>Single Date</option>
                                                        <option value={"multiple"}>Multiple Date</option>
                                                    </select>
                                                :
                                                    ""
                                            }
                                            
                                        </div>
                                            {
                                                (this.props.id == "form")
                                                ?
                                                    (this.state.typeDate == "single")
                                                    ?
                                                        //single date input start here
                                                        this.state.start.map((dt, index) => 
                                                        {
                                                            let lengthStart = this.state.start.length;
                                                            let seq = parseInt(index) + 1;
                                                            let isReadyDate = false
                                                            /*validasi start activity*/
                                                            /*reset date aggregator in first looping*/
                                                            if(index == 0){
                                                                this.state.dateAgg = []
                                                            }

                                                            let d = dt.date
                                                            let m = dt.month
                                                            let y = dt.year
                                                            
                                                            let idx = this.state.dateAgg.indexOf(d+m+y)
                                                            if(idx == -1){
                                                                this.state.dateAgg.push(d+m+y)
                                                            }else{
                                                                isReadyDate = true
                                                            }

                                                            return <div id="wrp-dt-dt-0923">
                                                                        <div id="dt-dt-0923" style={{display: "flex"}}>
                                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                <KeyboardDatePicker style={{width: "300px", color: "#F00 !importtant"}}
                                                                                    margin="normal"
                                                                                    id="date-picker"
                                                                                    label="Date"
                                                                                    // format="MM/dd/yyyy"
                                                                                    format="MMM dd, yyyy"
                                                                                    inputVariant="outlined"
                                                                                    value={new Date(dt.year+"-"+dt.month+"-"+dt.date)}
                                                                                    onChange={(date) => this.handleDateChange(date, index)}
                                                                                />
                                                                                &nbsp;
                                                                                <TimePicker
                                                                                    id="time-picker"
                                                                                    clearable
                                                                                    ampm={false}
                                                                                    label="Time"
                                                                                    inputVariant="outlined"
                                                                                    margin="normal"
                                                                                    value={new Date(dt.year+"-"+dt.month+"-"+dt.date+"T"+dt.startTime)}
                                                                                    onChange={(date) => this.handleStartTimeChange(date, index)}
                                                                                />
                                                                            </MuiPickersUtilsProvider>
                                                                            {
                                                                                (lengthStart == seq)
                                                                                ? 
                                                                                    <a onClick={this.addStartActivity}>
                                                                                        <div style={{marginLeft: "15px"}}>
                                                                                            <img 
                                                                                                src={AddIcon} 
                                                                                                style={{width: "24px", opacity: "0.4", marginTop: "30px"}}
                                                                                            />
                                                                                        </div>
                                                                                    </a>
                                                                                : 
                                                                                    <a onClick={() => this.removeStartAcitivity(index)} style={{marginTop: "8px"}}>
                                                                                        <div style={{marginLeft: "15px"}}>
                                                                                            <img src={MinusIcon}
                                                                                                style={{width: "24px", opacity: "0.6", marginTop: "25px"}}
                                                                                            />
                                                                                        </div>
                                                                                    </a>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            (isReadyDate)
                                                                            ?   <div style={{color: "#F00", fontSize: "13px", marginTop: "-5px", marginBottom: "5px"}}>
                                                                                    This date have been set on previous input
                                                                                </div>
                                                                            :   ""
                                                                        }
                                                                    </div>
                                                        }) //single date input finish here
                                                    : 
                                                        //multiple date input start here
                                                        <div id="dt-dt-0923" 
                                                            style={{display: "flex", 
                                                                    justifyContent: "space-between"}}
                                                        >
                                                            <div>
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                    <KeyboardDatePicker style={{width: "300px"}}
                                                                        margin="normal"
                                                                        id="date-picker"
                                                                        label="Start Date"
                                                                        // format="MM/dd/yyyy"
                                                                        onChange={(date) => this.handleDateChangeMultiple(date)}
                                                                        format="MMM dd, yyyy"
                                                                        inputVariant="outlined"
                                                                        value={this.state.startDate}
                                                                    />
                                                                    &nbsp;
                                                                    <TimePicker
                                                                        id="time-picker"
                                                                        clearable
                                                                        ampm={false}
                                                                        label="Start Time"
                                                                        inputVariant="outlined"
                                                                        margin="normal"
                                                                        value={this.state.startDate}
                                                                        onChange={(date) => this.handleDateChangeMultiple(date)}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </div>
                                                            <div style={{marginLeft: "50px"}}>
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                    <KeyboardDatePicker style={{width: "300px"}}
                                                                        margin="normal"
                                                                        id="date-picker"
                                                                        label="Finish Date"
                                                                        // format="MM/dd/yyyy"
                                                                        format="MMM dd, yyyy"
                                                                        inputVariant="outlined"
                                                                        onChange={(date) => this.handleEndDateChangeMultiple(date)}
                                                                        value={this.state.endDate}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </div>
                                                        </div>
                                                        //multiple date finish here
                                                :
                                                    //<--read only start acvitivity
                                                    <>
                                                    <>
                                                    {
                                                        this.state.applicantSchedules.map((schedule, index) => {
                                                            let userApplicant = schedule.userApplicant
                                                            let userProfile = (userApplicant) ? userApplicant.userProfile : null
                                                            let dt = this.state.start[index] /*this array created in setValue() function*/
                                                            return  <div id="tm-dt-0923-ro" style={{display: "flex"}}>
                                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                            <KeyboardDatePicker style={{width: "300px"}}
                                                                                margin="normal"
                                                                                id="date-picker"
                                                                                disabled
                                                                                label="Date"
                                                                                // format="MM/dd/yyyy"
                                                                                format="MMM dd, yyyy"
                                                                                inputVariant="outlined"
                                                                                value={new Date(dt.year+"-"+dt.month+"-"+dt.date)}
                                                                            />
                                                                            &nbsp;
                                                                            <TimePicker
                                                                                id="time-picker"
                                                                                clearable
                                                                                ampm={false}
                                                                                disabled
                                                                                label="Time"
                                                                                inputVariant="outlined"
                                                                                margin="normal"
                                                                                value={new Date(dt.year+"-"+dt.month+"-"+dt.date+" "+dt.startTime)}
                                                                            />
                                                                        </MuiPickersUtilsProvider>
                                                                        {
                                                                            (userProfile)
                                                                            ?
                                                                                <div style={{display: "flex", alignItems: "center", marginTop: "8px", marginLeft: "20px"}}>
                                                                                    <img src={People} style={{width: "35px", marginRight: "10px"}}/>
                                                                                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                                                                                        <div style={{fontSize: "14px", fontWeight: "bold"}}>{userProfile.name}</div>
                                                                                        <div style={{fontSize: "12px", fontWeight: "bold", color: "#767676"}}>{userProfile.email}</div>
                                                                                    </div>
                                                                                </div>
                                                                            :   ""
                                                                        }
                                                                    </div>
                                                        })
                                                    }
                                                    </>

                                                    <>
                                                    {
                                                        this.state.applicantSchedulesDenied.map((schedule, index) => {
                                                            let userApplicant = schedule.userApplicant
                                                            let userProfile = userApplicant.userProfile
                                                            let dt = this.state.startDateDenied[index] /*this array created in setValue() function*/
                                                            
                                                            return  <div id="tm-dt-0923-ro" style={{display: "flex"}}>
                                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                            <KeyboardDatePicker style={{width: "300px", background: "#f1f1f1", borderRadius: "5px"}}
                                                                                margin="normal"
                                                                                id="date-picker"
                                                                                disabled
                                                                                label="Date"
                                                                                // format="MM/dd/yyyy"
                                                                                format="MMM dd, yyyy"
                                                                                inputVariant="outlined"
                                                                                value={new Date(dt.year+"-"+dt.month+"-"+dt.date)}
                                                                            />
                                                                            &nbsp;
                                                                            <TimePicker style={{background: "#f1f1f1", borderRadius: "5px"}}
                                                                                id="time-picker"
                                                                                clearable
                                                                                ampm={false}
                                                                                disabled
                                                                                label="Time"
                                                                                inputVariant="outlined"
                                                                                margin="normal"
                                                                                value={new Date(dt.year+"-"+dt.month+"-"+dt.date+" "+dt.startTime)}
                                                                            />
                                                                        </MuiPickersUtilsProvider>
                                                                        <div style={{display: "flex", alignItems: "center", marginTop: "8px", marginLeft: "20px"}}>
                                                                            <img src={People} style={{width: "35px", marginRight: "10px"}}/>
                                                                            <img src={DenyIcon} style={{width: "20px", position: "absolute", marginTop: "25px"}}/>
                                                                            <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                                                                                <div style={{fontSize: "14px", fontWeight: "bold"}}>{userProfile.name}</div>
                                                                                <div style={{fontSize: "12px", fontWeight: "bold", color: "#767676"}}>{userProfile.email}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div style={{display: "flex", alignItems: "center", marginLeft: "20px"}}>
                                                                            <img src={info} style={{width: "15px", position: "absolute", marginTop: "-25px", marginLeft: "-10px"}}/>
                                                                            <div style={{display: "flex", 
                                                                                        alignItems: "center", 
                                                                                        border: "1px solid #CCC", 
                                                                                        height: "40px", 
                                                                                        borderRadius: "5px", 
                                                                                        padding: "5px"}}>
                                                                                <div style={{fontSize: "12px", fontWeight: "bold", color: "#000"}}>
                                                                                    {schedule.confirmReason}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                        })
                                                    }
                                                    </>
                                                    </>
                                                    //read only start acvitivity-->
                                            }
                                    </div>
                                    {/* <div style={{padding: "20px"}}>
                                        <div className="ttl-ip">Finished Activity</div>
                                        <div style={{display: "flex"}}>
                                            {
                                                (!this.isManager() && this.props.id == "form")
                                                ?
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker style={{width: "300px"}}
                                                            margin="normal"
                                                            id="date-picker"
                                                            label="Date"
                                                            // format="MM/dd/yyyy"
                                                            format="MMM dd, yyyy"
                                                            inputVariant="outlined"
                                                            value={this.state.finish}
                                                            onChange={this.handleFinishDateChange}
                                                        />
                                                        &nbsp;
                                                        <TimePicker
                                                            id="time-picker"
                                                            clearable
                                                            ampm={false}
                                                            label="Time"
                                                            inputVariant="outlined"
                                                            margin="normal"
                                                            value={this.state.finishTime}
                                                            onChange={this.handleFinishTimeChange}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                :
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker style={{width: "300px"}}
                                                            margin="normal"
                                                            id="date-picker"
                                                            disabled="false"
                                                            label="Date"
                                                            disabled
                                                            // format="MM/dd/yyyy"
                                                            format="MMM dd, yyyy"
                                                            inputVariant="outlined"
                                                            value={this.state.finish}
                                                        />
                                                        &nbsp;
                                                        <TimePicker
                                                            id="time-picker"
                                                            clearable
                                                            ampm={false}
                                                            disabled
                                                            label="Time"
                                                            inputVariant="outlined"
                                                            margin="normal"
                                                            value={this.state.finishTime}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                            }
                                        </div>
                                    </div> */}
                                </div>
                                {/* <div style={{paddingLeft: "10px"}}>
                                    <Calendar className="rpt-calendar" disabled/>
                                </div> */}
                            </div>
                            <div className="bs-frm-ip">
                                <div className="bs-bs-frm-ip" style={{width: "100%"}}>
                                    <div className="ttl-ip">Kind of Activity</div>
                                    <div style={{display: "flex", justifyContent: "space-between"}}>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            {
                                                (this.state.kindAct == "demo")
                                                ? <a style={{marginTop: "10px"}}>{check}</a>
                                                : <a onClick={() => this.selectActivity("demo")} style={{marginTop: "10px"}}>{radio}</a>
                                            }
                                            &nbsp;&nbsp;<span>Demo</span>
                                        </div>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            {
                                                (this.state.kindAct == "training")
                                                ? <a style={{marginTop: "10px"}}>{check}</a>
                                                : <a onClick={() => this.selectActivity("training")} style={{marginTop: "10px"}}>{radio}</a>
                                            }
                                            &nbsp;&nbsp;<span>Training</span>
                                        </div>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            {
                                                (this.state.kindAct == "others")
                                                ? <a style={{marginTop: "10px"}}>{check}</a>
                                                : <a onClick={() => this.selectActivity("others")} style={{marginTop: "10px"}}>{radio}</a>
                                            }
                                            &nbsp;&nbsp;<span>Others</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bs-frm-ip">
                                <div className="bs-bs-frm-ip">
                                    <div className="ttl-ip">Describe your others</div>
                                    <input placeholder="Describe your others" 
                                        value={this.state.describe}
                                        onChange={(e) => this.setState({describe: e.target.value})}
                                        className="frm-input"
                                        type="text"/>
                                </div>
                                <div className="bs-bs-frm-ip">
                                    <div className="ttl-ip">Upload proof of ACC</div>
                                    {
                                        (this.props.id == "form")
                                        ?
                                            <a onClick={this.selectFile}>
                                                <div placeholder="Upload proof of ACC" 
                                                    id="ipt-upl-proof"
                                                    className="frm-input" 
                                                    style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}
                                                >
                                                    <div>
                                                    {
                                                        (this.state.fileName == "") 
                                                        ? 
                                                            <span style={{fontSize: "14px", color: "rgb(132 132 132)"}}>Select File</span>
                                                        : 
                                                            (this.state.successUpload)
                                                            ?
                                                                <div style={{display: "flex", alignItems: "center", fontSize: "12px"}}>
                                                                    <img src={GreenCheks} style={{width: "15px"}}/>&nbsp;&nbsp;&nbsp;
                                                                    <div style={{lineHeight: "1.2"}}>
                                                                        {this.state.fileName}
                                                                        <div style={{fontSize: "10px", color: "green", fontWeight: "bold"}}>upload successfully</div>
                                                                    </div>
                                                                </div>
                                                            :
                                                                <div style={{display: "flex", alignItems: "center", marginLeft: "-10px"}}>
                                                                    <img src={Spinner} style={{width: "40px"}}/>
                                                                    <div>
                                                                        <div>{this.state.fileName}</div>
                                                                        <BarLoadUpload id="br-ld-upl">
                                                                            <PercentUploaded ref={this.barPercentUpload}/>
                                                                        </BarLoadUpload>
                                                                    </div>
                                                                </div>
                                                    }
                                                    </div>
                                                    {paper}
                                                </div>
                                            </a>
                                        :
                                            (this.state.fileName != "")
                                            ?
                                                <a onClick={this.downloadFile}>
                                                    <div placeholder="Upload proof of ACC" 
                                                        id="ipt-upl-proof-ulded"
                                                        className="frm-input" 
                                                        style={{display: "flex", border: "1px solid #CCC", justifyContent: "space-between", alignItems: "center"}}
                                                    >
                                                        <div id='usb-frm-fl' style={{display: "flex", alignItems: "center", fontSize: "12px"}}>
                                                            {paper}&nbsp;&nbsp;
                                                            <div style={{lineHeight: "1.2", fontWeight: "bold"}}>
                                                                {this.state.fileName}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        (this.state.isDownloadProof)
                                                        ?
                                                            <div id="dbf-uled" 
                                                                style={{display: "flex", 
                                                                        alignItems: "center", 
                                                                        position: "absolute", 
                                                                        marginLeft: "15px"}}
                                                            >
                                                                <img src={Spinner} style={{width: "25px"}}/>
                                                                <div style={{fontSize: "12px", marginLeft: "0px", color: "#CCC", fontWeight: "bold"}}>downloading</div>
                                                            </div>
                                                        :
                                                            ""
                                                    }
                                                </a>
                                            :
                                                <div placeholder="Upload proof of ACC" 
                                                    id="ipt-upl-proof-ni-"
                                                    className="frm-input" 
                                                    style={{display: "flex", border: "1px solid #CCC", justifyContent: "space-between", alignItems: "center"}}
                                                >
                                                    <div id='usb-frm-fl' style={{display: "flex", alignItems: "center", fontSize: "12px"}}>
                                                        {paper}&nbsp;&nbsp;
                                                        <div style={{lineHeight: "1.2", fontWeight: "bold", color: "#CCC"}}>
                                                            No file uploded
                                                        </div>
                                                    </div>
                                                </div> 
                                    }
                                    <input type="file" ref={this.inputFile} onChange={this.selectFileInput} style={{display: "none"}}/>
                                </div>
                            </div>
                            <div className="bs-frm-ip">
                                <div className="bs-bs-frm-ip">
                                    <div className="ttl-ip">Type of Tools</div>
                                    <select placeholder="Type of Tools" 
                                        value={this.state.typeTools} 
                                        onChange={(e) => this.setState({typeTools: e.target.value})}
                                        className="frm-input" 
                                        isMandatory="true"
                                        type="text"
                                    >
                                        {
                                            dropDownTypeOfTools.map(dt => {
                                                return <option value={dt}>{dt}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="bs-bs-frm-ip">
                                    <div className="ttl-ip">Include Tricefy</div>
                                    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            {
                                                (this.state.isTricefy == "yes")
                                                ? <a style={{marginTop: "10px"}}>{check}</a>
                                                : <a onClick={() => this.selectTricefy("yes")} style={{marginTop: "10px"}}>{radio}</a>
                                            }
                                            &nbsp;&nbsp;<span style={{color: "#333333", fontSize: "18px"}}>Yes</span>
                                        </div>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            {
                                                (this.state.isTricefy == "no")
                                                ? <a style={{marginTop: "10px"}}>{check}</a>
                                                : <a onClick={() => this.selectTricefy("no")} style={{marginTop: "10px"}}>{radio}</a>
                                            }
                                            &nbsp;&nbsp;<span style={{color: "#333333", fontSize: "18px"}}>No</span>
                                        </div>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            {
                                                (this.state.isTricefy == "others")
                                                ? <a style={{marginTop: "10px"}}>{check}</a>
                                                : <a onClick={() => this.selectTricefy("others")} style={{marginTop: "10px"}}>{radio}</a>
                                            }
                                            &nbsp;&nbsp;<span style={{color: "#333333", fontSize: "18px"}}>Others</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div id="rgn-bs-ipt" className="bs-frm-ip">
                                <div className="bs-bs-frm-ip">
                                     <div className="ttl-ip">Region</div>
                                        <select placeholder="Type of Tools"
                                            value={this.state.regionId} 
                                            onChange={(e) => this.setState({regionId: e.target.value})}
                                            className="frm-input" type="text"
                                        >
                                            <option value="" style={{display: "none"}}>Select Region</option>
                                            {this.props.region.map(dt => {
                                                return <option value={dt.id}>{dt.name}</option>
                                            })}
                                        </select>
                                </div>
                                <div className="bs-bs-frm-ip">
                                    <div className="ttl-ip">Demo Terms</div>
                                    <select placeholder="Demo Terms" 
                                        value={this.state.demo}
                                        onChange={(e) => this.setState({demo: e.target.value})}
                                        className="frm-input" type="text"
                                    >
                                        {
                                            dropDownDemoTerms.map(dt => {
                                                return <option value={dt}>{dt}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div> */}

                            <div className="bs-frm-ip">
                                {/* <div className="bs-bs-frm-ip">
                                    <div className="ttl-ip">Training Terms</div>
                                    <select placeholder="Training terms" 
                                        value={this.state.training}
                                        onChange={(e) => this.setState({training: e.target.value})}
                                        className="frm-input" type="text">
                                        {
                                            dropDownDemoTerms.map(dt => {
                                                return <option value={dt}>{dt}</option>
                                            })
                                        }
                                    </select> */}
                                {/* </div> */}
                                {/* <div className="bs-bs-frm-ip"> */}
                                    {/* <div className="ttl-ip">Region</div>
                                    <select placeholder="Type of Tools"
                                        value={this.state.regionId} 
                                        onChange={(e) => this.setState({regionId: e.target.value})}
                                        className="frm-input" type="text"
                                    >
                                        <option value="" style={{display: "none"}}>Select Region</option>
                                        {this.props.region.map(dt => {
                                            return <option value={dt.id}>{dt.name}</option>
                                        })}
                                    </select> */}
                                {/* </div> */}
                            </div>
                            {/* {
                                (this.props.id != "form")
                                ?
                                    <div className="bs-bs-frm-ip">
                                        <div className="ttl-ip">Applicant</div>
                                        <select placeholder="Type of Tools"
                                            id="apl-ipt"
                                            value={this.state.employeeId} 
                                            onChange={(e) => this.setState({employeeId: e.target.value})}
                                            className="frm-input" type="text"
                                        >
                                            <option value={""} style={{display: "none"}}>{"Select applicant"}</option>
                                            {this.props.employeesAppl.map(dt => {
                                                console.log(dt)
                                                let profile = dt.user.profile
                                                let fn = profile.firstName
                                                let ln = profile.lastName
                                                let full = fn+" "+ln
                                                return <option value={dt.id}>{full}</option>
                                            })}
                                        </select>
                                    </div>
                                : 
                                    ""
                            } */}
                            <div className="bs-bs-frm-ip">
                                {/* <div className="ttl-ip">Include Tricefy</div>
                                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        {
                                            (this.state.isTricefy == "yes")
                                            ? <a style={{marginTop: "10px"}}>{check}</a>
                                            : <a onClick={() => this.selectTricefy("yes")} style={{marginTop: "10px"}}>{radio}</a>
                                        }
                                        &nbsp;&nbsp;<span style={{color: "#333333", fontSize: "18px"}}>Yes</span>
                                    </div>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        {
                                            (this.state.isTricefy == "no")
                                            ? <a style={{marginTop: "10px"}}>{check}</a>
                                            : <a onClick={() => this.selectTricefy("no")} style={{marginTop: "10px"}}>{radio}</a>
                                        }
                                        &nbsp;&nbsp;<span style={{color: "#333333", fontSize: "18px"}}>No</span>
                                    </div>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        {
                                            (this.state.isTricefy == "others")
                                            ? <a style={{marginTop: "10px"}}>{check}</a>
                                            : <a onClick={() => this.selectTricefy("others")} style={{marginTop: "10px"}}>{radio}</a>
                                        }
                                        &nbsp;&nbsp;<span style={{color: "#333333", fontSize: "18px"}}>Others</span>
                                    </div>
                                </div> */}
                            </div>

                            <div className="bs-frm-ip">
                                <div className="bs-bs-frm-ip" style={{width: "100%"}}>
                                    <div className="ttl-ip">Additional Accessories</div>
                                    <div>
                                        <textarea placeholder="Additional Accessories..." 
                                                value={this.state.additionalAcc} 
                                                onChange={(e) => this.setState({additionalAcc: e.target.value})}
                                                className="frm-input textarea-frm-input" 
                                                isMandatory="true"
                                                style={{height: "130px"}}></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="bs-btn">
                                {
                                    (!this.props.inProcess)
                                    ? 
                                        (this.props.id == 'form')
                                        ?   <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                                                <div style={{fontSize: "14px", width: "400px", display: "flex", alignItems: "center", marginRight: "10px"}}>
                                                    <img src={InfoRed} style={{width: "25px", height: "25px", marginLeft: "10px", marginRight: "10px"}}/>
                                                    <div style={{color: "#AF0A0A", fontWeight: "bold", textAlign: "justify"}}>Sales Jabodetabek tidak dapat / diperkenankan untuk memilih rumah sakit sumatera</div>
                                                </div>
                                                <button className="btn-sv" onClick={this.save}>Save</button>
                                            </div>
                                        : 
                                            (this.isManager())
                                            ? <button className="btn-sv" onClick={this.setEmployee}>Save</button>
                                            : ""
                                    : <button className="btn-sv" style={{opacity: "0.7"}}>Please wait..</button>
                                }
                            </div>
                        </FormBase>
                    </div>
                </div>
                {/* </BaseMain> */}
            </Base>
        )
    }
}

const styles = {
    inptSeactHsp : {
        padding: "5px", borderRadius: "3px", fontSize: "10px", width: "150px", border: "none", outline: "none"
    }
}

const radio = <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="13" cy="13" r="12.5" fill="white" fill-opacity="0.06" stroke="#08A0F7"/>
            </svg>

const check = <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="13" cy="13" r="12.5" fill="#08A0F7" stroke="#08A0F7"/>
                <path d="M9.37501 13.4578L12.062 16.1448L17.436 10.7707" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

const paper = <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="20">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.000244141 0.0117188H17.0529V19.8652H0.000244141V0.0117188Z" fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.57335 1.51172C2.91635 1.51172 1.54035 2.85372 1.50135 4.50872V15.2037C1.46435 16.9167 2.81435 18.3277 4.51035 18.3657H12.5743C14.2433 18.2967 15.5653 16.9097 15.5533 15.2097V6.33972L10.9183 1.51172H4.58535H4.57335ZM4.58535 19.8657H4.47635C1.95435 19.8087 -0.0536524 17.7107 0.00134757 15.1877V4.49072C0.0593476 2.00972 2.10835 0.0117188 4.57135 0.0117188H4.58835H11.2383C11.4423 0.0117188 11.6373 0.0947188 11.7793 0.241719L16.8443 5.51872C16.9783 5.65772 17.0533 5.84472 17.0533 6.03772V15.2037C17.0713 17.7127 15.1173 19.7627 12.6043 19.8647L4.58535 19.8657Z" fill="#949494"/>
                </g>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2985 6.98449H13.5435C11.7135 6.97949 10.2255 5.48749 10.2255 3.65949V0.750488C10.2255 0.336488 10.5615 0.000488281 10.9755 0.000488281C11.3895 0.000488281 11.7255 0.336488 11.7255 0.750488V3.65949C11.7255 4.66349 12.5425 5.48149 13.5455 5.48449H16.2985C16.7125 5.48449 17.0485 5.82049 17.0485 6.23449C17.0485 6.64849 16.7125 6.98449 16.2985 6.98449Z" fill="#949494"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7887 14.1084H5.38867C4.97467 14.1084 4.63867 13.7724 4.63867 13.3584C4.63867 12.9444 4.97467 12.6084 5.38867 12.6084H10.7887C11.2027 12.6084 11.5387 12.9444 11.5387 13.3584C11.5387 13.7724 11.2027 14.1084 10.7887 14.1084Z" fill="#949494"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.7437 10.3564H5.3877C4.9737 10.3564 4.6377 10.0204 4.6377 9.60645C4.6377 9.19245 4.9737 8.85645 5.3877 8.85645H8.7437C9.1577 8.85645 9.4937 9.19245 9.4937 9.60645C9.4937 10.0204 9.1577 10.3564 8.7437 10.3564Z" fill="#949494"/>
            </svg>

export default user_sales_web