import React, { Component } from 'react'
import RedInfo from '../../images/info-red.png'
import ApproveIcon from '../../images/quality.png'
import EmailService from '../../service/email_service'
import Loader from '../../images/Pulse-1s-51px.gif'

class approval extends Component {

    constructor(){
        super()
        this.state = {
            message: "",
            success: true,
            data : [],
            isProcess: true
        }
    }

    componentDidMount(){
        let confirmId = this.props.match.params.id
        this.fetch(confirmId)
    }

    fetch = async (id) => {
        let serv = new EmailService()
        let resp = await serv.approveSchedules(id)
        
        if(resp.status == 400){
            let msg = resp.data.message
            this.setState({
                message: msg,
                success: false,
                isProcess: false
            })
        }else{
            let msg = resp.data.message
            let data = resp.data.data.schedule
            this.setState({
                message: msg,
                success: true,
                data : data,
                isProcess: false
            })
        }
    }

    render(){
        return(
            <div style={{marginTop: "50px", display: "flex", alignItems: "center", flexDirection: "column"}}>
                {
                    (this.state.isProcess)
                    ? 
                        <div style={{textAlign: "center"}}>
                            <img src={Loader} style={{width: "40px"}}/>
                            <div style={{fontSize: "12px", color: "#767676", fontWeight: "bold"}}>Loading..</div>
                        </div>
                    :
                        (this.state.success)
                        ?
                            <>
                            <img src={ApproveIcon} style={{width: "80px"}}/>
                            <div style={{fontWeight: "bold", fontSize: "20px", textAlign: "center", marginTop: "10px"}}>
                                <div>Approval succesfully</div>
                                <div style={{marginTop: "10px"}}>
                                    {/* <div style={{fontSize: "14px"}}>Applicants</div> */}
                                    {
                                        this.state.data.map(text => {
                                            return  <div style={{padding: "5px", 
                                                                fontSize: "12px", 
                                                                border: "1px solid #CCC", 
                                                                maxWidth: "300px", 
                                                                margin: "auto",
                                                                width: "80%",
                                                                background: "#FFF",
                                                                borderRadius: "4px", 
                                                                color: "#767676"}}>
                                                        {text}
                                                    </div>
                                        })
                                    }
                                
                                </div>
                            </div>
                            <a href='/dashboard'>
                                <button className='btn-primary' style={{padding: "10px", borderRadius: "5px", marginTop: "40px"}}>View Dashboard</button>
                            </a>
                            </>
                        :
                            <>
                            <img src={RedInfo} style={{width: "80px", opacity: "0.7"}}/>
                            <div style={{fontWeight: "bold", fontSize: "20px", textAlign: "center", marginTop: "10px", width: "200px"}}>
                                <div>{this.state.message}</div>
                            </div>
                            <a href='/dashboard'>
                                <button className='btn-primary' style={{padding: "10px", borderRadius: "5px", marginTop: "40px"}}>View Dashboard</button>
                            </a>
                            </>
                }
                
            </div>
        )
    }
}

export default approval