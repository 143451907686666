import React from 'react'
import Styled from 'styled-components'
import ApprIcon from '../../../images/quality.png'
import DenyIcon from '../../../images/multiply.png'
import DoneIcon from '../../../images/check-blue.png'
import SickIcon from '../../../images/medicine.png'
import FilterAtv from '../../../images/filter-atv.png'
import { useDispatch } from 'react-redux'
import { filterReport, filterStatus } from '../../../redux/action'

const Base = Styled.div`
    background: rgba(0,0,0, 0.4);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
`

const FilterMobile = (props) => {

    // let dataUserSales = useSelector((state) => state.dataUserSales)
    let dispatch = useDispatch();

    const selectFilter = (e, name) => {
        dispatch(filterReport(name))
        props.hideFilter()
    }
    
    const hidePopup = (e) => {
        let elm = document.getElementById("flt-mbl-tch-xckz")
        if(elm && !elm.contains(e.target)){
            props.hideFilter()
        }
    }

    return(
        <Base onClick={hidePopup}>
            <div id="flt-mbl-tch-xckz" style={{background: "#FFF", width: "200px"}}>
                <div style={{display: "flex", 
                            alignItems: "center", 
                            padding: "10px 20px", 
                            borderBottom: "1px solid #e5e5e5", 
                            background: "#f5f5f5"}}
                >
                    <img src={FilterAtv} style={{width: "14px"}}/>
                    &nbsp;
                    <div style={{fontWeight: "bold", fontSize: "14px"}}>Filter Status</div>
                </div>
                <div onClick={(e) => selectFilter(e, "")} 
                    className="menu-hvr-item-item" 
                    style={{padding: "10px 20px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                >
                    <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px", color: "#868686"}}>All Data</div>
                </div>
                <div onClick={(e) => selectFilter(e, "pending")} 
                    className="menu-hvr-item-item" 
                    style={{padding: "10px 20px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                >
                    {/* <img src={ApprIcon} style={{width: "14px"}}/> */}
                    <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>Pending</div>
                </div>
                
                <div onClick={(e) => selectFilter(e, "on progress")} 
                    className="menu-hvr-item-item" 
                    style={{padding: "10px 20px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                >
                    {/* <img src={DoneIcon} style={{width: "14px"}}/> */}
                    <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>On Progress</div>
                </div>

                <div onClick={(e) => selectFilter(e, "rejected")} 
                    className="menu-hvr-item-item" 
                    style={{padding: "10px 20px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                >
                    {/* <img src={DenyIcon} style={{width: "14px"}}/> */}
                    <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>Rejected</div>
                </div>

                <div onClick={(e) => selectFilter(e, "finished")} 
                    className="menu-hvr-item-item" 
                    style={{padding: "10px 20px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                >
                    {/* <img src={SickIcon} style={{width: "14px"}}/> */}
                    <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>Finished</div>
                </div>
            </div>
        </Base>
    )
}

export default FilterMobile