import React, { Component } from 'react'
import Styled from 'styled-components'
import LoaderGif from '../images/Spinner-0.7s-200px.gif'

const Base = Styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10000;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.5);
    left: 0;
`

let itv = null

class preview_image extends Component{

    componentDidMount(){
        this.loadImage()
    }

    componentWillUnmount(){
        clearInterval(itv)
    }

    loadImage = () => {
        itv = setInterval(() => {
            let elm = document.getElementById("pv-img-fl")
            let w = elm.offsetWidth
            if(w > 0){
                let elm1 = document.getElementById("ldr-img-zxr")
                elm1.style.display = "none"
                elm.style.opacity = 1
            }
            // console.log(w)
        }, 1)
    }

    imgClick = (e) => {
        e.stopPropagation()
    }

    render(){
        return(
            <Base onClick={this.props.hideImage}>
                <img id="ldr-img-zxr" src={LoaderGif} style={{width: "50px", position: "absolute"}}/>
                <img id="pv-img-fl" onClick={this.imgClick} 
                    src={this.props.url} 
                    style={{maxWidth: "90%", maxHeight: "90%", borderRadius: "4px", opacity: "0"}}/>
            </Base>
        )
    }
}

export default preview_image