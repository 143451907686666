import React, { Component } from 'react'
import { connect } from 'react-redux'
import Styled from 'styled-components'
import { setAvailable } from '../../redux/action'
import { getFullName, getAvatar, getRoleName } from "../function/read_token"
import Availability from '../availability'
import axios from 'axios'

const Header = Styled.div`
    height: 80px;
    background: #FFF;
    display: flex;
    align-items: center;
    padding: 0px 40px;
    border-bottom: 1px solid rgba(31, 71, 136, 0.2);
`

const BarAvailable = Styled.div`
    background: #CCC;
    width: 40px;
    height: 20px;
    border-radius: 20px;
    cursor: pointer;
    padding: 2px;
`

const CircleAvailable = Styled.div`
    width: 18px;
    height: 18px;
    background: #000;
    border-radius: 100%;
`

class header extends Component{

    constructor(){
        super()
        this.state = {
            available: true,
            popup: ""
        }
    }
    
    setNotAvailability = () => {
        this.setState({
            popup: <Availability setAvailable={false} cancel={() => this.setState({popup: ""})}/>
        })
    }

    setAvailable = async () => {
        this.setState({
            popup: <Availability setAvailable={true} cancel={() => this.setState({popup: ""})}/>
        })
    }

    render(){
        return(
            <>
            <Header id="header-byd">
                <div style={styles.pic}>
                    <img src={getAvatar()} style={{width: "100%", height: "100%", objectFit: "contain"}}/>
                </div>
                <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                    <span style={styles.txtName}>
                        {getFullName()}
                    </span>
                    <div style={{display: "flex", alignItems: "center"}}>
                        {
                             (getRoleName().toLowerCase() == "applicant")
                             ?
                                (this.props.isAvailable)
                                ?
                                    <>
                                    <a onClick={this.setNotAvailability}>
                                        <BarAvailable style={{display: "flex", justifyContent: "end", background: "blue"}}>
                                            <CircleAvailable style={{background: "#FFF", border: "1px solid blue"}}/>
                                        </BarAvailable>
                                    </a>&nbsp;&nbsp;<span style={{fontWeight: "bold"}}>Available</span>
                                    </>
                                :
                                    <>
                                    <a onClick={this.setAvailable}>
                                        <BarAvailable style={{display: "flex", justifyContent: "start"}}>
                                            <CircleAvailable style={{border: "1px solid #CCC"}}/>
                                        </BarAvailable>
                                    </a>&nbsp;&nbsp;<span style={{fontWeight: "bold", color: "#8f8f8f"}}>Available</span>
                                    </>
                            :   ""
                        }
                    </div>
                </div>
            </Header>
            {this.state.popup}
            </>
        )
    }
}

const styles = {
    txtName : {
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "27px",      
        color: "#1F4788"
    },
    pic: {
        height: "40px",
        width: "40px",
        position: "relative",
        borderRadius: "100%",
        background: "#FFF",
        border: "1px solid #CCC",
        overflow: "hidden",
        marginRight: "16px"
    }
}

const mapStateToProps = state => {
    return{
        isAvailable: state.isAvailable
    }
}

const mapDispatchToProps = dispatch => {
    return{
        setAvailable: () => dispatch(setAvailable())
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (header)