import {searchRreportByNameAction, 
        setDataReportAction, 
        setDataUserAction, 
        setDataUserSalesAction,
        setPageDataUserSalesAction, 
        setPageDataUserAction,
        searcDataUserAction,
        setPageReportAcrtion,
        setLoadedFlsUser,
        searchDataUserSalesByNameAction,
        filterReportAction,
        setAvailableAction,
        setDataApplicantAction,
        setPageDataApplicantAction,
        setDataStatusApplicantAction,
        searchRsApplicantAction,
        filterStatusAction,
        filterStatusApplicantAction,
        setSortReportAction,
        setDataCalendarAction} from "./action_type"
        
const initState = {
    dataReport: {
        data:[],
        isLoaded: false,
        pageReport: 1,
        total: 0,
        searchName: "",
        filter:"",
        sortBy:"",
        sortType: "",
    },
    dataUserSales: {
        data:[],
        filterStatus: {id:"", name: ""},
        isLoaded: false,
        page: 1,
        total: 0,
        searchName: ""
    },
    dataApplicant: {
        data:[],
        filterStatus: {id:"", name: ""},
        isLoaded: false,
        page: 1,
        total: 0,
        searchName: ""
    },
    dataUser: {
        data: [],
        isLoaded: false,
        page: 1,
        total: 0,
        searchName: ""
    },
    isAvailable: true,
    status: "",
    dataCalendar: []
}

const reducer = (state = initState, action) => {
    if(action.type == setDataReportAction){
        let data = state.dataReport
        data.data = action.data.result
        data.total = action.data.total
        data.isLoaded = true
        
        state = {
            ...state,
            dataReport: data
        }
    }

    if(action.type == setPageReportAcrtion){
        let data = state.dataReport
        data.pageReport = action.data
        state = {
            ...state,
            dataReport: data
        }
    }

    if(action.type == setDataUserSalesAction){
        let data = state.dataUserSales
        data.data = action.data.result
        data.total = action.data.total
        data.isLoaded = true
        state = {
            ...state,
            dataUserSales: data
        }
    }

    if(action.type == setDataApplicantAction){
        let data = state.dataApplicant
        let respData = action.data

        data.data = respData.data.data
        data.total = respData.data.result
        data.isLoaded = true
        console.log(data)
        state = {
            ...state,
            dataApplicant: data
        }
    }
    

    if(action.type == setPageDataApplicantAction){
        let data = state.dataApplicant
        let page = action.data

        data.page = page 
        data.isLoaded = true
        state = {
            ...state,
            dataApplicant: data
        }
    }

    if(action.type == setDataCalendarAction){
        let data = action.data

        state = {
            ...state,
            dataCalendar: data
        }
    }

    if(action.type == searchRreportByNameAction){
        let data = state.dataReport
        data.searchName = action.data
        
        state = {
            ...state,
            dataReport: data
        }
    }

    if(action.type == filterReportAction){
        let data = state.dataReport
        data.filter = action.data

        state = {
            ...state,
            dataReport: data
        }
    }

    if(action.type == setDataUserAction){
        let data = state.dataUser
        data.data = action.data.data
        data.isLoaded = true
        data.page = 1
        data.total = action.data.total

        state = {
            ...state,
            dataUser: data
        }
    }

    if(action.type == setPageDataUserSalesAction){
        let data = state.dataUserSales
        data.page = action.data

        state = {
            ...state,
            dataUserSales: data
        }
    }

    if(action.type == setPageDataUserAction){
        let data = state.dataUser
        data.page = action.data

        state = {
            ...state,
            dataUser: data
        }
    }

    if(action.type == searchDataUserSalesByNameAction){
        let data = state.dataUserSales
        data.searchName = action.data

        state = {
            ...state,
            dataUserSales: data
        }
    }

    if(action.type == searcDataUserAction){
        let data = state.dataUser
        data.searchName = action.data

        state = {
            ...state,
            dataUser: data
        }
    }

    if(action.type == setLoadedFlsUser){
        let data = state.dataUser
        data.isLoaded = false

        state = {
            ...state,
            dataUser: data
        }
    }

    if(action.type == setAvailableAction){
        let data = state.isAvailable

        state = {
            ...state,
            isAvailable: !data
        }
    }

    if(action.type == setDataStatusApplicantAction){
        let param = action.data
        let data = state.status
        data = param
        let sts = (data.name == "AVAILABLE" || data.name == "BUSY") ? true : false

        state = {
            ...state,
            status: data,
            isAvailable: sts
        }
    }

    if(action.type == searchRsApplicantAction){
        let data = action.data
        let ste = state.dataApplicant
        ste.searchName = data
        
        state = {
            ...state,
            dataApplicant: ste
        }
    }

    if(action.type == filterStatusAction){
        let data = action.data
        let dataUserSales = state.dataUserSales
        dataUserSales.filterStatus = data

        state = {
            ...state,
            dataUserSales: dataUserSales
        }
    }

    if(action.type == filterStatusApplicantAction){
        let data = action.data
        let dataApplicant = state.dataApplicant
        dataApplicant.filterStatus = data

        state = {
            ...state,
            dataApplicant: dataApplicant
        }
    }

    if(action.type == setSortReportAction){
        let sort = action.sort
        let type = action.typeSort
        let sortName = ""
        
        if(sort == "customerName") sortName = "Customer Name"
        if(sort == "startDate") sortName = "Start Date"
        if(sort == "endDate") sortName = "End Date"
        if(sort == "user.profile.name") sortName = "Techniciant"
        if(sort == "createDate") sortName = "Created Date"
   
        let data = state.dataReport
        data.sortBy = sortName
        data.sortType = type

        state = {
            ...state,
            dataReport: data
        }
    }

    return state
}

export default reducer