import axios from 'axios'
import React, { Component } from 'react'
import Web from './applicant_web'
import Mobile from './appicant_mobile'
import { connect } from 'react-redux'
import { setDataApplicant, setDataStatusApplicant, setPageDataApplicant } from '../../../redux/action'
import service from '../../../service/applicant_service'
import { errorFetch } from '../../function/function'

class applicant extends Component{

    constructor(){
        super()
        this.state = {
            inProcess: true,
            data: [],
            windowWidth: window.innerWidth
        }
    }

    componentDidMount(){
        this.fetchData()
        window.addEventListener("resize", this.resize)
    }

    resize = () => {
        this.setState({
            windowWidth: window.innerWidth
        })
    }

    fetchData = async () => {
        let isLoaded = this.props.isLoaded /*param from redux untuk check apa data applicant sudah diload*/
        if(!isLoaded){
            let serv = new service()
            let response = await serv.fetchStatus() //axios.get("/applicant/myStatus")
            let resp = response.data
            let data = resp.data
            let status = data.status
            this.props.setApplicantStatus(status)

            if(status.name != "AVAILABLE" && status.name != "BUSY") {
                return false
            }

            resp = await serv.fetchDataApi()
            this.props.setDataApplicant(resp)
            this.setState({
                inProcess: false
            })
        }
    }
    
    render(){
        return(
            <>
            {
                (this.state.windowWidth > 1202)
                ? <Web 
                    dataApplicant={this.props.dataApplicant} 
                    isLoaded={this.props.isLoaded} 
                    setDataApplicantRdx={this.props.setDataApplicant}
                    setPage={this.props.setPage}/>
                : <Mobile 
                    dataApplicant={this.props.dataApplicant} 
                    isLoaded={this.props.isLoaded}
                    setDataApplicantRdx={this.props.setDataApplicant}
                    setPage={this.props.setPage}/>
            }
            </>
        )
    }
}

const mapState = state => {
    return {
        dataApplicant: state.dataApplicant,
        page: state.dataApplicant.page,
        isLoaded: state.dataApplicant.isLoaded,
        status: state.status
    }
}

const mapDispatch = dispatch => {
    return{
        setDataApplicant: (data) => dispatch(setDataApplicant(data)),
        setApplicantStatus: (data) => dispatch(setDataStatusApplicant(data)),
        setPage: (data) => dispatch(setPageDataApplicant(data))
    }
}
export default connect(mapState, mapDispatch) (applicant)