import React, { Component } from 'react'
import Header from '../../header/header'
import Styled from 'styled-components'
import SrcIcon from '../../../images/search-interface-symbol.svg'
import { Link } from 'react-router-dom'
import { getRoleId, getRoleName } from '../../function/read_token'
import LoaderGif from '../../../images/Pulse-1s-51px.gif'
import Row from './row_web'
import Excel from '../../../images/excel.png'
import SortIcon from '../../../images/sort.png'
import { connect } from 'react-redux'
import { filterReport, searchReportByName, setPageReport, setSortReport } from '../../../redux/action'
import { baseUrl } from "../../../environment/index"
import axios from 'axios'
import { convertDateDDMMYYYString, isManagerTechniciant } from '../../function/function'
import AzSort from '../../../images/fast-forward-asc.png'
import ZaSort from '../../../images/fast-forward-desc.png'
import { relativeTimeThreshold } from 'moment-timezone'

const Base = Styled.div`
    position: relative;
`
const SrcBase = Styled.div`
    width: 223px;
    padding: 0px 10px;
    height: 37px;
    background: #FFF;
    border-radius: 8px;
    display: flex;
    align-items: center;
`

const FilterBase = Styled.div`
    width: 124px;
    cursor: pointer;
    padding: 0px 10px;
    height: 37px;
    background: #FFF;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 25px;
`

const Btn = Styled.button`
    width: 226px;
    height: 42px;
    background: #7784EE;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    color: #FFF;
    border: none;
    outline: none;
`
const FilterItem = Styled.div`
    padding: 6px 10px; 
    font-weight: 600;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #6d6d6d;
    border-bottom: 1px solid #f1f1f1;
`

let size = 0

class manager_web extends Component{

    constructor(){
        super()
        this.state = {
            isExporting: false,
            filtering: false
        }
    }

    setPagePosition = (type) => {
        let currPage = this.props.page
        if(type == "next"){
            if(currPage < size){
                currPage += 1
            }
        }else{
            if(currPage > 1){
                currPage -= 1
            }
        }
        
        /*redux props action*/
        this.props.setPage(currPage)
    }

    componentDidMount(){
        document.addEventListener("click", this.handleClickOutside)
    }

    firstPos = () => {
        this.props.setPage(1)
    }

    lastPos = () => {
        this.props.setPage(size)
    }
    
    search = (e) => {
        this.props.search(e.target.value)
        this.props.setPage(1)
    }

    isManager = () => {
        return isManagerTechniciant(); 
    }

    handleClickOutside = (e) => {
        let elm = document.getElementsByClassName("choos-sts-dt")
        let elm2 = document.getElementsByClassName("")
        for(let i = 0;i<elm.length;i++){
            let elmi = elm[i]
            if(elmi && !elmi.contains(e.target)){
                elmi.style.display = "none"
            }
        }
    }

    export = () => {
        const FileDownload = require('js-file-download');
        let date = new Date()
        let name = "export "+convertDateDDMMYYYString(date)+".xlsx"

        this.setState({
            isExporting: true
        })

        axios({
            url: "/report/excel", //your url
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            // const url = window.URL.createObjectURL(new Blob([response.data]));
            FileDownload(response.data, name);
            this.setState({
                isExporting: false
            })
        });
    }

    setFilter = (e) => {
        e.stopPropagation()
        this.handleClickOutside(e)
        let elm = document.getElementById("choos-sts-dt")
        elm.style.display = "block"
    }

    selectFilter = (e, n) => {
        e.stopPropagation()
        let elm1 = document.getElementById("choos-sts-dt")
        elm1.style.display = "none"
        this.props.setFilter(n)
    }

    setOrder = (e) => {
        e.stopPropagation()
        this.handleClickOutside(e)
        let elm = document.getElementById("choos-sort-dt")
        elm.style.display = "block"
    }

    selectOrder = (e, sort, type) => {
        e.stopPropagation()
        let elm = document.getElementById("choos-sort-dt")
        elm.style.display = "none"
        this.props.fetchData(sort, type)
        this.props.setSort(type, sort)
    }

    render(){
        let seq = 0
        const dataReport = this.props.data.map((dt, index) => {
            let isView = false
            let search = this.props.searchParam.toLowerCase()
            let filter = this.props.filterParam

            let costumerName = dt.customerName.toLowerCase()
            let techniciant = dt.user
            let profile = techniciant.profile
            let nameTehcniciant = (profile) ? profile.name.toLowerCase() : ""
  
            if(search == 0 || costumerName.match(search) || nameTehcniciant.match(search)){
                isView = true
            }

            if(filter != 0 && filter.toLowerCase() != dt.statusReportForm.toLowerCase()){
                isView = false
            }

            if(isView){
                seq++
                let st = (this.props.page > 1) ? (this.props.page * 10) - 9 : this.props.page
                let ed = this.props.page * 10

                if(seq >= st && seq <= ed && isView){
                    return <Row data={dt}/>
                }
            }

            let idx = parseInt(index) + 1
            let len = this.props.data.length
            if(idx == len){
                size = Math.ceil(seq / 10)
            }
        })
        return(
            <Base>
                {/* <BaseMain> */}
                <Header/>
                <div style={{padding: "40px 51px"}}>
                    <div style={{fontSize: "24px", 
                                color: "#1F4788",
                                display: "flex", 
                                justifyContent: "space-between", 
                                alignItems: 'center'}}>
                        <span>Dashboard {/*Manager*/}</span>
                        {
                            (getRoleId() == "00000000-0000-0000-0000-000000000012")
                            ?
                                <Link to="/dashboard/techniciant/form">
                                    <Btn>+ Create New</Btn>
                                </Link>
                            :
                                ""
                        }
                    </div>
                    <div style={{display: "flex", marginTop: "50px", alignItems: "center"}}>
                        <SrcBase>
                            <img src={SrcIcon} style={{width: "15px"}}/>
                            <div>
                                <input type="text" 
                                    onChange={(e) => this.search(e)}
                                    value={this.props.searchParam}
                                    style={{width: "100%", 
                                            marginLeft: "10px", 
                                            border: "none", 
                                            outline: "none"}} 
                                    placeholder="Search by name"
                                />
                            </div>
                        </SrcBase>
                        <FilterBase onClick={this.setFilter}>
                            <div style={{color: '#717171', textTransform:"capitalize", fontSize: "12px"}}>
                                {(this.props.filterParam == "") ? "Filter By" : this.props.filterParam}
                            </div>
                            {chevron}
                            {
                                // (this.state.filtering)
                                // ?
                                    <div id="choos-sts-dt" className="choos-sts-dt"
                                        style={{position: "absolute", 
                                                width: "200px", 
                                                height: "auto",
                                                marginTop: "195px",
                                                display: "none",
                                                borderRadius: "3px",
                                                zIndex: 1,
                                                border: "1px solid #C8DDEA", 
                                                background: "#FFF"}}>

                                        <a onClick={(e) => this.selectFilter(e, "")}>
                                            <FilterItem className="item-slc">All</FilterItem>
                                        </a>
                                        <a onClick={(e) => this.selectFilter(e,"pending")}>
                                            <FilterItem className="item-slc">Pending</FilterItem>
                                        </a>
                                        <a onClick={(e) => this.selectFilter(e,"on progress")}>
                                            <FilterItem className="item-slc">On Progress</FilterItem>
                                        </a>
                                        <a onClick={(e) => this.selectFilter(e,"rejected")}>
                                            <FilterItem className="item-slc">Rejected</FilterItem>
                                        </a>
                                        <a onClick={(e) => this.selectFilter(e,"finished")}>
                                            <FilterItem className="item-slc">Finished</FilterItem>
                                        </a>
                                    </div>
                                // : ""
                            }
                        </FilterBase>
                        <FilterBase onClick={this.setOrder}>
                            <div style={{color: '#717171', textTransform:"capitalize", fontSize: "12px"}}>
                                {(this.props.sortParam == "") ? "Sort By" : this.props.sortParam}
                            </div>
                            {
                                (this.props.sortParam == "")
                                ?   <img src={SortIcon} style={{width : "20px"}}/>
                                :   (this.props.sortType == "asc")
                                    ? <img src={AzSort} style={{width: "14px"}}/>
                                    : <img src={ZaSort} style={{width: "14px"}}/>
                            }
                            
                            {
                                <div id="choos-sort-dt" className="choos-sts-dt"
                                    style={{position: "absolute", 
                                            width: "200px", 
                                            height: "auto",
                                            marginTop: "325px",
                                            display: "none",
                                            borderRadius: "3px",
                                            zIndex: 1,
                                            border: "1px solid #C8DDEA", 
                                            background: "#FFF"}}>

                                    <a onClick={(e) => this.selectOrder(e, "customerName", "asc")}>
                                        <FilterItem className="item-slc" style={{}}>
                                            <img src={AzSort} style={{width: "14px", marginRight: "5px"}}/>
                                            Customer Name
                                        </FilterItem>
                                    </a>
                                    <a onClick={(e) => this.selectOrder(e, "customerName", "desc")}>
                                        <FilterItem className="item-slc" style={{}}>
                                            <img src={ZaSort} style={{width: "14px", marginRight: "5px"}}/>
                                            Customer Name
                                        </FilterItem>
                                    </a>
                                    <a onClick={(e) => this.selectOrder(e,"techniciant", "asc")}>
                                        <FilterItem className="item-slc">
                                            <img src={AzSort} style={{width: "14px", marginRight: "5px"}}/>
                                            Techniciant
                                        </FilterItem>
                                    </a>
                                    <a onClick={(e) => this.selectOrder(e,"techniciant", "desc")}>
                                        <FilterItem className="item-slc">
                                            <img src={ZaSort} style={{width: "14px", marginRight: "5px"}}/>
                                            Techniciant
                                        </FilterItem>
                                    </a>
                                    <a onClick={(e) => this.selectOrder(e,"startDate", "asc")}>
                                        <FilterItem className="item-slc">
                                            <img src={AzSort} style={{width: "14px", marginRight: "5px"}}/>
                                            Start Date
                                        </FilterItem>
                                    </a>
                                    <a onClick={(e) => this.selectOrder(e,"startDate", "desc")}>
                                        <FilterItem className="item-slc">
                                            <img src={ZaSort} style={{width: "14px", marginRight: "5px"}}/>
                                            Start Date
                                        </FilterItem>
                                    </a>
                                    <a onClick={(e) => this.selectOrder(e,"endDate", "asc")}>
                                        <FilterItem className="item-slc">
                                            <img src={AzSort} style={{width: "14px", marginRight: "5px"}}/>
                                            End Date
                                        </FilterItem>
                                    </a>
                                    <a onClick={(e) => this.selectOrder(e,"endDate", "desc")}>
                                        <FilterItem className="item-slc">
                                            <img src={ZaSort} style={{width: "14px", marginRight: "5px"}}/>
                                            End Date
                                        </FilterItem>
                                    </a>
                                    <a onClick={(e) => this.selectOrder(e,"createdDate", "asc")}>
                                        <FilterItem className="item-slc">
                                            <img src={AzSort} style={{width: "14px", marginRight: "5px"}}/>
                                            Created Date
                                        </FilterItem>
                                    </a>
                                    <a onClick={(e) => this.selectOrder(e,"createdDate", "desc")}>
                                        <FilterItem className="item-slc">
                                            <img src={ZaSort} style={{width: "14px", marginRight: "5px"}}/>
                                            Created Date
                                        </FilterItem>
                                    </a>
                                </div>
                            }
                        </FilterBase>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {
                            (this.isManager())
                            ?
                                (!this.state.isExporting)
                                ?
                                    <a onClick={this.export}>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <img src={Excel} style={{width: "24px"}}/>
                                            &nbsp;<span style={{fontWeight: "bold", color: "#1F4788"}}>Export</span>
                                        </div>
                                    </a>
                                : 
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <img src={Excel} style={{width: "24px"}}/>
                                        &nbsp;<span style={{fontWeight: "bold", color: "#CCC"}}>Exporting...</span>
                                    </div>
                            : ""
                        }
                        
                    </div>

                    <div id="tbl-bs" style={{marginTop: "50px"}}>
                        <table style={{width: "100%"}}>
                            <thead>
                                <th>No</th>
                                <th>Serial Number</th>
                                <th>Customer Name</th>
                                <th>Techniciant</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th style={{minWidth: "120px"}}>Status</th>
                                <th>Action</th>
                            </thead>
                            <tbody>
                                {
                                    (this.props.isLoad)
                                    ?
                                        <tr>
                                            <td colSpan="8" style={{textAlign: "center", padding: "50px"}}>
                                                <img src={LoaderGif} style={{width: "35px"}}/>
                                            </td>
                                        </tr>
                                    :
                                        (this.props.data.length == 0)
                                        ?
                                            <tr>
                                                <td colSpan="7" style={{textAlign: "center", padding: "50px", color: "#CCC"}}>
                                                    No data to display
                                                </td>
                                            </tr>
                                        :
                                            dataReport
                                }
                            </tbody>
                        </table>
                        {/* {this.props.data.length} */}
                        <div id="ft-tbl-dir" style={{marginTop: "50px",}}>
                            <span style={{fontSize: "14px",lineHeight: "28px", color: "#333333"}}>
                                Showing {this.props.page} of {size} entries
                            </span>
                            <div>
                                <button className="img-pgn" onClick={this.firstPos}>First</button>
                                <button className="img-pgn" onClick={() => this.setPagePosition("prev")}>Prev</button>
                                <button className="img-pgn">{this.props.page}</button>
                                <button className="img-pgn" onClick={() => this.setPagePosition("next")}>Next</button>
                                <button className="img-pgn" onClick={this.lastPos}>Last</button> 
                            </div>
                        </div>
                    </div>
                </div>
                {/* </BaseMain> */}
            </Base>
        )
    }
}

const radio = <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="13" cy="13" r="12.5" fill="white" fill-opacity="0.06" stroke="#08A0F7"/>
            </svg>

const chevron = <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.625 1.4375L7.5 7.5625L1.375 1.4375" stroke="#717171" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>


const sortIcon = <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sort" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 512" class="svg-inline--fa fa-sort fa-w-10 fa-9x"><path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z" class=""></path></svg>

const mapDispatchToProps = dispatch => {
    return{
        setPage: (data) => dispatch(setPageReport(data)),
        search: (data) => dispatch(searchReportByName(data)),
        setFilter: (data) => dispatch(filterReport(data)),
        setSort: (sort, type) => dispatch(setSortReport(type, sort))
    }
}

const mapStateToProps = state => {
    return{
        searchParam: state.dataReport.searchName,
        filterParam: state.dataReport.filter,
        sortParam: state.dataReport.sortBy,
        sortType: state.dataReport.sortType
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (manager_web)