import React, {useEffect, useState} from 'react'
import Styled from 'styled-components'
import ApprIcon from '../../../images/quality.png'
import DenyIcon from '../../../images/multiply.png'
import DoneIcon from '../../../images/check-blue.png'
import SickIcon from '../../../images/medicine.png'
import FilterAtv from '../../../images/filter-atv.png'
import { useDispatch } from 'react-redux'
import { filterStatus } from '../../../redux/action'
import DataStatus from '../../../data/status'

const Base = Styled.div`
    background: rgba(0,0,0, 0.4);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
`

let DS = new DataStatus()
const FilterMobile = (props) => {

    // let dataUserSales = useSelector((state) => state.dataUserSales)
    let dispatch = useDispatch();

    const selectFilter = (e, id, name) => {
        e.stopPropagation()
        let obj = {
            id: id,
            name: name
        }

        dispatch(filterStatus(obj))
        props.hideFilter()
    }
    
    const hidePopup = (e) => {
        let elm = document.getElementById("flt-mbl-apl-xckz")
        if(elm && !elm.contains(e.target)){
            props.hideFilter()
        }
    }

    return(
        <Base onClick={hidePopup}>
            <div id="flt-mbl-apl-xckz" style={{background: "#FFF", width: "200px"}}>
                <div style={{display: "flex", 
                            alignItems: "center", 
                            padding: "10px 20px", 
                            borderBottom: "1px solid #e5e5e5", 
                            background: "#f5f5f5"}}
                >
                    <img src={FilterAtv} style={{width: "20px"}}/>
                    &nbsp;
                    <div style={{fontWeight: "bold", fontSize: "16px"}}>Filter</div>
                </div>
                <div onClick={(e) => selectFilter(e, "")} 
                    className="menu-hvr-item-item" 
                    style={{padding: "10px 20px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                >
                    <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px", color: "#868686"}}>All Data</div>
                </div>
                {
                    DS.statusFlag().map(dt => {
                        return  <div onClick={(e) => selectFilter(e, dt.id, dt.name)} 
                                    className="menu-hvr-item-item" 
                                    style={{padding: "10px 20px", display: "flex", alignItems: "center", borderBottom: "1px solid #eeeeee"}}
                                >
                                    <img src={dt.icon} style={{width: "14px"}}/>
                                    <div style={{fontSize: "12px", fontWeight: "bold", marginLeft: "5px"}}>{dt.name}</div>
                                </div>
                    })
                }
            </div>
        </Base>
    )
}

export default FilterMobile