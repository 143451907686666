import React, { Component } from 'react'
import { connect } from 'react-redux'
import { filterReport, filterStatusApplicant, seacrhRsApplicantByName, searchDataUserSales, searchReportByName, setPageDataApplicant, setPageDataUserSales, setPageReport } from '../../redux/action'
import Menu from '../menu/menu'
import Cancel from '../../images/cancel.png'
import Filter from '../../images/filter-grs.png'
import FilterBlue from '../../images/filter-blue.png'
import Styled from 'styled-components'
import { data } from 'jquery'

const DropdownFilter = Styled.div`
    width: 100%;
    position: absolute;
    background: #FFF;
    border: 1px solid #f1f1f1;
`
const FilterItem = Styled.div`
    padding: 6px 10px; 
    font-weight: 600;
    font-size: 14px;
    color: #6d6d6d;
    border-bottom: 1px solid #f1f1f1;
`

const BarAvailable = Styled.div`
    background: #CCC;
    width: 40px;
    height: 20px;
    border-radius: 20px;
    cursor: pointer;
    padding: 2px;
`

const CircleAvailable = Styled.div`
    width: 18px;
    height: 18px;
    background: #000;
    border-radius: 100%;
`

class header_mobile extends Component{

    constructor(){
        super()
        this.state = {
            menu: "",
            isSearch: false,
            search: "",
            isFilter: false,
            placeHolderSrc: ""
        }
    }

    menu = () => {
        this.setState({
            menu: <Menu hideMenu={this.hideMenu}/>
        })
    }

    componentDidMount(){
        this.setSearchValueInit()
    }

    // componentDidUpdate(prevProps){
    //     console.log(this.props)
    //     if(prevProps != this.props){
    //         console.log("re render header")
    //         this.setSearchValueInit()
    //     }
    // }

    hideMenu = () => {
        this.setState({
            menu: ""
        })
    }

    search = () => {
        if(this.props.enableSearch){
            this.setState({isSearch: true})
        }
    }

    /*inisiasi valua search input*/
    setSearchValueInit = () => {
        let path = window.location.pathname
        let paths = path.split("/")
        let name = paths[parseInt(paths.length - 1)]

        if(name.toLocaleLowerCase() == "techniciant"){
            /*search techniciant redux*/
            this.setState({
                search: this.props.searchTechniciantState,
                isFilter: true,
                placeHolderSrc: "Search by name"
            })

            /*show search input*/
            if(this.props.searchTechniciantState != 0){
                this.setState({isSearch: true})
            }
        }

        if(name.toLocaleLowerCase() == "user_sales"){
            /*search techniciant redux*/
            this.setState({
                search: this.props.searchUserSales,
                placeHolderSrc: "Search by name"
            })

            /*show search input*/
            if(this.props.searchUserSales != 0){
                this.setState({isSearch: true})
            }
        }

        if(name.toLocaleLowerCase() == "applicant"){
            /*search techniciant redux*/
            this.setState({
                search: this.props.searchApplicant,
                placeHolderSrc: "Search by name"
            })

            /*show search input*/
            if(this.props.searchApplicant != 0){
                this.setState({isSearch: true})
            }
        }
    }

    changeSearch = (e) => {
        let path = window.location.pathname
        let paths = path.split("/")
        let name = paths[parseInt(paths.length - 1)]

        this.setState({
            search: e.target.value
        })

        if(name.toLocaleLowerCase() == "techniciant"){
            /*search techniciant redux*/
            this.props.searchTechniciant(e.target.value) /*set data search*/
            this.props.setPageTechniciant(1) /*set pagination techniciant 1*/
        }

        if(name.toLocaleLowerCase() == "user_sales"){
            /*search techniciant redux*/
            this.props.searchSales(e.target.value) /*set data search*/
            this.props.setPageSales(1) /*set pagination user sales 1*/
        }

        if(name.toLocaleLowerCase() == "applicant"){
            /*search techniciant redux*/
            this.props.searchRsApplicant(e.target.value) /*set data search*/
            this.props.setPageSales(1) /*set pagination user sales 1*/
        }
    }

    xSearch = () => {
        let path = window.location.pathname
        let paths = path.split("/")
        let name = paths[parseInt(paths.length - 1)]

        this.setState({isSearch: false, search: ""})

        if(name.toLocaleLowerCase() == "techniciant"){
            /*search techniciant redux*/
            this.props.searchTechniciant("")
            this.props.setPageTechniciant(1)
            this.props.setFilter("")
        }

        if(name.toLocaleLowerCase() == "user_sales"){
            /*search techniciant redux*/
            this.props.searchSales("")
            this.props.setPageSales(1)
            this.props.setFilter("")
        }

        if(name.toLocaleLowerCase() == "applicant"){
            /*search techniciant redux*/
            this.props.searchRsApplicant("")
            this.props.setPageApplicant(1)
        }
    }

    filterTch = () => {
        let elm = document.getElementById("flt-tech")
        elm.style.display = "block"
    }

    selectFilter = (e, n) => {
        e.stopPropagation()
        let elm1 = document.getElementById("flt-tech")
        elm1.style.display = "none"
        this.props.setFilter(n)
    }

    render(){
        return(
            <div style={{position: "fixed", zIndex: "10", width: "100%", top: "0px"}}>
                <div id="header-byd" 
                    style={{padding: "18px", 
                            background: "#FFF", 
                            display: "flex", 
                            justifyContent: "space-between", 
                            alignItems: "center", 
                            borderBottom: "1px solid #CCC"}}>
                    <a 
                        onClick={this.menu} 
                        style={{background: "none", border: "none"}}
                    >
                        {menu}
                    </a>
                    {
                        (this.state.isSearch)
                        ?
                            <>
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "90%"}}>
                                <input type="text" 
                                    value={this.state.search}
                                    onChange={this.changeSearch}
                                    placeholder={this.state.placeHolderSrc}
                                    style={{width: "60%", boxSizing: "border-box", fontSize: "12px", padding: "4px", border: "1px solid #CCC", textAlign: "center", borderRadius: "3px", outline: "none"}}/>
                                {
                                    // (this.state.isFilter)
                                    // ?
                                    //     (this.props.filterParamTech == 0)
                                    //     ? <a onClick={this.filterTch} id="lk-fltr-tch"><img src={Filter} style={{width: "12px"}}/></a>
                                    //     : <a onClick={this.filterTch} id="lk-fltr-tch"><img src={FilterBlue} style={{width: "12px"}}/></a>
                                    // : ""        
                                }
                            </div>
                            <a onClick={this.xSearch} style={{marginTop: "-2px"}}><img src={Cancel} style={{width: "10px", marginLeft: "15px"}}/></a>
                            </>
                        :
                            <>
                                <div id="ttl-header" style={{color: "#333333", fontSize: "18px", fontWeight: "500"}}>
                                    {this.props.title}
                                </div>
                                <a onClick={this.search} style={(this.props.enableSearch) ? {} : {opacity: "0.2"} }>{search}</a>
                            </>
                    }
                </div>
                {/*dropdown filter*/}
                <div style={{position: "relative", 
                            background: "#F00", 
                            marginLeft: "100px",
                            display: "none", 
                            marginRight: "33px", 
                            marginBottom: "23px",
                            marginTop: "-25px", 
                            borderRadius: "3px"}}>
                    <DropdownFilter className="shadow" id="flt-tech" style={{display: "none"}}>
                        <a onClick={(e) => this.selectFilter(e, "")}>
                            <FilterItem className="item-slc">All</FilterItem>
                        </a>
                        <a onClick={(e) => this.selectFilter(e,"pending")}>
                            <FilterItem className="item-slc">Pending</FilterItem>
                        </a>
                        <a onClick={(e) => this.selectFilter(e,"on progress")}>
                            <FilterItem className="item-slc">On Progress</FilterItem>
                        </a>
                        <a onClick={(e) => this.selectFilter(e,"rejected")}>
                            <FilterItem className="item-slc">Rejected</FilterItem>
                        </a>
                        <a onClick={(e) => this.selectFilter(e,"finished")}>
                            <FilterItem className="item-slc">Finished</FilterItem>
                        </a>
                    </DropdownFilter>
                </div>
                {this.state.menu}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        searchTechniciantState: state.dataReport.searchName,
        filterParamTech: state.dataReport.filter,
        searchUserSales: state.dataUserSales.searchName,
        searchApplicant: state.dataApplicant.searchName
    }
}

const mapDispatchToProps = dispatch => {
    return{
        searchTechniciant: (data) => dispatch(searchReportByName(data)),
        searchSales: (data) => dispatch(searchDataUserSales(data)),
        setPageTechniciant: (data) => dispatch(setPageReport(data)),
        setFilter: (data) => dispatch(filterReport(data)),
        setPageSales: (data) => dispatch(setPageDataUserSales(data)),
        searchRsApplicant: (data) => dispatch(seacrhRsApplicantByName(data)),
        filterStatus: (data) => dispatch(filterStatusApplicant(data)),
        setPageApplicant: (data) => dispatch(setPageDataApplicant(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (header_mobile)

const menu = <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.5 0.333313H15.5V1.99998H0.5V0.333313ZM0.5 6.16665H15.5V7.83331H0.5V6.16665ZM0.5 12H15.5V13.6666H0.5V12Z" fill="#333333"/>
            </svg>

const search = <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <ellipse cx="8.80553" cy="8.80553" rx="7.49047" ry="7.49047" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M14.0153 14.4043L16.9519 17.3334" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
