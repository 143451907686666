import React from 'react'
import Remove from '../../../images/remove.png'
import Details from '../../../images/view-details.png'
import CheckGreen64 from '../../../images/checklist-green-64.png'

const row_web = (props) => {
    const data = props.data
    let status = data.status.id
    return(
        <tr>
            <td>{data.name}</td>
            <td>{data.email}</td>
            <td>{data.role.name}</td>
            <td>
                {
                    (status == "00000000-0000-0000-0000-000000000001")
                    ? <div style={{background: "green", color: "#FFF", fontSize: "11px", borderRadius: "5px", textAlign: "center", padding: "5px"}}>Active</div>
                    : ""
                }
                {
                    (status == "00000000-0000-0000-0000-000000000000")
                    ? <div style={{background: "red", color: "#FFF", fontSize: "11px", borderRadius: "5px", textAlign: "center", padding: "5px"}}>Not Active</div>
                    : ""
                }
                {
                    (status == "00000000-0000-0000-0000-000000000003")
                    ? <div style={{background: "#8b8b8b", color: "#FFF", fontSize: "11px", borderRadius: "5px", textAlign: "center", padding: "5px"}}>Deleted</div>
                    : ""
                }
            </td>
            <td>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <a onClick={props.select} style={{display: "flex"}}><img src={Details} style={{width: "20px"}}/></a>
                    &nbsp;
                    {
                        (status == "00000000-0000-0000-0000-000000000001")
                        ? <a onClick={props.deactivate} style={{display: "flex"}}><img src={Remove} style={{width: "20px"}}/></a>
                        : <a onClick={props.activate} style={{display: "flex"}}><img src={CheckGreen64} style={{width: "26px"}}/></a>
                    }
                </div>
            </td>
        </tr>
    )
}

export default row_web