import React, { Component } from 'react'
import Styled from 'styled-components'
import axios from 'axios'
import { connect } from 'react-redux'
import Popup from '../../popup_save_schedule'
import { updateDataReport } from '../../../redux/action'
import CurrencyInput from 'react-currency-input'
import { Redirect } from 'react-router'
import { getRoleName } from '../../function/read_token'
import UploadIcon from '../../../images/upload.png'
import xIcon from '../../../images/cancel.png'
import DateFnsUtils from '@date-io/date-fns';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
    TimePicker
  } from '@material-ui/pickers';
import { getDate, getTime } from '../../function/function'
import { baseUrl, baseUrlUpload } from '../../../environment'
import LoaderGif from '../../../images/Spinner-0.7s-200px.gif'

const Base = Styled.div`
    position: relative;
`

const BaseMain = Styled.div`
    position: fixed; 
    background: #F9FAFC; 
    height: 100%;
    width: 100%; 
`

const FormBase = Styled.div`
    background: #FFFFFF;
    border-radius: 10px;
    padding: 20px 47px;
    margin-top: 25px;
`
const InputProduct = Styled.input`
    border: 1px solid #1F4788;
    font-size: 12px;
    padding: 8px;
    border-radius: 5px;
    outline: none;
    margin-right: 20px;
` 
class techniciant_web extends Component{
    constructor(){
        super()
        this.state = {
            isProcess: false,
            popup: "",
            custName: "",
            contactName: "",
            address: "",
            contact: "",
            start: new Date(),
            startTime: new Date(),
            finish: new Date(),
            finishTime: new Date(),
            reportCategory: "Installation",
            status: "on progress",
            comment: "",
            type: "",
            serialNumber: "",
            repair: "",
            pending: "",
            fnameUploading: "",
            product: [{name: "", price: "", qty: ""}],
            attachmentInput: [],
            attachment: [],
            deletedFile: [],
            isSuccess: false /*keperluan redirect ketika sukses add atau edit data*/
        }

        this.inputAttachment = React.createRef()
        this.barPercentUpload = React.createRef()
    }

    componentDidMount(){
        document.addEventListener("keyup", (e) => {
            let elm = e.target
            let classInput = elm.getAttribute("class")

            if(classInput){
                if(classInput.match("frm-input")){
                    elm.style.border = "1px solid #949494"
                }
            }
        })

        this.setValue()
        this.disableInput()
        this.setVisibilityTypeId()
    }

    removeAttachment = (e, index) => {
        e.stopPropagation()
        let data = this.state.attachment
        let id = data[index].id

        data.splice(index, 1)

        /*set data deleted id file*/
        let dataFileDeleted = this.state.deletedFile
        dataFileDeleted.push(id)

        this.setState({
            attachment: data,
            deletedFile: dataFileDeleted
        })
    }

    save = () => {
        /*validation data product*/
        let isProductValid = true
        let dataProduct = this.state.product
        if(dataProduct.length > 1){
            for(let i = 0;i<dataProduct.length;i++){
                let n = dataProduct[i].name
                let p = dataProduct[i].price
                let q = dataProduct[i].qty

                if(n == 0 || p == 0 || q == 0){
                    isProductValid = false
                }
            }
        }else{
            let n = dataProduct[0].name
            let p = dataProduct[0].price
            let q = dataProduct[0].qty

            if(n == 0 && p == 0 && q == 0){
                dataProduct = []
            }
        }

        if(!isProductValid){
            alert("Pastikan semua data product terisi")
        }

        let sd = this.state.start
        let st = this.state.startTime
        let startDt = getDate(sd)+" "+getTime(st)

        let fd = this.state.finish
        let ft = this.state.finishTime
        let finishDt = getDate(fd)+" "+getTime(ft)

        /*set data files id*/
        let filesId = []
        for(let i = 0;i<this.state.attachmentInput.length;i++){
            let data = this.state.attachmentInput[i]
            let id = data.id
            filesId.push(id)
        }
        
        const data = {
            "address": this.state.address,
            "contactName": this.state.contactName,
            "customerComment": this.state.comment,
            "customerName": this.state.custName,
            "endDate": finishDt,
            "reportCategory": this.state.reportCategory,
            "jobStatus": "okee",
            "pendingMatters": this.state.pending,
            "phoneOrExt": this.state.contact,
            "repairDetail": this.state.repair,
            "serialNumber": this.state.serialNumber,
            "startDate": startDt,
            "statusReportForm": this.state.status,
            "type": this.state.type,
            "products": dataProduct,
            "filesId": filesId
        }

        /*set id if exists*/
        if(this.props.data.id){
            data.id = this.props.data.id
        }

        if(this.state.status != "pending"){
            data.pendingMatters = ""
        }

        let isValid = this.validationInput()
        if(!isValid) return false

        this.setState({
            isProcess: true
        })

        if(this.props.data.id){
            this.updateData(data, this.state.deletedFile)
        }else{
            this.saveData(data)
        }
    }

    addItemProduct = () => {
        let data = this.props.data
        let status = data.statusReportForm

        let isM = this.isManager()
        if(isM || status == "finished") return false

        let product = [...this.state.product]
        let obj = {name: "", price: "", qty: ""}
        product.push(obj)
        
        this.setState({
            product: product
        })
    }

    updateData = async (data, deletedFile) => {
            await axios.put("/report", data).then(result => {
            this.setState({
                popup:  <Popup status="success"/>,
                custName: "",
                contactName: "",
                address: "",
                contact: "",
                start: new Date(),
                startTime: new Date(),
                finish: new Date(),
                finishTime: new Date(),
                reportCategory: "Installation",
                status: "pending",
                comment: "",
                type: "",
                serialNumber: "",
                repair: "",
                pending: "",
                products: [{name: "", price: "", qty: "", id: null}],
                filesId: [],
                attachment: [],
                isProcess: false
            })

            /*deleting attachment*/
            for(let i = 0;i<deletedFile.length;i++){
                let id = deletedFile[i]
                axios.delete("/report/file/"+id)
            }
            
            /*delete value input*/
            let elm = document.getElementsByClassName("frm-input")
            for(let i = 0;i<elm.length;i++){
                elm[i].value = ""
            }

            let newdata = result.data.data
            let report = this.props.dataReport.data

            for(let i = 0;i<report.length;i++){
                let data = report[i]
                if(data.id == newdata.id){
                    report[i] = newdata
                }
            }

            /*set new data report to redux*/
            this.props.updateDataReport(report)
            this.setState({isSuccess: true})
            window.scrollTo(0, 0)
        }).catch(error => {
            this.setState({
                popup: <Popup status="failed"/>,
                isProcess: false
            })

            this.hidePoup()
        })
    }

    saveData = (data) => {
        axios.post("/report", data).then(result => {
            this.setState({
                popup:  <Popup status="success"/>,
                custName: "",
                contactName: "",
                address: "",
                contact: "",
                start: new Date(),
                startTime: new Date(),
                finish: new Date(),
                finishTime: new Date(),
                reportCategory: "Installation",
                status: "pending",
                comment: "",
                type: "",
                serialNumber: "",
                repair: "",
                pending: "",
                product: [{name: "", price: "", qty: "", id: null}],
                isProcess: false,
                attachment: [],
                filesId: [],
            })
            
            /*delete value input*/
            let elm = document.getElementsByClassName("frm-input")
            for(let i = 0;i<elm.length;i++){
                elm[i].value = ""
            }

            /*fetch data after set new data*/
            this.props.fetchData()
            
            this.setState({
                isSuccess: true
            })
        }).catch(error => {
            this.setState({
                popup:  <Popup status="failed"/>,
                isProcess: false
            })

            this.hidePoup()
        })
    }

    removeItemProduct = (index) => {
        let data = this.props.data
        let status = data.statusReportForm

        let isM = this.isManager()
        if(isM || status == "finished") return false

        let product = [...this.state.product]
        product.splice(index, 1)

        this.setState({
            product: product
        })
    }

    setIndexCurrency = (index) => {
        this.indexItemForCurrency = index
    }

    hidePoup = () => {
        setTimeout(() => {
            this.setState({
                popup: ""
            })
        }, 3000)
    }

    setProduct = (type, index, e) => {
        let data = this.props.data
        let status = data.statusReportForm

        let isM = this.isManager()
        if(isM) return false

        if(!isM && status != "finished"){
            let product = [...this.state.product]
            if(type == "name") product[index].name = e.target.value
            if(type == "price") product[index].price = e.target.value
            if(type == "qty") product[index].qty = e.target.value
    
            this.setState({
                product: product
            })
        }
        
    }

    handleChangeCurrency = (e, maskedvalue, floatvalue) => {
        let data = this.props.data
        let status = data.statusReportForm

        let isM = this.isManager()
        let product = [...this.state.product]

        if(!isM && status != "finished"){
            let seq = e.target.getAttribute("seq")
            product[seq].price = floatvalue
        }
        
        this.setState({
            product: product
        })
    }

    setValue = () => {
        let data = this.props.data 
        if(this.props.data != ""){
            let reportCategory = (data.reportCategory == null) ? "Installation" : data.reportCategory 
            let start = data.startDate
            let end = data.endDate
            
            let obj = [{name: "", price: "", qty: ""}]
            let product = (data.products.length == 0) ? obj : data.products

            this.setState({
                custName: data.customerName,
                contactName: data.contactName,
                address: data.address,
                contact: data.phoneOrExt,
                start: start,
                startTime: start,
                finish: end,
                finishTime: end,
                reportCategory: reportCategory,
                status: data.statusReportForm,
                comment: data.customerComment,
                type: data.type,
                serialNumber: data.serialNumber,
                repair: data.repairDetail,
                pending: data.pendingMatters,
                product: product,
                isProcess: false,
                attachment: data.filesAttach,
                attachmentInput: data.filesAttach
            })
        }
    }

    validationInput = () => {
        let isValid = true
        let input = document.getElementsByClassName("frm-input")
        for(let i = 0;i<input.length;i++){
            let val = input[i].value
            if(val == 0){
                input[i].style.border = "1px solid #F00"
                isValid = false
            }
        }

        return isValid
    }

    uploadFile = () => {
        this.inputAttachment.current.click()
    }

    getTotal = () => {
        let total = 0
        let data = this.state.product
        for(let i = 0;i<data.length;i++){
            let q = data[i].qty
            let p = data[i].price
            let t = q * p
            total += t
        }

        return total
    }

    isManager = () => {
        let isM = false
        let role = getRoleName()
        if(role.toLowerCase() == "director / manager techniciant"){
            isM = true
        }
        
        return isM
    }

    isFinished = () => {
        let data = this.props.data
        let status = data.statusReportForm
        if(status == "finished") {
            return true
        }else{
            return false
        }
    }

    disableInput = () => {
        let data = this.props.data
        let status = data.statusReportForm

        let role = getRoleName()
        if(role.toLowerCase() == "director / manager techniciant" || status == "finished"){
            let elm = document.getElementsByClassName("frm-input")
            for(let i = 0;i<elm.length;i++){
                elm[i].disabled = true
            }
        }
    }

    setReportCategory = (val) => {
        let data = this.props.data
        let status = data.statusReportForm
        let im = this.isManager()

        if(!im && status != "finished"){
            this.setState({reportCategory: val})
        }
    }

    handleDateChange = (date) => {
        this.setState({
            start: date
        })
    }

    handleStartTimeChange = (time) => {
        this.setState({
            startTime: time
        })
    }

    handleFinishDateChange = (date) => {
        this.setState({
            finish: date
        })
    }

    handleFinishTimeChange = (time) => {
        this.setState({
            finishTime: time
        })
    }

    setVisibilityTypeId = async () => {
        let resp = await axios.get("/file/getVisibilityType")
        let result = resp.data
        let data = result.data
        this.setState({
            visibilityType: data
        })
    }

    getTypeId = (type) => {
        let id = ""
        let data = this.state.visibilityType
        for(let i = 0;i<data.length;i++){
            if(type == data[i].name){
                id = data[i].id
            }
        }

        return id
    }

    changeAttachment = async (e) => {
        let file = e.target.files[0]
        let fname = file.name
        let typeId = this.getTypeId("public")
        this.setState({
            fnameUploading: fname,
            upload: file
        })

        let form = new FormData()
        form.append("file", file)
        form.append("visibilityTypeId", typeId)
        let url = baseUrlUpload
        let dataLogin = await window.localStorage.getItem("token")
        let token;
        
        if (dataLogin != null) {
            dataLogin = JSON.parse(window.localStorage.getItem("token"))
            token = dataLogin.token
        }

        const requestOptions = {
            method: "POST",
            headers: { 'Authorization': "Bearer "+token },
            body: form
        }

        const xhr = new XMLHttpRequest();
        xhr.upload.onload = () => {
            console.log("Completed upload")
        }
        
        xhr.upload.onerror = () => {
            console.error("upload failed")
        }

        xhr.upload.onabort = () => {
            console.error('Upload cancelled.');
        }

        xhr.upload.onprogress = (event) => {
            //event.loaded returns how many bytes are downloaded
            //event.total returns the total number of bytes
            //event.total is only available if server sends `Content-Length` header
            let barLoadUpload = this.barPercentUpload.current
            let barUpload = barLoadUpload.parentElement
            barUpload.style.display = "block" /*show bar upload file*/

            let percent = (event.loaded / event.total) * 100
            barLoadUpload.style.width = percent+"%"
        }

        xhr.onreadystatechange = () => {
            if (xhr.readyState == XMLHttpRequest.DONE) {
                /*hide bar upload file*/
                let barLoadUpload = this.barPercentUpload.current
                let barUpload = barLoadUpload.parentElement
                barUpload.style.display = "none"

                let resp = JSON.parse(xhr.responseText)
                if(resp.success){
                    let data = resp.data
                    let stateAttachment = this.state.attachmentInput
                    stateAttachment.push(data)
                    this.setState({
                        attachment: stateAttachment,
                        attachmentInput: stateAttachment,
                        fnameUploading: ""
                    })
                }
            }
        }

        xhr.open("POST", url+"/file/uploadFile")
        xhr.setRequestHeader("Authorization", "Bearer "+token)
        xhr.send(form)
    }

    render(){
        return(
            <Base>
                {
                    (this.state.isSuccess)
                    ? <Redirect to="/dashboard/techniciant"/>
                    : ""
                }
                {this.state.popup}
                <div style={{padding: "40px 51px"}}>
                    <div style={{fontSize: "24px", color: "#1F4788"}}>
                        Dashboard Techniciant
                        <FormBase>
                            <div className="bs-frm-ip">
                                <div className="bs-bs-frm-ip">
                                    <div className="ttl-ip">Customer Name</div>
                                    <input
                                        value={this.state.custName}
                                        onChange={(e) => {this.setState({custName: e.target.value})}} 
                                        placeholder="Customer Name" 
                                        className="frm-input" 
                                        type="text"/>
                                </div>
                                <div className="bs-bs-frm-ip">
                                    <div className="ttl-ip">Contact Name</div>
                                    <input 
                                        value={this.state.contactName}
                                        onChange={(e) => {this.setState({contactName: e.target.value})}}
                                        placeholder="Contact Name" 
                                        className="frm-input" 
                                        type="text"/>
                                </div>
                            </div>
                            <div className="bs-frm-ip">
                                <div className="bs-bs-frm-ip">
                                    <div className="ttl-ip">Address</div>
                                    <input 
                                        value={this.state.address}
                                        onChange={(e) => {this.setState({address: e.target.value})}} 
                                        placeholder="Address" 
                                        className="frm-input" 
                                        type="text"/>
                                </div>
                                <div className="bs-bs-frm-ip">
                                    <div className="ttl-ip">Contact</div>
                                    <input
                                        value={this.state.contact} 
                                        onChange={(e) => {this.setState({contact: e.target.value})}} 
                                        placeholder="Contact" 
                                        className="frm-input" 
                                        type="text"/>
                                </div>
                            </div>
                            <div className="bs-frm-ip">
                                <div className="bs-bs-frm-ip">
                                    <div className="ttl-ip">Start Work</div>
                                    <div id="st-dt-tech" style={{display: "flex"}}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                           {
                                               (!this.isManager())
                                               ?
                                                    <KeyboardDatePicker
                                                        margin="normal"
                                                        id="date-picker"
                                                        label="Date"
                                                        format="MM/dd/yyyy"
                                                        inputVariant="outlined"
                                                        value={this.state.start}
                                                        onChange={this.handleDateChange}
                                                    />
                                                :
                                                    <KeyboardDatePicker
                                                        margin="normal"
                                                        id="date-picker"
                                                        disabled="false"
                                                        label="Date"
                                                        disabled
                                                        format="MM/dd/yyyy"
                                                        inputVariant="outlined"
                                                        value={this.state.start}
                                                    />
                                           }
                                            &nbsp;
                                            {
                                                (!this.isManager())
                                                ?
                                                    <TimePicker
                                                        id="time-picker"
                                                        clearable
                                                        ampm={false}
                                                        label="Time"
                                                        inputVariant="outlined"
                                                        margin="normal"
                                                        value={this.state.startTime}
                                                        onChange={this.handleStartTimeChange}
                                                    />
                                                :
                                                    <TimePicker
                                                        id="time-picker"
                                                        clearable
                                                        ampm={false}
                                                        label="Time"
                                                        disabled
                                                        inputVariant="outlined"
                                                        margin="normal"
                                                        value={this.state.startTime}
                                                    />
                                            }
                                           
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                                <div className="bs-bs-frm-ip">
                                    <div className="ttl-ip">Finished Work</div>
                                    <div id="fn-dt-tech" style={{display: "flex"}}>
                                        {
                                            (!this.isManager())
                                            ?
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        margin="normal"
                                                        id="date-picker"
                                                        label="Date"
                                                        format="MM/dd/yyyy"
                                                        inputVariant="outlined"
                                                        value={this.state.finish}
                                                        onChange={this.handleFinishDateChange}
                                                    />
                                                    &nbsp;
                                                    <TimePicker
                                                        id="time-picker"
                                                        clearable
                                                        ampm={false}
                                                        label="Time"
                                                        inputVariant="outlined"
                                                        margin="normal"
                                                        value={this.state.finishTime}
                                                        onChange={this.handleFinishTimeChange}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            :
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        margin="normal"
                                                        id="date-picker"
                                                        label="Date"
                                                        format="MM/dd/yyyy"
                                                        inputVariant="outlined"
                                                        value={this.state.finish}
                                                        disabled
                                                    />
                                                    &nbsp;
                                                    <TimePicker
                                                        id="time-picker"
                                                        clearable
                                                        ampm={false}
                                                        label="Time"
                                                        inputVariant="outlined"
                                                        margin="normal"
                                                        value={this.state.finishTime}
                                                        disabled
                                                    />
                                                </MuiPickersUtilsProvider>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="bs-frm-ip">
                                <div className="bs-bs-frm-ip" style={{width: "100%"}}>
                                    <div className="ttl-ip">Report Category</div>
                                    <div style={{display: "flex", justifyContent: "space-between"}}>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            {   
                                                (this.state.reportCategory.toLocaleLowerCase() == "installation")
                                                ?
                                                    <a style={{marginTop: "10px"}}>{check}</a>
                                                :
                                                    <a style={{marginTop: "10px"}} onClick={() => this.setReportCategory("Installation")}>{radio}</a>
                                            }
                                            &nbsp;&nbsp;<span>Installation</span>
                                        </div>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            {   
                                                (this.state.reportCategory.toLocaleLowerCase() == "service")
                                                ?
                                                    <a style={{marginTop: "10px"}}>{check}</a>
                                                :
                                                    <a style={{marginTop: "10px"}} onClick={() => this.setReportCategory("Service")}>{radio}</a>
                                            }
                                            &nbsp;&nbsp;<span>Service</span>
                                        </div>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            {   
                                                (this.state.reportCategory.toLocaleLowerCase() == "waranty")
                                                ?
                                                    <a style={{marginTop: "10px"}}>{check}</a>
                                                :
                                                    <a style={{marginTop: "10px"}} onClick={() => this.setReportCategory("Waranty")}>{radio}</a>
                                            }
                                            &nbsp;&nbsp;<span>Warranty</span>
                                        </div>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            {   
                                                (this.state.reportCategory.toLocaleLowerCase() == "others")
                                                ?
                                                    <a style={{marginTop: "10px"}}>{check}</a>
                                                :
                                                    <a style={{marginTop: "10px"}} onClick={() => this.setReportCategory("Others")}>{radio}</a>
                                            }
                                            &nbsp;&nbsp;<span>Others</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bs-frm-ip">
                                <div className="bs-bs-frm-ip">
                                    <div className="ttl-ip">Status</div>
                                    <select value={this.state.status} 
                                        onChange={(e) => this.setState({status: e.target.value})} className="frm-input"
                                    >
                                        <option value="pending">Pending</option>
                                        <option value="on progress">On Progress</option>
                                        <option value="finished">Finished</option>
                                        <option value="rejected">Rejected</option>
                                    </select>
                                </div>
                                <div className="bs-bs-frm-ip">
                                    <div className="ttl-ip">Customer Comment</div>
                                    <input 
                                        value={this.state.comment}
                                        onChange={(e) => {this.setState({comment: e.target.value})}} 
                                        placeholder="Customer Comment" 
                                        className="frm-input" 
                                        type="text"/>
                                </div>
                            </div>
                            <div className="bs-frm-ip">
                                <div className="bs-bs-frm-ip">
                                    <div className="ttl-ip">Type</div>
                                    <input 
                                        value={this.state.type}
                                        onChange={(e) => {this.setState({type: e.target.value})}}
                                        placeholder="Type" 
                                        className="frm-input" 
                                        type="text"/>
                                </div>
                                <div className="bs-bs-frm-ip">
                                    <div className="ttl-ip">Serial Number</div>
                                    <input 
                                        value={this.state.serialNumber}
                                        onChange={(e) => {this.setState({serialNumber: e.target.value})}} 
                                        placeholder="Serial Number" 
                                        className="frm-input" 
                                        type="text"/>
                                </div>
                            </div>
                            <div className="bs-frm-ip">
                                <div className="bs-bs-frm-ip" style={{width: "100%"}}>
                                    <div className="ttl-ip">Repair Detail</div>
                                    <div>
                                        <textarea 
                                            value={this.state.repair}
                                            onChange={(e) => {this.setState({repair: e.target.value})}}
                                            placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit ..." 
                                            className="frm-input textarea-frm-input" 
                                            style={{height: "130px"}}>    
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="bs-frm-ip">
                                <div className="bs-bs-frm-ip" style={{width: "100%"}}>
                                    <div className="ttl-ip">Attachment</div>
                                    <div>
                                        {
                                            this.state.attachment.map((dt, index) => {
                                                return <a onClick={() => this.props.selectFile(dt)}><div className='atc-s-fls-tch' style={{display: "flex", float: "left", marginRight: "10px", marginBottom: "10px"}}>
                                                            <div style={{padding: "10px",
                                                                    background: "#f1f1f1",
                                                                    border: "1px solid #d9d9d9",
                                                                    borderRadius: "20px", 
                                                                    float: "left"}}>
                                                                <div style={{fontSize: "12px", fontWeight: "bold"}}>
                                                                    {dt.name}
                                                                </div>
                                                                {/* <div style={{marginBottom: "-5px", display: "flex", alignItems: "center", marginTop: "-5px", marginLeft: "-5px"}}>
                                                                    <img src={LoaderGif} style={{width: "25px", verticalAlign: "middle",}}/>
                                                                    <span style={{fontSize: "10px"}}>downloading..</span>
                                                                </div> */}
                                                            </div>
                                                            <a onClick={(e) => this.removeAttachment(e, index)}>
                                                                <img src={xIcon}
                                                                    style={{width: "10px", 
                                                                            height: "10px", 
                                                                            background: "#FFF", 
                                                                            border: "1px solid #CCC", 
                                                                            marginLeft: "-10px",
                                                                            padding: "4px", 
                                                                            borderRadius: "100%"}}/>
                                                            </a>
                                                         </div></a>
                                            })
                                        } 

                                        <a onClick={this.uploadFile}><div id="atc-s-fls-tch-up" style={{padding: "10px", 
                                                    display: "flex",
                                                    background: "#FFF",
                                                    border: "1px solid #d9d9d9", 
                                                    marginRight: "10px",
                                                    marginBottom: "10px",
                                                    alignItems: "center",
                                                    borderRadius: "20px", 
                                                    float: "left"}}>
                                            <img src={UploadIcon} style={{width: "20px", marginRight: "5px"}}/>
                                            <div style={{fontSize: "12px", fontWeight: "bold"}}>
                                                {
                                                    (this.state.fnameUploading == "")
                                                    ? "Upload File"
                                                    : this.state.fnameUploading
                                                }
                                                <div id="br-atch" style={{background: "#f1f1f1", 
                                                            height: "8px", 
                                                            width: "100px", 
                                                            position: "relative",
                                                            display: "none",
                                                            border: "1px solid #e4e4e4", 
                                                            borderRadius: "5px"}}>
                                                    <div id="br-pct-atch" 
                                                        ref={this.barPercentUpload} 
                                                        style={{height: "100%", 
                                                                width: "0%", 
                                                                background: "rgb(65 163 131)", 
                                                                borderRadius: "5px"}}></div>
                                                </div>
                                            </div>
                                        </div></a>
                                        <input id="tch-wb-ch-99" 
                                            ref={this.inputAttachment}
                                            onChange={this.changeAttachment}
                                            type="file" style={{display: "none"}}/>
                                    </div>
                                </div>
                            </div>
                            <div className="bs-frm-ip">
                                <div className="bs-bs-frm-ip" style={{width: "100%"}}>
                                    <div className="ttl-ip">Product</div>
                                    <div style={{marginTop: "-10px"}}>
                                        <table>
                                            <tbody>
                                                {
                                                    this.state.product.map((dt, index) => {
                                                        let l = this.state.product.length
                                                        let idx = parseInt(index) + 1
                                                        // console.log(dt.price+" * "+dt.qty)
                                                        return <tr style={{marginBottom: "3px"}}>
                                                                    <td style={{border: "none", padding: "0px"}}>
                                                                        <div style={{fontSize: "12px", color: "#797979", fontWeight: "bold", marginBottom: "3px"}}>Name :</div>
                                                                        <InputProduct type="text" 
                                                                            value={dt.name}
                                                                            onChange={(e) => this.setProduct("name", index, e)} 
                                                                            style={{width: "200px"}} 
                                                                            placeholder="product name"/>
                                                                    </td>
                                                                    <td style={{border: "none", padding: "6px 6px"}}>
                                                                        <div style={{fontSize: "12px", color: "#797979", fontWeight: "bold", marginBottom: "3px"}}>Price :</div>
                                                                        <CurrencyInput precision="0" 
                                                                            type="text" 
                                                                            seq={index}
                                                                            onChangeEvent={this.handleChangeCurrency}
                                                                            value={dt.price}
                                                                            style={{width: "140px", 
                                                                                    padding: "8px", 
                                                                                    fontSize: "12px", 
                                                                                    borderRadius: "5px",
                                                                                    border: "1px solid #1F4788", 
                                                                                    marginRight: "20px",
                                                                                    outline: "none"}}
                                                                        />
                                                                    </td>
                                                                    <td style={{border: "none", padding: "0px"}}>
                                                                        <div style={{fontSize: "12px", color: "#797979", fontWeight: "bold", marginBottom: "3px"}}>Quantity :</div>
                                                                        <InputProduct type="text"
                                                                                    value={dt.qty} 
                                                                                    onChange={(e) => this.setProduct("qty", index, e)} 
                                                                                    style={{width: "40px"}} placeholder="QTY"/>
                                                                    </td>
                                                                    <td style={{border: "none", padding: "0px"}}>
                                                                        <div style={{fontSize: "12px", color: "#797979", fontWeight: "bold", marginBottom: "3px"}}>Total :</div>
                                                                        <CurrencyInput precision="0" 
                                                                            type="text" 
                                                                            seq={index}
                                                                            disabled="true"
                                                                            value={dt.price * dt.qty}
                                                                            style={{width: "140px", 
                                                                                    padding: "8px", 
                                                                                    fontSize: "12px", 
                                                                                    borderRadius: "5px",
                                                                                    border: "1px solid #CCC", 
                                                                                    marginRight: "20px",
                                                                                    outline: "none"}}
                                                                        />
                                                                    </td>
                                                                    <td style={{border: "none", padding: "0px"}}>
                                                                        {
                                                                            (l == idx)
                                                                            ? 
                                                                                <a onClick={this.addItemProduct}>
                                                                                    <img 
                                                                                        src="https://img.icons8.com/material-outlined/48/000000/plus--v1.png" 
                                                                                        style={{width: "24px", opacity: "0.4", marginTop: "15px"}}
                                                                                    />
                                                                                </a>
                                                                            : 
                                                                                <a onClick={() => this.removeItemProduct(index)} style={{marginTop: "8px"}}>
                                                                                    <img src="https://img.icons8.com/small/64/000000/minus.png"
                                                                                        style={{width: "24px", opacity: "0.6", marginTop: "15px"}}
                                                                                    />
                                                                                </a>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                    })
                                                }
                                                <tr>
                                                    <td colSpan="3" 
                                                        style={{textAlign: "right", background: "#efefef", border: "none", paddingRight: "19px"}}>Total :
                                                    </td>
                                                    <td style={{background: "#efefef", border: "none", paddingLeft: "9px"}}>
                                                        <CurrencyInput precision="0"
                                                                    disabled="true"
                                                                    value={this.getTotal()}
                                                                    style={{width: "140px", 
                                                                            fontSize: "12px", 
                                                                            borderRadius: "5px",
                                                                            background: "none",
                                                                            border: "none", 
                                                                            marginRight: "20px",
                                                                            outline: "none"}}
                                                                />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {
                                (this.state.status == "pending")
                                ?
                                    <div className="bs-frm-ip">
                                        <div className="bs-bs-frm-ip" style={{width: "100%"}}>
                                            <div className="ttl-ip">Pending Matters</div>
                                            <div>
                                                <textarea 
                                                    onChange={(e) => {this.setState({pending: e.target.value})}}
                                                    value={this.state.pending}
                                                    placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit ..."  
                                                    className="frm-input textarea-frm-input" 
                                                    style={{height: "130px"}}>
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    ""
                            }
                            <div className="bs-btn">
                                {this.isManager()}
                                {
                                    (!this.state.isProcess)
                                    ?
                                        (!this.isManager() && !this.isFinished())
                                        ? <button onClick={this.save} className="btn-sv">Save</button>
                                        : ""
                                    :
                                        <button style={{opacity: "0.7"}} className="btn-sv">Please wait...</button>
                                }
                                
                                
                            </div>
                        </FormBase>
                    </div>
                </div>
                {/* </BaseMain> */}
            </Base>
        )
    }
}

const radio = <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="13" cy="13" r="12.5" fill="white" fill-opacity="0.06" stroke="#08A0F7"/>
            </svg>


const check = <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="13" cy="13" r="12.5" fill="#08A0F7" stroke="#08A0F7"/>
                <path d="M9.37501 13.4578L12.062 16.1448L17.436 10.7707" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

const mapStateToProps = state => {
    return{
        dataReport: state.dataReport
    }
}

const mapDispatchToProps = dispatch => {
    return{
        updateDataReport: (data) => dispatch(updateDataReport(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (techniciant_web)