import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { convertDate, isUserSales } from '../../function/function'
import Remove from '../../../images/remove.png'
import View from '../../../images/view-details.png'
import { isManagerApplicant } from "../../function/function"
import ApprIcon from '../../../images/quality.png'
import DenyIcon from '../../../images/multiply.png'
import DoneIcon from '../../../images/check-blue.png'
import SickIcon from '../../../images/medicine.png'
import reasonIcon from '../../../images/question.png'
import PendingIcon from '../../../images/sand-clock.png'
import { baseUrl } from '../../../environment'
import DataStatus from '../../../data/status'

let DS = new DataStatus()
class row_web extends Component{

    initialName = (name) => {
        if(name != null && name !== undefined && name != ""){
            let l = name.split(" ")
            let init = ""
            
            for(let i = 0;i<l.length;i++){
                if(i<2){
                    init += l[i][0]
                }else{
                    break
                }
            }

            return init.toUpperCase()
        }else{
            return ""
        }
    }

    colorKindAct = (kind) => {
        if(kind == "demo"){
            return "#1778B0"
        }else if(kind == "training"){
            return "#34A770"
        }else{
            return "#000"
        }
    }

    getApplicant = (data) => {
        if(data != null){
            if(data.user != null){
                if(data.user.profile != null){
                    let fname = data.user.profile.firstName
                    let lname = data.user.profile.lastName
                    let fullName = fname+" "+lname
                    return fullName
                }else{
                    return ""
                }
            }else{
                return ""
            }
        }else{
            return ""
        }
    }

    getSalesName = () => {
        let data = this.props.data
        if(data.salesUser){
            let sales = data.salesUser
            let profile = sales.profile
            let fn = profile.firstName
            let ln = profile.lastName
            let full = fn+" "+ln
            return full
        }else{
            return ""
        }
    }

    previewImage = (data) => {
        this.props.previewImage(data)
    }

    render(){

        const data = this.props.data
        let formApplicant = data.formApplicant
        let formId = formApplicant.formId
        // let hospital = formApplicant.hospital
        // let nameHospital = hospital.name
        let nameHospital = formApplicant.hospital.name
        let contact = formApplicant.contactPerson
        let startDate = data.month+"/"+data.date+"/"+data.year
        let startTime = data.startTime
     
        let dataApplicant = data.userApplicant
        let profileAppicant = (dataApplicant) ? dataApplicant.userProfile : null
        let nameApplicant = (profileAppicant) ? profileAppicant.name : ""
        let emailApplicant = (profileAppicant) ? profileAppicant.email : ""
        let salesUser = formApplicant.salesUser
        let nameSales = salesUser.name

        let confirmStatus = data.flagStatus
        let idStatus = confirmStatus.id
        let completionFile = data.uploadCompletions
        let sizeCompletion = completionFile.length

        let reasonLeave = (data.confirmReason) ? data.confirmReason : (data.reasonLeave != "") ? data.reasonLeave : "";

        return(
            <tr>
                <td>
                    {
                        (nameApplicant == 0) 
                        ?
                            <div style={{textAlign: "center", color: "#CCC", fontSize: '12px'}}>Not Applicant Set</div> 
                        :
                            <>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <div className="pic-dt-td">
                                    {this.initialName(nameApplicant)}
                                </div>
                                <div>
                                    {nameApplicant}
                                    <div style={{fontSize: "11px", fontWeight: "bold"}}>{emailApplicant}</div>
                                </div>    
                               
                            </div>
                            </>
                    }
                </td>
                {/* <td style={{textAlign: "center"}}>
                    {
                        (applicant == "")
                        ? <div style={{textAlign: "center", color: "#CCC", fontSize: '12px'}}>Not Applicant Set</div> 
                        : email
                    }
                </td> */}
                <td valign="center" style={{textAlign: "center"}}>
                    {nameHospital}
                </td>
                {
                    (!isUserSales())
                    ?   <td valign="center" style={{textAlign: "center"}}>
                            {nameSales}<br/>
                        </td>
                    : ""
                }
                <td valign="center" style={{textAlign: "center"}}>{contact}</td>
                <td style={{textAlign: "center"}}>
                    {convertDate(startDate)} 
                    {
                        (startTime != "00:00")
                        ? <><br/>{startTime}</>
                        : ""
                    }
                </td>
                <td id="bs-sts-rw">
                    {
                        DS.statusFlag().map(dt => {
                            if(idStatus == dt.id){
                                return <div style={{color: dt.color, fontSize: "12px", borderRadius: "5px", fontWeight: "bold", display: "flex", alignItems: "center"}}>
                                    <img src={dt.icon} style={{width: "20px"}}/>&nbsp;&nbsp;{confirmStatus.name}
                                </div>
                            }
                        })
                    }
                </td>
                <td style={{textAlign: "center"}}>
                    {
                        (idStatus == "00000000-0000-0000-0000-100000000060" || idStatus == "00000000-0000-0000-0000-aa0000000030")
                        ?
                            (reasonLeave != "")
                            ?
                                <div id="bs-rsn-rw" style={{maxWidth: "120px", 
                                        fontSize: "11px", 
                                        fontWeight: "bold",
                                        padding: "5px", 
                                        border: "1px solid #CCC", 
                                        textAlign: "left", 
                                        margin: "auto", 
                                        borderRadius: "5px"}}>
                                    <img src={reasonIcon} style={{width: "15px", position: "absolute", marginLeft: "-15px", marginTop: "-10px"}}/>
                                {(reasonLeave != "") ? <span style={{color: "#000"}}>{reasonLeave}</span> : "" /*<span style={{color: "#CCC"}}>No reason</span>*/}
                                </div>
                            :   ""
                        :
                            (completionFile.length > 0)
                            ?   <a onClick={() => this.previewImage(completionFile)} style={{display: "flex", justifyContent: "center"}}>
                                    {/* <span style={{fontSize: "12px", color: "blue"}}>{completionFile[0].name}</span> */}
                                    <div style={{display: "flex"}}>
                                        <img style={{maxWidth: "40px", maxHeight: "25px", borderRadius: "5px"}} src={baseUrl+completionFile[0].url}/>
                                        {
                                            (sizeCompletion > 1)
                                            ?
                                                <div id="tip-sz-rpt-up" style={{border: "2px solid #CCC", 
                                                            background: "#FFF", 
                                                            fontSize: "11px",
                                                            marginLeft: "-5px",
                                                            marginTop: "-5px", 
                                                            height: "20px", 
                                                            width: "20px", 
                                                            display: "flex", 
                                                            alignItems: "center", 
                                                            borderRadius: "100%",
                                                            justifyContent: "center"}}
                                                >
                                                    {sizeCompletion - 1}+
                                                </div>
                                            :
                                                ""
                                        }
                                    </div>
                                </a>
                        :   ""
                    }
                </td>
                <td style={{textAlign: "center"}}>
                    <Link style={{textDecoration: "underline"}} to={"/dashboard/user_sales/"+formId}>
                        <img src={View} style={{width: "20px"}}/>
                    </Link>
                    {
                        (isManagerApplicant())
                        ? <>&nbsp;&nbsp;<a onClick={() => this.props.delete(formId)}><img src={Remove} style={{width: "20px"}}/></a></>
                        : ""
                    }
                </td>
            </tr>
        )
    }
}

const radio = <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="13" cy="13" r="12.5" fill="white" fill-opacity="0.06" stroke="#08A0F7"/>
            </svg>

const chevron = <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.625 1.4375L7.5 7.5625L1.375 1.4375" stroke="#717171" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                
export default row_web